import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function CitydobrodruzstviSliderS03() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek />
      <Carousel {...settings}>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/3hS7S2n/AAAABT9j-XMay482-LBRyqt-G68-Aay5-Mfh-Lzp-Xfanpm5a-X2-Qw-h-Zkd-KDJZ7c-XPX3-YLYe-I4o-N2-uh7-Ni9-Yx-LNy.jpg" loading="eager" />
              <b>1. Díky, Hanku</b>
              <p>Hank, unavený neustálou neúctou, opouští Fendrichovu posádku a jde pracovat pro Mary Sinclair.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/BC455rj/AAAABUf3-DAh-GIXIG33-Lmf7-Woi-T6p-TThs-IL8-G8-EFGEHFr-YB6-RM4k-YI1-Fyvm-NLp-Jx-HK0-Vwge8-Jj-Om-IUxw.jpg" loading="eager" />
              <b>2. Dívají se děti?</b>
              <p>Poručík Gracie Goodhart se připojí k programu výměny důstojníků a konkuruje Dukovi jako nejoblíbenějšímu policistovi ve městě.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/kgpB17p/AAAABZLfw1gv-Vx-B2yx5dg-S0-Iu-OFgykwi-Cw-L8-t-Y11-Q4-WVn3-L-AR4-LOgqsx-Zn-Oo-T6-Nl-PHVTlytk-Uc-WMr3.jpg" loading="eager" />
              <b>3. Vyšetřovatelka Shirley</b>
              <p>Moderátor zpráv Craig Woodman zmizí, Gabby Tocamera se zoufale obrátí na Shirley Keeper s prosbou o pomoc.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/yFnNNvF/AAAABZ9-Nv-Kcjz-VH8x-Hi-A5pz2-A-My-SNac1j-RXFe-JT6-Iqh-Ts-Oq-IKiv25r-Oidk-VAIg-Lkac5c-MMz-JUfl-NE6-O.jpg" loading="eager" />
              <b>4. Prosím a tesáky</b>
              <p>Když se městská rozvodná síť přetíží, Mistrovský architekt, Ave Empire musí zachránit situaci – pokud dokáže, že není upír.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/WgHS5Hy/AAAABa5-Sa-FTe-Se-T63355-IKd-GTg7v1-LBLY3ni-KDPe-Rr-Dt-Gu4yt-HLh-EP2-Qtb-Ns-Hq5-H4v-Fcy-Rrlga-Xv-API.jpg" loading="eager" />
              <b>5. Akce ve škole</b>
              <p>Když studentovi zmizí počítačové tablety, detektivové Grizzled a Partnur se jako student a učitel vydají v utajení, aby chytili zloděje.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/djt3J5T/AAAABcsj0-QPd-Yew-ZIhzu-XEOlfa-W67o-GWDBuh-K0-Pm-D5fy-LBo-eeo-D-9-Bx-Prdhg1-Zh-V3wm-E-J8qv-MNo-QB26n.jpg" loading="eager" />
              <b>6. Jsme jedničky</b>
              <p>Charitativní organizace Police vs Oheň se změní z dobromyslné na rozzlobenou poté, co je na poslední chvíli přiveden konkurenční Tread Octane.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/RYDPmtq/AAAABT2f2j-Kzy-H23-J7g-Ulu-JDg-ZAM0ty-NIm-TO4cfp-Ygc6d3-B1m-En-Xjm-FGv-Cup-IWf-Xcr-Xy3-M7tzh-ZVr-Mn0.jpg" loading="eager" />
              <b>7. Wylde Wex</b>
              <p>Po rozmrazení svého nejlepšího přítele, doktora Wylda, se musí doktor Wexler pomoci Wylde přizpůsobit tomuto modernímu světu o rok později.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/98f43RW/AAAABVt6-Uhgc-PEcg2ge-XKDd-MUZb-EKQK7-VM9-Is-Eak-Mz47vd-RZJo-IZONi-IPk-Eh-E0tj4-Hg-K2r0-Ly5g-ZZmab-V.jpg" loading="eager" />
              <b>8. Třaskavý podnik</b>
              <p>Daisy, unavená životem se svou panovačnou matkou, zahájí vlastní podnikání, aby si koupila továrnu na dynamit jako nový úkryt. </p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/F5srH9H/AAAABSJd8-N5o-Pi-Os1-BIv-Zi-BEyx-N2-YSLH4-C6-Bnle-ky-HJf-LGoa3-KTCKCM3-Vdd-W3bp-B52-RMo-Xufu-K3-O0-F.jpg" loading="eager" />
              <b>9. Přestavba města</b>
              <p>S pomocí obřího jeřábu Harla Hubbse je Ave Empire odhodlán umístit svou operu na dokonalé místo ve městě.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/VTJTgBs/AAAABTc53b-QSz-U5-Qvwqz-Ni3-BJg-RAtwy-YC5-VI5tf-Z-oa-Xp-Aq3-Bcr0mwwz-Ho-PIHPLl-G9-QXF40n-WVEO2-E9mgm.jpg" loading="eager" />
              <b>10. Světla! Kamera! Grizzled!</b>
              <p>Detektiv Sam Grizzled je najat jako konzultant ve filmu o akční bankovní loupeži, kterou zmařil v prvních policejních dnech.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/02rN5Pw/AAAABVg-Rya-J0-Ct0-Bas-AYEt-Re-UTMl-KMsbl-Igax-Yk-Guoba-EGm3kg-AXht0v-Nq8-BDp-Xc-jy-Le-YBPm-Ab-Oc9-C.jpg" loading="eager" />
              <b>11. Čas pro Dukea</b>
              <p>Když vyčerpaný vévoda Detain udělá ve službě netypickou chybu, dobrovolně si vezme svůj vůbec první den volna.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/k3cz45r/AAAABSQr-QSEntw-ZLPb-SQq4-T7d1al-TKC9l-WLCXl-ZWC-g-P5ra-Fef-UA1-Us-JZzf3-ZHM13-ZQify-Am-X6-NVm-2-KVo.jpg" loading="eager" />
              <b>12. Harpy Stubbs</b>
              <p>Mezi Poppy Starr, největší popovou hvězdou světa, a Harlem Hubbsem, skromným a ochotným údržbářem města, vznikne nečekané přátelství.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/nPCK94x/AAAABYSNUKn-oi7yo-TFc-U0-Sh-Cqi-WBuso-MEt0-Wd-BRKStbl-Qr-Oy-KCGIVsom-Lgek-Hn5-32-Eepz-Cq-KWh-Gt3-W2.jpg" loading="eager" />
              <b>13. Obrovská neohroženost</b>
              <p>Aby zachránila noviny, Maddy se stane neohroženou reportérkou Nomi D. Plume a využije svou anonymitu jako dítě k tomu, aby prolomila exkluzivní narážky.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/QjPtpX8/AAAABYli-ZG9ks4-UJVEof-D-lt-FEqh69a-Khjz-E4i-Oh-EKOp-ROHRTxsox-I6h-Du-Jb9801-G4idu03ycfxz-MOO6-Wo-QT.jpg" loading="eager" />
              <b>14. Zelené světlo</b>
              <p>Fendrich se pokouší ukrást high-tech zařízení přepravované do Eko-elektrárny. Wheeler pošle neochotného Allena, aby ho zastavil.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/19vVvGL/AAAABdl-Hj-Ei-ASZYv-PBw3-F8im-P8-Z-auirn4-Qk-E-U-r-Xka-T-j-HVOgt-Kc6-BOedqqi-Yz-L3-DPxnj-Yt4-Ako-G7.jpg" loading="eager" />
              <b>15. Má bezva teta Freya</b>
              <p>Freyina snaha bavit se se svým synovcem se vymkne kontrole, když se znovu spojí s vnitřně divokým dítětem, které se léta učila potlačovat.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/yffdxs7/AAAABRr-Vcmgzd-HWceqx-We-WZfzd-Uz-Jvb-Mc7-Cclo-00s-EOj5q-Fbhh-0p20l99-p80-WJFK7a2-ZCEy-KToxt-KPD70dp.jpg" loading="eager" />
              <b>16. Kráva skočila přes lžíci</b>
              <p>Westbrook W. Sleet sleduje tajemnou stojící krávu z nížin, o které se domnívá, že je volně ve městě – což by mohlo způsobit zmatek.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/9ywcSKp/AAAABb-AF0tsc-MYR-5-ZBf-YOgqdv-Xn-WJKk-l-USp-WBhgf-YON3q-Qg-PZSz-Tv-Zx7l-Php-LDCu-OKu-VOd7s-M49-EHq.jpg" loading="eager" />
              <b>17. Město kaskadérů</b>
              <p>Kaskadérský tým se objeví ve městě připravený oslnit, vidí každodenní dění ve městě a uvědomí si, že musí svou hru zlepšit.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/SVpq6Qj/AAAABXh-ZPz7-ONGUWsyp-Bz-V7z-Ha-UTIk-EBWm-NBD5-Wc-KBV23h7sb-FHHGnm-ALnt-Bl-Qe-Egt-Lwx-ZJ1-F4-GTDSPRA.jpg" loading="eager" />
              <b>18. O otcích a sekáčích</b>
              <p>Přísný a upjatý otec náčelníka Wheelera přichází do města s plány investovat do velké kaskadérské show, kterou Wheeler miluje.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/tDzvhDL/AAAABd-FAFgyl-OM6y86-Upl4-M7pg-All6n-Fdi-Zm1-F3sr7u8v-Pj-Zeq-Ecoz-Se3-UABQZBN8-Rjy-KALYQu-Kxyw-JRB-J.jpg" loading="eager" />
              <b>19. Kanadský vtípek Flekovi</b>
              <p>Když tajemný Prankster pošle starostovi Fleckovi e-mailem výhružku, že ho bude žertovat na veřejnosti, Carol požádá Duka, aby byl starostovým bodyguardem.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/7KLQTF3/AAAABXzmkk-HCQVVy-XZ9xj5-N2-thv4tbv-ZVdv-Mj1-Dzx-Jpl-AQo-CGheysa-rx-Gn-Gy-Zz07-T4s-GNkix5wxx0-E7i-AP.jpg" loading="eager" />
              <b>20. Lineárně vzhůru</b>
              <p>Maloměstské způsoby hasičského maršála Finna McCormacka se střetnou s chaotickým městem a rozhodne se, že v nové kaskadérské show je třeba provést bezpečnostní změny.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-21">
            <div className="btn">
              <img src="https://i.ibb.co/NFd1fKh/AAAABf-A-QDZHLw-AJs-o-NLIZFPg9h-v-D-MZv2-SL55zg-Re-F24-BGcs-GP9zzp-Ti-K8-TUfyrn-VKDw1o-Zsx-Tfm-OULi.jpg" loading="eager" />
              <b>21. Pomozte jim</b>
              <p>Billy a Maddy se s pomocí kaskadérského týmu připojí k dětské překážkové dráze po celém městě.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-3-epizoda-22">
            <div className="btn">
              <img src="https://i.ibb.co/P1rDxhv/AAAABb-Vh-J6-OVj0-Iq7-HBA5c-RPn-I8f-QBegb-Ncca-FKS-j-Xyc-U9-KLP-ag7-Uf-Tv-ANn-Bjo-Ra-F80-Zn4m-Cc-Qs.jpg" loading="eager" />
              <b>22. Přirozené prostředí</b>
              <p>Gabby ToCamera uvádí dokument o zeleném městě a nachází záhadu kolem zmizení milovaných delfínů.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default CitydobrodruzstviSliderS03;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -70px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -70px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
