import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function MonkieKidS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-0">
              <img src="https://i.ibb.co/LP0XwZh/w2fh66dimk29.jpg" loading="eager" />
              <b> 0. Zrození hrdiny</b>
              <p>Poté, co jej omylem ukradl jeho majiteli, je mladý chlapec poslán na cestu, aby vrátil kouzelnou hůl na její správné místo.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-1">
              <img src="https://i.ibb.co/3vR8Khd/5u96ezpydzof.jpg" loading="eager" />
              <b>1. Špatné počasí</b>
              <p>Red Son přebírá "Meteorologickou stanici" prestižní klub, který umožňuje svým členům ovládat počasí v různých částech města.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-2">
              <img src="https://i.ibb.co/rHNDx5T/yyir291zttxt.jpg" loading="eager" />
              <b>2. Duplikace</b>
              <p>MK se rozhodne vytvořit klony aby mohl zůstat doma a hrát videohry. Ale MK ve skutečnosti neví jak využít své síly, takže klony způsobí spoušť.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-3">
              <img src="https://i.ibb.co/swh6krJ/02nw8deehm9w.jpg" loading="eager" />
              <b>3. Příchod domů</b>
              <p>Když Mei, člen Dračí rodiny a MKův hlídač domu, DBKovi přisluhovači se pokusí infiltrovat dům a ukrást legendární Dračí čepel.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-4">
              <img src="https://i.ibb.co/6rzm7qK/yz47ieat68c8.jpg" loading="eager" />
              <b>4. Nudle nebo život</b>
              <p>Když je Pigsy zajat pavoučí královnou jako rukojmí, Sandy, MK, Mei a Mo musí se svou závodní lodí sjet do kanalizace a osvobodit své přátele.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-5">
              <img src="https://i.ibb.co/XsqPf3J/99rwfcaxhc65.jpg" loading="eager" />
              <b>5. Tykev</b>
              <p>MK se probouzí k dokonalému životu... PŘÍLIŠ dokonalému...ale uvědomí si že žije v iluzi způsobené tím, že je uvězněn v hanebném Calabashi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-6">
              <img src="https://i.ibb.co/301Bbz5/zoc81nqnvu45.jpg" loading="eager" />
              <b>6. Závod velké zdi</b>
              <p>Mei a MK vstupují do pouličního závodu který zajistí vítězi "nesmrtelnost", ale neshoda je vede k tomu že spolu soutěží.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-7">
              <img src="https://i.ibb.co/rxn4DW7/gf158tdgskaj.jpg" loading="eager" />
              <b>7. Nemožná roznáška</b>
              <p>Pigsy vyzývá MK, aby doručil objednávku ve stanoveném čase a MK prochází překážkami, které zpochybňují jeho soustředění aby dosáhl svého cíle.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-8">
              <img src="https://i.ibb.co/8rpQrPG/21bew53qci8p.jpg" loading="eager" />
              <b>8. Kostlivčí klíč</b>
              <p>MK drží klíč od prastaré brány kterou chce rodina DBK odemknout. Je to hra na kočku a myš, ve které se rodina snaží získat klíč od našich hrdinů</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-9">
              <img src="https://i.ibb.co/Rv7BT3n/6nl8uacs24zv.jpg" loading="eager" />
              <b>9. Makak</b>
              <p>Starý nepřítel Opičího krále se snaží vysát jeho síly z MK, aby porazil jeho legendárního úhlavního nepřítele.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-1-epizoda-10">
              <img src="https://i.ibb.co/3sXdrd0/e2gy9p11os2y.jpg" loading="eager" />
              <b>10. Konec je tady!</b>
              <p>Když MK zjistí že DBK převzal město, musí použít vše co se naučil aby porazil rodinu která mu způsobuje tolik problémů!</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default MonkieKidS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
