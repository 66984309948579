import styled from 'styled-components';
import { Helmet } from 'react-helmet';

function Aktualizace(props) {
  return (
    <Container>
      <Helmet>
        <title>Aktualizace | NCE+</title>
      </Helmet>
      <h4 className="aktu-header">NCE+ Aktualizace</h4>
      <Background>
        <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" />
      </Background>
      <DV>
        <div className="aktu-img">
          <div className="btnhome-aktu">
            <a href="/aktualizace-1.0">
              <img className="hovimg-aktu" src="https://i.ibb.co/TY9XB9V/image.png" />
            </a>
          </div>
          <h2 className="akt-cislo">Aktualizace 1.0 - 2.0</h2>
          <h4 className="akt-dat">28.07.2024</h4>
        </div>
      </DV>
    </Container>
  );
}

const DV = styled.main`
`;

const Container = styled.main`
  min-height: calc(100vh - 250px);
  padding: 0 calc(3.5vw + 5px);
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
    }

    @media (max-width: 768px) {
      height: 100vh;
      width: 100vw;
      background-size: cover;
      background-position: center;
    }
`;

export default Aktualizace;
