import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getDocs, query, collection, where, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import styled from 'styled-components';
import { useAuth } from './contexts/AuthContext';

// Role hierarchy
const roleHierarchy = {
    'Majitel': 1,
    'Admin': 2,
    'Moderátor': 3,
    'VIP': 4,
    'User': 5
};

const PridatRoli = ({ closeModal }) => {
    const { user } = useAuth(); // Ověřte, že 'user' je správně inicializován
    const inputRef = useRef(null);
    const [serverError, setServerError] = useState('');
    const [idValid, setIdValid] = useState(false);
    const [idTouched, setIdTouched] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [selectedRole, setSelectedRole] = useState(''); // Track selected role
    const [successMessage, setSuccessMessage] = useState(''); // New state for success message
    const [showSuccessModal, setShowSuccessModal] = useState(false); // New state to control success modal visibility

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Auto-focus when component mounts
        }

        // Načtení role aktuálně přihlášeného uživatele
        const fetchUserRole = async () => {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUserRole(userDocSnap.data().role);
                } else {
                    setServerError('Uživatel nebyl nalezen.');
                }
            } catch (error) {
                console.error('Chyba při načítání role uživatele:', error);
                setServerError('Došlo k chybě při načítání role uživatele.');
            }
        };

        fetchUserRole();
    }, [user.uid]);

    const formik = useFormik({
        initialValues: {
            id: ''
        },
        validationSchema: Yup.object({
            id: Yup.string().required('ID je povinné').length(5, 'ID musí mít přesně 5 číslic')
        }),
        onSubmit: async () => {
            if (!idValid || !selectedRole) {
                return;
            }

            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('ID', '==', formik.values.id));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    setServerError('Uživatel s tímto ID nebyl nalezen.');
                    return;
                }

                const userDocs = querySnapshot.docs;
                for (const userDoc of userDocs) {
                    const userUID = userDoc.id;
                    const userRef = doc(db, 'users', userUID);
                    const targetUserData = (await getDoc(userRef)).data();
                    const targetUserRole = targetUserData.role;

                    // Prověřte, zda má aktuálně přihlášený uživatel dostatečná práva a zda nemění svou vlastní roli
                    if (userUID === user.uid) {
                        setServerError('Nemůžete měnit svou vlastní roli.');
                        return;
                    }

                    if (roleHierarchy[userRole] >= roleHierarchy[targetUserRole]) {
                        setServerError('Nemáte oprávnění měnit roli uživatele se stejnou nebo vyšší rolí.');
                        return;
                    }

                    await updateDoc(userRef, {
                        role: selectedRole
                    });

                    // Nastavit úspěšnou zprávu a zobrazit modal
                    setSuccessMessage(`Role ${selectedRole} úspěšně přidána pro ID ${formik.values.id}`);
                    setShowSuccessModal(true);
                }
            } catch (error) {
                console.error('Chyba při aktualizaci role:', error);
                setServerError('Došlo k chybě při aktualizaci role.');
            }
        }
    });

    const checkId = async () => {
        try {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('ID', '==', formik.values.id));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                setIdValid(true);
                setServerError(''); // Clear previous error message
            } else {
                setIdValid(false);
                setServerError('Toto ID není zaregistrováno v databázi.');
            }
        } catch (error) {
            console.error('Chyba při ověřování ID:', error);
            setIdValid(false);
            setServerError(`Došlo k chybě při ověřování ID: ${error.message}`);
        }
    };

    useEffect(() => {
        if (idTouched && formik.values.id.length === 5) {
            checkId();
        }
    }, [idTouched, formik.values.id]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setIdTouched(true);
        }
    };

    const handleRoleSelection = (role) => {
        setSelectedRole(role);
    };

    // Function to handle focus and make sure the field is usable
    const handleFocus = () => {
        if (!formik.values.id) {
            setIdTouched(true); // Trigger validation if the field is focused
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,5}$/.test(value)) { // Allow only numbers and up to 5 digits
            formik.setFieldValue('id', value);
            if (value.length === 5) {
                setIdTouched(true);
            } else {
                setIdTouched(false);
                setIdValid(false);
            }
        }
    };

    const handleSuccessModalClose = () => {
        setShowSuccessModal(false);
        closeModal(); // Close the main modal after the success popup is closed
    };

    return (
        <>
            <ModalContent>
                <ModalTitle>PŘIDAT ROLI</ModalTitle>
                <form onSubmit={formik.handleSubmit}>
                    <ModalInput 
                        type="text"
                        value={formik.values.id}
                        placeholder="Zadejte ID uživatele"
                        autoComplete="off"
                        autoCorrect="off"
                        ref={inputRef}
                        onChange={handleInputChange} // Handle input change
                        onBlur={() => setIdTouched(true)}
                        onFocus={handleFocus} // Add onFocus handler
                        onKeyDown={handleKeyPress}
                        style={{ borderColor: idValid ? '#2ecc71' : '#ccc' }}
                    />
                    {formik.touched.id && formik.errors.id ? (
                        <ErrorText>{formik.errors.id}</ErrorText>
                    ) : idValid ? (
                        <SuccessText>ID je zaregistrováno.</SuccessText>
                    ) : null}
                    {serverError && !idValid && (
                        <ErrorText>{serverError}</ErrorText>
                    )}
                    <RoleButtons>
                        <RoleButton
                            type="button" // Change to "button" to prevent form submission
                            onClick={() => handleRoleSelection('VIP')}
                            active={selectedRole === 'VIP'}
                            disabled={!idValid}
                        >
                            VIP
                        </RoleButton>
                        <RoleButton
                            type="button" // Change to "button" to prevent form submission
                            onClick={() => handleRoleSelection('User')}
                            active={selectedRole === 'User'}
                            disabled={!idValid}
                        >
                            Uživatel
                        </RoleButton>
                        <RoleButton
                            type="button" // Change to "button" to prevent form submission
                            onClick={() => handleRoleSelection('Moderátor')}
                            active={selectedRole === 'Moderátor'}
                            disabled={!idValid}
                        >
                            Moderátor
                        </RoleButton>
                    </RoleButtons>
                    {serverError && idValid && (
                        <ErrorText>{serverError}</ErrorText>
                    )}
                    <ModalButtons>
                        <ModalButton type="submit" disabled={!idValid || !selectedRole}>Uložit</ModalButton>
                        <ModalButton type="button" onClick={closeModal}>Zrušit</ModalButton>
                    </ModalButtons>
                </form>
            </ModalContent>

            {showSuccessModal && (
                <SuccessModal>
                    <SuccessMessage>{successMessage}</SuccessMessage>
                    <SuccessButton onClick={handleSuccessModalClose}>Zavřít</SuccessButton>
                </SuccessModal>
            )}
        </>
    );
};

// Stylované komponenty
const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px; /* Pevná šířka */
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    color: white;
`;

const ModalInput = styled.input`
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    &:focus {
        border-color: #2ecc71; /* Zelený okraj při zaměření */
    }
`;

const RoleButtons = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 10px;
    margin: 10px 0;
`;

const RoleButton = styled.button`
    background: ${props => props.active ? '#2ecc71' : '#fff'};
    border: 1px solid #ccc;
    color: ${props => props.active ? '#fff' : '#333'};
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: ${props => props.disabled ? '#ccc' : '#2ecc71'};
        color: ${props => props.disabled ? '#666' : '#fff'};
    }

    &:disabled {
        background: #ccc;
        color: #666;
        cursor: not-allowed;
    }
`;

const ErrorText = styled.p`
    color: #e74c3c;
    margin: 4px 0;
    font-size: 14px;
    text-align: center;
`;

const SuccessText = styled.p`
    color: #2ecc71;
    margin: 4px 0;
    font-size: 14px;
    text-align: center;
`;

const ModalButtons = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
`;

const ModalButton = styled.button`
    background: #e50914;
    border: none;
    color: #fff;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: #f40612;
    }

    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }
`;

const SuccessModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #202021;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 300px;
    text-align: center;
    z-index: 1000;
`;

const SuccessMessage = styled.p`
    margin-bottom: 20px;
    font-size: 16px;
    color: #2ecc71;
`;

const SuccessButton = styled.button`
    background: #2ecc71;
    border: none;
    color: #fff;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: #27ae60;
    }
`;

export default PridatRoli;
