import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS08() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-1">
              <img src="https://i.ibb.co/GJg3GX5/AAAABS0-Icac1hv0ah4qnb5r8z-DNTMGYSv-XPI3qs-Equ-DIy4p-U4-Iz-LChpz9-Ura-ZM8-Dmt4fse-YS0-XFrj-Hg-Dpg-TS.jpg" loading="eager" />
              <b>1. Maska klamu</b>
              <p>Maska klamu se dostává do rukou nového padoucha a Lloyd svolává ninji ze všech stran.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-2">
              <img src="https://i.ibb.co/8B271Lf/AAAABSc-M8-Lf-Ly-Iu-XEE7-CZNmf-kgu4zfgb-PRQOw5ru4-J79v-ys0-Wx7gua-Nq6p1ga-Dn7l-FBO6-W3-Nuep-U8-m6-GG.jpg" loading="eager" />
              <b>2. Nefritová princezna</b>
              <p>Lloyd se spřátelí s odvážnou princeznou Harumi, ale po bombovém útoku synů Garmadonu se ztratí další relikvie a taky zmizí rodiče princezny.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-3">
              <img src="https://i.ibb.co/WsKRkBv/AAAABZKh5q-QTbf-AAy-INTs-I0-Y-0-OZ6-Hz-R88-Tc-Xjhu0aoo-AV5-Zy14-Pxi-S-TM4xois-A9o-XLSo-Lkzn-ADiqf-CF.jpg" loading="eager" />
              <b>3. Oni a drak</b>
              <p>Při pátraní po synech Garmadonu vyslechnou ninjové zajatého člena gangu a v přestrojení se vydají do karaoke baru.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-4">
              <img src="https://i.ibb.co/0Mfhk0X/AAAABZW63i9-G2-VDXJuf-M7wbq-n5-Ebc-u-CMSl-u-Z1cx-Vyt-UX7ep58vn-Hb-M5-Ts-UIT4-QYy3sl-NG9m-De-T8-O8i-K.jpg" loading="eager" />
              <b>4. Had Jaguár</b>
              <p>Zanea odvedou na tajnou základnu synů Garmadonu, kde se dozvídá o jejich záhadném vůdci. Lloyd mezitím odhalí, k čemu je vlastně celý gang.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-5">
              <img src="https://i.ibb.co/GMtGXYs/AAAABUA10-Sln-RODgggn-Q4m2co1-FTNVl-Pg-NUy-MMvmr-Rvf-Sd-My-VPec-NJbs0-Av7t-Dcgc-TR-SLR4-Eyzg-OQjnu-L.jpg" loading="eager" />
              <b>5. Bouře mrtvého muže</b>
              <p>Zane se utká jeden na jednoho s panem E. Ninjové se zatím vydají na cestu plnou překážek, aby našli třetí masku Oni.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-6">
              <img src="https://i.ibb.co/BsDT2Ts/AAAABXmnsc-X6-GGRi0s-A4-NIi-CTDQY-p-DLLud-Ey-N49i-COl-Ix-L1u-J17-VJ3yroy-UFQt-IDpuk-Vlq-Mu-Ogvlmm-SW.jpg" loading="eager" />
              <b>6. Tichý</b>
              <p>I když jsou po ztroskotání lodi každý jinde, snaží se ninjové dostat masku Oni dřív než synové Garmadonu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-7">
              <img src="https://i.ibb.co/k1qrtmv/AAAABV3-ZTHw3-Dcxfspf6-AMP-k-Jwj59k-Tm-XLla-Wd-GGUwh6-HVIZXU3-KL59q-VVfa-UDlif-Qp-Aj-You-BYzui-Z2-XA.jpg" loading="eager" />
              <b>7. Hra masek</b>
              <p>Harumi a Lloyd se při hledání třetí masky odvažují pořád dál do chrámu Oni. Záblesk minulosti odhalí pravou totožnost Tichého.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-8">
              <img src="https://i.ibb.co/5vwgPD2/AAAABQbonboxl0e-Ug-Aw-MRk-Z49bvhw-Fj-Wq1-SPp-CJXGYYxe-AB0-Ug-Cb-CRiyw-TQwv8-Si-XJxtgi-Cn90t-Idpxi-Kl.jpg" loading="eager" />
              <b>8. Hrůza přichází</b>
              <p>Lloyd se probouzí jako zajatec Tichého, který plánuje vzkřísit lorda Garmadona. K tomu má posloužit další rukojmí.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-9">
              <img src="https://i.ibb.co/dWpyGzw/AAAABdc-AVd-EMX8-B7-Ue-6xx-Jy-Lus-ECdtr-Fh-WABq-T-5-ZIAWK5arhg3a-YScwu-AZV-eisky-Am-AUGKeta-Tz4ug-WZ.jpg" loading="eager" />
              <b>9. Pravý potenciál</b>
              <p>Ninjové slaví na karaoke, dokud se nedozví, že Garmadon osvobodil Harumi z vězení.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-10">
              <img src="https://i.ibb.co/tLw4QFK/AAAABdnn-F0-IXImwxky2-Hk9-VMy-RELLa-F5i-G2j-TXHt-KJv-TBg76-CXKTT6-SAe-RKk0-Ze-j-JM1-T4-Uu-QUFVKNK5-V.jpg" loading="eager" />
              <b>10. Velký problém, malé Ninjago</b>
              <p>Mystake léčí zraněného Lloyda, jeho přežití ale vyjde draho. Garmadon oživí ničivého obra, aby předvedl svoje schopnosti.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS08;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }
  
    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
