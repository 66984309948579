import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

function VIPobsahlist() {

  return (
    <Container>
      <Helmet>
        <title>VIP Obsah | NCE+</title>
      </Helmet>
          <h4 className="serialy">VIP Obsah</h4>
          <Content>
            <Wrap>
              <a className="prevent-select" href="/ninjago-wyldfyre-navrat-do-divociny">
                <img className="hovimg" src="/images/Nahledovky/WYLDFYRE.jpg" alt="Wyldfyre Návrat do Divočiny"/>
              </a>
            </Wrap>
            <Wrap>
              <a href="/ninjago-snovy-tym">
                <img className="hovimg" src="/images/Nahledovky/NINJAGO-SNOVY-TYM.jpg" alt="Ninjago Snový Tým"/>
              </a>
            </Wrap>
          </Content>
    </Container>
  )
}

export default VIPobsahlist;

const Container = styled.div`
  font-size: 25px;
  padding: 0 42px;

  h4 {
    font-family: 'Noto Sans Lao', sans-serif;
    margin-bottom: 10px;
  }
`;

const Content = styled.div`
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
`;

const Wrap = styled.div`
  background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
  padding-top: 56.25%;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  outline: 0px solid transparent;

  img {
    inset: 0px;
    display: block;
    height: 100%;
    object-fit: fill;
    opacity: 1;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
  }

  &:hover {
    outline: 3.2px solid #C7C7C7;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
`;
