import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function StarwarsSerialyaFilmy() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Star Wars Seriály a Filmy</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-all-stars">
              <img src="/images/Nahledovky/ALL-STARS.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-all-stars-shorts">
              <img src="/images/Nahledovky/ALL-STARS-SHORTS.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-dobrodruzstvi-freemakeru">
              <img src="/images/Nahledovky/DOBRODRUZSTVI-FREEMAKERU.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-dobrodruzstvi-freemakeru-shorts">
              <img src="/images/Nahledovky/DOBRODRUZSTVI-FREEMAKERU-SHORTS.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-pribehy-z-yodovi-kroniky">
              <img src="/images/Nahledovky/PRIBEHY-Z-YODOVI-KRONIKY.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-historky-z-odboje">
              <img src="/images/Nahledovky/STAR-WARS-HISTORKY-Z-ODBOJE.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/star-wars-pribehy-droidu">
              <img src="/images/Nahledovky/PRIBEHY-DROIDU.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-star-wars-svatecni-special">
              <img src="https://i.ibb.co/vqBDJhj/SVATECNI-SPECIAL.png" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-star-wars-hruzostrasne-historky">
              <img src="https://i.ibb.co/vHh5MzD/HRUZOSTRASNE-HISTORKY.png" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-star-wars-letni-prazdniny">
              <img src="https://i.ibb.co/26jDzWW/LETNI-PRAZDNINY.png" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-star-wars-padawanska-hrozba">
              <img src="/images/Nahledovky/PADAWANSKA-HROZBA.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-star-wars-imperium-utoci">
              <img src="/images/Nahledovky/IMPERIUM-UTOCI.jpg" />
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default StarwarsSerialyaFilmy;

const Container = styled.div`
margin-top: 450px;
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }
b {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 3px;
   }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    }
`;
