import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function NinjagoKOLEKCE() {
  return (
    <Container>
      <div className="backgroundofdiv">
        <a href="/ninjago-dekodovani">
          <img className="NinjagoKOLEKCEDKD" src="https://i.ibb.co/KwrSf27/DKD1.png" />
        </a>
        <a href="/ninjago-draci-povstani">
          <img className="NinjagoKOLEKCEPOSTER" src="/images/Postery/NINJAGO-DP-POSTER.jpg" />
        </a>
        <a href="/ninjago-mistri-spinjitzu">
          <img className="NinjagoKOLEKCEPOSTER2" src="https://i.ibb.co/84w8CSH/NINJAGO-POSTER-1.png" />
        </a>
        <div className="NINDIV">
          <a href="/svet-ninjago">
            <button className="NINJAGOKOLEKCE">ZOBRAZIT SVĚT</button>
          </a>
        </div>
      </div>
    </Container>
  );
}

export default NinjagoKOLEKCE;

const Container = styled.div`
margin-top: 30px;
`;
