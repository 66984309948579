import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function SvetDCSlider2() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Objevuje se: Aquaman</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-batman-do-ligy">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial ">DC: Super hrdinové</div>
                <div className="svet-text">Batman&nbsp;do&nbsp;ligy</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/HCcmPxC/LEGO-DC-SUPER-HRDINOVE-BATMAN-DO-LIGY-NAHLEDOVKA.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-vs-legie-zkazy">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial ">DC: Liga spravedlivých</div>
                <div className="svet-text">Liga&nbsp;spravedlivých&nbsp;vs...</div>
                <div className="svet-minuty-dc">1 h 17 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/mTmWB1w/LIGA-SPRAVEDLIVYCH-VS-LEGIE-ZKAZY.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-vesmirny-souboj">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial ">DC: Liga spravedlivých</div>
                <div className="svet-text">Vesmírný&nbsp;souboj&nbsp;</div>
                <div className="svet-minuty-dc">1 h 18 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/12ypmHG/LIGA-SPRAVEDLIVYCH-VESMIRNY-SOUBOJ.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-flash">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial ">DC: Super hrdinové</div>
                <div className="svet-text">Flash</div>
                <div className="svet-minuty-dc">1 h 18 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/FxtWp3x/DC-SH-FLASH.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-aquaman">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">DC: Super hrdinové</div>
                <div className="svet-text">Aquaman</div>
                <div className="svet-minuty-dc">1 h 18 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/KD6YbKV/DC-SUPER-HEROES-AQUA.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default SvetDCSlider2;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

   a {
    text-decoration:none;
 }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        opacity 1;
    }

    &:hover {
        img {
            opacity 0.4;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
`;
