import styled from 'styled-components';

function Aktulizacejednanula(props) {
  return (
    <Container>
      <h4 className="aktu-header">1.0</h4>
      <Background>
        <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" />
      </Background>
      <DV>
        <div className="aktu-img">
          <div className="btnhome-aktu">
            <h2 className="h2-aktu">Novinky</h2>
            <h4 className="h4-aktu">
              · Nový přehrávač.
              <br />
              · Nově předěleané domovská, seriálova, filmová a od nce+ stránka.
              <br />
              · Nové funkce. např: ztmavení horejšku když sjedete dolů.
              <br />
              · Nové seriály a filmy.
              <br />
              · Nové ikonky např: šipky, ikony horního menu.
              <br />
              · Nejnovější zkušební funkce na 4 seriálech kde je i možnost předešlé a další epizody v přehrávači.
              <br />
              · Nově udělané sránky seriálů a pozměněné stránky filmů.
              <br />
              <br />
              Seznam:
              <br />
              Seriály:
              <br />
              Ninjago: Dekódování
              <br />
              Star Wars: Příběhy droidů
              <br />
              Atlantis
              <br />
              Hero factory
              <br />
              Bionicle
              <br />
              2 Řada / Star Wars: Dobrodružství Freemakerů
              <br />
              <br />
              Filmy
              <br />
              Všech 8 doposud nadabovaných DC filmů
              <br />
              Všechny 4 Bionicle filmy
              <br />
              <br />
              <br />
              Opravené chyby
              <br />
              · Opravené chyby u epizod a obrazkového přepínání na domovské stránce.
              <br />
              kdy se ukazovalo kliknutí na části kde by se klikat nemělo.
              <br />
              · Všechny odkazy na jiné stránky vás hodí na novou stránku.
              <br />
              · Opravena chyba kdy si přehrávač dělal co chtěl a byl třeba jen u nějakého zařízení moc velký nebo moc malý.
              <br />
              · Opraveno pár chyb na telefonu např: pozadí, dolní ikonky už jdou vidět i horní ikonka.
              <br />
              · Spousta dalších drobných chyb.
              <br />
              <br />
              <br />
              <strong>Poznámky k aktualizaci: 1.01</strong>
              <br />
              Opraveny drobnější chyby např: Zarovnání do středu ukázkového videa u Ninjago DKD
              <br />
              a dalších, opraveny šipky na *Další epizodu* a opraveno pár chyb na mobilním zařízení. Všechny videa opět fungují.
              <br />
              <br />
              <br />
              <strong>Poznámky k aktualizaci: 1.02</strong>
              <br />
              Opraveno pár chyb.
              <br />
              Nový vzhled u seriálu Atlantis a v kolonce seriály a filmy je pozadí tmavší.
            </h4>
          </div>
          <h4 className="aktu-header">1.1</h4>
          <h4 className="h4-aktu">
            · Upravený vzhled domovské stránky.
            <br />
            · Nový vzhled náhledovek k seriálům a rychlejší načítání
            <br />
            · Opraveno pár chyb.
            <br />
            · A další drobné změny
            <br />
            · Nové seriály a filmy
            <br />
            <br />
            Seznam:
            <br />
            Seriály:
            <br />
            Star Wars: All Stars
            <br />
            Star Wars: All Stars Shorts
            <br />
            Star Wars: Dobrodružství Freemakerů Shorts
            <br />
            DREAMZzz
            <br />
            Ninjago: Dračí povstání (3. Června)
            <br />
            <br />
            Filmy
            <br />
            Star Wars: Impérium útočí
            <br />
            Star Wars: Padawanská hrozba
          </h4>
          <h4 className="aktu-header">1.2 </h4>
          <h4 className="h4-aktu">
            · U každého seriálu je nyní podtrhnutá zvolená sezóna ať už jde o staré funkce nebo nové.
            <br />
            3 vývoj funkcí u City dobrodružství který je zřejmě už poslední a aplikuje se u všech seriálů v budoucnu jak u nových tak u starých.
            <br />
            · Opraveno pár chyb.
            <br />
            · A další drobné změny
            <br />
            · Nové seriály a filmy
            <br />
            <br />
            Seznam:
            <br />
            Seriály:
            <br />
            City Dobrodružství
            <br />
            <br />
            Filmy
            <br />
            Princezny: Dobrodružství na zámku
          </h4>
          <h4 className="aktu-header">1.3 </h4>
          <h4 className="h4-aktu">
            · Kompletní remake Ninjago Mistři Spinjitzu na nejnovější 3 vývoj funkcí
            <br />
            · Opraveno pár chyb.
            <br />
            · A další drobné změny
            <br />
            · Nové díly a řady
            <br />
            <br />
            Seznam:
            <br />
            Seriály:
            <br />
            City Dobrodružství (Sezóna 4)
            <br />
            Dreamzzz (Part 2)
            <br />
            Ninjago: Dračí povstání - Part 2 (12 Října)
            <br />
            <br />
            <br />
            <strong>Poznámky k aktualizaci: 1.3.1</strong>
            <br />
            · Kompletní remake Ninjago na nejnovější 3 vývoj funkcí
            <br />
            [PC] Opravena chyba kdy boční pravý slider kterým se jede dolů a nahoru neměl svůj vlastní vzhled, vzhled byl také změněn od předešlého
            <br />
            <br />
            <br />
            <strong>Poznámky k aktualizaci: 1.3.2</strong>
            <br />
            · Kompletní remake Legendy Chima na nejnovější 3 vývoj funkcí
            <br />
            2 Řada / Ninjago: Dračí povstání
            <br />
            <br />
            <br />
            <strong>Poznámky k aktualizaci: 1.3.3</strong>
            <br />
            · Kompletní remake Nexo Knights a Ninjago: Dekódování na nejnovější 3 vývoj funkcí
            <br />
            <br />
            <br />
            <h4 className="aktu-header">2.0 </h4>
          <h4 className="h4-aktu"/>
            · Kompletní remake všeho na 3 vývoj funkcí
            <br />
            · Systém přihlášení
            <br />
            · Loader na stránky
            <br />
            · Opravena spousta chyb (hlavně na hlavní stránce).
            <br />
            · Nové efekty.
            <br />
            · A další drobné změny
            <br />
            · Nové epizody
            <br />
            <br />
            Seznam:
            <br />
            Seriály:
            <br />
            Ninjago Dračí povstání řada 2 část 2 (cz titulky) - 8 epizod zatím pouze pro VIP
            <br />
            <br />  
          </h4>
        </div>
      </DV>
    </Container>
  );
}

const DV = styled.main`
`;

const Container = styled.main`
  min-height: calc(100vh - 250px);
  padding: 0 calc(3.5vw + 5px);
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
    }

    @media (max-width: 768px) {
      height: 100vh;
      width: 100vw;
      background-size: cover;
      background-position: center;
    }
`;

export default Aktulizacejednanula;
