import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

function Bioniclezrozenilegendy() {
  return (
    <Container>
      <Helmet><title>Bionicle: Zrození legendy | NCE+</title></Helmet>
      <Background>
        <img src="https://i.ibb.co/PQ0ZVzr/bionicle-4-pozadi.jpg" />
      </Background>
      <ImageTitle>
        <img src="https://i.ibb.co/KqL2Zt6/bionicle-4-logo.png" />
      </ImageTitle>
      <Controls>
        <PlayButton>
          <a href="/bionicle-zrozeni-legendy-film">
            <img src="https://i.ibb.co/r0yJhL4/play-icon-black.png" />
            <span>PŘEHRÁT</span>
          </a>
        </PlayButton>
        <Tooltip title="Film v HD" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
          <HD>
            <span>HD</span>
          </HD>
        </Tooltip>
        <DABING>
          <Tooltip title="Dabing" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
            <img src="https://i.ibb.co/TqkqFsF/DUB.png" loading="eager" />
          </Tooltip>
        </DABING>
      </Controls>
      <SubTitle>
        <zanry>2009 | Akční / Dobrodružný / Fantasy | 1 h 17 min</zanry>
      </SubTitle>
      <Description>
        <zanry>
          Na ostrově Okoto se skrývají starodávné masky síly. Zlí nepřátelé
          <br />
          se snaží masky ukrást a ochránci se jim již sami nemohou ubránit.
        </zanry>
      </Description>
    </Container>
  );
}

export default Bioniclezrozenilegendy;

const Container = styled.div`
    min-height: calc(100vh - 70px);
    padding: 0 calc(3.5vw + 5px);
    position: relative;   
`;

const Background = styled.div`
@media (max-width: 768px) {
    background-size: cover;
  }
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    @media (max-width: 768px) {
        height: 100vh;
        width: 100vw;
        background-size: cover;
        background-position: center;
      }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
    }

`;

const ImageTitle = styled.div`
  align-items: flex-end;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin: 0px auto;
  height: 20vw;
  min-height: 170px;
  width: 100%;
  img {
    margin-bottom: -5px;
    margin-left: -50px;
    @media (max-width: 768px) {
        margin-left: 0px;
      }
    max-width: 600px;
    min-width: 200px;
    width: 35vw;
  }
`;

const Controls = styled.div`
    display: flex;
    align-items: center;

`;

const PlayButton = styled.button`
@media (max-width: 768px) {
  height:44px;
  font-size: 11px;
  padding: 0 0px;
  margin-left: -10px;
}

font-family: 'Kanit', sans-serif;
    border-radius: 4px;
    font-size: 18px;
    padding: 0 10px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    height:56px;
    background: rgb(249, 249, 249);
    border: none;
    letter-spacing: 0.3px;
    justify-content: flex-end;
    flex-flow: row nowrap;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    bottom 55;

    a {
    text-decoration:none;
    display: flex;
    align-items: center;
    padding: 0 12px;
    }

    span {
      padding: 0 10px;
      color: rgb(0, 0, 0);
    }

    &:hover {
        background: rgb(198, 198, 198);
        transition: all 0.3s ease-in-out 0s;
    }
`;

const SubTitle = styled.div`
    margin-left: 20px;
    color: rgb(249, 249, 249);
    font-size: 15px;
    min-height: 20px;
    margin-top: 26px;
`;

const Description = styled.div`
    margin-left: 20px;
    line-height: 1.4;
    color: rgb(249, 249, 249);
    font-size: 20px;
    margin-top: 16px;
`;

const HD = styled.button`
    margin-left: 14px;
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15%;
    border: 1px solid white;
    background-color: rgba(51, 46, 49, 0.67);
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;

    span {
        font-size: 20px;
        font-family: 'Russo One', sans-serif;
        color: white;
    }

    &:hover {
      transition: background-color 0.3s ease;
      background-color: rgb(0, 0, 0);
  }
`;

const DABING = styled.button`
    margin-left: 12px;
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15%;
    border: 1px solid white;
    background-color: rgba(51, 46, 49, 0.67);
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;

    img {
    width: 32px;
    }

    span {
        font-size: 20px;
        font-family: 'Russo One', sans-serif;
        color: white;
    }

    &:hover {
      transition: background-color 0.3s ease;
      background-color: rgb(0, 0, 0);
  }
`;
