import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function MonkieKidS02() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-0">
              <img src="https://i.ibb.co/z6T8T4R/dadsdada-MV5-BYm-Nl-Mzhm-OTEt-NDdi-Ni00-Y2-M4-LWE4-NTMt-NDhk-MDBl-M2-Vk-OTdi-Xk-Ey-Xk-Fqc-Gde-QXVy-M.jpg" loading="eager" />
              <b>0. Pomsta Pavoučí královny</b>
              <p>Pavoučí královna se spojí s tajemným, temným padouchem, aby sbíral mocné artefakty, k tomu, aby porazil MK a jeho legendárního učitele Opičího krále.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-1">
              <img src="https://i.ibb.co/GJdRhYN/bhdilcynem62.jpg" loading="eager" />
              <b>1. Spávek</b>
              <p>Monkie Kid bojuje s novou hrou, kterou vytvořil jeden z nohsledů Pavoučí královny a která vyřadí každého, kdo ji hraje.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-2">
              <img src="https://i.ibb.co/zr5wqPN/k8zbh9n401vu.jpg" loading="eager" />
              <b>2. Knedlíčková katastrofa</b>
              <p>Monkie Kidovi zavolá opičí král, který omylem srazil nebeský knedlík, který by mohl zničit vše na Zemi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-3">
              <img src="https://i.ibb.co/txMKDtr/ur94w9ll1gur.jpg" loading="eager" />
              <b>3. Pingpongová panika</b>
              <p>Monkie Kid pomáhá Pigsymu vymanit se ze závislosti na ping-pongu a Mo je nucen zastavit hromadu ovocných miminek v kleci před šílenstvím.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-4">
              <img src="https://i.ibb.co/LJg0NsN/j267bx59f607.jpg" loading="eager" />
              <b>4. Sladká tradice</b>
              <p>Obchody Speedy Panda jsou všude a kazí životy MK a Pigsy.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-5">
              <img src="https://i.ibb.co/cX0hncw/tsowre2nd6gv.jpg" loading="eager" />
              <b>5. Muší váha</b>
              <p>Monkie Kid se chce naučit nové schopnosti, jen aby se náhodou zmenšil a spadl stokou přímo zpět do doupěte pavoučí královny.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-6">
              <img src="https://i.ibb.co/bsfhQmQ/blo980epkord.jpg" loading="eager" />
              <b>6. Vzhůru do hry</b>
              <p>Pod tlakem démona Lady Bone a ze strachu, že to někomu přizná, Monkie Kid používá VR videohru, aby se naučil být lepším hrdinou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-7">
              <img src="https://i.ibb.co/Dt5JnDh/i402qyvh9741.jpg" loading="eager" />
              <b>7. Hra stínů</b>
              <p>MK a jeho přátelé navštěvují hru, kterou tajně provozuje Macaque, která se zdá být spojena s opičím králem a blízkým přátelstvím Macaque.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-8">
              <img src="https://i.ibb.co/RyHKkgw/fdm38wwvikda.jpg" loading="eager" />
              <b>8. Jako chytat list</b>
              <p>Poté co se Monkie Kid zúčastní prokleté soutěže v pojídání masových kuliček, dostane ošklivý případ škytavky a začne způsobovat obří zemětřesení.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-9">
              <img src="https://i.ibb.co/GQ4M0Dx/240xpyvb3ub5.jpg" loading="eager" />
              <b>9. 72 transformací</b>
              <p>Pavoučí královna a její nohsledi se pokoušejí přestavět pavoučího mecha, který zničil město ve snaze porazit démona Lady Bone.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-2-epizoda-10">
              <img src="https://i.ibb.co/WV8fxDc/75gcd16ekrmo.jpg" loading="eager" />
              <b>10. Tohle je konec!</b>
              <p>Lady Bone Demon vytvoří mocného kostěného mecha, kterého použije k útoku na MK, ale mohl by překvapivý návrat opičího krále zachránit MK?</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default MonkieKidS02;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
