// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';

// Definice základního reduceru
const initialState = {
  // Zde můžete definovat počáteční stav vaší aplikace
};

const rootReducer = (state = initialState, action) => {
  // Zde můžete implementovat logiku pro změnu stavu na základě akcí
  return state;
};

// Vytvoření obchodu s konfigurací
const store = configureStore({
  reducer: rootReducer,
  // Pokud máte další middleware, můžete je přidat zde
  // např. middleware: [...getDefaultMiddleware()],
});

export default store;
