import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function SvetStarwarsSlider1() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Star Wars: Období klonových válek</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/star-wars-pribehy-z-yodovi-kroniky-sezona-1-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw ">Star Wars: Příběhy z Yodovy kroniky</div>
                <div className="svet-text">Ř1D1&nbsp;Skrytý&nbsp;klon</div>
                <div className="svet-minuty">23 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/VVfMyQx/SWS01E01.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-pribehy-z-yodovi-kroniky-sezona-1-epizoda-2">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw ">Star Wars: Příběhy z Yodovy kroniky</div>
                <div className="svet-text">Ř1D2&nbsp;Hrozba&nbsp;Sithů</div>
                <div className="svet-minuty">23 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/VT91TBm/SWS01E02.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-pribehy-z-yodovi-kroniky-sezona-1-epizoda-3">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw ">Star Wars: Příběhy z Yodovy kroniky</div>
                <div className="svet-text">Ř1D3&nbsp;Jediové&nbsp;útočí</div>
                <div className="svet-minuty">23 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/S3B3yqp/SWS01E03.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-pribehy-droidu-sezona-1-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial ">Star Wars: Příběhy Droidů</div>
                <div className="svet-text">Ř1D1&nbsp;Odlet&nbsp;z&nbsp;Endoru</div>
                <div className="svet-minuty">23 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/9H97V2Z/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-pribehy-droidu-sezona-1-epizoda-2">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial ">Star Wars: Příběhy Droidů</div>
                <div className="svet-text">Ř1D2&nbsp;Krize&nbsp;na&nbsp;Corusa...</div>
                <div className="svet-minuty">23 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/r5TFwr5/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default SvetStarwarsSlider1;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

   a {
    text-decoration:none;
 }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
    border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        opacity 1;
    }

    &:hover {
        img {
            opacity 0.4;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
`;
