import React from 'react';
import { Helmet } from 'react-helmet';

function LIGASPRAVEDLIVYCHVESMIRNYSOUBOJFILM() {
  return (
    <div className="container-placer">
      <Helmet>
        <title>NCE+ | Přehrávač</title>
        <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="embed-container">
        <iframe src="https://filemoon.sx/e/y20ffeo6353i/LEGO_DC_Super_hrdinov__Vesm_rn__souboj" frameBorder="0" allowFullScreen="true" />
      </div>
    </div>
  );
}

export default LIGASPRAVEDLIVYCHVESMIRNYSOUBOJFILM;
