import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NexoKnightsSliderS04() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-1">
              <img src="https://i.ibb.co/VxkFf5f/S04E01.webp" loading="eager" />
              <b>1. Víkend u Halbertů</b>
              <p>Nálada klesá, a tak se král rozhodne uspořádat večírek. Mezitím Jestro zaútočí na Fortrex.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-2">
              <img src="https://i.ibb.co/HTTX4jG/S04E02.webp" loading="eager" />
              <b>2. Šedý rytíř</b>
              <p>Monstrox zasáhne bleskem Claye a promění ho v kamenného bojového rytíře. Ale použije Clay své síly za dobro nebo za zlo?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-3">
              <img src="https://i.ibb.co/xjRWfcg/S04E03.webp" loading="eager" />
              <b>3. Dobro, zlo a lakomec</b>
              <p>Clay se probouzí v chudém městečku, které doslova nic nemá. Pamatuje si Clay, kdo je a za co bojuje?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-4">
              <img src="https://i.ibb.co/6Hy0cBc/S04E04.webp" loading="eager" />
              <b>4. V královských tajných službách</b>
              <p>Nexo Knights chtějí použít Claye k infiltraci do nepřátelského tábora, ten se ale musí naučit, jak být dobrým špiónem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-5">
              <img src="https://i.ibb.co/5xKCgt5/S04E05.webp" loading="eager" />
              <b>5. Cizinec v Halpách </b>
              <p>Jestro s Monstroxem staví novou kamennou armádu z obřích stavebních bloků, aby rozdrtila Knighton.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-6">
              <img src="https://i.ibb.co/WVZ52hP/S04E06.webp" loading="eager" />
              <b>6. Krakenskul</b>
              <p>Jorah Tightwad hledá zlato, ale najde starověkého lorda Krakenskulla pohřbeného s armádou, které hledá Monstrox.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-7">
              <img src="https://i.ibb.co/KbY9Ww1/S04E07.webp" loading="eager" />
              <b>7. Srdce z kamene</b>
              <p>Clay Moorington už má dost Monstroxe, Jestra a jejich Zakázaných sil a rozhodne se, že se jim postaví do boje.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-8">
              <img src="https://i.ibb.co/R3NVZWM/S04E08.webp" loading="eager" />
              <b>8. Mezi mlýnskými kameny</b>
              <p>Jestro a Oblak Monstrox potřebují pouze Zakázanou sílu absolutní zkázy, aby odhalili, co se skrývá v hoře Thunderstrox.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-9">
              <img src="https://i.ibb.co/wJgqg09/S04E09.webp" loading="eager" />
              <b>9. Colosův pád</b>
              <p>Obří kamenný kolos pochoduje Knightonem. Clay si musí zvolit jestli se postaví na stranu rodiny nebo přátel.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-4-epizoda-10">
              <img src="https://i.ibb.co/3h7F6gf/S04E10.webp" loading="eager" />
              <b>10. Pád</b>
              <p>Kolos Monstrox nyní stojí před Knightonem. Bude stačit pomoc rytířů z akademie na poražení zla? </p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NexoKnightsSliderS04;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }


    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
