import styled from 'styled-components';
import React from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';

function NotFound() {
  return (
    <Container>
      <Helmet>
        <title>Stránka kterou hledáte nebyla nalezena.</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>404</h1>
      <Container2>
        <a href="/">
          <span className='cursorpointer'>Návrat Domů</span>
        </a>
      </Container2>
    </Container>
  );
}

const Container = styled.main`
@media (max-width: 768px) {
  font-size: 100px;
  margin-top: 220px;
}
font-family: 'Bebas Neue', cursive;
font-size: 150px;
text-align: center;
letter-spacing: 28px;
&:after {
  background: url("https://i.ibb.co/9YqQkdT/404BG.jpg") center center / cover
    no-repeat fixed;
  content: "";
  position: absolute;
  inset: 0px;
  opacity: 0.3;
  z-index: -1;
}
`;

const Container2 = styled.div`
@media (max-width: 768px) {
  margin-top: -175px;
}
margin-top: -250px;
align-items: center;
display: flex;
height: 100%;
justify-content: center;    
position: relative;

a {
  text-decoration:none;
  display: flex;
  padding: 0 12px;

  span {
    font-family: 'Bebas Neue', cursive;
    color: rgb(249, 249, 249);
    font-size: 32px;
    letter-spacing: 1.42px;
    line-height: 1.08;
    padding: 2px 0px;
    white-space: nowrap;
    position: relative;

    &:before {
      background-color: rgb(249, 249, 249);
      border-radius: 0px 0px 4px 4px;
      bottom: -6px;
      content: "";
      height: 2px;
      left: 0px;
      opacity: 0;
      position: absolute;
      right: 0px;
      transform-origin: left center;
      transform: scaleX(0);
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      visibility: hidden;
      width: auto;
    }
  }

  &:hover {
    span:before {
      transform: scaleX(1);
      visibility: visible;
      opacity: 1 !important;
    }
  }
}

/* @media (max-width: 768px) {
  display: none;
} */
`;

export default NotFound;
