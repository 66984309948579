import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import store from './app/store';
import { AuthProvider } from './contexts/AuthContext'; // Upravte cestu k AuthContext

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider> {/* Zabalte App do AuthProvider */}
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
