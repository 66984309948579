import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoSliderS16() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-1">
              <img src="https://i.ibb.co/brbvsr2/9tbij0x6qvtp.jpg" loading="eager" />
              <b>1. Sbohem, moře</b>
              <p>Rok po oběti Nyi se ninja znovu sejde, aby prozkoumal operaci pašování pomsty.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-2">
              <img src="https://i.ibb.co/wNcsFbT/Crystalized-The-Call-of-Home-5-52.png" loading="eager" />
              <b>2. Volání domova</b>
              <p>Poté co zachránila námořníka před utopením, Nya se setkala s dalším vodním elementálem a vymyslela plán, jak znovu získat svou lidskost.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-3">
              <img src="https://i.ibb.co/Wgmh1RL/x94iiq35ugjm.jpg" loading="eager" />
              <b>3. Tvar Nyi</b>
              <p>Ninjové vyšetřují prsten pašeráckých kamenů, ale nakonec mezi sebou bojují po ponižující záchraně Nových ninjů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-4">
              <img src="https://i.ibb.co/NV9ZJbv/kdgr3c2vvlin.jpg" loading="eager" />
              <b>4. Velký problém</b>
              <p>Když starosta odmítne osvobodit Aspheeru z věznice Kryptárium aby mohla zachránit Nyin život, ninjové se rozhodnou vzít věci do svých rukou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-5">
              <img src="https://i.ibb.co/ysdDMGf/9v76kn7fk168.jpg" loading="eager" />
              <b>5. Veřejný nepřítel číslo 1, 2, 3, 4 a 5</b>
              <p>Ninjové se vydávají na pronásledování aby získal hůl, ale nové setkání s Novými ninjy prozradí jejich identitu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-6">
              <img src="https://i.ibb.co/zNN6Bhf/Crystalized-A-Painful-Promise-4-34.png" loading="eager" />
              <b>6. Bolestný slib</b>
              <p>Ninjové bojují s novými ninjami zatímco Aspheera pomáhá Nye získat zpět svou lidskou podobu. Nový padouch rekrutuje Aspheeru pro jejich věc.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-7">
              <img src="https://i.ibb.co/Z1PYXnh/fa95xjz30moi.jpg" loading="eager" />
              <b>7. Ninjago City vs ninjové</b>
              <p>Ninjové jsou za své zločiny posláni do vězení, kde Lloyda navštíví tajemný maskovaný posel, který ho varuje před křišťálovým králem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-8">
              <img src="https://i.ibb.co/g74W1mK/zvqi73tf3180.jpg" loading="eager" />
              <b>8. Blues věznice Kryptárium</b>
              <p>Ninjové ve vězení narazí na starého nepřítele a snaží se zabránit jeho útěku zatímco Nya nachází důvod k naději.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-9">
              <img src="https://i.ibb.co/SRCCyTW/54uvw4fvje4j.jpg" loading="eager" />
              <b>9. Ohař McChlubil</b>
              <p>Dareth a Nya jako Samurai X osvobodí ninjy. Nejtvrdší maršál Ninjago, HoundDog McBrag je najat šíleným starostou města Ninjago aby znovu zajali ninjy.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-10">
              <img src="https://i.ibb.co/85BkHkt/3hgp97x7xntl.jpg" loading="eager" />
              <b>10. Zármutek je prospěšný</b>
              <p>Zpěvačka na cestě do Ninjago City pomáhá Kaiovi Zaneovi a Darethovi uniknout od HoundDog McBraga. Zane chápe že na všech emocích záleží.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-11">
              <img src="https://i.ibb.co/JzggBBw/Crystalized-The-Fifth-Villain-6-59.png" loading="eager" />
              <b>11. Pátý zlosyn</b>
              <p>Ninjové se znovu sejdou na čerpací stanici s Twitchym, uniknou HoundDog McBragovi a rozhodnou se infiltrovat do Rady křišťálového krále.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-12">
              <img src="https://i.ibb.co/PNv1jpf/z81sewup8zev.jpg" loading="eager" />
              <b>12. Rada Křišťálového krále</b>
              <p>Lloyd v přestrojení infiltruje radu křišťálového krále, kde zjistí kdo se skrývá za maskou kabuki.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-13">
              <img src="https://i.ibb.co/PNHqzmC/m23kso96xsd2.jpg" loading="eager" />
              <b>13. Zlověstný Stín</b>
              <p>Harumi vypráví Lloydovi svůj příběh o tom jak přišla sloužit křišťálovému králi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-14">
              <img src="https://i.ibb.co/DDHkhTG/svi3o892gag7.jpg" loading="eager" />
              <b>14. Pavoukův Nákres</b>
              <p>HoundDog McBrag zabaví Odměnu osudu zatímco ninjové jsou přepadeni Krystalovými pavouky, kteří vyhodí do vzduchu tunel metra. Lloyd ukazuje náznaky své Oni strany.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-15">
              <img src="https://i.ibb.co/p1xn98N/sijgx8yxyfak.jpg" loading="eager" />
              <b>15. Pád Kláštera</b>
              <p>Křišťálová rada zaútočí na klášter aby ukradla zbraně Spinjitzu. Mezitím zajatý ninja hledá cestu ven z tunelu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-16">
              <img src="https://i.ibb.co/Vmm1HGw/9957uf8oc840.jpg" loading="eager" />
              <b>16. Temnota Uvnitř</b>
              <p>Křišťálová rada provádí rituál aby krystalizoval zbraně Spinjitzu a probudil křišťálového krále, zatímco Nya osvobodí ninjy z tunelu metra.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-17">
              <img src="https://i.ibb.co/XbQWFPH/ax5k3gvfvsly.jpg" loading="eager" />
              <b>17. Příchod Krále</b>
              <p>Křišťálový král dává Křišťálové radě nové síly a vynáší chrám do vzduchu. Dává Lloydovi šanci přidat se na jeho stranu, ale Lloyd odmítne a uteče s Harumi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-18">
              <img src="https://i.ibb.co/NLvFPKT/4sewlr13t3r3.jpg" loading="eager" />
              <b>18. Návrat k Pravěkému Oku</b>
              <p>Lloyd a Harumi si prorazí cestu Pravěkým okem zatímco je Křišťálová rada hledá. Ninjové získávají svá vozidla ze zabavení a podaří se jim najít a zachránit Lloyda.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-19">
              <img src="https://i.ibb.co/rf20fmW/igwzuprdui2m.jpg" loading="eager" />
              <b>19. Křišťálová Katastrofa</b>
              <p>Ninjové se střetnou s Vengestone armádou jen proto aby jejich vozidla vysály krystaly. Lloyd a Wu vystopují varování před touto událostí, která je zavedou na Garmadonem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-20">
              <img src="https://i.ibb.co/R677Tn6/z2cqf1lenzy7.jpg" loading="eager" />
              <b>20. Kristokapradí</b>
              <p>Garmadon vypráví Lloydovi a Wuovi o své nedávné cestě za sebezdokonalováním, když se naučil soucitu péčí o rostlinu kterou pojmenoval Kristokapradí.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-21">
              <img src="https://i.ibb.co/Jk5d6mj/viqgpz5pu0hs.jpg" loading="eager" />
              <b>21. Lekce ve Zlobě</b>
              <p>Zatímco se Garmadon snaží naučit Lloyda jak ovládnout svou Oni ​​formu, Wu teoretizuje že existuje opačná forma draka a osloví Misako pro výzkum.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-22">
              <img src="https://i.ibb.co/sCBHKnk/hhjiu3oads4l.jpg" loading="eager" />
              <b>22. Stateční, ale bláhoví</b>
              <p>Ninjové projíždí Ninjago City ve svých nových vozidlech a střetávají se s Křišťálovými bojovníky. Mezitím Wu čelí křišťálovému králi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-23">
              <img src="https://i.ibb.co/HB6CN1f/dvuxt916rbks.jpg" loading="eager" />
              <b>23. Čas Přestat!</b>
              <p>Sensei Wu padá padákem poté co byl odfouknut z Krystalového chrámu a je zachráněn Nelsonem a Antonií. Jay a Nya unikají z trosek Oděmeny osudu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-24">
              <img src="https://i.ibb.co/s9bjgM0/7xsyp46pvhq6.jpg" loading="eager" />
              <b>24. Návrat Ledového Krále</b>
              <p>Lloyd a Garmadon uniknou HoundDog McBragovi tím že jdou do stok, kde Lloyd zachrání McBraga před krystaly infikovanými Serpentýny.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-25">
              <img src="https://i.ibb.co/cL4DYRm/fb1yln6952f1.jpg" loading="eager" />
              <b>25. Bezpečné Útočiště</b>
              <p>Skylor zachrání Kaie a Colea před Pythorem a oni se ukryjí v arkádě kde narazí na Jakea. Mezitím Lloyd a Garmadon hledají své potenciální Serpentinské spojence.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-26">
              <img src="https://i.ibb.co/Wk5q13j/50tbjacm8tda.jpg" loading="eager" />
              <b>26. Slučitelný</b>
              <p>Kai a Skylor se spojí kvůli vaření pro uprchlíky. Ninjové znovu navážou komunikaci a rozhodnou se oslovit všechny své spojence o pomoc.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-27">
              <img src="https://i.ibb.co/ynwc2Ds/2om8gvwt6ya9.jpg" loading="eager" />
              <b>27. Volání o Pomoc</b>
              <p>Závodník č. 7 veze Colea přes Ninjago City aby pomohl civilistům a vyslal nouzový signál do Šintara. Mezitím Lloyd dává Garmadonovi šanci dokázat že se změnil.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-28">
              <img src="https://i.ibb.co/wRK3zyN/1vkx1vtm9mbp.jpg" loading="eager" />
              <b>28. Problémy s Důvěrou</b>
              <p>Ninjové se připravují na poslední útočiště proti křišťálové armádě když Lloyd a Garmadon plánují přepadnout křišťálového krále. Garmadon se však obává, že mu Lloyd nevěří.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-29">
              <img src="https://i.ibb.co/nQjYBFg/7l9abj6esrwr.jpg" loading="eager" />
              <b>29. Forma Draka</b>
              <p>Lloyd a Garmadon bojují s křišťálovým králem zatímco ninjové útočí na křišťálovou armádu se starými spojenci a novými silami.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-4-epizoda-30">
              <img src="https://i.ibb.co/JKNT2kh/oje60iubhs9o.jpg" loading="eager" />
              <b>30. Kořeny</b>
              <p>Ninjové ukážou svůj skutečný potenciál a provedou nesobecký čin aby zachránili Ninjago před křišťálovým králem.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoSliderS16;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
