import React from 'react';
import styled from 'styled-components';

function Bottom() {
  return (
    <body className="bodybot">
      <footer>
        <div className="footer-bottom">
          <a className='cursorpointer' href="https://discord.gg/dwRN6v9vz8" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/ZgyCYvp/discord-1.png" alt="Discord" loading="eager" />
          </a>
          <a className='cursorpointer' href="https://www.instagram.com/nce_plus" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/sV32QkR/instagram-1.png" alt="Instagram" loading="eager" />
          </a>
          <a className='cursorpointer' href="https://www.youtube.com/@NCEPLUS" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/GpHFpRM/4375133-logo-youtube-icon.png" alt="YouTube" loading="eager" />
          </a>
          <a className='cursorpointer' href="https://patreon.com/nceplus" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/zGK6q4N/patreon-logo-png-badge-7.webp" alt="Patreon" loading="eager" />
          </a>
          <p className='cursornone'>
            © 2024 NCE+ | Všechna práva vyhrazena
            <br />
            NinjagoCzech+ 2024&nbsp;Vytvořeno z ❤️ k LEGU
          </p>
        </div>
      </footer>
    </body>
  );
}

export default Bottom;
