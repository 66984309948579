import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS00() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-0-epizoda-1">
              <img src="https://i.ibb.co/JpLZFkT/grefgher.jpg" loading="eager" />
              <b>1. Cesta nindži a Zlatá zbraň</b>
              <p>Sourozenci kováři Kai a Nya se setkávají se záhadným starým mužem, mistrem Wu, který hledá jejich pomoc, aby pod ním vycvičili hrozbu jeho brzkého návratu bratra Garmadona.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-0-epizoda-2">
              <img src="https://i.ibb.co/KwpfjF0/tzjzjztjk.jpg" loading="eager" />
              <b>2. Král stínů a Zbraně osudu</b>
              <p>Nindžové úspěšně našli tři ze čtyř zlatých zbraní a zůstal jen meč ohně. Budou však muset být provedeny nebezpečné střety, aby byly zbraně mimo dosah Garmadona.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS00;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -62px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -62px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        right: 30%; 
        overflow: visible;

        @media (max-width: 768px) {
            right: 0px;
          }
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
