import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoSliderS12A13() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-1">
              <img src="https://i.ibb.co/CvR1qXK/AAAABa-Wm-X1-Jub29g-Mz-DTxtixr-Ad1u-Fpa-8h-EWe3b-Zu-Ve-Pn40-Rds-Ybsf8-Gm-Qip-XTu-WL-x-Iswj-JJNRGQp-Q.jpg" loading="eager" />
              <b>1. Kdo chce vstoupit do Svrchované říše?</b>
              <p>Nindžové při vyšetřování krádeže objeví legendární hru, která se nikdy nedočkala vydání. Ta ale zničehonic převezme kontrolu nad všemi ostatními hrami v Ninjagu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-2">
              <img src="https://i.ibb.co/cxgdHwm/AAAABZcv-R87-g-Py-Zh-f-NRwpd-W5-XNBAdp-J9-KWzg-YAZin-GPh-W3iab-Rv-ODf-VN2-Gs-D4y-KGUEd-Et-B1g-I55-QO.jpg" loading="eager" />
              <b>2. Dyerův ostrov</b>
              <p>Nindžům dojde, že Jayovo zmizení nějak souvisí s hrou Svrchovaná říše, a vyrazí proto na Dyerův ostrov. Tam si chtějí promluvit se záhadným autorem hry Miltonem Dyerem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-3">
              <img src="https://i.ibb.co/2tj7Wys/AAAABYf-Z1-TU2kzwd-GWw-JLPr57a-N3c-Ehn-L-f-Kmhr-V39-Jbg4-e7-NSe-L8-H9x57y1-45-Oc9p3-ZFTVVJZtpxb-JFTx.jpg" loading="eager" />
              <b>3. 13. úroveň</b>
              <p>Cyrus Borg odhalí velké tajemství o Svrchované říši. Při pokusu dostat se na třináctou úroveň se nindžové musí utkat s uprchlým Mechanikem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-4">
              <img src="https://i.ibb.co/p4wM1Mm/AAAABa-IHDg-QT-6-PIJRg7u-GYCr-Zi5y-H9-Uiw-Z7h-Ms-J8-LI12-BM2-Hj3-Uzb-Cbrjt-Pmpr-Xs-HL4lrk-Lmp-C1-Kws.jpg" loading="eager" />
              <b>4. Superstar Válející Jay</b>
              <p>Nindžové se ve Svrchované říši ocitli v neznámém prostředí, kde hledají Jaye, a přitom se musí vyhnout armádě Červených přileb.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-5">
              <img src="https://i.ibb.co/gRFJPSN/AAAABdha-BSac-I4rb-CYuh-DLro6-OVRIwu-SA0d-HEg-GYS86-Wx-Gq-Bark-Zw-XA8q-V-95y-BNKiu-LYb-y-NRH5fj-LOq.jpg" loading="eager" />
              <b>5. Já jsem Okino</b>
              <p>V jedné z herních zón prožívá samuraj Okino vážnou krizi a přestává si věřit. Pořád se mu totiž nedaří přivést svoje pány k vítězství.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-6">
              <img src="https://i.ibb.co/1JBz2sd/AAAABbo-YS0-W792-Mw-IMy-WM0rw-Is-Lk-B04-HHZWce-I4x-CCxk8wa-E-EB8-Iz-ZDtj-CLyx-KAogdv-xj-Ua-k-A45vb-A.jpg" loading="eager" />
              <b>6. Chyba</b>
              <p>Nindžové se musí zmocnit tří Klíč-tan, aby mohli zastavit Unagamiho. První z nich se skrývá v jednom z nejnebezpečnějších herních prostředí Svrchované říše.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-7">
              <img src="https://i.ibb.co/HzGgRXm/AAAABa-REkelz8-CCClgit-DIwe6-Gen7-TMAGxa-Z5xsjbhtf3-MOO4-w2u-Wza-Hw55n-GD4y-THbl4u0-EZW5-Hv92-Nluu-X.jpg" loading="eager" />
              <b>7. Útesy Hysterie</b>
              <p>Nindžové se snaží Okina rozveselit a dorazí k Útesům hysterie. Jak je ale mají zdolat?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-8">
              <img src="https://i.ibb.co/kqHbd77/AAAABZ4-Qpp-XTnuob-Dn-GR23pz-IJIf-YAf2m7m-EYn-LTKC5w4-MQ01-Kedvps1-Lo-UXx29-F9-A6h-A6-SDFn-Yai-PRpe.jpg" loading="eager" />
              <b>8. Bludiště červeného draka</b>
              <p>V herní zóně Terra Karana stojí samuraj a průvodce Okino před velkým rozhodnutím. Má pomoct nindžům, nebo poslechnout rozkazy svého mistra Unagamiho?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-9">
              <img src="https://i.ibb.co/kx8NHL3/AAAABc4-UMVOhg24m-HFh-VV6k-Sr-M1-L08-H4mm-G-Zx-S7-i-x-Fc-Ft-X5n-VTXl-d1-Hfal-RJh-Q5-Z-Sj-BAWJo-Ja9-K.jpg" loading="eager" />
              <b>9. Jeden krok vpřed a dva zpět</b>
              <p>Nindžové se dozví, že druhou Klíč-tanu získají jedině tak, že vyhrají závod v zóně Terra Technica. Jenže závodní auta si musí sehnat sami, a to nebude žádná hračka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-10">
              <img src="https://i.ibb.co/VD4wBFF/AAAABas0-QAn-MLBQon2g-Nm-M9-A2t-Hux-E8-BGe-Wtpz-Bsu-YJss2-Yg-Q7-U0-VNql400g-A9-Sodbi-A37-L1h-RP4-Fx.jpg" loading="eager" />
              <b>10. Závodník č. 7</b>
              <p>Nindžové se připravují na brutální závod na Dálnici pěti miliard. Lloyd se seznámí se závodníkem číslo sedm, který může být klíčem k vítězství.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-11">
              <img src="https://i.ibb.co/cCRD2zT/AAAABT7-I-HF8z39x-Bfa2-HABos-CTYD2fw-MIj-C5-J9y-WER785-Fz6-H91-JKt7k-MATXLdgh-Il-F7p-Qa-Yesxc-5f3o6.jpg" loading="eager" />
              <b>11. Dálnice pěti miliard</b>
              <p>Unagamiho vojáci zvaní Červené přilby konečně najdou tajné místo, kde se nachází Scottova garáž. Tam potom zaútočí na nindže.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-12">
              <img src="https://i.ibb.co/MhJKP37/AAAABUv-QDi-Hxf-Ldal-BZa79rh1-Tozs86eb-Df-W8bxljw-Xsv-NAx-B-Me-b-E3-Hqt27y-O6i2fw-ROPbh-OB0-Tl3-N2q.jpg" loading="eager" />
              <b>12. Zastav se, poklekni a vpřed</b>
              <p>Nindžové se ocitnou v chaotické herní zóně Terra Domina a nemají kvůli tomu ani čas oplakat padlé kamarády.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-13">
              <img src="https://i.ibb.co/tQxNwMj/AAAABai42-ONSz-Rj-Jd-J6aa-Qvli-PFy-MDKEG-Av55-Km-PYMVJ0-Mioy-A5zonepl-SMh-FVS-tjip-Pqs-Hiltjs-K9v5-B.jpg" loading="eager" />
              <b>13. Tajnosti v Ninjagu</b>
              <p>Zane s Pixal se po návratu do Ninjaga vypraví do temných ulic vypátrat Miltona Dyera. Zjistí ale, že všechno je tak trochu jinak.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-14">
              <img src="https://i.ibb.co/H4MyrKK/AAAABQ-BIiu-EY-u0-Pl-YXTo-Slq-Xyp-Nj-XYDfctev-Algy21-DMpm-ZOCYZz-XZp-F7yu2h-UPv-Az-FFTibl-Nvc-KAdl-V.jpg" loading="eager" />
              <b>14. Marnotratný otec</b>
              <p>Zane, kterého unesl Mechanik, se octne tváří v tvář s Unagamim. Pixal se chce od Miltona Dyera dozvědět, jak vypnout Svrchovanou říši.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-15">
              <img src="https://i.ibb.co/KL6jmpW/AAAABb-I-e-Odiuzy-Qv9-Ja1oad3h-Js-F0cm-E7p58-CO-9l-Af4-ildh-Nz-Mc-Ts-K-XO0-HF1vh-Jo-Npj-Zms14-Bo-MYR.jpg" loading="eager" />
              <b>15. Chrám bláznovství</b>
              <p>Jay se v závěrečném souboji musí sám postavit Unagamimu. Toho ale čeká ještě důležitější bitva, když se dozví, kdo ve skutečnosti je.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-16">
              <img src="https://i.ibb.co/PM07BNT/AAAABSfd-Ga6q9ffe-Tz-CJCRUVH-2-ITov8au6ysj9dn-Xik-Eau-As-IK0u-Z3frp-Rzj4g-Kcoqw-Rc-LQ0-Jyz0qo1an-Ljz.jpg" loading="eager" />
              <b>16. Konec hry</b>
              <p>Unagami pronikne do skutečného světa a začne terorizovat Ninjago. Jay, Zane a Pixal se ho snaží dostat k jeho stvořiteli, a zabránit tak katastrofě.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-17">
              <img src="https://i.ibb.co/x7y6XQr/AAAABSCz-F7-NPEjtls-Av-O7a-IVh-Zb8orpdg-HMa-JDj-GNMDZ7u-cjj-Ms-Jt-LSFXypi3-Zafo-YL7-Y48-HYDPKQfah-IX.jpg" loading="eager" />
              <b>17. Šintaro</b>
              <p>Nindžové vyrazí na dalekou cestu do království Šintaro na oslavu narozenin princezny Vanii a vezmou s sebou i mistra Wua.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-18">
              <img src="https://i.ibb.co/mqtpfN6/AAAABQs-Ydm-UGQK3-GSKi-Erbb-Hr-Y-b-EH30-T-ISYbw-RO8hum-Tr9c-EPSIBUZ9o-Nr76yr-Kt3-WRTQ0-Cq-Djinz4-JBA.jpg" loading="eager" />
              <b>18. Do temnoty</b>
              <p>Nindžové dorazí do Šintara, kde Cole s princeznou Vaniou řeší záhadného návštěvníka i tajný důl.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-19">
              <img src="https://i.ibb.co/gyWQx7G/AAAABRVYXMn9-Pz-Dob7-Qyc9t-Kh-T9-Nsm-Yx-O7-Br-MQg-XQf44-Xj-QM0gp-Ad-ARNKwy-HOR4h2et-HMBSb4-Jlwc-FHS5.jpg" loading="eager" />
              <b>19. Nejhorší záchrana vůbec</b>
              <p>Nindžové se pokusí zachránit Colea. Jeho spoluvězni se ale začnou handrkovat, přitáhnou tím pozornost Čaroděje lebek a nindžové taky skončí za mřížemi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-20">
              <img src="https://i.ibb.co/2hz5VD0/AAAABSx-A5llc-Tt-I1hnw-Y0-W-LHM0hoqfv-Mn-Kt-Bi-BJc-OLfll-HGl-Km4-TX7z-GUJk-Pocz-QRCr-USBGWGR7s-Ow-RV.jpg" loading="eager" />
              <b>20. Dvě čepele</b>
              <p>Jestli chtějí nindžové uprchnout před řetězy Čaroděje lebek, musí přesvědčit kmeny Munceů a Geklů, aby si začali důvěřovat a táhnout za jeden provaz.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-21">
              <img src="https://i.ibb.co/9y0KKqR/AAAABc8-KLvw-OV-tc-VGQ4e-IUt-Tmmo5hx-RYEe-KV2-Qeg3e3-WSScnv-UG6oi-Ch-Pg0-VT5h9b9m-Aylpv-CFkevx-G5-Va.jpg" loading="eager" />
              <b>21. Královna Munců</b>
              <p>Nya, Jay a Lloyd zůstanou sami a vydají se s mužem jménem Murt daleko do hor, kde se setkají s královnou Munců.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-22">
              <img src="https://i.ibb.co/gJ7772q/AAAABY2l8-Hsy-GGBR21-Vsz-ENZl22kn8ey-Pq-HBa-CIJRGIP7j-ZHYA-xne-Bn-c-RXMys8pkzfb-JJJ3e-W6y8-GDLh-C96i.jpg" loading="eager" />
              <b>22. Minův soud</b>
              <p>Kai a Zane narazí na skupinu Geklů. Ti je ale podezřívají ze spolupráce s Čarodějem lebek a odvedou je ke kancléři Gulčovi, který jim velí.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-23">
              <img src="https://i.ibb.co/k4TMwdw/AAAABd7yw-Zk-S1-L4-XITk8-AEk-Iuy91h-AZOj-M093-TI-sw-An-WJcn0-GNx-X5kpkaf8f-BDBqs-Oy-AH70hyx-U20-APxh.jpg" loading="eager" />
              <b>23. Čaroděj lebek</b>
              <p>Cole se musí spolehnout na svoje instinkty a správně se rozhodnout, aby se dostal ven a upozornil krále Vangelise na nebezpečného Čaroděje lebek.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-24">
              <img src="https://i.ibb.co/ypndS3p/AAAABTMhn-Nr-ULk-QP-q-YMZJ11k-kw7-Um-Yg9-S8s-Nn-TSMSQa-LSdm-CEt-V47-NIwz-NU84-I4x4-BFj-Vscd-q-T4bcf.jpg" loading="eager" />
              <b>24. Skutečný pád</b>
              <p>Princezna Vania se snaží chytit mistra Wu a Colea, kteří padají do nitra hory. Nemá na to ale dost silná křídla a pádu nezabrání.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-25">
              <img src="https://i.ibb.co/85LnL1H/AAAABWTMvn4-Kdltxrmm-PTPir-Wzqw-Ta-Si8urc-E-u-GYRti-Nu-HLMHMXqh27-Cjs9w-Bq-Hc-Gn-N2mj-ARzuw-Ult-Bzb.jpg" loading="eager" />
              <b>25. Párty ve vězení!</b>
              <p>Cole, Wu a princezna Vania přežili nebezpečný pád a uvnitř hory se spřátelí s nesourodou partou Ponížených.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-26">
              <img src="https://i.ibb.co/8MdH4YP/AAAABWr-Gm-Xk-Fpx-Y4-MQi-Lp-URNRwq-QUdgcvov-Jj-Au-F-P9-B88-S-yha7q-Yn-Ekaytzdxpzj5hy-Iv-Dw-YHVg-RKUp.jpg" loading="eager" />
              <b>26. Plazení ve vězení!</b>
              <p>Cole se spolu s Poníženými, princeznou Vaniou a mistrem Wu snaží dostat z Kamenného dna. Situace ale vypadá beznadějně.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-27">
              <img src="https://i.ibb.co/SfQksS0/AAAABa-U1-Bn-FJhx-HPw-Dn-Gg-FBSz3sp-J0-Rp-Nq-A3-DNHN7-LLNr50h-Tr-Ae-Gjy-LIhg-N6fn-Megfx-Ry-X6tyfps-I.jpg" loading="eager" />
              <b>27. Nositel žalu</b>
              <p>Nya a Kai se pokusí sjednotit kmeny Munceů a Geklů, aby porazili společného nepřítele Čaroděje lebek.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-28">
              <img src="https://i.ibb.co/Hnsw2ZL/AAAABR0-Sk6gj-WJbm-UT1csc-OHvb7gf2-PEj-Q1y-ESWpa-Ocg-Nl-Ogx-T4-Hla0aa-Ui-FPs186o-OQ5-Ay-N3ba-9-Qzwsk.jpg" loading="eager" />
              <b>28. Mistři se nikdy nevzdávají</b>
              <p>Cole, princezna Vania, Wu a Ponížení s lávovou příšerou v patách dorazí do tajemného chrámu hluboko v hoře Šintaro.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-29">
              <img src="https://i.ibb.co/tD570Z8/AAAABVy-Ct-SOe-Jqv-R3-Qd-W27f-XLOr59lnm-EYbj-DKo-Gca6y-Ml-Cd-Erc-CKLu-P4-Slgu7tb-Fy-DZqco8-Ba-Ig-IR0.jpg" loading="eager" />
              <b>29. Nejtemnější hodinka</b>
              <p>Nindžové, Munceové a Geklové musí utéct před Nositelem žalu. Útočiště najdou v Silné jeskyni kmene Geklů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-30">
              <img src="https://i.ibb.co/dKv4ZkB/AAAABZa-MWJQR0jvg-XYf-Yz7-RJZQPU3o-Fx-C6vw-PFla-TRic-SEd1le-EHk0-Hsc-DAE7-Ol75vd5-En3-N4kpm-WYs-KL4b.jpg" loading="eager" />
              <b>30. Výstup</b>
              <p>Cole, Ponížení, princezna Vania a mist Wu se dozví, že kamenný mechanismus využívá energii elementů, a dostanou se díky němu z hory ven.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-31">
              <img src="https://i.ibb.co/Q9CvhDq/jfayr73cpk8t.jpg" loading="eager" />
              <b>31. Povýšení vrací úder</b>
              <p>Cole a Povýšení dorazí k sídlu Čaroděje lebek. Tam najdou nindže zamknuté v klecích a uvidí Gekly a Munce, jak tam zase pracují v řetězech.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-2-epizoda-32">
              <img src="https://i.ibb.co/bN72b3N/AAAABc-U7p-F9-GBVRQVygzu-Pkt-Ihi-PUuxp-Vo-GUNHc-EAw-Wf-S6-XPxt-S5wjyas-XHfl5m-COzm09h-CJub9n3ow-ZAJJ.jpg" loading="eager" />
              <b>32. Lilyin syn</b>
              <p>Cole se utká s Čarodějem lebek. Geklové a Munceové vtrhnou do Čarodějova sídla. Plundar pustí nindže z klece ven, aby se mohli přidat do boje.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoSliderS12A13;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
