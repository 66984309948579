import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-1">
              <img src="https://i.ibb.co/wwwMj0z/S01E01.jpg" loading="eager" />
              <b>1. Vzpoura hadů</b>
              <p>Zasloužený odpočinek nindžů naruší syn lorda Garmadona Lloyd. Chce pokračovat tam, kde jeho otec skončil.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-2">
              <img src="https://i.ibb.co/cvh6X9C/AAAABew-Zc-k3-Lp-FDXh0-Qku-B65-CHV432fz-Ccxcq7-Nk-YOzp-Py-AKVg-Fxy-TPA8-KYW-RWflb3ea8-Tyi1-J7j-Ic-GT.jpg" loading="eager" />
              <b>2. Domov</b>
              <p>Kvůli hrozbě ze strany Lloyda a Hypnobrai se nindžové znova pouštějí do cvičení. Zane se na cestě za sokolem dostává do spousty problémů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-3">
              <img src="https://i.ibb.co/BgqwLr1/AAAABRl-R9vt-Au-LC-bq-Bc-As-Jk-OXFi-T8-TVGq-XKx-Vb-Vm-NXb0-QTDXS77a-W6kg-Bxgxcvt-PAwto1sp-OMzrkngdu3.jpg" loading="eager" />
              <b>3. Hadí uštknutí</b>
              <p>Lloyd osvobodí druhý kmen Fangpyre, aby s jeho pomocí získal zpátky svoji nadvládu. Fangpyrové napadnou Jayovu rodnou vesnici a nindžové se pustí do práce.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-4">
              <img src="https://i.ibb.co/rHTVdDF/image.png" loading="eager" />
              <b>4. Nikdy nevěř hadům</b>
              <p>Kmeny Fangpyre a Hypnobrai spojí síly. Lloyd pak probudí Pythora: posledního zbývajícího hada kmene Anacondrai.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-5">
              <img src="https://i.ibb.co/tY2htmS/image.png" loading="eager" />
              <b>5. Skrytá hrozba</b>
              <p>Lloyd se snaží pomáhat, ale spíš překáží. Pythor probouzí Venomari a Constrictai. Začíná totální hadí válka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-6">
              <img src="https://i.ibb.co/hm7ypY5/image.png" loading="eager" />
              <b>6. Král hadů</b>
              <p>Pythor se prohlásí za vyvoleného vůdce, který znovu probudí Bájného požírače, starobylé monstrum, které nelze zabít a které pozře celé Ninjago.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-7">
              <img src="https://i.ibb.co/LknDBfr/image.png" loading="eager" />
              <b>7. Tik Tak</b>
              <p>Čtyři nindžové sledují do lesů tajemného sokola. Narazí na skrytou dílnu, kde se Zane dozví tajemství o svojí minulosti.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-8">
              <img src="https://i.ibb.co/pX3DpF3/AAAABZN1-Znk-XOMqf-Xe2i-PR2hqypk-R3-CKn-OZjr7n99-JF-cf-V-j8-HZ5dxu-JEtal-u-z-Pp7-AOo-WUx-Bm307-Jia-P.jpg" loading="eager" />
              <b>8. Jedno kousnutí bolí dvakrát</b>
              <p>Jay a Nya musí zkrátit svoje první rande v Megamonstrózním zábavním parku, protože hadí lidé se vydali získat jeden ze čtyř stříbrných tesáků.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-9">
              <img src="https://i.ibb.co/gJRc5Bt/AAAABQX0-ncpo-ZPBR2-AVxf-l-K1nwp-Pwnzw-C2vr-Qdb-AO4-Ks-Tzp-FZX0-PQF5-Ktj-De-Kxi-U8n0iw-Tz9-CDx-Ya-Jo.jpg" loading="eager" />
              <b>9. Královští kovotepci</b>
              <p>Nindžové se převléknou za taneční soubor a přihlásí se do talentové soutěže o Dýkový pohár, ve kterém je ukryt jeden ze stříbrných tesáků.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-10">
              <img src="https://i.ibb.co/xCfx5Dd/AAAABTWp-DTz-Gi-Rjm-Yu-Utw3c1-Fp-NQFOtue-Gb9-Dxa-TYfc3-Uuw-s-Nx-Wla-ZV0hj8k-Sc-J9g7-Ax5o68-BBJMp-VID.jpg" loading="eager" />
              <b>10. Zelený nindža</b>
              <p>Do hledání pohřešovaného syna se zapojí úhlavní nepřítel lord Garmadon a nindžové tuší zradu. Uvědomují si ale, že s ním budou muset spolupracovat.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-11">
              <img src="https://i.ibb.co/RQgmfgy/AAAABSAp-Se-Izz-Pu-L4b-R-wmhzb-J-f-Ri-YR1gf-UFq-UMdubs-es-Lfm-Qr4m-IB8-fv-B8pvkg-Chq-Zy-Ei-Fk-X2-NLG.jpg" loading="eager" />
              <b>11. Všechno nebo nic</b>
              <p>Nindžové se snaží proniknout do vnitřní svatyně hadích lidí a spadnou přitom do pasti. Lloydova záchranná mise selže a pomoci může pouze lord Garmadon.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-12">
              <img src="https://i.ibb.co/jWKx7bN/AAAABe-EMYObk-To-UJKm-Dz-8nnga-QVr-JEWFtt6-PHr-Eh-OHd-Jt-HBYTi-FQ7-Hl-Rn-8-JH6j-Gwy6099-QCxqoy-N2c-Z.jpg" loading="eager" />
              <b>12. Bájný požírač ožívá</b>
              <p>Nindžové spěchají do ztraceného města Ouroboros, aby Pythorovi zabránili v probuzení Bájného požírače. Je to ale závod s časem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-13">
              <img src="https://i.ibb.co/wRJSBCL/AAAABXgn8n-X9wjsk-K3-Ykd-Buze7-Qf-XXBXFMGr-Nsw-Rqh88-ZQ1-JNLO-Qg-QF-iu1z-T24-Nwc-Nxu-Uf-Hgdolk-NKc-J.jpg" loading="eager" />
              <b>13. Den Bájného požírače</b>
              <p>Tváří v tvář hrozící katastrofě se nindžové musí pokusit o zázrak: porazit všepohlcujícího Bájného požírače a zachránit Ninjago.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS01;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
