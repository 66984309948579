import React, { useEffect } from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function ImgSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    beforeChange: (current, next) => handleSlideChange(next),
  };

  useEffect(() => {
    handleSlideChange(0); // Initial active slide
  }, []);

  const handleSlideChange = (next) => {
    const slides = document.querySelectorAll('.slick-slide');
    slides.forEach((slide, index) => {
      const link = slide.querySelector('a');
      if (link) {
        if (index === next + 1 || index === next - 1) {
          link.classList.remove('disabled');
        } else {
          link.classList.add('disabled');
        }
      }
    });
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-draci-povstani">
            <img src="https://i.ibb.co/t2jd3h8/NINJAGO-DP-SLIDER-2.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz">
            <img src="https://i.ibb.co/Kyjkn6W/DREAMZZZ-1.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi">
            <img src="https://i.ibb.co/v4JFCqG/CITY-DOBRODRUZSTVI-SLIDER-NORMAL.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-princezny-dobrodruzstvi-na-zamku">
            <img src="https://i.ibb.co/kQ8Dnwd/PRINCEZNY-DOBRODRUZSTVI-NA-ZAMKU-slider.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-all-stars">
            <img src="https://i.ibb.co/8B3YkqK/ALL-STARS.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-dobrodruzstvi-freemakeru">
            <img src="https://i.ibb.co/Nn5LQSZ/SLIDER-STAR-WARS-DOBRODRUZSTVI-FREEMAKERU.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-dekodovani">
            <img src="https://i.ibb.co/wzwhFJr/SLIDER-NINJAGO-DEKODOVANI.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-pribehy-droidu">
            <img src="https://i.ibb.co/vqWSkkX/SLIDER-STAR-WARS-PRIBEHY-DROIDU.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/atlantis">
            <img src="https://i.ibb.co/1nzv4t4/SLIDER-ATLANTIS.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/hero-factory">
            <img src="https://i.ibb.co/DR1y5vw/SLIDER-HERO-FACTORY.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-utek-z-gothamu">
            <img src="https://i.ibb.co/wMNp8nf/SLIDER-DC-SUPER-HRDINOVE-UTEK-Z-GOTHAMU.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/bionicle-maska-svetla">
            <img src="https://i.ibb.co/52KTNGz/SLIDER-BIONICLE-MASKA-SVETLA.jpg" loading="eager" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ledove-kralovstvi-polarni-zare">
            <img src="https://i.ibb.co/105c5q5/SLIDER-LEDOVE-KRALOVSTVI-POLARNI-ZARE.jpg" loading="eager" />
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default ImgSlider;

const Container = styled.div`
  padding: 0 calc(3.5vw + 5px);
`;

const Carousel = styled(Slider)`
  position: relative;
  margin-top: 10px;

  .slick-slide {
    padding: 10px;
    transition: opacity 0.5s ease; /* Přidání přechodu pro opacity */
  }

  .slick-prev {
    margin-top: -10px;
    left: 3% !important;
    z-index: 1;
  }
    
  .slick-next {
    margin-top: -10px;
    margin-right: 86px;
    z-index: 1;
  }

  .slick-next li button::after {
    content: '';
    display: block;
    position: absolute;
    top: -14px;
    right: -14px;
    bottom: -14px;
    left: -14px;
    z-index: 1;
    pointer-events: auto;
  }

  @media (max-width: 768px) {
    .slick-next {
      margin-top: -10px;
      margin-right: 42px;
      z-index: 1;
    }
  }

  .slick-slide:not(.slick-active) {
    opacity: 0.3;
  }

.slick-active {
    cursor: pointer;
  opacity: 1; /* Ujistěte se, že aktivní slide má plnou opacity */
}
  
  ul.slick-dots {
    position: absolute;
    bottom: 30px;
    right: 10px;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    pointer-events: none;
  }

  ul.slick-dots li {
    margin: 0 8px;
    position: relative;
    pointer-events: auto;
  }

  ul.slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    background: gray;
    border: none;
  }

  ul.slick-dots li button::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: gray;
    line-height: 8px;
  }

  ul.slick-dots li button::after {
    content: '';
    display: block;
    position: absolute;
    top: -14px;
    right: -14px;
    bottom: -14px;
    left: -14px;
    z-index: 1;
    pointer-events: auto;
  }

  ul.slick-dots li.slick-active button::before {
    background-color: white;
  }

  .slick-list {
    overflow: visible;
  }
`;

const Wrap = styled.div`
  background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
  border-radius: 4px;
  
  a.disabled {
    pointer-events: none;
    cursor: default;
  }

  img {
    object-fit: cover;
    outline: 0px solid transparent;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
      rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    transition-duration: 250ms;

    &:hover {
      outline: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
`;
