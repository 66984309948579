import React from 'react';
import { Helmet } from 'react-helmet';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { useAuth } from '../../../contexts/AuthContext';

function DPS02E15() {

  return (
    <div className="container-placer">
      <Helmet>
            <title>NCE+ | Přehrávač</title>
            <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="embed-container">
            <iframe
              src="https://filemoon.sx/e/vndoowyayyoq/LEGO_Ninjago__Dra__povst_n__S02E15_Spole_n__padneme"
              autoPlay="true"
              frameBorder="0"
              allowFullScreen="true"
            />
            <div className="zpet">
              <a href="/ninjago-draci-povstani-sezona-2-epizoda-14">
                <Tooltip title="Předešlá epizoda" arrow enterDelay={50} leaveDelay={25} placement="right">
                  <SkipPreviousIcon sx={{ color: grey[700], fontSize: 80 }} opacity="0.5" />
                </Tooltip>
              </a>
            </div>
            <div className="zpet-na-serial">
              <a href="/ninjago-draci-povstani">
                <Tooltip title="Zpět na Ninjago: Dračí povstání" arrow enterDelay={50} leaveDelay={25} placement="right">
                  <SlideshowIcon sx={{ color: grey[700], fontSize: 60 }} opacity="0.5" />
                </Tooltip>
              </a>
            </div>
            <div className="dalsi">
              <a href="/ninjago-draci-povstani-sezona-2-epizoda-16">
                <Tooltip title="Další epizoda" arrow enterDelay={50} leaveDelay={25} placement="left">
                  <SkipNextIcon sx={{ color: grey[700], fontSize: 80 }} opacity="0.5" />
                  </Tooltip>
                  </a>
        </div>
      </div>
    </div>
  );
}

export default DPS02E15;
