import styled from 'styled-components';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import StarwarsSerialyaFilmy from './StarwarsSerialyaFilmy';
import SvetStarwarsSlider1 from './SvetStarwarsSlider1';
import SvetStarwarsSlider3 from './SvetStarwarsSlider3';
import SvetStarwarsSlider2 from './SvetStarwarsSlider2';

function SvetStarwars(props) {
  useEffect(() => {
    const videoElement = document.getElementById('introVideo');
    if (videoElement) {
      videoElement.play();
      videoElement.addEventListener('ended', () => {
        $('#introVideo').fadeOut(1000, () => {
          $('.scdown').fadeIn(1000);
        });
      });
    }

    $(window).scroll(() => {
      const st = $(window).scrollTop();
      const range = 700; // finetune this to the desired effect
      $('.scdown').css('opacity', 1 - st / range); // animate your element
    });

    return () => {
      $(window).off('scroll');
    };
  }, []);

  return (
    <Container>
      <Helmet>
        <title>NCE+ | Svět Star Wars</title>
      </Helmet>
      <StarwarsSerialyaFilmy />
      <SvetStarwarsSlider1 />
      <SvetStarwarsSlider2 />
      <SvetStarwarsSlider3 />
      <Background>
        <video id="introVideo" className="video-background" src="/Videa/STAR-WARS-BACKGROUND.mp4" muted />
        <img className="scdown" src="https://i.ibb.co/ck9bM3t/SW-NH.png" loading="eager" />
        <div className="bgcolor" bis_skin_checked="1" />
      </Background>
    </Container>
  );
}

const Container = styled.main`
  position: relative;
  overflow-x: hidden;
  display: block;
  top: 72px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    display: none;
  }

  @media (max-width: 768px) {
    video {
      display: none;
    }

    img {
      display: block;
    }
  }
`;

export default SvetStarwars;
