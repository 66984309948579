import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function DreamzzzSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/DVjWMkr/AAAABRue0-FEZz-YB8o-Ap-Eai04-Bm0-SXCvnvawqp9j3-Or-JSy-D02-SBMJ1-Zk-Yje-Yk-FAe-KIpdx8k-Rfrrow-GONRRU3.jpg" loading="eager" />
              <b>1. Probuzení</b>
              <p>Sourozenci Mateo a Izzie se ze světa, který znají, přenesou do říše snů a zjišťují, že si na ni brousí zuby zlý král.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/r7HCmVr/AAAABRNWG5-MSOOWae5-E4m-Pfgi-VGAe-VCTONHId4s3rgi-K2v-Vqfvvh-Rec-PEJs-LK-3j-Bp-Uhw-Fxm11-Jfwe2-VPIh-I.jpg" loading="eager" />
              <b>2. Lovci snů</b>
              <p>Tak to nebyl jenom sen! Mateo, Izzie a Z-Blob se vrací zachránit Coopera. Narazí přitom na nečekaného spojence a dozví se něco o svých zvláštních nových schopnostech.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/5WZvSpr/AAAABTdp5fmcr-YX7-BC1-L0a-CQLojq-Ywi-B9m-Nw-q-Fj-CNneot-RWTu0-GEet-Th9-OIRwk-I9x8ar40-HE5q-LXphuo-AI.jpg" loading="eager" />
              <b>3. Pronásledovaní snílci</b>
              <p>Mateo, Izzie, Cooper a Logan se začínají cvičit na lovce snů. Úkol číslo jedna: zkrotit svoji fantazii a vyzkoušet ji v mocné Kovárně snů.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/ch4nVWx/AAAABd-PTBmvgvy-u4-Ars2-Bxnivr-k-Wl-P0x97b42ue1-K5wz-PYY9-Js-Ro-G-g0-VH-j-Bdah4-W3-LFV-09lwz6-Kxs-Zv.jpg" loading="eager" />
              <b>4. Kovárna snů</b>
              <p>Izzie se seznámí s novou holkou, dá se jí ale věřit? Lovci snů se konečně dostanou do Kovárny. Oswald prozradí, po čem jde Král nočních můr.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/Q6LX2dB/AAAABf2-Krm-MTa-Ue-h-Oe3-G3no-Su-Hjl-Ez-JZn9-PM-No-W1ww-ZQ-OJ1-E07-C6tp-bic-VFQ52-RO7r-M0cvjqa-MIx3r.jpg" loading="eager" />
              <b>5. Vrcholný výkon</b>
              <p>Logan udělá velikou chybu a upozorní Krále nočních můr na to, že lovci snů jsou nablízku a k tomu u sebe mají něco cenného.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/GsqBkJ6/AAAABXv-M-phz6yjpo-Ash-HB4-Fy-NFGr-o0ok-Xg-4-VQ86sh-Eie-RYlme-V8-Bd-Y92ly-ZAja-BOspi-VUk-ZJNo5-X9s-S.jpg" loading="eager" />
              <b>6. Anomálie</b>
              <p>Z-Blob upoutá nechtěnou pozornost a dá se na útěk. Cooper váhá, jestli dát přednost Lovcům snů, nebo domácím povinnostem.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/NFyhVKy/AAAABRk-Jd-XBQ3k-GICNg-AK9-APgemu-Vom-Pag-IVSWZu7sw7-C5-Am3r-Zar-LP64g2l-I6k-On-Ifk-R4-J1f-Qe-Ewuem.jpg" loading="eager" />
              <b>7. Kodex podvádění</b>
              <p>Parta potřebuje pauzu a dopřeje si oraz v Kyberříši. Jenže Logan žárlí a podvádí, a tak se hra začne zadrhávat.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/9NyWhdp/AAAABb-IFl1i01i-QR-OTcwxu-ROac-Xznq-B73-Vc-Mydtz-Ql0-Xb-Noj-YGqt-A5-Q8-GKQz-Pkq4-VNEwe-ODHX3-PSj-TFv.jpg" loading="eager" />
              <b>8. Širší pohled</b>
              <p>Jednotka kvůli testu z matiky vnikne učitelce do snů. Logan dostane lákavou nabídku. Izzie objeví nový školní klub, který skrývá děsivé tajemství.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/FwmTtxr/AAAABc-TTVuxdd-Zytrzp-Rvg-Hs-Wluy-IOCHEPXo-FM3k-Iwgt-K9l-BLp4t-59j-Wy8d-CYZt-Hr7u-OSj-E7-Zk-SXt-N2gz.jpg" loading="eager" />
              <b>9. Podraz</b>
              <p>Logan se vrací do hry Kyberříše, kde se utká s hrozivým protivníkem. V reálném světě pak musí ostatní členové týmu odehrát na školním dni fotbalový zápas.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/9nBGVxw/AAAABS8-GK-Kva-IF2mnl-KSs-h-YBe-Mgrzp1vju2l-Khyq-R5t-Rt-L2x-Vhka-VHk-MZl-Xvfs-TJls-B5-IC954y-EY4a-l.jpg" loading="eager" />
              <b>10. Útěk</b>
              <p>Přátelé proniknou do říše nočních můr, kde se pokusí zachránit Logana. Král se ale dozví o jejich zeleném kamarádovi a dostane ďábelský nápad…</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/J7V0M6S/AAAABUv-Snh-Ru-Hy-Avgme-Gw-EMxvj-Lu-Qc-A6-PFIIWUTiyowbun79byo-Tv5-Nk-Dg-Um-Ep3-Ywz-VLWEQd-Fefqn-Prak.jpg" loading="eager" />
              <b>11. Blok snílků</b>
              <p>Ve škole mají všichni čím dál horší noční můry. Říši snů zaplavili Zplozenci a parta je v pasti.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/4JhDYrc/AAAABSg-H0-LH2-ROr8-LPkj-Mr-Gl-Ie-Gc-q-Tf1p-DFCS6-Z3-Pwts-Ckulc-H6-Qz-EJBb1x-O4cp0z-XTR4-Xav-Jz-Moq4.jpg" loading="eager" />
              <b>12. Opice a bandita</b>
              <p>Agentka Stricková má Zeda a Noční lovec Alberta. Lovci snů se připravují na záchrannou misi ve světě snů i v bdělém světě.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/828VV34/AAAABbw-BUQbg-OKaldd-Ge-Lw-WJI3ldv-ZLTg-DZovbafzjo2ht-Stzqs-Gnq-Snt7-SIx-VDS6w-IRy-S8-CDQ9-Ru-VDUh-E.jpg" loading="eager" />
              <b>13. Soukromé očko</b>
              <p>Mateo zkusí svoji lásku pozvat na rande, ale je z toho jenom chaos. Král nočních můr najde způsob, jak špehovat bdělý svět.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/BCdbS4z/AAAABXBEf1-Iq8b-Eh-UHa-Wf-Qp-QOOnauxdp-Ekac-DMDWVev-Or-OVYp2-IEZOez8vy-EF1-Sftf7j-Hz-B22-Vc-Nz-J-95.jpg" loading="eager" />
              <b>14. Píseň mlhy</b>
              <p>Lovci snů uvíznou v tajemné mračné říši, kde se to hemží zlými sirénami a kde se ztrácejí sny i vzpomínky.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/NrT4fcq/AAAABa-EEmda-Nw87-NCMRF4qq-SY2p-VMal-Vx-WUufzm-JZli-Kas59-W-hl-P181-FCEFZen-q-QXQ1-BJHYh7-VR-gh8jv-N.jpg" loading="eager" />
              <b>15. Sladké sny</b>
              <p>Izzie má plán, jak Nespavcům zařídit sladké sny. Vezme je přímo do Říše sladkostí.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/Vw4DKfS/AAAABYAWSPdq-D3-U6kw-4q-N5ld2ux-YJXOMV7p41k-Myi-Xb-Esy-UXiy-4-Ba-Gtcd1t2-CNE6ha-MLRx-K93m-IDUl3-IOMH.jpg" loading="eager" />
              <b>16. Snílek, který za to stojí</b>
              <p>Snílci najdou hrad Nocturnia v troskách. Zemí se navíc plíží zlá příšera a jednoho snílka čeká děsivá zkouška.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/7C5dzst/AAAABb-JW-NJSaq-Mlr6tl-NXVW0gcz-NBZ4-B2-0-Lu-GRTIM945-Ft-Onl-JFa-II5o-Nc-NTkyygh2-Ij-HVb-Gs0n-4-Qhk.jpg" loading="eager" />
              <b>17. Světlo nocturnie</b>
              <p>Lovci snů se snaží pochopit Luniin příběh a získat klíč k jejím tajemstvím. Král nočních můr rozpoutá totální útok na hrad Nocturnia.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/wwQJhCy/AAAABVRF610-Loeiir9a-UT3cn-Tj-YB-p-Sv-W1nh-RPMri-F9-Jwj-YU7q-Kr-GX5-DS-W8-Lpp-D6s-P1n-HEXEZGz-0-I8-P.jpg" loading="eager" />
              <b>18. Noční lov</b>
              <p>Trhlina mezi bdělým světem a světem snů se rozevírá. Mateo začíná chápat, že za příběhem o Luniině střetu s Králem nočních můr je možná něco víc.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/NtjmsCH/AAAABam-Q7-RPIFmd6t-Ooicc195-Ezsi-V7-Ik2-OGQX2-G1-J3-Rwjl0-Sehd-Ulobx7gsl-UIf7-NIh-Sw3o-LJFs-C3-0n5.jpg" loading="eager" />
              <b>19. Trhlina</b>
              <p>Noční děs naruší průběh podzimního festivalu. Noční lovec pronikne do bdělého světa a vyhrožuje Mateovi.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/dreamzzz-sezona-1-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/4pD7Vj4/AAAABQR8x-RYGOJRQmt-Aj-Dc8mzu-GVXag-Du6-X-3s-KUq1-Glqzp-AC2695u-h-Bo-Qx-7-Wtfh6-CCKAJ0-LM79qk-Dl-Wag.jpg" loading="eager" />
              <b>20. Vstupte do noční můry</b>
              <p>Král nočních můr strhne bariéry a pronikne do bdělého světa, a parta proto musí v závěrečném souboji zachránit sny všech okolo.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default DreamzzzSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    translateZ(0px);
    letter-spacing: -0.8px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    transform: translateY(-30%);
    translateZ(0px);
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
  margin-left: -8px;
  margin-top: -76px;
}

.slick-next {
  margin-right: -8px;
  margin-top: -76px;
}

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;











