import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyDWcmII0127RVctnD-GvIgAIGJVJ8kk6BY',
    authDomain: 'nce-plus.firebaseapp.com',
    projectId: 'nce-plus',
    storageBucket: 'nce-plus.appspot.com',
    messagingSenderId: '10153472705',
    appId: '1:10153472705:web:abcdef1234567890',
    databaseURL: 'https://nce-plus-default-rtdb.europe-west1.firebasedatabase.app/'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);

console.log('Firebase app initialized:', app);
console.log('Auth initialized:', auth);
console.log('Firestore initialized:', db);
console.log('Realtime Database initialized:', rtdb);

export { app, auth, db, rtdb };
