// SmazatUcet.js
import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from './contexts/AuthContext';
import styled from 'styled-components';

const SmazatUcet = ({ onClose }) => { // Změněno jméno props na onClose
    const { user, deleteUser } = useAuth();
    const inputRef = useRef(null);
    const [serverError, setServerError] = useState('');

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            confirm: ''
        },
        validationSchema: Yup.object({
            confirm: Yup.string()
                .required('Musíte potvrdit smazání účtu.')
                .oneOf(['Smazat'], 'Musíte zadat "Smazat" pro potvrzení.')
        }),
        onSubmit: async (values) => {
            if (!user) {
                console.error('Není přihlášen žádný uživatel.');
                setServerError('Není přihlášen žádný uživatel.');
                return;
            }

            try {
                console.log('Snažím se smazat uživatele:', user.uid);
                await deleteUser();
                console.log('Účet úspěšně smazán');
                if (typeof onClose === 'function') {
                    console.log('Zavírám modální okno');
                    onClose(); // Zavřít modální okno po úspěšném smazání
                } else {
                    console.warn('onClose není funkce');
                }
            } catch (error) {
                console.error('Chyba při mazání účtu:', error);
                setServerError('Došlo k chybě při mazání účtu.');
            }
        }
    });

    const handleCloseModal = () => {
        if (typeof onClose === 'function') {
            console.log('Zavírám modální okno');
            onClose();
        } else {
            console.warn('onClose není funkce');
        }
    };

    return (
        <ModalContent>
            <ModalTitle>Potvrzení smazání účtu</ModalTitle>
            <form onSubmit={formik.handleSubmit}>
                <ModalInput 
                    type="text"
                    {...formik.getFieldProps('confirm')}
                    placeholder="Zadejte 'Smazat' pro potvrzení"
                    ref={inputRef}
                    autoComplete="off"
                    autoCorrect="off"
                />
                {formik.touched.confirm && formik.errors.confirm ? (
                    <ErrorText>{formik.errors.confirm}</ErrorText>
                ) : null}
                {serverError && (
                    <ErrorText>{serverError}</ErrorText>
                )}
                <ModalButtons>
                    <ModalButton type="submit">Smazat účet</ModalButton>
                    <ModalButton type="button" onClick={handleCloseModal}>Zavřít</ModalButton>
                </ModalButtons>
            </form>
        </ModalContent>
    );
};

// Stylované komponenty
const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border-radius: 8px;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
`;

const ModalInput = styled.input`
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    box-sizing: border-box;
`;

const ErrorText = styled.p`
    color: #e74c3c;
    margin: 4px 0;
    font-size: 14px;
    text-align: center;
`;

const ModalButtons = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
`;

const ModalButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    &:nth-of-type(1) {
        background-color: #e74c3c;
        color: #fff;
    }
    &:nth-of-type(2) {
        background-color: #ccc;
    }
`;

export default SmazatUcet;
