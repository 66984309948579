import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function SvetNinjagoSlider2() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Ninjago: Mistři Spinjitzu - Klíčové díly</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-0-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř0D1&nbsp;Cesta&nbsp;nindži&nbsp;a&nbsp;zla...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/JpLZFkT/grefgher.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-0-epizoda-2">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř0D2&nbsp;Král&nbsp;stínů&nbsp;a&nbsp;zbran...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/KwpfjF0/tzjzjztjk.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-7">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř1D7&nbsp;Tik&nbsp;Tak</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/LknDBfr/image.png" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-1-epizoda-10">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř1D10&nbsp;Zelený&nbsp;nindža</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/xCfx5Dd/AAAABTWp-DTz-Gi-Rjm-Yu-Utw3c1-Fp-NQFOtue-Gb9-Dxa-TYfc3-Uuw-s-Nx-Wla-ZV0hj8k-Sc-J9g7-Ax5o68-BBJMp-VID.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-5">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř2D5&nbsp;Dětská&nbsp;hra</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/jMHvqpQ/AAAABd2-DOXlp-IUF3-s-Fuh-R2w-TUjq-Y6-Le1oh-Gb-Dn-Lwb8a-UPa-E3-Ba-XHJb7333w7ynm-C5-Stj39n-X4j3-INWZQ3.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-6">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř2D6&nbsp;V&nbsp;nesprávný&nbsp;čas...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/QFcPJVb/AAAABfxu-LBdmjy5-Pud-LB-8-Y4j-NVYCi4v-Fm-Chgi-Nh-Ez-YDovkskc-Ey-Atm-ZCHDIPP8-Dv-m-SJkky-V4e-A39mhvv9.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-13">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř2D13&nbsp;Nejvyšší&nbsp;mistr...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/wymNHxs/AAAABZNKuvux94-SNLzw-O01s-J2-UWy-NA1-N2-JBMZDm84-LFKc0l-Ai-PVCrw13vx-7q-FTDz5-P6-Ht-G1k-T7-Lro6-Np-L.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-8">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř3D8&nbsp;Titanový&nbsp;nindža</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/DzYKXF4/AAAABQ-7b9k-Od-Adkc-Z3-Ewj3-V8y-FSx-MGFlik-WU78ad-ZCMQ2h-RI2-GTrst-C7-Jnlr8-Bb-NP9n793r-Zk-CAB3k-MA1.jpg" />
              </div>
            </div>
          </a>
        </Wrap>

        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř4D1&nbsp;Pozvání</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/bs9cWQG/AAAABRU0-Hcc7t-PIf-EPc75k5-d-IEGl4-t-f-J6x8t-RLBl-WB5pjb-Usz-T-UBs-VIy76-BVTPXl-H0h4-Xq-im3f-Ozkk-Sp.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-10">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř4D10&nbsp;Koridor&nbsp;starších</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/87NL0rJ/AAAABbsuv-Z-o-ZN-k-N-kj-Yx-VCRby-Pl-Hx-Zx6d-RVERxe-Ur8-Il1-BFROs-In-Og-j-Ly-A4-P28-DZJXIXm-Mp4m-C4z.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-4">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř5D4&nbsp;Chrám&nbsp;na&nbsp;straši...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/mCYGqc9/AAAABel6ny-QV-KHucf-G68po-UBasmw-Ua-J7w-SN3k-F28slz-ZJRA-4chj-WHf7e-Hp-IBo-GPq549-Lnv-WOb7-Zh-M7d-LQ.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-10">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř7D10&nbsp;Ztraceni&nbsp;v&nbsp;čase</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/bRzzGGr/AAAABbvo-Tx-Bo6c6b-Fncflpmf-dq-R3-Hdr0-Vf-N4-JHam-Ry-Gjb-EIe4kt-R6-FBq-Ntq-Qa-Ix-Mw3-Ls-Ars3-Gmd-VVh.jpg" />
              </div>
            </div>
          </a>
        </Wrap>

        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-8">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř8D8&nbsp;Hrůza&nbsp;přichází</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/5vwgPD2/AAAABQbonboxl0e-Ug-Aw-MRk-Z49bvhw-Fj-Wq1-SPp-CJXGYYxe-AB0-Ug-Cb-CRiyw-TQwv8-Si-XJxtgi-Cn90t-Idpxi-Kl.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-8-epizoda-9">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř8D9&nbsp;Pravý&nbsp;potenciál</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/dWpyGzw/AAAABdc-AVd-EMX8-B7-Ue-6xx-Jy-Lus-ECdtr-Fh-WABq-T-5-ZIAWK5arhg3a-YScwu-AZV-eisky-Am-AUGKeta-Tz4ug-WZ.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-3">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř9D3&nbsp;Rádio&nbsp;Svobodné...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/sq5h1Vy/AAAABRpm-UBwjq7c3yhxm-IVFr-Jy0m-H69-Kvz-YIQRK5wp-Sz-BX7-C13yr1-JV-lp-H0p-AO32f-RYP3-EO3g95-OMIFw3-MM.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-10">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř9D10&nbsp;Zelený&nbsp;osud</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/Nn6qnnc/AAAABa7-Ls-Z9-0e2-N1-ZQWEH5-VJl-KEa-X1wtxn-J-Hw3vg-UMs-Pzw-Iyc-Pm-CDBz-Xe-Rvx-4-Qig-Wc-u-My-YCUPGi-O.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu-sezona-10-epizoda-4">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Mistři Spinjitzu</div>
                <div className="svet-text">Ř10D4&nbsp;Konce</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/cYC25M8/AAAABb9-Pz3-KX9zk-A4-Z3-VMLQPDFk-3mh-Znukcndz4w-MQBsf-Je-d-PDEKGt-FPOKy8pj-W8-Q910h-Tz-V05b-APTb-LUE.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default SvetNinjagoSlider2;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

   a {
    text-decoration:none;
 }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        opacity 1;
    }

    &:hover {
        img {
            opacity 0.4;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
`;
