import React from 'react';
import { Helmet } from 'react-helmet';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { useAuth } from '../../../contexts/AuthContext';

function WFS01E01() {
  const { user, role, loading } = useAuth();

  const allowedRoles = ['Majitel', 'Admin', 'Moderátor', 'VIP'];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-placer">
      {allowedRoles.includes(role) ? (
        <>
          <Helmet>
            <title>NCE+ | Přehrávač</title>
            <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="embed-container">
            <iframe
              src="https://filemoon.sx/e/88wfpy81m3jo/Wyldfyre_shorts_S01E01"
              autoPlay="true"
              frameBorder="0"
              allowFullScreen="true"
            />
            <div className="zpet-na-serial-prvni-epizoda">
              <a href="/ninjago-wyldfyre-navrat-do-divociny" >
                <Tooltip title="Zpět na Ninjago: Wyldfyre: Návrat do divočiny" arrow enterDelay={50} leaveDelay={25} placement="right">
                  <SlideshowIcon sx={{ color: grey[700], fontSize: 60 }} opacity="0.5" />
                </Tooltip>
              </a>
            </div>
            <div className="dalsi">
              <a href="/ninjago-wyldfyre-navrat-do-divociny-sezona-1-epizoda-2">
                <Tooltip title="Další epizoda" arrow enterDelay={50} leaveDelay={25} placement="left">
                  <SkipNextIcon sx={{ color: grey[700], fontSize: 80 }} opacity="0.5" />
                </Tooltip>
              </a>
            </div>
          </div>
        </>
      ) : (
        <div className="access-denied">
          <h2>Pro přehrání epizody potřebuješ VIP</h2>
        </div>
      )}
    </div>
  );
}

export default WFS01E01;
