import React from 'react';
import { Helmet } from 'react-helmet';

function NINJAGOFILMCZECH() {
  return (
    <div className="container-placer">
      <Helmet>
        <title>NCE+ | Přehrávač</title>
        <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="embed-container">
        <iframe src="https://filemoon.sx/e/1aparp2ymwkh/LEGO_Ninjago_Film" frameBorder="0" allowFullScreen="true" />
      </div>
    </div>
  );
}

export default NINJAGOFILMCZECH;
