import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// Import Player.js (pokud je dostupný jako npm balíček)
import Player from 'player.js'; // Zkontrolujte správný import podle dokumentace

// Definice validační schémy pro Formik
const validationSchema = Yup.object({
    fileCode: Yup.string().required('Kód souboru je povinný.')
});

const FileInfo = () => {
    const [fileInfo, setFileInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const fileCodeRef = useRef(null); // Reference na textové pole
    const playerRef = useRef(null); // Reference na přehrávač

    useEffect(() => {
        // Nastavit fokus na textové pole po načtení komponenty
        if (fileCodeRef.current) {
            fileCodeRef.current.focus();
        }

        // Inicializace přehrávače, pokud je video k dispozici
        if (fileInfo && fileInfo.videoUrl) {
            playerRef.current = new Player('video-player', {
                url: fileInfo.videoUrl,
                controls: true,
                autoplay: false,
            });
        }

        return () => {
            // Cleanup player on unmount
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [fileInfo]);

    const fetchFileInfo = async (fileCode) => {
        setLoading(true);
        setError('');
        setFileInfo(null);
        try {
            // Předpokládáme, že FSLinks API vrací data ve správném formátu
            const response = await fetch(`https://fslinks.org/api/file/${fileCode}`);
            const data = await response.json();
            if (response.ok) {
                setFileInfo(data);
            } else {
                setError('Soubor nebyl nalezen.');
            }
        } catch (err) {
            setError('Došlo k chybě při načítání informací.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', padding: '20px' }}>
            <h1>Informace o Souboru</h1>
            <Formik
                initialValues={{ fileCode: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    fetchFileInfo(values.fileCode);
                    resetForm();
                }}
            >
                {({ isSubmitting }) => (
                    <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ marginBottom: '15px' }}>
                            <Field
                                type="text"
                                name="fileCode"
                                placeholder="Zadejte kód souboru"
                                innerRef={fileCodeRef} // Nastavení reference
                                style={{ padding: '10px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '4px', width: '300px', maxWidth: '100%' }}
                            />
                            <ErrorMessage name="fileCode" component="div" style={{ color: 'red', marginTop: '10px' }} />
                        </div>
                        <button
                            type="submit"
                            style={{ padding: '10px 20px', fontSize: '16px', color: '#fff', backgroundColor: '#007bff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                            disabled={isSubmitting}
                        >
                            Načíst informace
                        </button>
                    </Form>
                )}
            </Formik>
            {loading && <p>Načítání...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {fileInfo && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <h2>{fileInfo.title}</h2>
                    <div style={{ marginTop: '20px', width: '100%', maxWidth: '600px', textAlign: 'center' }}>
                        {/* Element pro přehrávač videa */}
                        <div id="video-player" style={{ width: '100%', height: '500px' }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileInfo;
