import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Filmylist() {
  return (
    <Container>
      <h4 className="serialy">Filmy</h4>
      <Content>
        <Wrap>
          <a className="prevent-select" href="/lego-pribeh-1">
            <img className="hovimg" src="https://i.ibb.co/Wv1V3Nq/LEGO-PRIBEH.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-pribeh-2">
            <img className="hovimg" src="https://i.ibb.co/H7tQMnF/LEGOPRIBEH-2.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-ninjago-film">
            <img className="hovimg" src="https://i.ibb.co/Gn431Q2/NINJAGO-MOVIE-POSTER.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-den-zesnulych">
            <img className="hovimg" src="https://i.ibb.co/DzFv9kW/DEN-ZESNUL-CH.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-clutch-powers-zasahuje">
            <img className="hovimg" src="https://i.ibb.co/NSnwvrs/CLUTCH-POWERS-ZASAHUJEE.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-svatecni-special">
            <img className="hovimg" src="https://i.ibb.co/vqBDJhj/SVATECNI-SPECIAL.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-hruzostrasne-historky">
            <img className="hovimg" src="https://i.ibb.co/vHh5MzD/HRUZOSTRASNE-HISTORKY.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-letni-prazdniny">
            <img className="hovimg" src="https://i.ibb.co/26jDzWW/LETNI-PRAZDNINY.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-padawanska-hrozba">
            <img className="hovimg" src="/images/Nahledovky/PADAWANSKA-HROZBA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-imperium-utoci">
            <img className="hovimg" src="/images/Nahledovky/IMPERIUM-UTOCI.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-se-spojili">
            <img className="hovimg" src="https://i.ibb.co/StVq2Yw/SUPER-H-SE-SPJILI.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-batman-do-ligy">
            <img className="hovimg" src="https://i.ibb.co/HCcmPxC/LEGO-DC-SUPER-HRDINOVE-BATMAN-DO-LIGY-NAHLEDOVKA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-vs-bizarro">
            <img className="hovimg" src="https://i.ibb.co/9VKnQ0n/LIGA-SPRAVEDLIVYCH-VS-BIZARRO.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-vs-legie-zkazy">
            <img className="hovimg" src="https://i.ibb.co/mTmWB1w/LIGA-SPRAVEDLIVYCH-VS-LEGIE-ZKAZY.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-vesmirny-souboj">
            <img className="hovimg" src="https://i.ibb.co/12ypmHG/LIGA-SPRAVEDLIVYCH-VESMIRNY-SOUBOJ.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-liga-spravedlivych-utek-z-gothamu">
            <img className="hovimg" src="https://i.ibb.co/7KWZ8QG/LIGA-SPRAVEDLIVYCH-UTEK-Z-GOTHAMU.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-flash">
            <img className="hovimg" src="https://i.ibb.co/FxtWp3x/DC-SH-FLASH.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-aquaman">
            <img className="hovimg" src="https://i.ibb.co/KD6YbKV/DC-SUPER-HEROES-AQUA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/lego-batman-film">
            <img className="hovimg" src="https://i.ibb.co/XJB6zB3/BATMAN-FILM.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/bionicle-maska-svetla">
            <img className="hovimg" src="https://i.ibb.co/nL9TN7s/bionicle-1-photo.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/bionicle-legenda-metru-nui">
            <img className="hovimg" src="https://i.ibb.co/5vHfXdM/bionicle-2-photo.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/bionicle-pavucina-stinu">
            <img className="hovimg" src="https://i.ibb.co/X5YS0NZ/bionicle-3-photo.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/bionicle-zrozeni-legendy">
            <img className="hovimg" src="https://i.ibb.co/JvkCZpX/bionicle-4-photo.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-princezny-dobrodruzstvi-na-zamku">
            <img className="hovimg" src="/images/Nahledovky/PRINCEZNY-DOBRODRUZSTVI-NA-ZAMKU.jpg" />
          </a>
        </Wrap>
      </Content>
    </Container>
  );
}

export default Filmylist;

const Container = styled.div`
      font-size: 25px;
      padding: 0 42px;

      h4 {
        font-family: 'Noto Sans Lao', sans-serif;
        margin-bottom: 10px;
      }
`;

const Content = styled.div`
@media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(5, minmax(0,1fr));
`;

const Wrap = styled.div`
padding-top: 56.25%;
border-radius: 4px;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;
cursor: pointer;
overflow: hidden;
position: relative;
transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
outline: 0px solid transparent;
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));

img {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: fill;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}

    &:hover {
        outline: 3.2px solid #C7C7C7;
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
        rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        }
`;
