import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function SvetNinjagoSlider1() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  
  return (
    <Container>
      <Zacatek>
        <h4>Ninjago: Tajemství zakázaného Spinjitzu - Ohnivá část</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D1&nbsp;Plýtvání&nbsp;Potenc...</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/CnNTJ40/AAAABR8-B-wu9-LPQg-Og-DUOj-Je-IMKw-Lsp-Bps-Nuq-F-rq-Azl1-UOUPf2-G7-VQd-PJs40-CCzw3nj-XNO4-SR-LHR4i7.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-2">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D2&nbsp;Pátrání&nbsp;po&nbsp;misích</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/L1HQv7T/AAAABZF1-Nu-E0-Q-Ylt-PFeb-UCoc-Hxj-en-X2szjcbim3-DEYPk-Irrp-PNn-Ug-LTb-Ypb-Gp-A5u-Qpqg-VMPCEHLft-Gxh.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-3">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D3&nbsp;Tvrdý&nbsp;začátek</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/rkn3sfH/AAAABRf-Wca-BWLcz-J4e-Ktj4bd-AJ-o3c-Vc-Bd-T5-Xq-L1-CS2-q-Dnz4l-OFEV2-Oawr-IW-k-Pdmg5v-RTF7n5cw-ZSon7.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-4">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D4&nbsp;V&nbsp;břiše&nbsp;bestie</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/GQP8NQz/AAAABSjn9o-Wf-IFr2-CDquj-DIq-EUn-D99z-DMGUCAQb-RN0-BTZr-WJth03m-N4-VXlil5aa-Q8nsxng9-B33-Z08-O6-B8d.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-5">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D5&nbsp;Pasti&nbsp;a&nbsp;jak&nbsp;je&nbsp;přežít</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/tPPFQWb/AAAABWz-Jf-TDo6-Di-RWd0n-wssgp-SX0-EENzp-PUq-SAVr-D5-QVW2-Dl-RMUk-Kik-wu6-Ge-Ye-Mmlzn3-Ziby-K6u-PAI1.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-6">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D6&nbsp;Noviny&nbsp;nikdy&nbsp;nespí</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/4M3GcdG/AAAABVl-Im0-Shvh7ffj-GHJLV47-GSb-Lk-Oz-Alr-u-V8slnj7-5-Mqul-KJr-Iqhh-PHm-ZMt-HOfx-Rz5qrb1r6-Q8-GXsr.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-7">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D7&nbsp;Nindžové&nbsp;versus...</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/J7x3J0N/AAAABUGQD2jjaq-NSEe-W1-MUO-Ena-HYD3-Q9-Dl-VU5-Mq9-IDt-Rns-Bb-JZo1il-X68yu7-YUJGb-JXe-N8u-MQgy-PJ0sqe.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-8">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D8&nbsp;Hadostrofa</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/1Q0J0Jk/AAAABVm-ADy-P5-HWxn-Z93kwd-KLM6pv9-Ojp-BLJn4q50-HZL3r-W9zmh4lw-AZBXv-V6-PRCMit-Uv-LWm-Zui7-K8-Bd-R9.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-9">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D9&nbsp;Bezmocný</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/NWrGtwH/AAAABWFa-X117hf-XWWus5hm-PUH4j-Fk0-Vby-Rl-Rt-BHAUNNax-Nh-Wftx-Op-CLl-EQQnr-IE5d3xo-KTMc1thia37nyd-GH.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-10">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D10&nbsp;Starodávné&nbsp;dějiny</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/2kgBSbb/AAAABVLv-Dumzv-O-gl-YHa-VV03z-Ragmt-ZX7-Doibg-ZRSMO8u-H3b-Tj-CZd-Vl-OFHX2q7-J57-W9-NGp-VIf-LGdv6k2kh.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-11">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D11&nbsp;Nikdy&nbsp;nevěř&nbsp;člo...</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/fSHHfd9/AAAABWft-Pg8e13-Dx-A-Anr-MOZSVj0w-BC0bp-UD2m0-CLfmn-V65vhe6c-CBXy-DXB2-ZPO0-CFj-PQK1-D-RV4-GNro5-Rk7.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-12">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D12&nbsp;V&nbsp;obležení</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/VxftSKL/AAAABZUinav-SOr-Jyad-Icg-VPo4-BIOn1b11-4y-QPay-Hab-Xru04wuhv-Vxp4wr-P79-DDQ0nq4j-OVn-UBu3-Q3r-DOKm-J.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-13">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D13&nbsp;Klub&nbsp;Průzkumníků</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/QPyf4Pm/AAAABQl-J0-Zv-YQXL6-X9-Ie-L2s-Ex-Bmbti-XZ1-HB5-Hvp-Ko-IORt-Tn-LUn-Wz-Cv7-Is1-Ze-ZLj8e-JJndh-Ud-I7t-O.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-14">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D14&nbsp;Pomsta&nbsp;je&nbsp;má!</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/NxkSKDB/AAAABckao-L9-Ec-Ij5io-JGKVu-DUp-Tpk7-Jv-B1-Zeh-Jj-Vva-Juyszk-6s-F6d-deql-Rf-R3-Je-Wxqy-D1y0-G-MXxw-z.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-rada-1-epizoda-15">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago</div>
                <div className="svet-text">Ř1D15&nbsp;Chladné&nbsp;sbohem</div>
                <div className="svet-minuty">11 min</div>
                <div className="svet-play">▶</div>
                <img className="img-svet" src="https://i.ibb.co/Lg89R8f/AAAABYXtj-VVSLKFxzib5-E9-OFc-V35n4o-Hq-CPh-Mk5-PMHTGEQp-Mas-Fa-HFj-Arc-OCOBQQp-C3z-OCQIP4-KZ0-BBf-J0.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default SvetNinjagoSlider1;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

   a {
    text-decoration:none;
 }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px; 
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        opacity 1;
    }

    &:hover {
        img {
            opacity 0.4;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
`;
