import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS02() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-1">
              <img src="https://i.ibb.co/ZGM9W1f/AAAABb7z-ABWv3x-XSa7-NXFVOUDXg-K7-Xs-XUzh-HOw-C1-Wr69v-EPf-F-n65-WKY6-P4-SMnr2-LSYXlaox-Qfl-Ur-DHTFC.jpg" loading="eager" />
              <b>1. Temnota ustoupí</b>
              <p>Práce na obnově Ninjago začínají. Naši nindžové si uvědomují, že lord Garmadon vytvořil z jejich ztracených Zlatých zbraní jednu velikou megazbraň.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-2">
              <img src="https://i.ibb.co/Vq1yXZV/AAAABas-P37m-HZca-D-e-OOtf-GKf-JDf-STy-Q8m-EGGRW99-Q3-T8ucxey-PZx-NOa-Wt-Jpr-Es-YIf9-WTRo-X-e07sb-Zp.jpg" loading="eager" />
              <b>2. Piráti verzus nindžové</b>
              <p>Garmadon omylem přivede k vědomí krutého kapitána Sota a jeho piráty. Nindžové pak musí město Ninjago zachránit před vydrancováním.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-3">
              <img src="https://i.ibb.co/Tb359w3/AAAABbb-IGwu-Ri-SSuyms-EFW49mj-tsbbw-LSDo8k-JGq-Noyaq-Ex90-AJPh50-P9f-Oyzl-MOe-ef8-NFFs24-Lad6-Px-OB.jpg" loading="eager" />
              <b>3. Dvojité trable</b>
              <p>Naši hrdinové dostanou pozvání zpět do internátní školy Darkly’s. Zde zjistí, že se jedná o past nastraženou lordem Garmadonem a jeho podlými nindža replikanty.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-4">
              <img src="https://i.ibb.co/nC3kdsP/AAAABa-Q-f3-Uas-ZXwr-Ed-P7kr-Pt-QAxru-Ty-SP6wu-Coj2-Y4a-ULUHSLVv-r7l-ZGb-Yjj-Bm-Gx-J5g-Cp-AHQ2z71-Tj.jpg" loading="eager" />
              <b>4. Závod Ninjaball</b>
              <p>Nindžové zjistí, že Darrethovu Mojo Doju hrozí zničení. Přihlásí se proto na každoroční turnaj NinjaBall Run a snaží se vyhrát finanční odměnu, aby dojo zachránili.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-5">
              <img src="https://i.ibb.co/jMHvqpQ/AAAABd2-DOXlp-IUF3-s-Fuh-R2w-TUjq-Y6-Le1oh-Gb-Dn-Lwb8a-UPa-E3-Ba-XHJb7333w7ynm-C5-Stj39n-X4j3-INWZQ3.jpg" loading="eager" />
              <b>5. Dětská hra</b>
              <p>Nindžové zabrání Garmadonovi v použití megazbraně k oživení zkamenělého monstra. Pak se promění v děti a potřebují Lloydovu pomoc.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-6">
              <img src="https://i.ibb.co/QFcPJVb/AAAABfxu-LBdmjy5-Pud-LB-8-Y4j-NVYCi4v-Fm-Chgi-Nh-Ez-YDovkskc-Ey-Atm-ZCHDIPP8-Dv-m-SJkky-V4e-A39mhvv9.jpg" loading="eager" />
              <b><strong>6. V nesprávný čas na nesprávném místě</strong></b>
              <p>Garmadon se vydá zpět v čase změnit minulost a vytvořit budoucnost bez nindžů. Nindžové ho sledují a snaží se ho pomocí Zlatých zbraní zastavit.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-7">
              <img src="https://i.ibb.co/K7fR2Hg/AAAABd94p-Fso4-Gb2v-N0f-O1-Xkdn-be-Ek-QVdd-Lz-Ghe-WZGo8wf-DBfd-z-Rs-Yg-TZm-D-Rpw-JBgf-Jff-ZX4ig-FXv.jpg" loading="eager" />
              <b>7. Kamenná armáda</b>
              <p>Nindžové se utkají s kamennou sochou samuraje, kterou oživil jed Bájného požírače.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-8">
              <img src="https://i.ibb.co/Z2rTwm8/AAAABdu-BFh-Qstb-UWa-Co-EIHSd-cpm2yf-Ne9-Of-Gc-Nk-ua-Ebii-UIYTTtha3-A6wuho-M0j-L-n80d-BJcbee-URBXz-Z.jpg" loading="eager" />
              <b>8. Den, kdy Ninjago osiřelo</b>
              <p>Nindžové se snaží uprchnout před kamennou armádou, kterou oživil jed Požírače. Garmadon se pokouší dostat armádu pod své velení.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-9">
              <img src="https://i.ibb.co/br5vGhD/AAAABQvl-Gm-Nv-Imm-Rj-C2bu-Ga-Kl-Jwpjy-AD1-Zh-Z3-GGc-YJ4b-L11-GPj-Z8ty-IXWEw4v-Qe-Az-Sz1-DA10v-WH10t.jpg" loading="eager" />
              <b>9. Poslední plavba</b>
              <p>Nindžové, Lloyd, mistr Wu a Misako putují na Temný ostrov hledat Chrám světla, kde mohou obnovit své živelné síly.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-10">
              <img src="https://i.ibb.co/LR05CFC/AAAABQDVQ1gy-Dzz9hk-VKd-BP-4j7-HP9-Vzt-VAu1-Gb-NNLk-FUCHau5-NWXg-Bq-LRZ6-Sr86-WJFVgq-Ujvq1-Dmzazab-B.jpg" loading="eager" />
              <b>10. Ostrov temnot</b>
              <p>Nindžové odrazí útok Kamenné armády a dorazí do Chrámu světla. Zde ovládnou své ryzí živelné síly a soustředí je na Lloyda.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-11">
              <img src="https://i.ibb.co/fYp66LZ/AAAABfys-SVVMe-XEGLWBm-DTo-MPjz3-Hfeb-EX08-49m-ZY8-T3-Hbst-I93z4j-XXqp-A4cg-v-OJbq2mfm-Nbwk-SGd-SEI6.jpg" loading="eager" />
              <b>11. Poslední naděje</b>
              <p>Nindžové se snaží vrátit Garmadonovu Helmu temnoty zpět na její místo v Nebeských hodinách. Chtějí tak zabránit poslední bitvě mezi Lloydem a jeho otcem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-12">
              <img src="https://i.ibb.co/9g51wtH/AAAABauju08p-QXi-N3-KGFBf8j-Nup1r-VPfjmp-AYC-opcii-Rzot-Yzc4r-Rus3il-MJx5e-KL6-Kf-KE-y-UJf4j2-Tn-JGO.jpg" loading="eager" />
              <b>12. Návrat Pána temnot</b>
              <p>Garmadon pomocí své nové superzbraně změní obyvatele vesnice Jamanakai ve zloduchy. Tím naruší rovnováhu a umožní Overlordově duši vstoupit zpět do těla.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-2-epizoda-13">
              <img src="https://i.ibb.co/wymNHxs/AAAABZNKuvux94-SNLzw-O01s-J2-UWy-NA1-N2-JBMZDm84-LFKc0l-Ai-PVCrw13vx-7q-FTDz5-P6-Ht-G1k-T7-Lro6-Np-L.jpg" loading="eager" />
              <b>13. Nejvyšší mistr Spinjitzu</b>
              <p>Garmadonova superzbraň změní obyvatele Ninjaga ve zloduchy. Celé město se ponoří do hluboké temnoty. Nindžové však zjistí, že světlo je mocnější než tma.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS02;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
