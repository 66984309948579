import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS06() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-1">
              <img src="https://i.ibb.co/x61KrfM/AAAABdufv-Wb-Lnh6-Q-afvz-Ix-TQ5n6-Hr9m-GLWvk-Lh2-MYJYBKEh-Nn-OH-siy-ZFrp4p-CClv-Qsg-Jk-Qey-Rqwthmee.jpg" loading="eager" />
              <b>1. Neblaze proslulí</b>
              <p>Ninjové zjistí, že se za ně někdo vydává a dopouští se po celém Ninjagu různých zločinů. Z oslavovaných hrdinů jsou proto najednou psanci, kterým jdou ostatní po krku.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-2">
              <img src="https://i.ibb.co/QfqLQDn/AAAABf-G5j-T4-Nr-Bey-S3e-Pw-GY-P9-QF737-Aa1-Fzd-EOGg-Uu-BQATdp-Ic0-AJ7-R9-Fz-DB8-O8-FOi-Pn-J9-AR2gsx.jpg" loading="eager" />
              <b>2. Veřejný nepřítel číslo jedna</b>
              <p>Nadakhan kuje pikle, aby se zmocnil Říšského krystalu. Prchající Ninjové mají v patách Ronina, který od policie dostal úkol je vypátrat.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-3">
              <img src="https://i.ibb.co/PWXQrB5/AAAABT8myl-X6y6pls8kl-NCKHdsj-Hq66nx-HAhve8e-YFk2k-Q1-V-28s9-GGVc8htthb-z1v-EUF-OJh-Sd42w-DYYk-Jxgo5.jpg" loading="eager" />
              <b>3. Ve vězení</b>
              <p>Ninjové se neprávem ocitnou za mřížemi, kde jim spoluvězeň řekne o vzácném jedu proti džinům, který může Nadakhana zastavit. Museli by pro něj ale na jeden ostrov.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-4">
              <img src="https://i.ibb.co/7rH710x/AAAABcc-Zlhk8-Pef-FVED8-J6yvnc-Hv-BAj-Sv-HN-p-QHb-Fhocb-Pf56-CIh-v-CNp-YYf-THb-SMUe-OWHC9u-Hq-Sz-VOj.jpg" loading="eager" />
              <b>4. Smolná tvrz stoupá</b>
              <p>Nadakhan přesvědčí Jaye, aby si přál vítězství nad Nyou. Důstojník Flintlocke omylem zničí Říšský krystal a Cole ukradne Sotovu mapu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-5">
              <img src="https://i.ibb.co/6Fns70C/AAAABRBt-Hu-Ips-Zgk-K7-Yw-DH7-X9wwsiphe5-M4-O57-Hi8kw-Vg-XOOQea-TNiq-Csh3msi-B6z3d5-QXdb4-Ks6f-Ab-JN.jpg" loading="eager" />
              <b>5. Zbožné přání</b>
              <p>Zmizí velké části Ninjaga a ninjové zamíří na Ostrov tygří vdovy, kde Jay najde vzácný jed. V patách ale mají Nadakhana.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-6">
              <img src="https://i.ibb.co/kB0wYdD/AAAABdx6f4-OPgxs-ICsku0-B1-Bp-RFlx-G-TYCnq4-RFuu-ZR0n-Cbkus-Xq1-Px9-Kz-Kb-UGWz-VB4-LE4t-GZ-zmm-ADOG5.jpg" loading="eager" />
              <b>6. Večeře s Nadakhanem</b>
              <p>Lloyd, Cole a Nya se na voru snaží uprchnout z Ostrova tygří vdovy. Členové Nadakhanovy posádky se chtějí pobavit a nutí Jaye, aby s nimi zápasil.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-7">
              <img src="https://i.ibb.co/n1zj2NB/AAAABYy-Cwnu-Eb-DR2v-L-FGyv-Sdl-XZYb-HWCx-Kec0xp-UYij-Afolipi45k-QRu-IXc-T5uk-Tp91-Vr4t-K10n-Uc-E165.jpg" loading="eager" />
              <b>7. Mistři přání</b>
              <p>Ninjové se naučí mluvit jako piráti a proniknou na Nadakhanovu loď. Chtějí mu podstrčit jed a osvobodit Jaye.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-8">
              <img src="https://i.ibb.co/L0VhScf/AAAABQRC3-WNz-Mb-Rh-PRB0-Yf4-BK-IZF9-XMNOE2rkv-Wg-XEy-Hm-m0w-RRk-DVUX8h7n7-Nbtnoh7-S3-I5-PSf0x09-Scd.jpg" loading="eager" />
              <b>8. Poslední možnost</b>
              <p>Flintlocke se postaví do čela vzpoury. Myslí si totiž, že jakmile Nadakhan získá moc přát si cokoli, tak jeho posádku opustí. Džin ale zvítězí a zajme Nyu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-9">
              <img src="https://i.ibb.co/59HXTcB/AAAABQ0hhgn-PNXckhw-Co6tfp1dgb-JPq-Ky-OEfy-I5o8-Eej6ry-H0-Wm-K2yk-Goq91-C4-JVh52lj-HIb-PPk7t-v-URYDB.jpg" loading="eager" />
              <b>9. Země na obzoru</b>
              <p>Nya musí nacvičovat na svatební obřad s Nadakhanem v Chrámu džinů. Jay chce zachránit přátele a dá dohromady nový tým.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-6-epizoda-10">
              <img src="https://i.ibb.co/HX3YRty/AAAABe-Rjqf7o-g9c-V8y-Ft7d-OK8-XULPDBek-Jc4-ZMLa-PRm-Gy-Km35-PDo5qur-HQMPHV66-AIZMOFLZd-Bj1-Qwd-Vpn.jpg" loading="eager" />
              <b>10. Cesta zpátky</b>
              <p>Nadakhan se ožení s Nyou a získá sílu mít nekonečná přání. Flintlocke s Dračí smyčkou se přidají k ninjům a ve velkolepém souboji si to rozdají s džinem.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS06;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
