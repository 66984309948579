import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoDPSliderS02() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/K6b8JGP/AAAABXC1u-Rkn-He-F6ay-IPh0-Ovf-II974yfg-LXDNDQj-Zo-Jvi-Zv7o-Zr-Wl-Pm-PPU7-Uy-STjx-EIp9-Ipuzw-H3-Xl-U.webp" loading="eager" />
              <b>1. Krvavý měsíc</b>
              <p>Arin a Sora vyšetřují záhadné vloupání na skládku, zatímco nevyspalý Lloyd trpí stresovými sny o válečnících s maskami.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/tPfyG1N/AAAABeu7hj-QAk-RYs-AK-ncka5-RNnd-Dfj-KWCMBbz0-C9-T4a-N-ktqf-9-JZPUBRw-R2a-Jw2-NGc-OJh-Nyhw-Puuv-Gtjs.webp" loading="eager" />
              <b>2. Roztříštěné sny</b>
              <p>Ninjové vyslýchají nešikovného obchodníka ohledně ukradené paličky. Mohl by být prastarý nástroj propojen s archivy Království v mracích – a Lloydovými vizemi?</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/WKgXFB5/AAAABRdvl-N1-Uc-PT6u-GGc-HXvp-UOOi9e-Gqy8-Jwww-IQd917m-OBN-UCnu37-R88jr3n-GOCJm-ELTc01mb4bicm-Cs-VBa.webp" loading="eager" />
              <b>3. Za Jeskyní představ</b>
              <p>Kovová brána. Učitel. A směr...? Lloyd zažívá vizi Zdrojových draků, která posílá ninjy na pravé, ale nejasné pátrání.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/wJp0S3L/AAAABYWm-TKL0-LMBSOKPtn-LXDu-Kw-Lxtzb-MXg-Ka-Hr-T7m-D0-Iu3yom-E4-DNL-cadd-Wm-Ouv-AKhs-4-Sd8-LADHTb-9.webp" loading="eager" />
              <b>4. Síla z východu</b>
              <p>Aby se ninjové setkali s legendárním učitelem z Lloydových vizí, musí projít řadou testů. Splní ale starověký mistr jejich očekávání?</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/CKRkzS9/AAAABYl-O206-PMakb-ZBGMsv-NSq-QBJLF6-Rh7drk-Pv-TYW2-Dkz-Nr-Dl-GDm-BWp-Dx-Xayg-r3x-Vqob-XMvkuq-Eup-EY.webp" loading="eager" />
              <b>5. Kouzlo u vodopádu</b>
              <p>Ninjové začínají svůj trénink, aby porazili Zakázanou pětku, ale Wyldfyre se cítí odstrčena. Bonzle odhalí nečekanou souvislost.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/LNFnRtT/AAAABb7-OM8-Dm-r-A6-E4i8pcg-Y1t-I6-NDy1z-Fmfc6-P1-I-h-Kf-BRtm-K82y81-Mlj7ah4ai-L8k-XG0j-34-A8-DZIM5g.webp" loading="eager" />
              <b>6. Do Mystéria</b>
              <p>Bonzle a její tým se vydávají hledat jistou čarodějku, ale nejprve budou potřebovat mapu. Mezitím Arin bojuje s tréninkem Spinjitzu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/sqgqPfL/AAAABfo-LI9-EGlyi63-Ugdqx-DTf-OVLz-Oxh-RN9m5u-Vj-P3-EZ8-A14kx-Mytr-EXhmsp0-A5k-Pv-Lufi-YVf-IUAR-dk-J.webp" loading="eager" />
              <b>7. Uprchlíci z Šílenství</b>
              <p>S blížícím se Krvavým měsícem požádá Bonzle svou excentrickou stvořitelku o pomoc, zatímco ninjové mají poslední šanci zvládnout techniku ​​Povstávajícího draka.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/yPbH6GQ/AAAABcs-EXk3-PQx-LFr-Ahs-Ko-U6jmicn-Bw-ZFshco-Qh2-Obp-UBYRBEmw0-LWc-G-v-Zs-Bz-Wm-T3i-Ng-w-DMek3w-v5.webp" loading="eager" />
              <b>8. Tajemství Wyldness</b>
              <p>Ninjové míří do Divočiny, kde se musí postavit lordu Rasovi - ať už jsou připraveni nebo ne. Čarodějka odhalí svůj plán, jak udržet Bonzle v bezpečí.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/t4Xj9kM/AAAABc-Soc68-Q8pvpr-jz-VWl-BQVie31rcya-Mx3-ISAy-BIp-Meaijs2-THBd-ICHMkt-TOx-ALg1n-Nns-RCk8r-Gk-SH75.webp" loading="eager" />
              <b>9. Les duchů</b>
              <p>Čarodějka hledá ve skladišti kouzlo na záchranu Bonzle, zatímco Arin zachycuje speciální balíček. Poté zbývající ninjové proniknou do rituálu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/N9VjzcD/AAAABbe4-A3-FNd69xof-Cvnkciug-Du-Pxms-Sf-DKfqo-GSz-G3h-Jon-BMWE71-B9m2-GR27id-Qedxh-GJk0-YHtk-Rs6-E1.webp" loading="eager" />
              <b>10. Povstávající ninja</b>
              <p>S oběťmi, kouzlem a Krvavým měsícem pod kontrolou má Lord Ras vše, co potřebuje k navrácení Zakázané pětky – pokud ho ninjové všas nezastaví!</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/jbS5d93/g3fc41ibexbl.jpg" loading="eager" />
              <b>11. Tvar pohybu</b>
              <p>Při pátrání po rodičích Arin a nindžové zachrání malého draka a odhalí novou záhadu a úkol pro tým.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/rZgLpHd/0qzu2o1lf8xw.jpg" loading="eager" />
              <b>12. Vstupte do Města chrámů</b>
              <p>Když nindžové nejsou pozváni na Turnaj zdrojů, vloupají se do města chrámů, aby si vstup zasloužili.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/9rMRJpB/oxod49h9kep9.jpg" loading="eager" />
              <b>13. Shromáždění na hostině</b>
              <p>Po vstupu na turnaj se ninjové scházejí na hostině s přáteli a starými nepřáteli.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/PCKDQJT/1nkizy7jvp9q.jpg" loading="eager" />
              <b>14. Uvnitř bludiště</b>
              <p>Arin pokračuje v pátrání a Nyu čeká na prvním souboji někdo, koho nikdo nečeká.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/j65zyyR/lg0u716urqc6.jpg" loading="eager" />
              <b>15. Společně padneme</b>
              <p>Wyldfyre se chová nesportovně a ninjové tak musejí na záchranu na Jeskyní závod.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/p3psYbS/27ap0a8trtj9.jpg" loading="eager" />
              <b>16. Pravda a lži</b>
              <p>Arin uvěří děsivému objevu a ninjové mezitím dokončí závod a tím zůstávají v Trurnaji.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/xjdtjt6/ziwii3pqx85o.jpg" loading="eager" />
              <b>17. Roztříštěný meč</b>
              <p>Arin jde hledat stopy do muzea, kde objeví bližší propojení mezi Turnajem a Zdrojovými draky</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/GF2HMCF/puanbm1exw7u.jpg" loading="eager" />
              <b>18. Stopy a podezřelí</b>
              <p>Roby a Bleckt navštěvují zraněného Lloyda, zatímco Arin upírá pozornost na Bleckta</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/qRwMDbr/6eom5t8wgx3k.jpg" loading="eager" />
              <b>19. Poslední hra</b>
              <p>Arin se dozvídá hrozné tajemství o Sloučení, zatímco se Sora chystá na finále proti Noktovi.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-2-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/GWL4cfV/2xzaoblnfp2d.jpg" loading="eager" />
              <b>20. Živly zrady</b>
              <p>Zaítmco Arin, Cole a Zane brání Dračí rohy, živelní mistři urputně bojují s Vlčími maskami.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoDPSliderS02;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -73px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -73px;
  }


    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
