import React, { useState, useEffect, useRef } from 'react'; 
import { useFormik } from 'formik'; 
import * as Yup from 'yup'; 
import { getDocs, query, collection, where } from 'firebase/firestore'; 
import { db } from './firebase'; 
import styled from 'styled-components';  

// Funkce pro načtení uživatelských dat
const fetchUserData = async (id) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('ID', '==', id));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        console.log('Uživatelská data:', userData); // Logování pro kontrolu 

        // Vraťte data uživatele
        return { id: userDoc.id, email: userData.email || 'Není k dispozici', ...userData };
    }

    return null;
};

const SeznamUzivatelu = ({ closePopup }) => {
    const [userData, setUserData] = useState(null);
    const [serverError, setServerError] = useState('');

    const inputRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            id: ''
        },
        validationSchema: Yup.object({
            id: Yup.string().required('ID je povinné').length(5, 'ID musí mít přesně 5 číslic')
        }),
        onSubmit: async () => {
            try {
                const data = await fetchUserData(formik.values.id);
                if (data) {
                    setUserData(data);
                    setServerError('');
                } else {
                    setServerError('Uživatel s tímto ID nebyl nalezen.');
                    setUserData(null);
                }
            } catch (error) {
                console.error('Chyba při načítání uživatele:', error);
                setServerError('Došlo k chybě při načítání uživatele.');
                setUserData(null);
            }
        }
    });

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <ModalOverlay>
            <ModalContent>
                <ModalTitle>HLEDAT UŽIVATELE PODLE ID</ModalTitle>
                <form onSubmit={formik.handleSubmit}>
                    <ModalInput
                        ref={inputRef}
                        type="text"
                        name="id"
                        value={formik.values.id}
                        placeholder="Zadejte ID uživatele"
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ borderColor: formik.touched.id && formik.errors.id ? '#e74c3c' : '#ccc' }}
                    />
                    {formik.touched.id && formik.errors.id ? (
                        <ErrorText>{formik.errors.id}</ErrorText>
                    ) : null}
                    {serverError && (
                        <ErrorText>{serverError}</ErrorText>
                    )}
                    <ModalButtons>
                        <ModalButton type="submit">Vyhledat</ModalButton>
                        <ModalButton type="button" onClick={closePopup}>Zavřít</ModalButton>
                    </ModalButtons>
                </form>
                {userData && (
                    <UserInfo>
                        <UserDetail>ID: {userData.ID}</UserDetail>
                        <UserDetail>Email: {userData.email}</UserDetail>
                        <UserDetail>Role: {userData.role}</UserDetail>
                    </UserInfo>
                )}
            </ModalContent>
        </ModalOverlay>
    );
};

// Stylované komponenty
const ModalOverlay = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #333;
    color: white;
    cursor: default;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
`;

const ModalInput = styled.input`
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    &:focus {
        border-color: #2ecc71;
    }
`;

const ErrorText = styled.p`
    color: #e74c3c;
    margin: 4px 0;
    font-size: 14px;
    text-align: center;
`;

const ModalButtons = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
`;

const ModalButton = styled.button`
    background: #e50914;
    border: none;
    color: #fff;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background: #f40612;
    }
`;

const UserInfo = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const UserDetail = styled.p`
    font-size: 16px;
    margin: 4px 0;
    background-color: #3498db; /* Modré pozadí */
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px; /* Zaoblení rohů */
    width: 100%;
    text-align: center;
    box-sizing: border-box;
`;

export default SeznamUzivatelu;
