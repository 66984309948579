import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function CitydobrodruzstviSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/XCpmRFB/AAAABX4-Q41d4v-n-3n-Uao-CAN8-DHCHo-Nd-Cvt-DT-LDl-Vv-Ih-Syn-By-Mdg-MGp-n-Z7-PWge-J8-Rd1j-x-I-orsao-OS.jpg" loading="eager" />
              <b>1. Cubby a lupiči</b>
              <p>Trik propagace filmu se pokazí a vypustí na město obrovský balónek Policajt Cubby.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/xz8mFtJ/AAAABc-Udpahxnkjl5-8u-Ylm-RL1-KSfvf-A1w-HXWj-Go5-VBb-GYK5g-Jw3tvm5-H5-Zs-JR3-LJd-Q6-Riu2w-XG-c6j4-K0.jpg" loading="eager" />
              <b>2. Brouk Billy</b>
              <p>Náčelník hasičů McCloud omylem koupí nefunkční Twirly Hat pro jejího synovce.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/p3fYsgh/AAAABZ7r-E9-Fx-Klzc-HXPv-Ak-GYRkt-HY7e-Kj-COO8-E21w9-YYo2p-Sd-Q2si-HNt-RBNd0o-Uv-Vds0-Or-Cj-VFH8-TGM.jpg" loading="eager" />
              <b>3. Letecká policie</b>
              <p>Policejní náčelník Wheeler oznamuje vytvoření Sky Police v hodnotě 700 milionů dolarů.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/zXTf6f0/AAAABWZ3r-Grr-Kc-Kr-V4-Lc-Vbtkist-FWq7y-Sfl4-OR-CQ8-CNo-LE69-Ur-COwchswk0d6-Wj9c-LKKs-DDOj-S5v-Ivx3.jpg" loading="eager" />
              <b>4. Průvod a porod</b>
              <p>Policejní kapitán Tom Bennett dostane telefonát, že jeho žena předčasně porodila.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/y0ycgP7/AAAABXQpsy-f-A5-UOb-Of4to-Le-OUSDXEOj-PX4-BTCZc-LAxzmn-OEBq-O27-FNbkgrq-Aely-Tr-FMFe-1-Qmz-FLOs7u-CW.jpg" loading="eager" />
              <b>5. Stavitelský závod</b>
              <p>Fendrich a Sinclair soutěží o vítězství v soutěži o nejvyšší budovy ve městě.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/CMWGbRD/AAAABS2-JAf-ZGur6-CErjd9w9ig4-Q5-V6p-Snd-JImte-LBOCaz6-XNQOxi-LC5-ZDPIuv-J9-KARLIKS-6q3b863vps-Pa-Zg.jpg" loading="eager" />
              <b>6. Harl Hubbs</b>
              <p>Vypravěč mimo obrazovku vypráví jeden den v životě užitečného kutila Harla Hubbse.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/nQ4x6JG/AAAABak-CWMzn4ml1-TWu-VHGk0t-Azxkr2-Bo-PDVLj-Zm-Cg8-VLo-EXu-Zjx-RNk-I4b-Mpdc-Ox-Qc-CKGl-Zry-Xk2-E1-U.jpg" loading="eager" />
              <b>7. Městský dveřník</b>
              <p>Během Dne rukou pryč! svátek, lidé ve městě k ničemu ruce nepoužívají.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/CK4ys5m/AAAABSFcazm2vfi-MXp-B-g-O-Sc-UZj7-UL1e-X84-Tb-Bt-s-RDHVho-T6-H97-c-X3-Ewj-FGkncr-D418aa3nn-Bb-Gq-ERd.jpg" loading="eager" />
              <b>8. Fendrich v divočině</b>
              <p>Přírodovědný dokumentární štáb sleduje miliardáře Fendricha, jako by byl zvíře v divočině.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/867dY08/AAAABYDr-VPujr-U2tjvg-34d-KH0-T0-T9ls-Jb-MRd-T5-Xq-ns-Ft-OAz-I-ws-ZSa-W8-N3j-QDVb-PIuk-C-S483n-Bg7z.jpg" loading="eager" />
              <b>9. Poppy Starr</b>
              <p>Dospívající hvězda Poppy Starr navštíví svého dědečka, seržanta Sama Grizzleda.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/WgJChMs/AAAABbt-MRGdmr6-Xu-XW21-JIBSk-XWh49n-JNv-ARHXio8h-XVGFe-Q-9wl9d-Q0za-MAu-RRx-Qp-TDzy-Vd-ZTh-IMCLNERm.jpg" loading="eager" />
              <b>10. Strašidelné místo</b>
              <p>Billy a Madison ztratí létající disk uvnitř strašidelně vypadajícího domu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/HNmrShR/AAAABYRVs-Yz-Et-Xhlzdx-TB6-ZUv-H4u-YOHUJq-PRQOi-Pd-pky-TH3-AGf0gq-Fo-MBcjp6-Es-df-Uv-Um-L1-Q64-NGm-X.jpg" loading="eager" />
              <b>11. Malá Carol</b>
              <p>Starosta a Carol pracují pozdě, když se začnou dít podivné události.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/MDbyH6k/AAAABdid-ETh-D-g-GH-v-WHH5sk-Tw-X1w-SBN4k-DKvb-Rmf-GBc-S1-A0fmmxesf-Ufyaz-C-ZVb-Lrt-8-0gfl-GQQox0l-C.jpg" loading="eager" />
              <b>12. Mise na Mars</b>
              <p>Hostitel Extreme Sports Dynamo pořádá soutěž o to, kdo se zúčastní mise City Mars.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/g6qJ34P/AAAABUa-Rddx0466-IGz3-8oqx-QDq-VU6sg-Vj-XWZCn8o-Czv-Sg-Af72o-OXf-QKKwft9-WQQd3d2y-EJK76rw-SWz-e-SUXJ.jpg" loading="eager" />
              <b>13. Zločinecké skrýše</b>
              <p>Důstojník Duke DeTain a sanitární pracovnice Shirley Keeper pracují na odhalení totožnosti hlavního zločince.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/jH0TSh9/AAAABec-P5-WC4-Ng-NGyi6s-Z7-Koaj-8-Z241-YVcd15j-Ef6-MNAck-T1tvjnc-Il-Gi-IKsb-Jz-Izau-Dg2-X7-FO2-Tmaf.jpg" loading="eager" />
              <b>14. Buster</b>
              <p>Hasič "Buster" Lloyd dostane šanci prožít svou fantazii být policistou.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/c84zmCS/AAAABZMNNF-7t1id-Ie-Eet-MArnn3-Xs5u-X2frb-Zy-Ukk-Tb2px2-C620egwci-Ha-EPz-TW6bz-Liq-IBNy-ABf-Huy24-EH.jpg" loading="eager" />
              <b>15. Shirley</b>
              <p>Sanitární pracovnice Shirley Keeper vzdělává místní obyvatele, jak se starat o své město.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/rwWtW4D/AAAABTPg8-WCTQzqg-Ihpg-Skmu-ERn-YT4l0-JA8-DEDawf1-Z7-Dlq-IBjx-AR-Xls-NTof-Og-HHya-Fc62-LHIGod2-O-80.jpg" loading="eager" />
              <b>16. 3, 2, 1</b>
              <p>Je den startu velké rakety a celé město má startovací horečku, ale ne všichni jsou nadšeni ze správného důvodu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/Gp2B011/AAAABe-4-Yj-UFdlken-Y9-WP0-Aaz-FHOACrk-R7-WRKOhf-Rjk-B8-D-lc-J58-Vj-IUN6lt2-Kx1-Ahn4-ERj3i-ADVyo-HUZ.jpg" loading="eager" />
              <b>17. Štědrý policejní večer</b>
              <p>Důstojník Duke DeTain hlídkuje na Štědrý den bez partnera.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/hFF2fkK/AAAABX8r-PDJtp-Gw-Gn-YUh-Viq-Qi-If-Gkvfcw-F3q98-Y1-SZOux7-E7-W-d-Yv0meu6-Y44-Oos-ISTIBU-Ov-Z8hkz-A3.jpg" loading="eager" />
              <b>18. Paradveře</b>
              <p>Astronauti se dostanou na oběžnou dráhu Marsu a připravují se k vypuštění svých sond.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/12HKkns/AAAABUf-0-GH5-XDm-S-3r9-Rz8-FZu-LEJQL7e-CGjx-LLFIr4-He-TBrx-NEt-Lj-K94kr0-AHe6-Ruf0lr-Dzym-NI56sg6zw.jpg" loading="eager" />
              <b>19. Práskni dveřmi</b>
              <p>Harl se pokouší zabránit Tippy, aby přinesla dveře Marsu zpět na Zemi.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-1-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/whn5FFW/AAAABfxn-FL56-K-8-Lr-GC3u6x-WK2ilyg-Og-Rl6x-VFa5kz7i-Nl9-QIo-A4l-KQdts-Bwy97-6-Ni17d-J5nnl-Mo-WPIWVR.jpg" loading="eager" />
              <b>20. Útěk z vězení!</b>
              <p>Tippy Dorman, zneuctěný vrátný města, čelí zkoušce století po svém pokusu ovládnout svět.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default CitydobrodruzstviSliderS01;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -62px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -62px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
