import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NexoKnightsSliderS03() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-1">
              <img src="https://i.ibb.co/wY6jCSN/S03E01.webp" loading="eager" />
              <b>1. Oblak</b>
              <p>Nad Knightonem se schyluje k bouři. Monstrox na sebe vzal podobu mraku a zvedá armádu kamenných monster.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-2">
              <img src="https://i.ibb.co/yQkKtXs/S03E02.webp" loading="eager" />
              <b>2. Trocha rezu</b>
              <p>Zatímco Monstrox a Jestro odemykají tajemnou Zakázanou sílu, Merlok 2.0 musí zprovoznit Zkombinované Nexo síly.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-3">
              <img src="https://i.ibb.co/jvPc6CZ/S03E03.webp" loading="eager" />
              <b>3. Hora Thunderstrox</b>
              <p>Jestro a Oblak narazí na sochaře, který pro ně vytvoří hordu zahradních trpaslíků. Pomohou rytířům jejich nové síly?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-4">
              <img src="https://i.ibb.co/3srjbcr/S03E04.webp" loading="eager" />
              <b>4. Prohnilé štestí</b>
              <p>Jestro s Monstroxem vypustí na Knighton Zakázanou sílu, která vše promění v zelenou mazlavou hnilobu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-5">
              <img src="https://i.ibb.co/Yc9ft8k/S03E05.webp" loading="eager" />
              <b>5. Bouře nad Kamenným lesem</b>
              <p>Robot Hoodlum a jeho parta ukradli Nexo Knights jejich štíty. A ve Zkamenělém lese něco ožívá a to se nelíbí rytířům.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-6">
              <img src="https://i.ibb.co/yWCnnk7/S03E06.webp" loading="eager" />
              <b>6. Nezmar v dole</b>
              <p>Vesničané jsou zotročeni, aby vykopali tři starověké válečníky, které chce Monstrox přivést zpět k životu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-7">
              <img src="https://i.ibb.co/NWxcnSZ/S03E07.webp" loading="eager" />
              <b>7. Rytíř v muzeu</b>
              <p>Monstrox se dozví, že v knightonském muzeu je ukrytá tabulka Zakázané síly, a udělá cokoliv, aby ji získal.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-8">
              <img src="https://i.ibb.co/SQXQPmF/S03E08.webp" loading="eager" />
              <b>8. Kameno-lávová masáž</b>
              <p>Staré Lávové příšery jsou zpět! A nová Zakázaná síla je uprostřed jejich nové vesnice Burningham.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-9">
              <img src="https://i.ibb.co/ypFG04r/S03E09.webp" loading="eager" />
              <b>9. Zkamenění</b>
              <p>Tři harpyje unesly Macynu matku. Nexo Knights použijí vše, co je v jejich silách, aby ji zachránili.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-3-epizoda-10">
              <img src="https://i.ibb.co/L5gbHkV/S03E10.webp" loading="eager" />
              <b>10. Ve vedení</b>
              <p>Monstrox roste s každou další Zakázanou silou. Hlavní město Knighton je pod útokem a Clay se proměnil v kámen.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NexoKnightsSliderS03;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }


    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
