import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function DCSerialyaFilmy() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>DC Filmy</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-super-hrdinove-se-spojili">
              <img src="https://i.ibb.co/StVq2Yw/SUPER-H-SE-SPJILI.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-super-hrdinove-batman-do-ligy">
              <img src="https://i.ibb.co/HCcmPxC/LEGO-DC-SUPER-HRDINOVE-BATMAN-DO-LIGY-NAHLEDOVKA.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-liga-spravedlivych-vs-bizarro">
              <img src="https://i.ibb.co/9VKnQ0n/LIGA-SPRAVEDLIVYCH-VS-BIZARRO.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-liga-spravedlivych-vs-legie-zkazy">
              <img src="https://i.ibb.co/mTmWB1w/LIGA-SPRAVEDLIVYCH-VS-LEGIE-ZKAZY.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-liga-spravedlivych-vesmirny-souboj">
              <img src="https://i.ibb.co/12ypmHG/LIGA-SPRAVEDLIVYCH-VESMIRNY-SOUBOJ.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-liga-spravedlivych-utek-z-gothamu">
              <img src="https://i.ibb.co/7KWZ8QG/LIGA-SPRAVEDLIVYCH-UTEK-Z-GOTHAMU.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-batman-film">
              <img src="https://i.ibb.co/XJB6zB3/BATMAN-FILM.png" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-super-hrdinove-flash">
              <img src="https://i.ibb.co/FxtWp3x/DC-SH-FLASH.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-dc-super-hrdinove-aquaman">
              <img src="https://i.ibb.co/KD6YbKV/DC-SUPER-HEROES-AQUA.jpg" />
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default DCSerialyaFilmy;

const Container = styled.div`
margin-top: 450px;
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }
b {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 3px;
   }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    }
`;
