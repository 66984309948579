import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function MonkieKidS03() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-1">
              <img src="https://i.ibb.co/64JjKbX/mgdf143130yk.jpg" loading="eager" />
              <b>1. Na útěku</b>
              <p>Naši hrdinové jsou po útěku před démonem Lady Bone na útěku – potřebují vystopovat mocnou zbraň, aby ji porazili.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-2">
              <img src="https://i.ibb.co/MDMVMhT/v06743c7khd1.jpg" loading="eager" />
              <b>2. Pra-pradrak z Východu</b>
              <p>Hrdinové jsou zajati drakem Východu - Meiin vzdáleným strýcem - a je na Mei, aby je zachránila.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-3">
              <img src="https://i.ibb.co/m8mpkgY/1219459172c59fa66b-w-1.jpg" loading="eager" />
              <b>3. Chytrolín</b>
              <p>Když se jejich dodávka v poušti porouchá, hrdinové uvíznou a jejich jedinou nadějí je zahrát si herní show lstivého démona zlaté rybky.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-4">
              <img src="https://i.ibb.co/yYjQjfq/vzmb0gvu1o2o.jpg" loading="eager" />
              <b>4. Vítězná strana</b>
              <p>Hrdinové se po zastávce v boxech vracejí na trénink, jen aby si Monkie Kid všiml, že míří na východ a ne na západ...</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-5">
              <img src="https://i.ibb.co/7kL7R3N/xdk3d7g4xp9x.jpg" loading="eager" />
              <b>5. Ztráta paměti</b>
              <p>Opičí král, vytržený z mystické meditace, je přesvědčen, že je jeho mladší já, a pomáhá Pigsymu zachránit Tanga.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-6">
              <img src="https://i.ibb.co/gPcLP6D/qnodza8ajto4.jpg" loading="eager" />
              <b>6. První prsten</b>
              <p>Monkie Kid, Mei a Sandy se ocitnou uvězněni v poušti, ale přijde známá postava, aby je zachránila.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-7">
              <img src="https://i.ibb.co/ZYrZSr3/hhycedsdumj3.jpg" loading="eager" />
              <b>7. Vaření s Chang’E</b>
              <p>Při sledování oblíbeného pořadu o vaření Pigsy Vaření s Chang'e si Monkie Kid uvědomí, že druhý prsten je v kuchyni pořadu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-8">
              <img src="https://i.ibb.co/R0q73dX/6dhrlkn9ahux.jpg" loading="eager" />
              <b>8. Na střídačce</b>
              <p>Hrdinové potřebují vyhrát talentovou show, aby získali poslední prsten, ale Tang, který uvízl v roli manažera kapely, je netrpělivý.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-9">
              <img src="https://i.ibb.co/rpbkmDk/rc3cog80ttv3.jpg" loading="eager" />
              <b>9. Král, Princ a Stín</b>
              <p>Opičí král zadrží Nezhu, zatímco prsteny jsou odneseny do mystické hory, ale pak dorazí Makak.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-10">
              <img src="https://i.ibb.co/4gztDt0/yd2k7yu1kpc2.jpg" loading="eager" />
              <b>10. Oheň Samadhi</b>
              <p>Tajemství se objevují, když se oheň Samádhi znovu ukuje, Mei se stane něco hrozného a tým hrdinů je dotlačen k bodu zlomu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/monkie-kid-sezona-3-epizoda-11">
              <img src="https://i.ibb.co/fCnZHzD/f84wr3tmsuvm.jpg" loading="eager" />
              <b>11. Nedokonalý svět</b>
              <p>Monkie Kid a Ne Zha se zoufale snaží zastavit Opičího krále, než bude příliš pozdě, a Mei se naučí, jak ovládat oheň Samadhi.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default MonkieKidS03;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
