import React from 'react';
import { Helmet } from 'react-helmet';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import $ from 'jquery';
import Atlantis2 from './Atlantis2';

function Atlantis() {
  $(window).scroll(() => {
    const st = $(window).scrollTop();
    const range = 420; // finetune this to the desired effect
    $('.scdown').css('opacity', 1 - st / range); // animate your element
  });

  window.addEventListener('DOMContentLoaded', () => {
    const tabs = document.querySelectorAll('[role="tab"]');
    const tabList = document.querySelector('[role="tablist"]');

    // Add a click event handler to each tab
    tabs.forEach((tab) => {
      tab.addEventListener('click', changeTabs);
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener('keydown', (e) => {
      // Move right
      if (e.keyCode === 39 || e.keyCode === 37) {
        tabs[tabFocus].setAttribute('tabindex', -1);
        if (e.keyCode === 39) {
          tabFocus++;
          // If we're at the end, go to the start
          if (tabFocus >= tabs.length) {
            tabFocus = 0;
          }
          // Move left
        } else if (e.keyCode === 37) {
          tabFocus--;
          // If we're at the start, move to the end
          if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
          }
        }

        tabs[tabFocus].setAttribute('tabindex', 0);
        tabs[tabFocus].focus();
      }
    });
  });

  function changeTabs(e) {
    const { target } = e;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')

      .forEach((t) => t.setAttribute('aria-selected', false));

    // Set this tab as selected
    target.setAttribute('aria-selected', true);

    // Hide all tab panels
    grandparent
      .querySelectorAll('[role="tabpanel"]')
      .forEach((p) => p.setAttribute('hidden', true));

    // Show the selected panel
    grandparent.parentNode
      .querySelector(`#${target.getAttribute('aria-controls')}`)
      .removeAttribute('hidden');
  }

  function changeTabs(e) {
    const { target } = e;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((t) => t.setAttribute('aria-selected', false));

    // Set this tab as selected
    target.setAttribute('aria-selected', true);

    // Hide all tab panels
    grandparent
      .querySelectorAll('[role="tabpanel"]')
      .forEach((p) => p.setAttribute('hidden', true));

    // Show the selected panel
    grandparent.parentNode
      .querySelector(`#${target.getAttribute('aria-controls')}`)
      .removeAttribute('hidden');
  }

  return (
    <Container>
      <Helmet><title>Star Wars: Historky z odboje | NCE+</title></Helmet>
      <Background>
        <img className="scdown" src="https://i.ibb.co/G9NXSpg/ATLANTIS-BG.png" loading="eager" />
        <div className="bgcolor" bis_skin_checked="1" />
      </Background>
      <ImageTitle>
      <img src="https://i.ibb.co/Lz50dNG/LEGO-ATLANTIS.png" loading="eager" />
      </ImageTitle>
      <Controls>
      <PlayButton>
          <a href="/atlantis-sezona-1-epizoda-1">
            <img className="playbutton-icon" src="https://i.ibb.co/r0yJhL4/play-icon-black.png" loading="eager" />
            <span>PŘEHRÁT 1 EPIZODU</span>
          </a>
        </PlayButton>
        <Tooltip title="Celá řada v HD" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
          <HD>
            <span>HD</span>
          </HD>
        </Tooltip>
        <DABING>
          <Tooltip title="Dabing" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
            <img src="https://i.ibb.co/TqkqFsF/DUB.png" loading="eager" />
          </Tooltip>
        </DABING>
      </Controls>
      <SubTitle>
      <zanry>2010 | Rodinný / Dobrodružný / Akční • 1 řada</zanry>
      </SubTitle>
      <Description>
      <zanry>
          Profesorka Samantha Rhodes, archeoložka a odbornice na starověké jazyky,
          <br />
          objevila na dně oceánu trojúhelníkovou mapu a věří že je to mapa Atlantidy.
        </zanry>
      </Description>
      <NavMenu>
        <div className="hlavni-tab3">
          <h1 className="hlavni-tab3">
            SEZÓNY
          </h1>
        </div>
      </NavMenu>
      <Atlantis2 />
    </Container>
  );
}

export default Atlantis;

const NavMenu = styled.div`
@media (max-width: 768px) {
  font-size: 22px;
  justify-content: space-between;
border-radius: 4px;
padding: 0 0px;
margin-right: 0px;
letter-spacing: 1.8px;
bottom 11;
}
font-size: 22px;
border-radius: 4px;
padding: 0 60px;
margin-right: 22px;
letter-spacing: 1.8px;
bottom 11;
a {
  text-decoration:none;
}
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    @media (max-width: 768px) {
      height: 100vh;
      width: 100vw;
      background-size: cover;
      background-position: center;
    }
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const ImageTitle = styled.div`
margin-left: -100px;
    height: 35vh;
    min-height: 10px;
    width: 30vw;
    min-width: 100px;

    a {
      text-decoration:none;
    }

    img {
        @media (max-width: 768px) {
            width:180%;
            margin-top: 110px;
            margin-left: 120px;
            }
        width: 90%;
        height: 90%;
        margin-top: 50px;
        margin-left: 145px;
        object-fit: contain;
    }
`;



const Controls = styled.div`
    display: flex;
    align-items: center;
`;

const PlayButton = styled.button`
@media (max-width: 768px) {
  height:44px;
  font-size: 11px;
  padding: 0 0px;
  margin-left: 30px;
}

font-family: 'Kanit', sans-serif;
    border-radius: 4px;
    font-size: 18px;
    padding: 0 10px;
    margin-left: 62px;
    display: flex;
    align-items: center;
    height:56px;
    background: rgb(249, 249, 249);
    border: none;
    letter-spacing: 0.3px;
    justify-content: flex-end;
    flex-flow: row nowrap;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    bottom 55;

    a {
    text-decoration:none;
    display: flex;
    align-items: center;
    padding: 0 12px;
    }

    span {
      padding: 0 10px;
      color: rgb(0, 0, 0);
    }

    &:hover {
        background: rgb(198, 198, 198);
        transition: all 0.3s ease-in-out 0s;
    }
`;

const HD = styled.button`
    margin-left: 14px;
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15%;
    border: 1px solid white;
    background-color: rgba(51, 46, 49, 0.67);
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;

    span {
        font-size: 20px;
        font-family: 'Russo One', sans-serif;
        color: white;
    }

    &:hover {
      transition: background-color 0.3s ease;
      background-color: rgb(0, 0, 0);
  }
`;

const DABING = styled.button`
    margin-left: 12px;
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15%;
    border: 1px solid white;
    background-color: rgba(51, 46, 49, 0.67);
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;

    img {
    width: 32px;
    }

    span {
        font-size: 20px;
        font-family: 'Russo One', sans-serif;
        color: white;
    }

    &:hover {
      transition: background-color 0.3s ease;
      background-color: rgb(0, 0, 0);
  }
`;

const SubTitle = styled.div`
@media (max-width: 768px) {
  margin-left: 30px;
}
    color: rgb(249, 249, 249);
    font-size: 15px;
    min-height: 20px;
    margin-top: 26px;
    margin-left: 62px;
`;

const Description = styled.div`
@media (max-width: 768px) {
  margin-left: 30px;
}
    line-height: 1.4;
    color: rgb(249, 249, 249);
    font-size: 20px;
    margin-top: 16px;
    margin-left: 62px;
`;

const Container = styled.div`
      font-size: 25px;
      padding: 0 4px;
      overflow: hidden;
`;
