import React, { useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #040714; // Tmavě modré pozadí
    position: fixed; // Použij fixed místo absolute
    top: 0;
    left: 0;
    z-index: 9999; // Zajišťuje, že loader bude nad ostatními prvky
`;

const Loader = () => {
    useEffect(() => {
        // Zakázat scrollování
        document.body.style.overflow = '';

        return () => {
            // Obnovit scrollování
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <LoaderWrapper>
            <ThreeCircles
                height="80"
                width="80"
                color="#ffffff" // Bílé kruhy
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#007bff" // Modrá barva pro vnější kruh
                innerCircleColor="#ffffff" // Bílé barva pro vnitřní kruh
                middleCircleColor="#007bff" // Modrá barva pro střední kruh
            />
        </LoaderWrapper>
    );
};

export default Loader;
