import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function CitydobrodruzstviSliderS04() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek />
      <Carousel {...settings}>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/QmY4G6h/AAAABeu-KPP9pdzpb-PXom6xj-Qti9w-Oa1-r-Pj-T3-Ux-La2-Ggh-Qj1-s-MUq-JOn1vj-NWIc-Tq7q-CGoo7-Ry3t-Mf-MAT.jpg" loading="eager" />
              <b>1. Povyk na radnici</b>
              <p>Při záchraně Maddyiny matky, když se na radnici spustí bezpečnostní systém, najdou Billy a Maddy stopu k identitě zakladatele města.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/88JtRY3/AAAABXLDPYB0n-K66a-K-2-SMXs-Lqn-Caj-I3k-Ug7-VJ0-GGGM5mp1-WCEHBw-Wmm5-Gz-FFb-A-zc-t1-I6l-DU7-RH-2-UBy.jpg" loading="eager" />
              <b>2. Kdo je Ludmilla Ersatzová?</b>
              <p>S pomocí dětí si Daisy Kaboom Lewisová vytvoří alter ego, aby na sebe vzala vinu za své minulé prohřešky, ale je těžké udržet si dvě identity.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/VvvTVhR/AAAABSt6t-BBNGAk-Wd-V8q-Rll-wf8-s-Fio-HXVv-Tb-Xf-NGEo9-NCI1dewt-YJTs1-H1jq7-QB3u-M-z-Ndz-PZg-Qtu6-CL.jpg" loading="eager" />
              <b>3. Základna na Měsíci hlásí krizi!</b>
              <p>Billy musí zachránit svého hrdinu astronauta, když má kapitán Momentous halucinace o mimozemské invazi poté, co snědl prošlé astronautské jídlo.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/TH5qXTx/AAAABT1-H4f-Ok-R9-Vg-YDn-j-Fq-U5noqr-F3-Dy7-KMwjb-Gf7zyi7-Ki0-H1jj7-OGow-Tl3wt-Vn0bc-Mg-QRRrzxix6y-W.jpg" loading="eager" />
              <b>4. Bezohledná jízda</b>
              <p>Billy and Maddy must help Stunt Driver Wallop pass a safe driving course, or risk being banned from city streets forever.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/Syx2Z5N/AAAABXm-Ai-GSaj-OYLl-B378s-Uc-EXr-B7-Icm19sj1-A8-LPuf8-P0-Pd-KMr-ZVl7-Uh-LZHj-KXQtmq-Gu-Du-K9w-Tp-Fl.jpg" loading="eager" />
              <b>5. Tohle nebude fungovat!</b>
              <p>Když se hasiči po poškození své stanice dočasně přestěhují k policii, dojde ke střetu kultur.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/8b9RLPf/AAAABa-Gg-FZWcn-GZw4l-R6kf-Gv-T6-BDPfxqj-YBe-TPTlpvm-Xuqbk-Wv-Nk2-JYi-DRknt-Ucb-Hyd-Llt-A7go3js-Cq7p.jpg" loading="eager" />
              <b>6. Trojský automat</b>
              <p>Zmrzlinářští bandité přepadnou policejní sbor se zmrzlými nápoji a způsobí policistům ledové zmrazení mozku.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/7pZ62bG/AAAABecx-Jwp-OR2nd-Gw-Flc7-MGz-N76trg-JD-d-N1-BEv-A5-Oh-UHUD1j-Ab5w5-Yl-MYa-Qt-Pfh7-Tc-Prghz-VMMTKsb.jpg" loading="eager" />
              <b>7. Rozená režisérka</b>
              <p>Když Maddy nabídne režii videa o požární bezpečnosti, její filmařské ambice se rozhoří.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/k3CjKKz/AAAABRczj2-FM3s-G1k-OBVysya-Naun-L2-RXEHIYT-5v-Gppbrm-Bzyc-q-KTMOHDM4-E5-BJbsq-NBBPD6-KBBMRwo0jzn7-K.jpg" loading="eager" />
              <b>8. Wexlerova domněnka o deflektoru</b>
              <p>Dr. Wexler v depresi odchází z vědy. Když však město ohrozí meteorit, Billy a Maddy ho musí přesvědčit, aby se vrátil!</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/r59HCbP/AAAABb-SXQQu-Rz6yhlto1-Xq5yx-Za-FIZp-XMMv3-S4mz-Geew-JD42-X7-Bsb-EYk-Fh-EL47-RDYadha-KIVk-Lsia-U2-W1.jpg" loading="eager" />
              <b>9. Billy jede na max</b>
              <p>Billy se obává, že nesplní mámino očekávání, když je přihlásí do týmové fyzické soutěže na charitativní akci.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/wskXVkf/AAAABZ7-Lg6-TNDezvc0tm37-B-Xs-Oiy3-m4-Us-Myc-Zhuqtjz-Wj-USDPG6-Auv-F776-G6ax-CIf-v-OOh-IZPq4k-CI31-C.jpg" loading="eager" />
              <b>10. Hodný, zlý… a želvy</b>
              <p>Maddy se ujme dvou ztracených želv, aniž by věděla, že patří rivalům Sinclairovi a Fendrichovi, kteří se domnívají, že je unesla.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/ZHb8tR0/AAAABT47-FMvw-DVz-CI4tmv-N5-Ie-J1-Ea-QWo-P945-Yr5-UHhtds-uc-Sd-Lf-HZDm7i2-IO8w4-YJt-Ly-Ghv32-QSNem-B.jpg" loading="eager" />
              <b>11. Den s Dreadem</b>
              <p>Když jsou jejich nejlepší přátelé mimo město, stráví Maddy a Dread den společně a snaží se najít společnou ř</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/PQzPCWF/AAAABd-VX0-PK7-Ywrxyos-k-Sb-KN85e-Ufg2-CM45u-U-6-BB9113-Rvr-BBEkqpud2-Urf-Oeew-NNNn-LUQpr-Uc-X-js-Ag.jpg" loading="eager" />
              <b>12. Pan Zelí kupuje farmu</b>
              <p>Poté, co si koupí farmu na pěstování vlastních produktů, je pan Produce přesvědčen, že po něm jdou sousední farmáři.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/DwpxZP9/AAAABQq-DRd-D1owd-D39-Ny-Na-fo-G1-Jie-Zdokvyau-Pxm-Kn01-Y6f94-XJYLln4-Gcn-EQp-JXWJC8716-O2g-TOyz-Cmd.jpg" loading="eager" />
              <b>13. Tohle patří Harlovi</b>
              <p>Fendrich a Sinclair uzavřou nepravděpodobné spojenectví, aby se pokusili vyhnat Harla z nezastavěného pozemku, který vlastní.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/drY10RR/AAAABYqz2-Fz-emvn-T8z91-Nv9f-PEz75-Ih-OUr-KGZM9-TUe5-L-Naozy-Ep19s3sg9-Iuq-EFBq4fh-Cp-KS2n7-Hs-Nmq-A.jpg" loading="eager" />
              <b>14. Shirley jede</b>
              <p>Shirley Keeper proniká do městského podsvětí, aby získala tajný vzorec pro novou příchuť Vita Rush.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/SV2M4KS/AAAABUHV8h-Oy-Qk-Hw-NGz-JNN1-T4sa33-Nh-Of9-O3-Ic-Y2-Jt-PUQm-W2u44fuq1-C3-Zw0-Q4fh211-MAPsv-R2r-TRzaz.jpg" loading="eager" />
              <b>15. Ano, ne, možná</b>
              <p>Aby mohl postavit továrnu nešetrnou k životnímu prostředí, využívá Fendrich potřeby starosty Flecka být oblíbený a schválit svůj projekt.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/v4X5Ht1/AAAABb9-VK22eo7-A90a9-Jp-MTAu7s-Tf-Yh-X558-Y0v-WRbhn-VA9wld-BT3-J9v3-Qa-A8-Da-MJSm-Y2a-CB5-Msi-Nh-XG.jpg" loading="eager" />
              <b>16. Šedivý a šedivější</b>
              <p>Do města se vrací první parťák detektiva Grizzleda se zlozvyky, což způsobuje frustraci jeho současnému parťákovi Rookymu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/G9HW3Qg/AAAABR6rm0-4-QJC1pf1r-OJb-GNi-CHge9-NESO7-P26-Q17w-P-R0-MDi-HHr-BEy-QNin-Op-Q1-FEsm-V8c3o-JV8-HVl-DW.jpg" loading="eager" />
              <b>17. Prostě Freya</b>
              <p>Děti si všimnou, že Freya je při rozhodování o požáru v rutině, a tak jí pomohou dostat se zpátky do pohody.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/vzQ5qRg/AAAABa9-Z8-Zjfl3c5a-T0u3k3-Ixfz-Z-mz5-A0e-Uy-Xr-z-CEQGRir-Y9-Djy5v2-F66r-TXn-JEw-TKh-Qm-QATq-UES530.jpg" loading="eager" />
              <b>18. Kde vězí šéf ovcí?</b>
              <p>Když se děti dozvědí, že Fendrich vlastní město, potřebují pomoc náčelníka Wheelera, ale ten se možná kouzlem proměnil v ovci.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/T8skryS/AAAABWs-CC7s-Yc-YKUc-WYCMXrwm-XYr-E34q-Mesgb-PMEfjha1-P52t0i-P-Yg8q7ter-L8-Sgz-Cdpu-L0o-Xkk-Ss-I-n-W.jpg" loading="eager" />
              <b>19. Král Fendrich I</b>
              <p>Billy, Maddy a jejich přátelé se brání Fendrichově železné vládě a zoufale se snaží dokázat, že mu město ve skutečnosti nepatří.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-4-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/g6RKmQ7/AAAABfr-Cb-ZXXU1wy-Izl71m-BREb-ZKVh7-B7-Qk-X-CXOHqw-Bl34-UU9-XCx0z-YJt-R0-LItd-Bn-e-W8f-Y-n-PDu-X6-D.jpg" loading="eager" />
              <b>20. Král Fendrich II</b>
              <p>Chvíli předtím, než se Fendrichovi podaří zbourat radnici, se Billy a Maddy dozvědí šokující pravdu o tom, komu město patří.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default CitydobrodruzstviSliderS04;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -62px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -62px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
