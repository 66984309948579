import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function ChimaSliderS03() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-1">
              <img src="https://i.ibb.co/kHpBw83/CS03E01.webp" loading="eager" />
              <b>1. Ohnivý sen</b>
              <p>Hluboko v Soutěsce věčné hlubiny se probouzí starobylé kmeny Šavlozubých tygrů, Mamutů a Supů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-2">
              <img src="https://i.ibb.co/VHLVRNQ/CS03E02.webp" loading="eager" />
              <b>2. Útok Ledového klanu</b>
              <p>Craggerův domov napadnou nové ledové kmeny a Cragger se tak musí znovu stát králem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-3">
              <img src="https://i.ibb.co/VSPh3br/CS03E03.webp" loading="eager" />
              <b>3. Volání hory Cavora</b>
              <p>Erisiny sny o ohnivém městě vedou Lavala a Craggera na horu Cavora.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-4">
              <img src="https://i.ibb.co/g71PgLC/CS03E04.webp" loading="eager" />
              <b>4. Zkouška ohněm</b>
              <p>Laval, Cragger a Eris se dozvídají o tom, co se v Chimě dělo před Příběhem příběhů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-5">
              <img src="https://i.ibb.co/F812qP4/CS03E05.webp" loading="eager" />
              <b>5. Půlměsíc</b>
              <p>Kmeny Chimy musejí k získání Ohnivé CHI střežit kopec uprostřed ničeho.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-6">
              <img src="https://i.ibb.co/Bs3X0jJ/CS03E06.webp" loading="eager" />
              <b>6. Žhavý boj</b>
              <p>Laval s Craggerem mají za úkol hlídat fénixího kluka Flinxe, zatímco ostatní vedou boj proti lovcům.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-7">
              <img src="https://i.ibb.co/7vpMkwZ/CS03E07.webp" loading="eager" />
              <b>7. Zmrazit a do sbírky</b>
              <p>Sir Fangar buduje svoji ledovou sbírku, do které mu schází jediný kus: Flinx.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-8">
              <img src="https://i.ibb.co/njsrfh3/CS03E08.webp" loading="eager" />
              <b>8. Lavinový efekt</b>
              <p>Eris začíná mít vidiny tajemného pantera a Mamuti si na ledu hrají s Nosorožci.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-9">
              <img src="https://i.ibb.co/GdD87z6/CS03E09.webp" loading="eager" />
              <b>9. Královské povinnosti</b>
              <p>Laval se musí ujmout královských povinností a vymyslet, jak zachránit svého otce.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-10">
              <img src="https://i.ibb.co/tbXJXhp/CS03E10.webp" loading="eager" />
              <b>10. Hodně kluzký svah</b>
              <p>Tormak s Lavalem vedou odvážný útok na ledovou horu, aby zachránili Li'Ellu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-11">
              <img src="https://i.ibb.co/Pcmvvw1/CS03E11.webp" loading="eager" />
              <b>11. Artefakt</b>
              <p>Sir Fangar nabízí Tormakovi, že vymění Li'Ellu za prastarý Artefakt fénixů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-12">
              <img src="https://i.ibb.co/Yjtx7c4/CS03E12.webp" loading="eager" />
              <b>12. Fénix přistál</b>
              <p>Li'Ella se připravuje na svatbu se Sirem Fangarem a Flinx jde sám proti Lovcům.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-13">
              <img src="https://i.ibb.co/NLDkqSR/CS03E13.webp" loading="eager" />
              <b>13. Jiskra naděje</b>
              <p>Laval narazí na dně soutěsky na strýce, který by jim mohl pomoci dostat se ven.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-14">
              <img src="https://i.ibb.co/txGbhzk/CS03E14.webp" loading="eager" />
              <b>14. Ohnivá křídla</b>
              <p>Tormak pomůže ostatním s hledáním postrojů na ohnivá křídla.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-3-epizoda-15">
              <img src="https://i.ibb.co/Dk1sVZk/CS03E15.webp " loading="eager" />
              <b>15. Srdce Cavory</b>
              <p>Laval a ostatní musí spustit velké osvícení dříve, než Lovci ovládnou horu Cavora.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default ChimaSliderS03;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -56px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -56px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
