import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS03() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-1">
              <img src="https://i.ibb.co/GV6fyMb/AAAABVp-SA7hh-X-Sym-WZqf-Cxy-V3k1-p0hs-LGyl-GRa-F1-3-IHz-HT0y-He-OA72hfz-ZNKy-KU0rd-5-Zr-Hy-Q4-Xyxp.jpg" loading="eager" />
              <b>1. Rázová vlna</b>
              <p>Po porážce Overlorda probíhá obnova města Ninjago. Nindžové vyučují na akademii mistra Wu. Mír však netrvá dlouho.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-2">
              <img src="https://i.ibb.co/cD5m6vt/AAAABXrd-Ui-D5-Zdk-FL4-Bcjd-W9-QHj-Wui-Vz-RZy-AQct-EOx-RGN2-TGDZv-WJPOCr-Qf-Fdl-LOknp-yvk-Cf1-QGJttp.jpg" loading="eager" />
              <b>2. Umění tiché pěsti</b>
              <p>Na cestě do kláštera mistra Garmadona jsou nindžové pronásledováni Nindroidy. Zpět do města Ninjago proklouznou v přestrojení za cirkus.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-3">
              <img src="https://i.ibb.co/2NwmJVP/AAAABQ3-Lq-QKd-ZQTNe4vx-Hs27t-FB7-Q3z-k-RN6nkud7-Yuui-T7f-Xtmm-DOmt-N5-Ib-2jah3-ZXn-Ut-TYUf7jnyw-Hh.jpg" loading="eager" />
              <b>3. Výpadek</b>
              <p>Nepřátelé útočí ze všech stran. K záchraně P.I.X.A.L. obětuje Zane polovinu svých sil. Lloyd a jeho otec jsou na cestě do Hirošiho labyrintu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-4">
              <img src="https://i.ibb.co/cFPR8Xj/AAAABWn-FOqi-Sw-Pv-ORkg-Mp-KTtzn-RZN7-TKKWZ6-Au-XDBo-LT7-DHDq47tcv4-HG9c3-USrzvro-WOrnwy0-ZBKA0-J5-A.jpg" loading="eager" />
              <b>4. Kletba Zlatého mistra</b>
              <p>Ninžové objeví tajemství Serpentinů, po jejichž boku bojují v podzemí města Ninjago proti Nindroidům.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-5">
              <img src="https://i.ibb.co/D86cF7v/AAAABS977-Reur-Xwqt-C3j6325-Y7-K3-SHJIk8jny-OCTUgud-FSJr-W3-AKYh-Jn-Kno-Qm2-M4-V7fak-ZJs-JQGq-Oal1-U.jpg" loading="eager" />
              <b>5. Vzhůru do digivesmíru</b>
              <p>Ninjové putují do Digiverse, aby se střetli s Digitálním Overlordem. Pythor a Wu mezitím vedou útok Nindroidů na Věž Borgů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-6">
              <img src="https://i.ibb.co/rfzYn1D/AAAABYOWRB9-T1-Oqwe-Ck-Sv-Q54qp-Yd-NBWPWz-PTHp-ILQBs-Pmz9-JOh-y5-S3to-N9w6-Qpf-Tp-Oq-Ss-U63-Jg53-I-s.jpg" loading="eager" />
              <b>6. Krycí jméno: Arkturus</b>
              <p>Pythor a Nindroidi osvobozují Digitálního Overlorda. Wu a Garmadon mezitím náhodou objeví Pythorův tajný plán: Projekt Arcturus.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-7">
              <img src="https://i.ibb.co/M501smR/AAAABch-VG0-SC-7-Wh-A-rc-Xj-LG9-FTFC-7-Tk0g-Udzi-Ig-RGWcfr-O6r-Cf8j-Zt-BTs-Utqlj-Zvn-Epxvb-Ie-Cb-CK.jpg" loading="eager" />
              <b>7. Pustina</b>
              <p>Po bitvě s Nindroidy mimo palubu lodi Arcturus míří plavidlo i s nindži do jádra komety, kde bude boj pokračovat.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-3-epizoda-8">
              <img src="https://i.ibb.co/DzYKXF4/AAAABQ-7b9k-Od-Adkc-Z3-Ewj3-V8y-FSx-MGFlik-WU78ad-ZCMQ2h-RI2-GTrst-C7-Jnlr8-Bb-NP9n793r-Zk-CAB3k-MA1.jpg" loading="eager" />
              <b>8. Titanový nindža</b>
              <p>Nindroidi promění pomocí Zlatých zbraní Overlorda ve Zlatého mistra. V troskách města Ninjago se nindžové připravují na poslední záchvěv odporu.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS03;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
