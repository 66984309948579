import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import React from 'react';
import VIPobsahlist from './VIPobsahlist';
import Bottom from '../Bottom';
import { useAuth } from '../../contexts/AuthContext';

function VIPobsah(props) {
  const { role } = useAuth();
  const allowedRoles = ['Majitel', 'Admin', 'Moderátor', 'VIP'];

  return (
    <Container>
      <Helmet>
        <title>VIP Obsah | NCE+</title>
        <meta name="description" content="Spousta zábavy u VIP obsahu na NCE+" />
      </Helmet>
      {allowedRoles.includes(role) ? (
        <>
          <Background>
            <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" alt="Background" />
          </Background>
          <Content>
            <VIPobsahlist />
          </Content>
          <BottomWrapper>
            <Bottom />
          </BottomWrapper>
        </>
      ) : (
        <div className="access-denied">
          <h2>Pro tento obsah potřebuješ VIP</h2>
        </div>
      )}
    </Container>
  );
}

export default VIPobsah;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Background = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
  }
`;

const Content = styled.div`
  overflow: hidden;
  flex: 1;
  padding: 24px;
`;

const BottomWrapper = styled.div`
  margin-top: auto;
`;
