import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import $ from 'jquery';
import Wyldfyreshorts2 from './Wyldfyreshorts2';
import { useAuth } from '../../contexts/AuthContext';
import Bottom from '../Bottom';

function Wyldfyreshorts() {

  const { user, role } = useAuth();
  const allowedRoles = ['Majitel', 'Admin', 'Moderátor', 'VIP'];

  useEffect(() => {
    // Scroll effect
    $(window).scroll(() => {
      const st = $(window).scrollTop();
      const range = 420; // finetune this to the desired effect
      $('.scdown').css('opacity', 1 - st / range); // animate your element
    });

    // DOMContentLoaded event logic
    const tabs = document.querySelectorAll('[role="tab"]');
    const tabList = document.querySelector('[role="tablist"]');

    if (tabs.length && tabList) {
      // Add a click event handler to each tab
      tabs.forEach((tab) => {
        tab.addEventListener('click', changeTabs);
      });

      // Enable arrow navigation between tabs in the tab list
      let tabFocus = 0;

      tabList.addEventListener('keydown', (e) => {
        // Move right
        if (e.keyCode === 39 || e.keyCode === 37) {
          tabs[tabFocus].setAttribute('tabindex', -1);
          if (e.keyCode === 39) {
            tabFocus++;
            // If we're at the end, go to the start
            if (tabFocus >= tabs.length) {
              tabFocus = 0;
            }
            // Move left
          } else if (e.keyCode === 37) {
            tabFocus--;
            // If we're at the start, move to the end
            if (tabFocus < 0) {
              tabFocus = tabs.length - 1;
            }
          }

          tabs[tabFocus].setAttribute('tabindex', 0);
          tabs[tabFocus].focus();
        }
      });
    }
    
    return () => {
      // Cleanup listeners on component unmount
      if (tabs.length) {
        tabs.forEach((tab) => {
          tab.removeEventListener('click', changeTabs);
        });
      }
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  function changeTabs(e) {
    const { target } = e;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((t) => t.setAttribute('aria-selected', false));

    // Set this tab as selected
    target.setAttribute('aria-selected', true);

    // Hide all tab panels
    grandparent
      .querySelectorAll('[role="tabpanel"]')
      .forEach((p) => p.setAttribute('hidden', true));

    // Show the selected panel
    grandparent.parentNode
      .querySelector(`#${target.getAttribute('aria-controls')}`)
      .removeAttribute('hidden');
  }

  return (
    <Container>
      <Helmet><title>Ninjago: Wyldfyre: Návrat do divočiny | NCE+</title></Helmet>
      {allowedRoles.includes(role) ? (
        <>
          <Background>
            <img className="scdown" src="https://i.ibb.co/q9WdTsL/Poster-NCE.png" loading="eager" />
            <div className="bgcolor" bis_skin_checked="1" />
          </Background>
          <ImageTitle>
            <img src="https://i.ibb.co/B2TD9qG/LogoCZ.png" loading="eager" />
          </ImageTitle>
          <Controls>
            <PlayButton>
              <a href="/ninjago-wyldfyre-navrat-do-divociny-sezona-1-epizoda-1">
                <img className="playbutton-icon" src="https://i.ibb.co/r0yJhL4/play-icon-black.png" loading="eager" />
                <span>PŘEHRÁT 1 EPIZODU</span>
              </a>
            </PlayButton>
            <Tooltip title="Celá řada v Full HD" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
              <HD>
                <span>FULL HD</span>
              </HD>
            </Tooltip>
            <DABING>
              <Tooltip title="AI Dabing" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
                <img src="https://i.ibb.co/TqkqFsF/DUB.png" loading="eager" />
              </Tooltip>
            </DABING>
          </Controls>
          <SubTitle>
            <zanry>2024 | Akční / Rodinný / Dobrodružný • 1 řada</zanry>
          </SubTitle>
          <Description>
            <zanry>
              „Ninjové se nevzdávají“ – to byla Kaiova slavná poslední slova. Ale co máte dělat,<br />když ztratíte svého nejlepšího přítele? Wyldfyre ví: sama je vztelká! Pokud to není zvuk... líhnoucích se dračích vajec?
            </zanry>
          </Description>
          <NavMenu>
            <div className="hlavni-tab3">
              <h1 className="hlavni-tab3">
                SEZÓNY
              </h1>
            </div>
          </NavMenu>
          <Wyldfyreshorts2 />
          <Bottom />
        </>
      ) : (
        <div className="access-denied">
          <h2>Pro tento obsah potřebuješ VIP</h2>
        </div>
      )}
    </Container>
  );
}

export default Wyldfyreshorts;

// Styled Components
const NavMenu = styled.div`
  @media (max-width: 768px) {
    font-size: 22px;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0 0px;
    margin-right: 0px;
    letter-spacing: 1.8px;
  }
  font-size: 22px;
  border-radius: 4px;
  padding: 0 60px;
  margin-right: 22px;
  letter-spacing: 1.8px;
  bottom: 11;
  a {
    text-decoration: none;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  @media (max-width: 768px) {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageTitle = styled.div`
  margin-left: -40px;
  height: 35vh;
  min-height: 10px;
  width: 20vw;
  min-width: 100px;

  a {
    text-decoration: none;
  }

  img {
    @media (max-width: 768px) {
      width: 170%;
      margin-top: 95px;
      margin-left: 105px;
    }
    width: 90%;
    height: 90%;
    margin-top: 65px;
    margin-left: 145px;
    object-fit: contain;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const PlayButton = styled.button`
  @media (max-width: 768px) {
    height: 44px;
    font-size: 11px;
    padding: 0 0px;
    margin-left: 30px;
  }

  font-family: 'Kanit', sans-serif;
  border-radius: 4px;
  font-size: 18px;
  padding: 0 10px;
  margin-left: 62px;
  display: flex;
  align-items: center;
  height: 56px;
  background: rgb(249, 249, 249);
  border: none;
  letter-spacing: 0.3px;
  justify-content: flex-end;
  flex-flow: row nowrap;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  bottom: 55px;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  span {
    padding: 0 10px;
    color: rgb(0, 0, 0);
  }

  &:hover {
    background: rgb(198, 198, 198);
    transition: all 0.3s ease-in-out 0s;
  }
`;

const HD = styled.button`
  @media (max-width: 768px) {
    width: 84px;
    height: 36px;
  }
  margin-left: 14px;
  width: 105px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7%;
  border: 1px solid white;
  background-color: rgba(51, 46, 49, 0.67);

  span {
    @media (max-width: 768px) {
      font-size: 15px;
    }
    font-size: 20px;
    font-family: 'Russo One', sans-serif;
    color: white;
  }

  &:hover {
    transition: background-color 0.3s ease;
    background-color: rgb(0, 0, 0);
  }
`;

const DABING = styled.button`
  margin-left: 12px;
  width: 50px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15%;
  border: 1px solid white;
  background-color: rgba(51, 46, 49, 0.67);
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;

  img {
    width: 32px;
  }

  span {
    font-size: 20px;
    font-family: 'Russo One', sans-serif;
    color: white;
  }

  &:hover {
    transition: background-color 0.3s ease;
    background-color: rgb(0, 0, 0);
  }
`;

const SubTitle = styled.div`
  @media (max-width: 768px) {
    margin-left: 30px;
  }
  color: rgb(249, 249, 249);
  font-size: 15px;
  min-height: 20px;
  margin-top: 26px;
  margin-left: 62px;
`;

const Description = styled.div`
  @media (max-width: 768px) {
    margin-left: 30px;
  }
  line-height: 1.4;
  color: rgb(249, 249, 249);
  font-size: 20px;
  margin-top: 16px;
  margin-left: 62px;
`;

const Container = styled.div`
  font-size: 25px;
  padding: 0 4px;
  overflow: hidden;
`;
