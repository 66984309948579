import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function DOPSTRNAKY() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Doporučené stránky</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="https://heroesjourneyscz.blogspot.com/" target="_blank" rel="noreferrer">
            <img className="hovimg" src="https://i.ibb.co/h2wYX6C/ETZU.png" />
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default DOPSTRNAKY;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-arrow{
    opacity: 0;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
    }
    
    .slick-arrow:hover{
    opacity: 1;
    }

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        right: 40%; 
        overflow: visible;

        @media (max-width: 768px) {
            right: 0px;
          }
    }
`;

const Wrap = styled.div`
padding-top: 56.25%;
border-radius: 4px;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;
cursor: pointer;
overflow: hidden;
position: relative;
transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
outline: 0px solid transparent;
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));

img {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: fill;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}

    &:hover {
        outline: 3.2px solid #C7C7C7;
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
        rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        }
`;
