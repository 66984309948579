import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function ChimaSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-1">
              <img src="https://i.ibb.co/XSQ00Wt/CS01E01.webp" loading="eager" />
              <b>1. Bitva začíná I. část</b>
              <p>Chrám Lvů přepadne zrovna při slavnosti plnoletosti armáda Vlků, Krokodýlů a Havranů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-2">
              <img src="https://i.ibb.co/0jS31k0/CS01E02.webp" loading="eager" />
              <b>2. Bitva začíná II. část</b>
              <p>Laval hledá pro záchranu Chimy magické Šelmy Legendy, mýtické tvory z Příběhu příběhů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-3">
              <img src="https://i.ibb.co/g9tNB7P/CS01E03.webp" loading="eager" />
              <b>3. Bojovník v tobě</b>
              <p>Laval se snaží zastavit Krokodýly, kteří chtějí ovládnout celou říši Chima.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-4">
              <img src="https://i.ibb.co/N74grpt/CS01E04.webp" loading="eager" />
              <b>4. Bláznivá jízda</b>
              <p>Eris s Lavalem si vyjednou na výlet, když tu zjistí, co mají Krokodýli za lubem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-5">
              <img src="https://i.ibb.co/ftrfDJ8/CS01E05.webp" loading="eager" />
              <b>5. Veletrh</b>
              <p>Krokodýli si najmou Havrany, aby jim pomohli ukrást a přebarvit Lavalův Speedor.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-6">
              <img src="https://i.ibb.co/N2mwJkZ/CS01E06.webp" loading="eager" />
              <b>6. Útok na Orlí věž</b>
              <p>Útok na Orlí věž - Krokodýli pomohou Vlkům s útokem na Orlí věž, kde se nachází jejich Zub Velké matky.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-7">
              <img src="https://i.ibb.co/JnCkCnw/CS01E07.webp" loading="eager" />
              <b>7. Stoletý měsíc</b>
              <p>Stoletý měsíc - Chima se připravuje na útok Vlků, kteří se kvůli Stoletému měsíci přestanou ovládat.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-8">
              <img src="https://i.ibb.co/sH1BBRt/CS01E08.webp" loading="eager" />
              <b>8. Největší závod všech dob</b>
              <p>Největší závod všech dob - Krokodýli se snaží překazit další závody Speedorů, když tu se na dráze objeví tajemný jezdec.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-9">
              <img src="https://i.ibb.co/Hnbzqwg/CS01E09.webp" loading="eager" />
              <b>9. Bláznivé Gorily</b>
              <p>Bláznivé Gorily - Cragger pokácí Květinovou věž, čímž vyruší Gorily z jejich Velkého dozrání.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-10">
              <img src="https://i.ibb.co/B3rR05j/CS01E10.webp" loading="eager" />
              <b>10. Liščí tanec</b>
              <p>Liščí tanec - Cruler podvádí svého bratra Craggera a pomáhá Vlkům napadnout Lví chrám.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-11">
              <img src="https://i.ibb.co/wsqqM4Q/CS01E11.webp" loading="eager" />
              <b>11. Zloději CHI</b>
              <p>Zloději CHI - Vlci žízní po veškeré CHI a Chima kvůli tomu není v rovnováze.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-12">
              <img src="https://i.ibb.co/Sv0n8mx/CS01E12.webp" loading="eager" />
              <b>12. Rovnováha</b>
              <p>Rovnováha - Laval s Eris se snaží najít způsob, jak bezpečně přepravovat CHI, aby ji Vlci nezískali.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-13">
              <img src="https://i.ibb.co/r0GSTZy/CS01E13.webp" loading="eager" />
              <b>13. Krokodýlí slzy</b>
              <p>Krokodýlí slzy - Kmeny Chimy se snaží přijít na způsob, jak osvobodit Krokodýli z vlčího vězení.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-14">
              <img src="https://i.ibb.co/FKKhrQ1/CS01E14.webp" loading="eager" />
              <b>14. Falešná CHI</b>
              <p>Falešná CHI - Cragger si u Regula nechá vytvořit vlastní CHI, aby se omluvil ostatním za předešlé nepokoje.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-15">
              <img src="https://i.ibb.co/7v3Sd5s/CS01E15.webp" loading="eager" />
              <b>15. Havrani proti Orlům</b>
              <p>Havrani proti Orlům - Havrani využívají nepřesnosti v orlích pravidlech a nárokují si tak Orlí věže.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-16">
              <img src="https://i.ibb.co/nrZm3rm/CS01E16.webp" loading="eager" />
              <b>16. Nepovedené setkání</b>
              <p>Nepovedené setkání - Zástupci kmenů Chimy se sejdou na dojednání trvalého míru pro Chimu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-17">
              <img src="https://i.ibb.co/FD8jQvt/CS01E17.webp" loading="eager" />
              <b>17. Laval ve vyhnanství</b>
              <p>Laval ve vyhnanství - Laval odchází do vyhnanství, kvůli krádeži zlaté CHI, pro záchranu svých kamarádů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-18">
              <img src="https://i.ibb.co/BwY5r58/CS01E18.webp" loading="eager" />
              <b>18. Černý mrak</b>
              <p>Černý mrak - K hoře Cavora se přiblíží děsivě vypadající černý mrak, za účelem získat CHI.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-19">
              <img src="https://i.ibb.co/wNHgGP5/CS01E19.webp" loading="eager" />
              <b>19. Vodopády Chimy</b>
              <p>Vodopády Chimy - Černý mrak zastavil vodopády CHI, to vede k dalším nepokojům mezi Lvy a Krokodýly.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-1-epizoda-20">
              <img src="https://i.ibb.co/QYTgkQV/CS01E20.webp" loading="eager" />
              <b>20. Za Chimu!</b>
              <p>Za Chimu! - Chrám Lvů opět zažije útok a Laval tak musí najít způsob, jak obnovit vodopády a tím udobřit Krokodýly.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default ChimaSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
