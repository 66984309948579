import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function ChimaSliderS02() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-2-epizoda-1">
              <img src="https://i.ibb.co/nnQ44xN/CS02E01.webp" loading="eager" />
              <b>1. V Divočině</b>
              <p>Laval s přátely se vydává do Divočiny pro záchranu šelem Legendy, vězněných novými zlými kmeny.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-2-epizoda-2">
              <img src="https://i.ibb.co/MyvWbYz/CS02E02.webp" loading="eager" />
              <b>2. Zamotaná pavučina</b>
              <p>Zatímco kmeny Chimy odolávají útokům Netopýrů a Pavouků, Laval vymýšlí, jak osvobodit Gorilu a Nosorožce Legendy.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-2-epizoda-3">
              <img src="https://i.ibb.co/Lnkyr1x/CS02E03.webp" loading="eager" />
              <b>3. Zloděj Legendy</b>
              <p>Lavertus odhalí svoje tajemství a ostatní pokračují v záchraně další šelemy Legendy.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-2-epizoda-4">
              <img src="https://i.ibb.co/5YG9kfn/CS02E04.webp" loading="eager" />
              <b>4. Orel a Medvěd</b>
              <p>Lavalův tým dostává výzvu zachránit orlí vajíčka a Medvěda Legendy z houpačkové pasti.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-2-epizoda-5">
              <img src="https://i.ibb.co/nbTwg6N/CS02E05.webp" loading="eager" />
              <b>5. Pozor na zuby</b>
              <p>Lavertus nechce říct Craggerovi tajemství o jeho rodičích a společně s Worrizem jdou na záchranu Šelmy Vlka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/legendy-chima-sezona-2-epizoda-6">
              <img src="https://i.ibb.co/bB6gZzX/CS02E06.webp" loading="eager" />
              <b>6. Trochu to štípne</b>
              <p>Laval musí uprostřed boje najít způsob, jak zachránit poslední Šelmu Legendy a tím i celou Chimu.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default ChimaSliderS02;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
