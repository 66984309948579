import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoSliderS14A15() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-1">
              <img src="https://i.ibb.co/XJQFxrZ/AAAABSi2-OZV1-IAZm-OM9cs-XIwq-XWh-i-AGYwk-G3-Ku5y-L4q-Pp-Th4f-GCag8v-Aq-Eai3a9ls-KSJn-Tuf-JUq-BORQw.jpg" loading="eager" />
              <b>1. Neprozkoumaný</b>
              <p>Nindžové zjistí, že mistr Wu, Misako a Clutch Powers zmizeli při výpravě na neprozkoumaný ostrov, a tak zahájí záchrannou operaci.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-2">
              <img src="https://i.ibb.co/wCr2pP4/0rssc7whimmo.jpg" loading="eager" />
              <b>2. Strážci Amuletu</b>
              <p>Při prohlídce ostrova nindžové objeví přátelského draka a kamenné sochy, které pohlcují energii a jsou připravené k útoku.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-3">
              <img src="https://i.ibb.co/Tc4tcty/image.png" loading="eager" />
              <b>3. Dárek Jay</b>
              <p>Vůdce Strážců vyšle Nyu, Kaie, Zanea a Colea do vězení. Jay se ale přesune do luxusní chaty, kde si vychutnává výborné jídlo.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-4">
              <img src="https://i.ibb.co/0KGkT4r/3rpbjkeb5iv0.jpg" loading="eager" />
              <b>4. Wojirin zub</b>
              <p>Nindžové vyklouznou z pout a pokusí se zachránit kamaráda. Jay se ocitne v jeskyni s pokladem, kde dojde k odhalení skutečného padoucha.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-5">
              <img src="https://i.ibb.co/g79QP48/AAAABW1-DURL-m-Pfgy94gc-Ez-BMg2z-UTj9z-D6-GWVHKQDPR7nb-UAZz-HKKw6-MONMo3n-Xcx-Zwo2c-Ib-Wvvu6nu-0b-Ny.jpg" loading="eager" />
              <b>5. Velké šplouchnutí</b>
              <p>Nindžové přepadnou novou sokyni slečnu Drsňačku. Nya se ji pokusí zastavit vodním vírem, nad kterým ale ztratí kontrolu, a nedaleká vesnice se proto ocitne v ohrožení.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-6">
              <img src="https://i.ibb.co/xYHYjH7/AAAABQUg2f-A4ry-Yf-f-Yx-QJMs4-ZXao-Kq-UVbormycgzi-T5-V9p-Wbf-MO2cqk-DUEp-Wg9-Em-Mu-TGgar-Wc-ZDe-Vb6h.jpg" loading="eager" />
              <b>6. Volání hlubin</b>
              <p>Maya se pokusí vzít Nyin život do vlastních rukou, aby za ni vyřešila její problémy. Zane a Pixal po nouzovém volání zasahují na moři.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-7">
              <img src="https://i.ibb.co/L63BWkT/AAAABSWNv-Z9-MX0fm-I59-Psrza-Qr7-MZb47-Onl-Wv1-FErwpz0p4-Wt9d-RFk-Mw-Vg-Qz2a-Ehj-On-EJz-Hb-FYUR8-Uo.jpg" loading="eager" />
              <b>7. Nepotopitelná</b>
              <p>Nya, Jay, Zane, Pixal a Lloyd se nalodí na novou ponorku Hydro Bounty a vyrazí hledat zdroj signálu, kvůli kterému Nya nemůže využívat svoje schopnosti.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-8">
              <img src="https://i.ibb.co/BLtDn2y/AAAABce-V-f2-Pwp-Us-Zi-JSxxb75d-FBf-XZRGum4gr-Kok-A3-Bhbaq2w-MZUuv3-ZPlg-HWE3-Wrpb-SVoi-KPErtrhzl-IS.jpg" loading="eager" />
              <b>8. Pět tisíc sáhů pod mořem</b>
              <p>Nya a Maya na sebe hodí speciální oblek a pod vodou vyrazí do starověkého chrámu. Ponorku totiž můžou rozjet jenom s jeho zdrojem energie.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-9">
              <img src="https://i.ibb.co/km5QXYG/AAAABTOJOpc7xv0j-P-z-AVjviv-Ap9bq-jzs0by-Gy-SD40b51c-Zn-Ga-EVlhv-Gc6d-Tv1-G1fsblhkv-Bwk-MH5-FMWf5-FK.jpg" loading="eager" />
              <b>9. Kalmaarův hněv</b>
              <p>Nya a Maya se snaží dostat ze svízelné situace. Jay se mezitím riskantně pokouší obnovit zdroj energie v ponorce.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-10">
              <img src="https://i.ibb.co/ynnFdwj/AAAABZVKZx8-Z1u5v-Kp-H9n1-IRIhzg-Hg-SWU3-Aah-N14-C7cmft-VA8x8-TB4-WAFXWa6-IBQg-COp-U74cx4hl6v-TKG8d0.jpg" loading="eager" />
              <b>10. Ať žije král</b>
              <p>Po záchraně Nyi a Mayi musí nindžové do Merlopie ke králi Trimaarovi. Kvůli nečekané zradě se z nich ale stanou uprchlíci.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-11">
              <img src="https://i.ibb.co/hdsbzqY/AAAABYxt6r-Rupxloa-La8-Xofx-K1r-jds-Gr-Cb-Z3zsy-Ni-NTv-Z4-OIa2-Zsh-WAa-iyz-m8dwcag-P7-FGVv9e-G7nzj2.jpg" loading="eager" />
              <b>11. Útěk z Merlopie</b>
              <p>Ninžové přežijí záplavy v paláci, ale v patách mají žraloky trhače. Benthomaar jim pomůže a dostane je do hangáru.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-12">
              <img src="https://i.ibb.co/3M8YfJK/AAAABYMtpbbf-SCutw-IAp6-FOq-Rc-Wg-P7-Le-Je-Jngla-Lb0-Q-9d-TIb6mft-EYJul6a2-Bgp-Vj-DHp1itt9-Zt1b-Gxj.jpg" loading="eager" />
              <b>12. Benthomaarův příběh</b>
              <p>Pohled do minulosti odhalí, jak král Trimaar kdysi našel malého Benthomaara a vychovával ho jako svého syna.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-13">
              <img src="https://i.ibb.co/vjP68cj/AAAABQT-0-Bxd-Pwgl0f8z-Fh-VZXg-Nw-Z-S-fbct-QVn-KXev-CXTCUd0x-TS2-H-on4-LYQ53x-Qsz5-Q8-Qu-XDm-XZ0lps.jpg" loading="eager" />
              <b>13. Amulet bouře</b>
              <p>Nindžové se vrací na ostrov Strážců požádat jejich velitele, aby se v Ninjagu postaral o amulet. Objeví ale velmi nečekanou věc.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-14">
              <img src="https://i.ibb.co/DRdS7ZB/AAAABbml1-C5x5-Ph-Fy2-Ar-O-E1zl2-Aa5-Voq-Ban-I0hjdk-Z4-Pr-GHb-Rr-Ed-IO4d-Hs-Ba-Vs-Iq-LILQQVl-IXQ6-PF.jpg" loading="eager" />
              <b>14. Hádanka sfingy</b>
              <p>Kai, Ray, Cole, Wu a Misako dostanou od Nyi varování a spěchají do Klubu průzkumníků. Benthomaar vyzve Nyu, aby svoje schopnosti využila nečekaným způsobem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-15">
              <img src="https://i.ibb.co/M8LNVwN/AAAABZOOWk-Xpg-C7-Zf4-RQ-Q-to-DOJ6sa-Rs-B50h-N6tj-N-PKgsi-Dzrl9bif-Lmu-ed-ZTPRAXrtls-G-PA3f-Jnyp7wm7.jpg" loading="eager" />
              <b>15. Kamelotka</b>
              <p>Kalmaar a nindžové bojují v Ninjagu a amuletu bouře se zmocní odhodlaná kamelotka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-16">
              <img src="https://i.ibb.co/0cMggKY/AAAABf6-U7sj-X56i-Hom-Kw-SRu-Crob-Cs-Oj-NXq-Iersl-K-O7-I83-Hd1ypvxk-AVGWjaf-Sp-Gh-RHd-YQui-O3gv5-DIB.jpg" loading="eager" />
              <b>16. Mistr moře</b>
              <p>Benthomaar povypráví Nyi příběh o Nyad. Mistr Wu, Kai, Cole, Ray a Misako se snaží zastavit Kalmaara, který chce zmizet s amuletem bouře.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-17">
              <img src="https://i.ibb.co/3c4t2p6/AAAABRq-Z2514i-Wv-J7-KWVJHE6sw-Hhe-Vg8-MQh-BUSn-Kjc-AAi-Aa4i3fyks84-XJ-e-Zdb-AJZf-Ws0k-JDip-O5-Nfrcx.jpg" loading="eager" />
              <b>17. Klid před bouří</b>
              <p>Z moře povstane apokalyptický had Wojira. V Ninjagu se z vody vynoří Glutinous a varuje před katastrofou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-18">
              <img src="https://i.ibb.co/C1LXbSM/AAAABVMv-EXKMNFxt2-Ez-Iy9-Kfm-TIgg-Tg2-C6-UJk-Ex-RXSwl-T-Hf-Inj-HGZ-WKLWcl-A8j-Ae-Vm-K1xzf-E7-DUbxj.jpg" loading="eager" />
              <b>18. Útok na Ninjago City</b>
              <p>Do Ninjaga dorazí Kalmaar a Wojira, kteří v celém městě způsobí velké záplavy. Nindžové proto musí zasáhnout!</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-19">
              <img src="https://i.ibb.co/VD0jfn7/AAAABe3bx5-YXh-B1-Focw-Co-zxug-W0ac-Z5n-Qe-Jfr-CP1-U-1o9-Sjs7-L-g-J7-PJd4-VTg-d-E-9-L2-Yq-9iqe7by6-U.jpg" loading="eager" />
              <b>19. Nyad</b>
              <p>Nindžové jsou na Wojiru krátcí, a tak se musí stáhnout do budovy NGTV. Nya může udělat rozhodnutí, které už nepůjde zvrátit.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-3-epizoda-20">
              <img src="https://i.ibb.co/bLV8B4Y/AAAABcnyo-Ru-ET39n1o5-TCNAl-IFANj-P9-CXN6y-O85-OVUc-HX-e9e-Wt-K1js-EXLs-Tum-UU45-JBtc-5-Mlz-UAIYHs-C.jpg" loading="eager" />
              <b>20. Příliv se obrací</b>
              <p>Během bitvy začne mít Wojira vznětlivého Kalmaara už dost. Nindžové se snaží pomoct Nyi naplnit její osud.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoSliderS14A15;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
