import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function DobrodruzstviFreemakeruSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-1">
              <img src="https://i.ibb.co/BnG4wms/DFS01E01.jpg" />
              <b>1. Nový hrdina</b>
              <p>Rowan se setkává s Jediem jménem Naare a dozvídá se o Krystalu kyber.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-2">
              <img src="https://i.ibb.co/9tR9tx4/DFS01E02.jpg" />
              <b>2. V dolech u Grabally</b>
              <p>Při pokusu získat krystal meče Kyber z pole asteroidů je Rowan zajat Graballou Huttem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-3">
              <img src="https://i.ibb.co/Lzxfj3H/DFS01E03.webp" />
              <b>3. Zanderova krasojízda</b>
              <p>Když Zander vezme klasickou hvězdnou stíhačku na projížďku, skončí ve spárech Dartha Vadera.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-4">
              <img src="https://i.ibb.co/r3Xcdvs/DFS01E04.webp" />
              <b>4. Tajemství Oblačného města</b>
              <p>Honba za drahocenným nákladem přivádí Freemakery do Oblačného města</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-5">
              <img src="https://i.ibb.co/L1tH1FR/DFS01E05.webp" />
              <b>5. Výprava na Kashyyyk</b>
              <p>Kordiho pokusy ušetřit peníze vynutí Freemakery do problémů na Kashyyyku</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-6">
              <img src="https://i.ibb.co/VxcgD9B/DFS01E06.webp" />
              <b>6. Setkání s hrdiny Povstání</b>
              <p>Luke Skywalker a princezna Leia ukazují Freemakerům nezištnou povahu Síly.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-7">
              <img src="https://i.ibb.co/gtRNfnG/DFS01E07.webp" />
              <b>7. Závody na Tatooine</b>
              <p>Graballa naláká Freemakery do pasti.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-8">
              <img src="https://i.ibb.co/r6m128D/DFS01E08.webp" />
              <b>8. Zkouška</b>
              <p>Freemakeři se setkají s Hondem který jim dá jednoduchou práci která se ukáže být mnohem těžší než si mysleli.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-9">
              <img src="https://i.ibb.co/3hY85Pf/DFS01E09.webp" />
              <b>9. Honba za krystaly Kyberu</b>
              <p>Freemakeři horlivě pronásledují zbývající krystaly Kyber, ale pokaždé když nějaký najdou, Naare ho ukradne.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-10">
              <img src="https://i.ibb.co/pfpW5mZ/DFS01E10.webp" />
              <b>10. Stavitel ze Zohu</b>
              <p>Freemakeři cestují na Zoh kde se setkají s Stavitelem, který učí Rowana jak stavět pomocí Síly.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-11">
              <img src="https://i.ibb.co/NSZw21m/DFS01E11.webp" />
              <b>11. Honička na Hothu</b>
              <p>Závěr závodu o Krystaly Kyber se rýsuje na obzoru.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-12">
              <img src="https://i.ibb.co/y6v638v/DFS01E12.webp" />
              <b>12. Osudový souboj</b>
              <p>Rowan a Naare vstoupí do souboje světelných mečů s neuvěřitelnými následky.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-13">
              <img src="https://i.ibb.co/vvRkQTf/DFS01E13.webp" />
              <b>13. Návrat meče Kyberu</b>
              <p>Vzrušující bitva o Krystal Kyber přichází na řadu, Rowan vs Naare vs Císař!</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default DobrodruzstviFreemakeruSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
