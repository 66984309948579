import {
    doc,
    setDoc,
    getDoc,
    updateDoc,
    deleteDoc,
    collection,
    query,
    where,
    getDocs
} from 'firebase/firestore';
import { db } from './firebase';

/**
 * Nastaví email uživateli podle jeho UID.
 * @param {string} userId - UID uživatele.
 * @param {string} email - Nový email uživatele.
 */
export const setUserEmail = async (userId, email) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, { email: email });
    } catch (error) {
        console.error('Chyba při nastavování emailu uživatele:', error);
        throw error;
    }
};

/**
 * Nastaví roli uživateli podle jeho UID.
 * @param {string} userId - UID uživatele.
 * @param {string} role - Nová role uživatele.
 */
export const setUserRole = async (userId, role) => {
    try {
        const formattedRole = role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
        const userDocRef = doc(db, 'users', userId);
        await setDoc(userDocRef, { role: formattedRole }, { merge: true });
    } catch (error) {
        console.error('Chyba při nastavování role:', error);
        throw error;
    }
};

/**
 * Vrátí roli uživatele podle jeho UID.
 * @param {string} userId - UID uživatele.
 * @returns {Promise<string|null>} - Role uživatele nebo null, pokud dokument neexistuje.
 */
export const getUserRole = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
            return userDoc.data().role || null;
        }
        return null;
    } catch (error) {
        console.error('Chyba při získávání role uživatele:', error);
        throw error;
    }
};

/**
 * Nastaví roli "Majitel" pro konkrétní UID.
 * @param {string} userId - UID uživatele.
 */
export const setOwnerRole = async (userId) => {
    await setUserRole(userId, 'Majitel');
};

/**
 * Aktualizuje čas strávený uživatelem na stránce.
 * @param {string} userId - UID uživatele.
 * @param {number} timeSpent - Čas strávený na stránce.
 */
export const updateUserTime = async (userId, timeSpent) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, { timeSpent: timeSpent });
    } catch (error) {
        console.error('Chyba při aktualizaci času uživatele:', error);
        throw error;
    }
};

/**
 * Načte čas strávený uživatelem na stránce.
 * @param {string} userId - UID uživatele.
 * @returns {Promise<number>} - Čas strávený na stránce.
 */
export const getUserTime = async (userId) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userDocRef);
        return docSnap.exists() ? docSnap.data().timeSpent || 0 : 0;
    } catch (error) {
        console.error('Chyba při načítání času uživatele:', error);
        throw error;
    }
};

/**
 * Smaže uživatelský dokument podle jeho UID.
 * @param {string} userId - UID uživatele.
 */
export const deleteUserAccount = async (userId) => {
    try {
        await deleteDoc(doc(db, 'users', userId));
        console.log('Dokument Firestore smazán');
    } catch (error) {
        console.error('Chyba při mazání dokumentu:', error);
        throw error;
    }
};

/**
 * Nastaví ID uživatele.
 * @param {string} userId - UID uživatele.
 * @param {string} id - Nové ID uživatele.
 */
export const setUserId = async (userId, id) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, { ID: id });
    } catch (error) {
        console.error('Chyba při nastavování ID uživatele:', error);
        throw error;
    }
};

/**
 * Získá uživatelská data podle ID.
 * Pokud dokument neexistuje, vytvoří nový dokument s unikátním ID.
 * @param {string} userId - UID uživatele.
 * @returns {Promise<object>} - Data uživatele.
 */
export const getUserById = async (userId) => {
    try {
        console.log(`Hledám uživatele s UID: ${userId}`);
        const userDocRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
            console.log('Uživatel nalezen:', docSnap.data());
            return docSnap.data();
        } else {
            console.log('Uživatelský dokument neexistuje, vytvářím nový dokument.');
            const uniqueId = await generateUniqueId(); // Vygeneruje unikátní ID pro nový dokument
            await setDoc(userDocRef, { ID: uniqueId, role: 'User' }); // Uložíme unikátní ID a roli 'User'
            console.log('Nový uživatelský dokument vytvořen.');
            return { ID: uniqueId, role: 'User' };
        }
    } catch (error) {
        console.error('Chyba při získávání uživatele:', error);
        throw error;
    }
};

/**
 * Generuje náhodné 5místné ID.
 * @returns {string} - Generované ID.
 */
const generateRandomId = () => {
    return Math.floor(10000 + Math.random() * 90000).toString();
};

/**
 * Ověří, zda ID již existuje v databázi.
 * @param {string} id - ID k ověření.
 * @returns {Promise<boolean>} - True, pokud ID existuje, jinak False.
 */
const checkIfIdExists = async (id) => {
    try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('ID', '==', id));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    } catch (error) {
        console.error('Chyba při kontrole ID:', error);
        throw error;
    }
};

/**
 * Generuje unikátní ID.
 * @returns {Promise<string>} - Unikátní ID.
 */
export const generateUniqueId = async () => {
    let id;
    let exists = true;

    while (exists) {
        id = generateRandomId();
        exists = await checkIfIdExists(id);
    }

    return id;
};
