import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function SvetNinjagoSlider3() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Ninjago: Dračí povstání - Sezóna 1</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D1&nbsp;Sloučení:&nbsp;První...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/PGhB40m/AAAABSckq8d-YRDZf1j1q-0236oj-QE52-BSVDQJPXY6-HSf-GK50h-Fqs4-OBd-G-GC5-SE-BX8-S6-EWn3khx0qm-Aed-Bz2-o.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-2">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D2&nbsp;Sloučení:&nbsp;Druhá...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/5s8dV8H/AAAABb-W-h-J9y-W1-Zl8dk-HZQxxx8-Cu-UT0t-Ec-NYn-Zx-HUw-e-Xk-Rrloh-K0p-Rvr9px0-LSUkzzcj-HGitp-5c8pnq-L.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-3">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D3&nbsp;Pouť&nbsp;na&nbsp;Rozcestí</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/nmSmYMx/AAAABR8-M1e-OLe-CDpz-J7-At-N4-N4-SDvp-EAv-Fg-XTBDL5zls-R-98s0-B2-P-Rwz51sop-Y30-T-w-Tl-FSYe-UU6ul-Jq.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-4">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D4&nbsp;Za&nbsp;hranicí&nbsp;Šílenství</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/5MbDbgd/AAAABed0-Somi8p-Xq-AK7s-O85-Scz-X-As4-Vb-Ntj-Gh-Eqm4-WYd-V63bvi8-U-ZRB6-Tsum-Efi-Drl0-I9h-RF99-p-fl.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-5">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D5&nbsp;Písaři&nbsp;osudu</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/5BWqXC0/AAAABVyiv-Ers-Mvo-Ll-MWPNT0n6w55-4d-Ovlzs4-G-o1v-Bz-Ys8a-Wsa9-Le6-YMzt-CCEFq-R75j06p39-BJJR-nie-NPTZ.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-6">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D6&nbsp;Návrat&nbsp;do&nbsp;Impéria</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/26nR3nY/AAAABTu-WI9kyua-RQSn-Fno4iyq5g-R7s-G02km-Kn-RDIow-Vl-QOk-Nun5-QQ7o-WOAm5e-IBdhr6h-Mjdm0-F7e-EHd8-Uhf.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-7">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D7&nbsp;Bezduché&nbsp;bestie</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/cy6YNFD/AAAABVL4r-E8x-Wl-PPwy-Kmb-OQ-W4-KLAo-X3k-ze-N2-BR8t-XCOeg-Vz-Ohw-Q1t5l-MT3-NKn8-W-8-Q-p2-Jcgg-I8i-B2.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-8">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D8&nbsp;Já&nbsp;jsem&nbsp;nebezpečí</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/zbF5h6F/AAAABU4ch-Vt-W93-Ayqk8sc-REOj-Aay2-Eyl-ANIj5-Bhm8wz5-Wq-UQy72-M5f-Ic9rwa-ERHZ7b-C-i0-Zi-V3usykg-EVNR.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-9">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D9&nbsp;Vnitřní&nbsp;klid</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/94tccWk/AAAABeu-P78-Isteq-JSo-Pxyxt-OSNycx5-DLn-u-Us-Ai-BS3-Ed-Y-L-v-Ldj-Xh-M5-L-AHU1f-If-Jrq-Wz-Ww1-Pd-W-Gc.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-10">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D10&nbsp;Bitva&nbsp;druhého...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/3SgFMJj/AAAABdh3-Uz-Bj-CSo-Tg9u-Mo-YCv-IWfz23vs-Ty-WNw-J09-U4-Wjpj2uya-CZgr-J1-Is-Vojp-s3w-JOCCqr7w-Uh-Ms-Lz.jpg" />
              </div>
            </div>
          </a>
        </Wrap>

        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-11">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D11&nbsp;Chrám&nbsp;a&nbsp;dračí...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/6XS86Wt/AAAABZAzn-W7vq-Ds-I6xj-01-BTg77cx7-Tjn-X1-Uz-JAh-ZQd-FVjbj-Hb-COjs-NZjc-Qr-B2-Xx-GOr-PBd-LCKAw-L6-KU.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-12">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D12&nbsp;Mořský&nbsp;gang</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/KrZBkxt/AAAABWETYFr-L5er-L-c-MAw-Hcs-xo-tj-Evyo1v-NMy-QVDJWevcgpoaui8-AAqm-L9qq-M3i-Qlrb-WGBJii-X9-Qn-Sspr8.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-13">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D13&nbsp;Divoce&nbsp;nevhodné</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/sQVXnvD/AAAABWPXDr-Rw-Rm-AUSA1nl-UXxfynr-Awja1-Ad-Z-5c-Qmfe-Flfhrecs-MAKn-Atk-H7n-Mp2-Auz-VW9d-Sd-KLb-Pv-G3r.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-14">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D14&nbsp;Poslední&nbsp;Džin</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/wrpDVNG/AAAABXYHb-Ql3w-V-n-Xq-H1-Qe-Gpotvz-XF2-I8-CKWSsfmd-N3-K35v5-Xi5gfm-SVbe7pn-JGxfofg-Qs-BK0o-SL6n-AVa.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-15">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D15&nbsp;Říkají&nbsp;tomu&nbsp;zkáza</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/rQhP1fP/AAAABWDKAHS94zgs-K2-YAWZX7-T-9sd-Nd1bt8di04nh-W-OJGJuudk-XWqa4il-OMn-q-Xru-L02-OAZzka-GYg-EFKGQZZuk6.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-16">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D16&nbsp;Země&nbsp;ztrace...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/TttbkWc/AAAABZef-ZFn-Jddwewa-VDsfh-GMn-Qsq-KP5ra-SJCd-Rmlf8-Y3k1-Gn-M9q-Yj-ZRCGb5-Wzar3z-Jt1q-Kalq3h8gx-G4tl.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-17">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D17&nbsp;Správa</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/YhkQr1H/AAAABQVce-Rc-POoj5y-YMF81m-Ha-D5khs-HBJn-HIGrhhk-Ictfxzgmc-XBPI1f-UWp-Ysbax7bv5-Lhk8las-Si-MD3-o-MO4.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-18">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D18&nbsp;Absolutní&nbsp;moc</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/pwcMkt1/AAAABb-Lmz1x1-Y97k-Ydi-Kjvl8-RENy-YTamln-NKe-Dy-Z8-Pi-It-Gi-XJHlvl-Bol-ALWRS8qf0-FNj-UX-je3y-Lc-Dk53.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-19">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D19&nbsp;Všichni&nbsp;jsme...</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/5Kdg5nX/AAAABS4y20q-YRKas-P8hci8p-TO9-v201-Niqmsp-WEQ2sgje4-Mmqud-Tl7m-Lf994-GJUAc-Wj-Pf-IAPgc-IWQBLo-GI-b-Q.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-20">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial">Ninjago: Dračí povstání</div>
                <div className="svet-text">Ř1D20&nbsp;Vnitřní&nbsp;síla</div>
                <div className="svet-minuty">22 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/PmSkBgJ/AAAABblxr6c-Taw-Yvt1t-FWXR4-HN-g-NGZ0pu0-Np-Xt-O2y9-GFz-Ep8u-FCosa4-KUtqj-KFc-s-Seej-RFC6t-Hm-FPhk-I.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default SvetNinjagoSlider3;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

   a {
    text-decoration:none;
 }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        opacity 1;
    }

    &:hover {
        img {
            opacity 0.4;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
`;
