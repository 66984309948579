import React from 'react';
import { Helmet } from 'react-helmet';

function LEGODCSUPERHRDINOVEAQUAMANFILM() {
  return (
    <div className="container-placer">
      <Helmet>
        <title>NCE+ | Přehrávač</title>
        <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="embed-container">
        <iframe src="https://filemoon.sx/e/b4ns96l0fdl3/LEGO_DC_Comics_Super_Heroes_Aquaman_Rage_of_Atlantis" frameBorder="0" allowFullScreen="true" />
      </div>
    </div>
  );
}

export default LEGODCSUPERHRDINOVEAQUAMANFILM;
