import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function CitydobrodruzstviSliderS02() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek />
      <Carousel {...settings}>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/Wx6nb4J/image.png" loading="eager" />
              <b>1. Buster v akci</b>
              <p>Buster musí získat svůj uprchlý knír v rychlé, bláznivé honičce po celém městě.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/0sQSSRX/image.png" loading="eager" />
              <b>2. Polda nebo ne polda</b>
              <p>Grizzled a Rooky jdou v utajení, aby vykořenili zločineckého strůjce za operací s padělanými koláči.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/W6X5fdd/image.png" loading="eager" />
              <b>3. Klika s autem</b>
              <p>Duke dostane špičkové, chytré auto. On a temperamentní automobilová honička Ice Cream Truck Bandits.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/tb6K7Ty/image.png" loading="eager" />
              <b>4. Stříbrná šmouha</b>
              <p>Nevšímavá paní Blumfeldová se promění v superhrdinku, která zastaví zločiny ve městě, zatímco Duke bojuje se svými rozkazy ji zatknout.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/9nDrzV9/image.png" loading="eager" />
              <b>5. Den Harla Hubbse</b>
              <p>Je Harl Hubbs Day a Harl se rozhodne pomoci celému městu, ale rychle zjistí, že není připraven na Harl mánii.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/MB5vkKS/image.png" loading="eager" />
              <b>6. Závodnice Ann</b>
              <p>Billyho matka, Ann , přijme závodní výzvu na vrakovišti proti městské pouliční závodní legendě Treadu Octaneovi.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/w0DPmxq/image.png" loading="eager" />
              <b>7. Úžasné dobrodružství Boba a Clemmonse</b>
              <p>Hasiči Bob a Clemmons získávají během typické nouzové situace bizarní požadavky náčelníka Freyi.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/2hR3rpR/AAAABb-Sg-JRpx-zol-GYk-Nmky-K8-N0-TJCEnh-Vjx-Eu1st-Mzee-Uqchx-By3-Vk1-Fm-Iwn-Zx-F5-Um-Fol8-Gz-QFVnb.jpg" loading="eager" />
              <b>8. Muž beze jména se jménem</b>
              <p>Je to staré zúčtování na západě, když se do města přiřítí nový protivník, otestuje město jak na zločince, tak na úřady.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/CwMDbCw/AAAABb-L0-If1v-bzt2sav-Kr-Zl-OMf9-LQ-h-KSETA-n2-M0c-YHHm7-Aj9-9-Sut28-Tm6r-Xa-QJof-Cx-Z6-CSzu4-GNWVq.jpg" loading="eager" />
              <b>9. Daisyna parta antihrdinů</b>
              <p>Když Fendrich zorganizuje charitu jako rozptýlení, zatímco se pokouší ukrást městskou listinu, Sinclair shromáždí antihrdiny, aby ho zastavili.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/whsQPvv/AAAABZjcc-Swa-TTWbf-MRj-AJ6k-KVf-Kl5ts-BO1-F22r-Gn-MFdarc5c-Q9-PMiiwoqm0-Lg-Ezxs-Og-Byb-Qvld7hms5-T.jpg" loading="eager" />
              <b>10. Zpátky do školy</b>
              <p>Freye chybí jeden kredit k absolvování hasičské akademie a je suspendována jako velitelka hasičů, dokud nedokončí kurz.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/87Rznx3/AAAABa4-Cn-DLOMt-Zbdd-J0-Rw29e-Rq-O-mgn-MWso-Sfc1ic9y-TMLFYrtb-Qvq-QTi-XIr-Hsqn-QSa-Sd-Lx-AFSA0-AKiy.jpg" loading="eager" />
              <b>11. Pomíchaní kandidáti</b>
              <p>Starosta Fleck kandiduje na znovuzvolení proti RE Fendrichovi, který se nezastaví před ničím, aby vyhrál, aby využil své nové síly k tomu, aby jednou provždy porazil Sinclaira.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/0h8FH34/AAAABehep-1-BJEYitq-8p-9-R-08-Is-NOcqpm-Vu-F-8-sp-Tz-RQmi9a-RCSVv-JKGt7-Mc-Trv-XAHn99-Wa-CUk-NOeg-HB.jpg" loading="eager" />
              <b>12. Padouch Duke</b>
              <p>Když Duke ztratí paměť, Snake Rattler ho oklame, aby uvěřil, že je jeho kriminální stoupenec.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/HHSdBjW/AAAABUQ1nj-Uj-Nc-Yeiti-He7-OAQOFww-Iiuu-N9-REjj-Hj-U5m-Omtp1-FQMb0kc-T-AJ2-Nsgqy-Jjcr5-z-PKl3z7-DNXX.jpg" loading="eager" />
              <b>13. Pro Wheelera</b>
              <p>Když se Rooky dozví, že náčelník Wheeler má narozeniny, je odhodlaná udělat z nich nezapomenutelné.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/37yn67x/AAAABe46-Wt-Gjg-DAb-JCQXY3zrrjn91d6-Ufm-Qn-UTde-p-Fe-MK-Lko-C0y5-Had-BU-m9-P5-EDRyzp-I-z-Ck-Uz-t-Zx.jpg" loading="eager" />
              <b>14. Kvaken</b>
              <p>Během obřadu zasvěcení městského hlubinného plavidla na počest vévody , Freyi a Harla byla loď náhodou poslána na zřícenou cestu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/6vNrCxY/AAAABa1-Mek-OTm-Akicy-QJa-Tl0ysu-FJDHRr-MWw-UHKUG3i-UQr0-L1a-TYTD2-Ld-HNEao6r0iuu-BNLt-VPNN-Ve-Y8-Bz.jpg" loading="eager" />
              <b>15. Poklad kapitána Nosepatche</b>
              <p>Když je u pobřeží spatřena bájná oceánská příšera, Quacken, známá dobrodruhka Jessica Sharpe přísahá, že ji chytí.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/8rHhfmB/AAAABSx2-Rup-T7r-Yr9i-ZPXe-Pev-VRYSq-Bw-IEv-KWs4ha-An-RR-lr-H2-TYSm-Dq-CZp-En-v-06-R7-NVDk2-Uj-D-VMV.jpg" loading="eager" />
              <b>16. Brickmudský sedmiúhelník</b>
              <p>Rooky a Grizzled se v utajení vydají na palubu Guardian of the Oceans hledat ztracený pirátský poklad.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/kgZbYwT/AAAABb-5-D-WHGW0-Or-P5dsbil-Hnb-Jpei-G0-Y98obpsba1kx5-EU6z6g-VHr9-Y3b-Dlm-Fok1-K6322-QZE9h-Qg0dub-Rp.jpg" loading="eager" />
              <b>17. Zatýkám tě, veselý mužíku</b>
              <p>Fendrich a jeho parta se dozvědí o Clusterově skrytém pokladu na smetišti. Harl, Cluster, Shirley a Tread se spojí, aby ji ochránili.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/d5bVtWM/AAAABSCZkb-NOn-E2oe56-Yf6-Q7tpmgs-Cn4-GVOHZRVWFrcp-ZDSb9-J81i-NVMxkuf-EFwol-J1-Sx1-YY1-Sy-Hk-TYYkvd6.jpg" loading="eager" />
              <b>18. Projeď se, hochu</b>
              <p>Snake a Daisy využívají Treada, aby ho přiměli, aby se choval jako řidič útěku a provedl loupež.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/3YkTG6L/AAAABau-EB52ee-Vj-W-w-KWugw-BNNCtv-Wf-Miolqjd-Jf64u-Ttkv-Zsx9wjet-Uz-FIY03uokgkrfs-ER0pv-Bw-Xa6t3-Fi.jpg" loading="eager" />
              <b>19. Hbitý doklad</b>
              <p>Když Fendrich vyhrožuje, že zničí Sinclairův sváteční gala, Duke zasáhne, aby zachránil večírek i prázdniny!</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/city-dobrodruzstvi-sezona-2-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/cNPCH7v/AAAABdhn-JAu-Cps-GNNYT9-Ad-Fv-Tcac-j-FBOBQZrfc-E3w-BYZo0-GK2m7y-NMz-A5o3ns-Exzn-Ot-OQr-Dsegj-Q15gq73.jpg" loading="eager" />
              <b>20. Tread padouchem</b>
              <p>Freya vezme Billyho a Marka na projížďku, ale když se věci vymknou kontrole, je na Markovi, aby zachránil situaci.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default CitydobrodruzstviSliderS02;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -70px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -70px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
