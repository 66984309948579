import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function NinjagoSerialyaFilmy() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Ninjago Seriály a Filmy</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <div className="btnsvet">
            <a className="prevent-select" href="/ninjago-mistri-spinjitzu">
              <img src="/images/Nahledovky/NINJAGO-MST.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/ninjago-den-zesnulych">
              <img src="https://i.ibb.co/DzFv9kW/DEN-ZESNUL-CH.png" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a className="prevent-select" href="/ninjago-dekodovani">
              <img src="/images/Nahledovky/NINJAGO-DEKODOVANI.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/ninjago">
              <img src="/images/Nahledovky/NINJAGO-SERIAL.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/ninjago-draci-povstani">
              <img src="/images/Nahledovky/NINJAGO-DP-NA.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/lego-ninjago-film">
              <img src="https://i.ibb.co/Gn431Q2/NINJAGO-MOVIE-POSTER.png" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/ninjago-snovy-tym">
              <img src="/images/Nahledovky/NINJAGO-SNOVY-TYM.jpg" />
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btnsvet">
            <a href="/ninjago-wyldfyre-navrat-do-divociny">
              <img src="/images/Nahledovky/WYLDFYRE.jpg" />
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoSerialyaFilmy;

const Container = styled.div`
margin-top: 450px;
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }
b {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 3px;
   }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-arrow{
    opacity: 0;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
    }
    
    .slick-arrow:hover{
    opacity: 1;
    }

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    }
`;
