import React from 'react';
import { Helmet } from 'react-helmet';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

function S05E09() {
  return (
    <div className="container-placer">
      <Helmet>
        <title>NCE+ | Přehrávač</title>
        <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="embed-container">
        <iframe src="https://filemoon.sx/e/bstukj0ynxwj/Ninjago_53_Proklet__sv_t__st_1" autoPlay="true" frameBorder="0" allowFullScreen="true" />
        <div className="zpet">
          <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-8">
            <Tooltip title="Předešlá epizoda" arrowenterDelay={50} leaveDelay={25} placement="right">
              <SkipPreviousIcon sx={{ color: grey[700], fontSize: 80 }} opacity="0.5" />
            </Tooltip>
          </a>
        </div>
        <div className="zpet-na-serial">
          <a href="/ninjago-mistri-spinjitzu">
            <Tooltip title="Zpět na Ninjago: Mistři Spinjitzu" arrowenterDelay={50} leaveDelay={25} placement="right">
              <SlideshowIcon sx={{ color: grey[700], fontSize: 60 }} opacity="0.5" />
            </Tooltip>
          </a>
        </div>
        <div className="dalsi">
          <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-10">
            <Tooltip title="Další epizoda" arrowenterDelay={50} leaveDelay={25} placement="left">
              <SkipNextIcon sx={{ color: grey[700], fontSize: 80 }} opacity="0.5" />
            </Tooltip>
          </a>
        </div>
      </div>
    </div>
  );
}

export default S05E09;
