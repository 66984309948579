import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoDPSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/PGhB40m/AAAABSckq8d-YRDZf1j1q-0236oj-QE52-BSVDQJPXY6-HSf-GK50h-Fqs4-OBd-G-GC5-SE-BX8-S6-EWn3khx0qm-Aed-Bz2-o.jpg" loading="eager" />
              <b>1. Sloučení: První část</b>
              <p>Poté, co vesmír zamíchá záhadná událost známá jako „Sloučení“, se ctižádostivý ninja spojí s chytrou techničkou, aby prošel novým a neznámým světem.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/5s8dV8H/AAAABb-W-h-J9y-W1-Zl8dk-HZQxxx8-Cu-UT0t-Ec-NYn-Zx-HUw-e-Xk-Rrloh-K0p-Rvr9px0-LSUkzzcj-HGitp-5c8pnq-L.jpg" loading="eager" />
              <b>2. Sloučení: Druhá část</b>
              <p>Arin a Sora vyvinou nové schopnosti, které jim mohou pomoci postavit se lovcům draků. Dokáže je Zelený ninja naučit, jak využít své schopnosti?</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/nmSmYMx/AAAABR8-M1e-OLe-CDpz-J7-At-N4-N4-SDvp-EAv-Fg-XTBDL5zls-R-98s0-B2-P-Rwz51sop-Y30-T-w-Tl-FSYe-UU6ul-Jq.jpg" loading="eager" />
              <b>3. Pouť na Rozcestí</b>
              <p>V den tréninku se Arin a Sora vyplíží z kláštera, aby se zúčastnili karnevalu na křižovatce a odhalili zlotřilé spiknutí, které otestuje jejich dovednosti.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/5MbDbgd/AAAABed0-Somi8p-Xq-AK7s-O85-Scz-X-As4-Vb-Ntj-Gh-Eqm4-WYd-V63bvi8-U-ZRB6-Tsum-Efi-Drl0-I9h-RF99-p-fl.jpg" loading="eager" />
              <b>4. Za hranicí Šílenství</b>
              <p>Když se vzducholoď Bounty vrátí bez pilota, Arin, Sora a Lloyd pomocí vodítek zjistí, co se stalo Kaiovi. Je to past – nebo něco horšího?</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/5BWqXC0/AAAABVyiv-Ers-Mvo-Ll-MWPNT0n6w55-4d-Ovlzs4-G-o1v-Bz-Ys8a-Wsa9-Le6-YMzt-CCEFq-R75j06p39-BJJR-nie-NPTZ.jpg" loading="eager" />
              <b>5. Písaři osudu</b>
              <p>Aby zachránili Království v mracích, musí Ninjové spolupracovat a poslat děsivou chapadlovou bestii zpět, odkud přišla, a uzavřít Sloutřesení – a to rychle.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-6">
            <div className="btn">
              <img src="https://i.ibb.co/26nR3nY/AAAABTu-WI9kyua-RQSn-Fno4iyq5g-R7s-G02km-Kn-RDIow-Vl-QOk-Nun5-QQ7o-WOAm5e-IBdhr6h-Mjdm0-F7e-EHd8-Uhf.jpg" loading="eager" />
              <b>6. Návrat do Impéria</b>
              <p>Když Ninjové potkají partnera Photaca - nezničitelné technické bestii - Sora věří, že mise do Impéria je jediný způsob, jak ho zničit.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-7">
            <div className="btn">
              <img src="https://i.ibb.co/cy6YNFD/AAAABVL4r-E8x-Wl-PPwy-Kmb-OQ-W4-KLAo-X3k-ze-N2-BR8t-XCOeg-Vz-Ohw-Q1t5l-MT3-NKn8-W-8-Q-p2-Jcgg-I8i-B2.jpg" loading="eager" />
              <b>7. Bezduché bestie</b>
              <p>V Impériu Arin mentoruje skupinu dychtivých mladých bojovníků za zločin. Sora se postaví padouchovi ze své minulosti. Lloyd osvobodí mistra živlu z vězení.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-8">
            <div className="btn">
              <img src="https://i.ibb.co/zbF5h6F/AAAABU4ch-Vt-W93-Ayqk8sc-REOj-Aay2-Eyl-ANIj5-Bhm8wz5-Wq-UQy72-M5f-Ic9rwa-ERHZ7b-C-i0-Zi-V3usykg-EVNR.jpg" loading="eager" />
              <b>8. Já jsem nebezpečí</b>
              <p>Aby Ninjové vyhnali uvězněné draky z Impéria, budou muset tvrdě pracovat, rychle přemýšlet a najít jeden velký východ. Pokud se tam nedostanou první Photakové.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-9">
            <div className="btn">
              <img src="https://i.ibb.co/94tccWk/AAAABeu-P78-Isteq-JSo-Pxyxt-OSNycx5-DLn-u-Us-Ai-BS3-Ed-Y-L-v-Ldj-Xh-M5-L-AHU1f-If-Jrq-Wz-Ww1-Pd-W-Gc.jpg" loading="eager" />
              <b>9. Vnitřní klid</b>
              <p>Sora se nabourá do protokolů Impéria, aby našla Riyu. Nya a Kai prozkoumají tajemnou chodbu. Lloyd vede epickou bitvu proti armádě Photaků.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-10">
            <div className="btn">
              <img src="https://i.ibb.co/3SgFMJj/AAAABdh3-Uz-Bj-CSo-Tg9u-Mo-YCv-IWfz23vs-Ty-WNw-J09-U4-Wjpj2uya-CZgr-J1-Is-Vojp-s3w-JOCCqr7w-Uh-Ms-Lz.jpg" loading="eager" />
              <b>10. Bitva druhého kláštera</b>
              <p>Ninjové se nevzdávají. Nya, Kai a Zane dávají bitvě proti Impériu novou naději, zatímco Lloyd odhalí záhadu ukrytou hluboko pod městem.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-11">
            <div className="btn">
              <img src="https://i.ibb.co/6XS86Wt/AAAABZAzn-W7vq-Ds-I6xj-01-BTg77cx7-Tjn-X1-Uz-JAh-ZQd-FVjbj-Hb-COjs-NZjc-Qr-B2-Xx-GOr-PBd-LCKAw-L6-KU.jpg" loading="eager" />
              <b>11. Chrám a dračí jádra</b>
              <p>Aby se Sloutřesení nezhoršovalo, ninjové cestují do Chrámu dračích jader, aby se dozvěděli více o něčem, co se nazývá „Sloučení“.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-12">
            <div className="btn">
              <img src="https://i.ibb.co/KrZBkxt/AAAABWETYFr-L5er-L-c-MAw-Hcs-xo-tj-Evyo1v-NMy-QVDJWevcgpoaui8-AAqm-L9qq-M3i-Qlrb-WGBJii-X9-Qn-Sspr8.jpg" loading="eager" />
              <b>12. Mořský gang</b>
              <p>Když se Ninjové rozdělili, aby našli tři dračí jádra, Arin a Lloyd vezmou Bounty, ale brzy potřebují pomoc od vesnice mírumilovných pěstitelů řas.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-13">
            <div className="btn">
              <img src="https://i.ibb.co/sQVXnvD/AAAABWPXDr-Rw-Rm-AUSA1nl-UXxfynr-Awja1-Ad-Z-5c-Qmfe-Flfhrecs-MAKn-Atk-H7n-Mp2-Auz-VW9d-Sd-KLb-Pv-G3r.jpg" loading="eager" />
              <b>13. Divoce nevhodné</b>
              <p>Pan Frohicky a Zane míří na Rozcestí, aby našli Wyconiovou cívku. Mezitím žerty Wyldfyre komplikují její a Kaiovo pátrání po Dračím jádru.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-14">
            <div className="btn">
              <img src="https://i.ibb.co/wrpDVNG/AAAABXYHb-Ql3w-V-n-Xq-H1-Qe-Gpotvz-XF2-I8-CKWSsfmd-N3-K35v5-Xi5gfm-SVbe7pn-JGxfofg-Qs-BK0o-SL6n-AVa.jpg" loading="eager" />
              <b>14. Poslední Džin</b>
              <p>Kameny hledačů Nyi ji a Soru zavedou k něčemu, co nehledali. Jako Zaneův strážce nemůže pan Frohicky udělat nic správného.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-15">
            <div className="btn">
              <img src="https://i.ibb.co/rQhP1fP/AAAABWDKAHS94zgs-K2-YAWZX7-T-9sd-Nd1bt8di04nh-W-OJGJuudk-XWqa4il-OMn-q-Xru-L02-OAZzka-GYg-EFKGQZZuk6.jpg" loading="eager" />
              <b>15. Říkají tomu zkáza</b>
              <p>Sora a Nya hledají Dračí jádro pomocí magického kompasu. Arin a Lloyd jsou vystaveni zkoušce v Mateřské zahradě. Wyldfyre a Kai hrají na střehu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-16">
            <div className="btn">
              <img src="https://i.ibb.co/TttbkWc/AAAABZef-ZFn-Jddwewa-VDsfh-GMn-Qsq-KP5ra-SJCd-Rmlf8-Y3k1-Gn-M9q-Yj-ZRCGb5-Wzar3z-Jt1q-Kalq3h8gx-G4tl.jpg" loading="eager" />
              <b>16. Země ztracených věcí</b>
              <p>V Zemi ztracených věcí Nya narazí na starého kamaráda, zatímco Sora hledá alternativní zdroj energie, aby odvrátila monstrum z vrakoviště zvané „Hadivák“.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-17">
            <div className="btn">
              <img src="https://i.ibb.co/YhkQr1H/AAAABQVce-Rc-POoj5y-YMF81m-Ha-D5khs-HBJn-HIGrhhk-Ictfxzgmc-XBPI1f-UWp-Ysbax7bv5-Lhk8las-Si-MD3-o-MO4.jpg" loading="eager" />
              <b>17. Správa</b>
              <p>Lloyd a Arin dorazí do administrativy, kde se dozvědí, že dračí jádro bude zničeno, pokud nebudou jednat rychle – a založí správné papíry.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-18">
            <div className="btn">
              <img src="https://i.ibb.co/pwcMkt1/AAAABb-Lmz1x1-Y97k-Ydi-Kjvl8-RENy-YTamln-NKe-Dy-Z8-Pi-It-Gi-XJHlvl-Bol-ALWRS8qf0-FNj-UX-je3y-Lc-Dk53.jpg" loading="eager" />
              <b>18. Absolutní moc</b>
              <p>Zatímco se ninjové snaží vymyslet způsob, jak obejít energetickou bariéru Impéria, císařovna Beatrix testuje smrtící zbraň poháněnou ukradenými dračími jádry.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-19">
            <div className="btn">
              <img src="https://i.ibb.co/5Kdg5nX/AAAABS4y20q-YRKas-P8hci8p-TO9-v201-Niqmsp-WEQ2sgje4-Mmqud-Tl7m-Lf994-GJUAc-Wj-Pf-IAPgc-IWQBLo-GI-b-Q.jpg" loading="eager" />
              <b>19. Všichni jsme draci</b>
              <p>K odhalení špatného zacházení císařovny Beatrix s draky naivním občanům Impéria budou ninjové potřebovat pomoc... od muže zevnitř.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-draci-povstani-sezona-1-epizoda-20">
            <div className="btn">
              <img src="https://i.ibb.co/PmSkBgJ/AAAABblxr6c-Taw-Yvt1t-FWXR4-HN-g-NGZ0pu0-Np-Xt-O2y9-GFz-Ep8u-FCosa4-KUtqj-KFc-s-Seej-RFC6t-Hm-FPhk-I.jpg" loading="eager" />
              <b>20. Vnitřní síla</b>
              <p>Řádění císařovny Beatrix tlačí sloutřesení nebezpečně blízko k bodu zlomu, pokud ninjové nedokáže spolupracovat – a zachránit svět.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoDPSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -73px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -73px;
  }


    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
