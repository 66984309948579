import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Ninjago from './Ninjago - Seriál/Ninjago';

function Viewers() {
  return (
    <Container2>
      <Container>
        <Wrap>
          <a className="prevent-select" href="/svet-ninjago">
            <img className="hovimg" src="https://i.ibb.co/p3zF8Jc/VZHLED-1.png" loading="eager" />
            <video autoPlay loop playsInline muted>
              <source src="/Videa/NINJAGO-NCM+.mp4" type="video/mp4" />
            </video>
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/nexo-knights">
            <img className="hovimg" src="https://i.ibb.co/Ks2515X/VZHLED-2.png" loading="eager" />
            <video autoPlay loop playsInline muted>
              <source src="/Videa/NEXO-KNIGHTS-NCM+.mp4" type="video/mp4" />
            </video>
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/legendy-chima">
            <img className="hovimg" src="https://i.ibb.co/8gmHQKc/VZHLED-3.png" loading="eager" />
            <video autoPlay loop playsInline muted>
              <source src="/Videa/LEGENDY-CHIMA-NCM+.mp4" type="video/mp4" />
            </video>
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/svet-star-wars">
            <img className="hovimg" src="https://i.ibb.co/L6b5j13/VZHLED-4.png" />
            <video autoPlay loop playsInline muted>
              <source src="/Videa/STAR-WARS-NCE+.mp4" type="video/mp4" />
            </video>
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/monkie-kid">
            <img className="hovimg" src="https://i.ibb.co/njgdXtM/VZHLED-6.png" />
            <video autoPlay loop playsInline muted>
              <source src="/Videa/MONKIE-KID-NCE+.mp4" type="video/mp4" />
            </video>
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/svet-DC">
            <img className="prevent-select" src="https://i.ibb.co/jD8QL00/VZHLED-5.png" />
            <video autoPlay loop playsInline muted>
              <source src="/Videa/DC-NCE+.mp4" type="video/mp4" />
            </video>
          </a>
        </Wrap>
      </Container>
    </Container2>
  );
}

const Container2 = styled.div`
padding: 0 calc(4.3vw + 6px);
`;

const Container = styled.div`
  margin-top: 5px;
  padding: 18px 0px 1px;
  display: grid;
  grid-gap: 15px;
  gap: 20px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Wrap = styled.div`
  padding-top: 56.25%;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  outline: 3px solid rgba(249, 249, 249, 0.1);

  img {
    inset: 0px;
    display: block;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    transition: opacity 500ms ease-in-out 0s;
    width: 100%;
    z-index: 1;
    top: 0;
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    opacity: 0;
    z-index: 0;
  }

  &:hover {
    outline: 3px solid #C7C7C7;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    video {
      object-fit: cover;
      opacity: 1;
    }
  }
`;

export default Viewers;
