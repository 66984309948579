import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NexoKnightsSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-1">
              <img src="https://i.ibb.co/k2GD2qd/86ac5c37-4d96-45c5-b242-0236e80b1ea5.webp" loading="eager" />
              <b>1. Kniha příšer, I. část</b>
              <p>Když Jestro najde Knihu příšer, v království Knighton se rozpoutá chaos.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-2">
              <img src="https://i.ibb.co/K9M5d4K/s01e02.webp" loading="eager" />
              <b>2. Kniha příšer, II. část</b>
              <p>Rytíři musí bránit království před Jestrovými monstry, protože kouzelník Merlok se obětoval, aby je zachránil.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-3">
              <img src="https://i.ibb.co/fq4Vzkn/S01E03.webp" loading="eager" />
              <b>3. Merlokova síla</b>
              <p>Když Jestro zaútočí na vesnici, Nexo Knights musejí najít vhodný dopravní prostředek, kterým se tam dostanou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-4">
              <img src="https://i.ibb.co/xGKGFXz/S01E04.webp" loading="eager" />
              <b>4. Rytířský kodex</b>
              <p>Jestro používá Knihu chaosu, aby napadl krále a jeho návštěvu na vyjížďce po království.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-5">
              <img src="https://i.ibb.co/TPzQZxx/S01E05.webp" loading="eager" />
              <b>5. Noc hrůzy</b>
              <p>Pomocí Knihy strachu Jestro zavolá Whipperellu a ta zjeví rytířům jejich největší obavy.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-6">
              <img src="https://i.ibb.co/85srZV7/S01E06.webp" loading="eager" />
              <b>6. Zlatý hrad</b>
              <p>Když je Lance obsazen jako hvězda v novém filmu, nečeká, že Jestro a jeho monstra zaútočí na natáčení.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-7">
              <img src="https://i.ibb.co/cxyB230/S01E07.webp" loading="eager" />
              <b>7. Labyrint překvapení</b>
              <p>Rytíři se ocitnou uvězněni v bludišti a hledají Knihu klamu, která bludiště neustále přetvařuje.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-8">
              <img src="https://i.ibb.co/V3c2hdS/S01E08.webp" loading="eager" />
              <b>8. Černý rytíř</b>
              <p>Zdá se, že Nexo Knights se přidali na stranu Jestra! Nové zlo musí porazit Černý rytíř.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-9">
              <img src="https://i.ibb.co/7R053t2/S01E09.webp" loading="eager" />
              <b>9. Kniha naprostého zla</b>
              <p>Nexo Knights musí bojovat s Jestrovými příšerami zkázy, které útočí po celém Knightonu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-1-epizoda-10">
              <img src="https://i.ibb.co/z6ZRP1g/S01E10.webp" loading="eager" />
              <b>10. Moc a magie</b>
              <p>Zatímco se Aaron snaží zachránit Nexo Knights, Jestro a jeho armáda vedou útok na královský hrad.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NexoKnightsSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }


    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
