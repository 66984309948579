import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NexoKnightsSliderS02() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-1">
              <img src="https://i.ibb.co/3Y76XfP/S02E01.webp" loading="eager" />
              <b>1. Zpátky do školy</b>
              <p>Nexo Knights se musí vrátit do školy, aby oprášili své znalosti a připravili se na další souboj s Jestrem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-2">
              <img src="https://i.ibb.co/VwRdQRJ/S02E02.webp" loading="eager" />
              <b>2. Chamtivost je fajn</b>
              <p>Jestro zajme Lanceovy rodiče, kteří jsou až příliš luxusní, než aby je zvládli i monstra.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-3">
              <img src="https://i.ibb.co/b31J6P3/S02E03.webp" loading="eager" />
              <b>3. Kniha posedlosti</b>
              <p>Rytíři mají za úkol chránit králův cenný komiks během festivalu. Poznají v kostýmech skutečného Jestra?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-4">
              <img src="https://i.ibb.co/bvdZDhn/S02E04.webp" loading="eager" />
              <b>4. Královský turnaj</b>
              <p>Jorah Tightwad vyzívá na královské oslavě Nexo Knights proti jeho rytířům Tighty Knighties.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-5">
              <img src="https://i.ibb.co/4W9hYsr/S02E05.webp" loading="eager" />
              <b>5. Monsteršéf</b>
              <p>Axl se zúčastní kuchařské soutěže, aby zabránil Jestrovi získat recept na nejpálivější chilli v říši.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-6">
              <img src="https://i.ibb.co/F64XZZX/S02E06.webp" loading="eager" />
              <b>6. Dovolená</b>
              <p>Ostatní Nexo Knights už nezvládají Claye, a tak ho pošlou na dovolenou, do místa, kam míří i Jestro.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-7">
              <img src="https://i.ibb.co/89DPw2V/S02E07.webp" loading="eager" />
              <b>7. Horečka rytířské noci</b>
              <p>Nachlazení Knihy příšer znamená pro rytíře potíže, když může vytvořit zcela nový druh „nemocných“ příšer.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-8">
              <img src="https://i.ibb.co/DgNv7YW/S02E08.webp" loading="eager" />
              <b>8. Klub komiků</b>
              <p>Klub komiků - Jestro hledá Knihu zrady ve Vtipálkově. Když tam přijedou i rytíři, zjsistí, že Jestro je teď největší komik ve vesnici.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-9">
              <img src="https://i.ibb.co/G0sM6bP/S02E09.webp" loading="eager" />
              <b>9. Fortrex a frmol</b>
              <p>V masivní honičce po Knightonu se Nexo Knights snaží udržet Knihu zrady mimo sevření Knihy příšer. Nezradí je někdo?</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/nexo-knights-sezona-2-epizoda-10">
              <img src="https://i.ibb.co/6WXgFyd/S02E10.webp" loading="eager" />
              <b>10. Království hrdinů</b>
              <p>Kniha příšer, která má na svém místě poslední kousky jeho ďábelského plánu, se snaží nabýt lidské podoby.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NexoKnightsSliderS02;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }


    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
