import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoSliderS11() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-1">
              <img src="https://i.ibb.co/CnNTJ40/AAAABR8-B-wu9-LPQg-Og-DUOj-Je-IMKw-Lsp-Bps-Nuq-F-rq-Azl1-UOUPf2-G7-VQd-PJs40-CCzw3nj-XNO4-SR-LHR4i7.jpg" loading="eager" />
              <b>1. Plýtvání Potenciálem</b>
              <p>Po velkém vítězství nindžové trochu vyměknou, až jim mistr Wu musí připomenout, co jsou vlastně zač a jaký mají úkol.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-2">
              <img src="https://i.ibb.co/L1HQv7T/AAAABZF1-Nu-E0-Q-Ylt-PFeb-UCoc-Hxj-en-X2szjcbim3-DEYPk-Irrp-PNn-Ug-LTb-Ypb-Gp-A5u-Qpqg-VMPCEHLft-Gxh.jpg" loading="eager" />
              <b>2. Pátrání po misích</b>
              <p>Nindžové nutně potřebují vyrazit na nějakou misi, aby úplně nezapomněli všechno, co se naučili. Jenže v Ninjagu se jak naschvál nic nekalého neděje.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-3">
              <img src="https://i.ibb.co/rkn3sfH/AAAABRf-Wca-BWLcz-J4e-Ktj4bd-AJ-o3c-Vc-Bd-T5-Xq-L1-CS2-q-Dnz4l-OFEV2-Oawr-IW-k-Pdmg5v-RTF7n5cw-ZSon7.jpg" loading="eager" />
              <b>3. Tvrdý začátek</b>
              <p>Nindžové vyrazí do Pouště zkázy, kde prý žije obrovský brouk.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-4">
              <img src="https://i.ibb.co/GQP8NQz/AAAABSjn9o-Wf-IFr2-CDquj-DIq-EUn-D99z-DMGUCAQb-RN0-BTZr-WJth03m-N4-VXlil5aa-Q8nsxng9-B33-Z08-O6-B8d.jpg" loading="eager" />
              <b>4. V břiše bestie</b>
              <p>Jestli si nindžové chtějí opravit vozítko Bounty, musí získat zpátky důležitý náhradní díl. Jenže ten teď leží v žaludku obrovského pouštního brouka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-5">
              <img src="https://i.ibb.co/tPPFQWb/AAAABWz-Jf-TDo6-Di-RWd0n-wssgp-SX0-EENzp-PUq-SAVr-D5-QVW2-Dl-RMUk-Kik-wu6-Ge-Ye-Mmlzn3-Ziby-K6u-PAI1.jpg" loading="eager" />
              <b>5. Pasti a jak je přežít</b>
              <p>Průzkumník Clutch Powers neochotně spojí síly s nindži a společně pak všichni vejdou do záhadné pyramidy. Tam ale nechtěně vypustí prastarou zlou příšeru.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-6">
              <img src="https://i.ibb.co/4M3GcdG/AAAABVl-Im0-Shvh7ffj-GHJLV47-GSb-Lk-Oz-Alr-u-V8slnj7-5-Mqul-KJr-Iqhh-PHm-ZMt-HOfx-Rz5qrb1r6-Q8-GXsr.jpg" loading="eager" />
              <b>6. Noviny nikdy nespí</b>
              <p>Malý Nelson začne rozvážet noviny. To ale ve městě, kde řádí ohniví hadi a jejich čarodějnice, nebude žádná hračka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-7">
              <img src="https://i.ibb.co/J7x3J0N/AAAABUGQD2jjaq-NSEe-W1-MUO-Ena-HYD3-Q9-Dl-VU5-Mq9-IDt-Rns-Bb-JZo1il-X68yu7-YUJGb-JXe-N8u-MQgy-PJ0sqe.jpg" loading="eager" />
              <b>7. Nindžové versus láva</b>
              <p>Nindžové, kteří uvízli v pyramidě, musí utéct před proudy lávy a varovat celé město před dalším vpádem hadů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-8">
              <img src="https://i.ibb.co/1Q0J0Jk/AAAABVm-ADy-P5-HWxn-Z93kwd-KLM6pv9-Ojp-BLJn4q50-HZL3r-W9zmh4lw-AZBXv-V6-PRCMit-Uv-LWm-Zui7-K8-Bd-R9.jpg" loading="eager" />
              <b>8. Hadostrofa</b>
              <p>Reportérka Gayle Drbavá s kameramanem přímo v ulicích Ninjaga natáčejí útok hadů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-9">
              <img src="https://i.ibb.co/NWrGtwH/AAAABWFa-X117hf-XWWus5hm-PUH4j-Fk0-Vby-Rl-Rt-BHAUNNax-Nh-Wftx-Op-CLl-EQQnr-IE5d3xo-KTMc1thia37nyd-GH.jpg" loading="eager" />
              <b>9. Bezmocný</b>
              <p>Nindžové pospíchají do Muzea historie pro nebezpečný svitek, než padne do rukou hadí čarodějnice.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-10">
              <img src="https://i.ibb.co/2kgBSbb/AAAABVLv-Dumzv-O-gl-YHa-VV03z-Ragmt-ZX7-Doibg-ZRSMO8u-H3b-Tj-CZd-Vl-OFHX2q7-J57-W9-NGp-VIf-LGdv6k2kh.jpg" loading="eager" />
              <b>10. Starodávné dějiny</b>
              <p>Hadí čarodějnice se chce pomstít takzvaném Zrádnému podvodníkovi. Nindžové objeví překvapivou věc.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-11">
              <img src="https://i.ibb.co/fSHHfd9/AAAABWft-Pg8e13-Dx-A-Anr-MOZSVj0w-BC0bp-UD2m0-CLfmn-V65vhe6c-CBXy-DXB2-ZPO0-CFj-PQK1-D-RV4-GNro5-Rk7.jpg" loading="eager" />
              <b>11. Nikdy nevěř člověku</b>
              <p>Mistr Wu vypráví příběh z mládí, ve kterém se spolu s mladým Garmadonem setkal s Aspheerou a naučil ji tajemství Spinjitzu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-12">
              <img src="https://i.ibb.co/VxftSKL/AAAABZUinav-SOr-Jyad-Icg-VPo4-BIOn1b11-4y-QPay-Hab-Xru04wuhv-Vxp4wr-P79-DDQ0nq4j-OVn-UBu3-Q3r-DOKm-J.jpg" loading="eager" />
              <b>12. V obležení</b>
              <p>Nindžové se dozví, kdo je onen Zrádný podvodník, a pak se v klášteře stanou terčem útoku.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-13">
              <img src="https://i.ibb.co/QPyf4Pm/AAAABQl-J0-Zv-YQXL6-X9-Ie-L2s-Ex-Bmbti-XZ1-HB5-Hvp-Ko-IORt-Tn-LUn-Wz-Cv7-Is1-Ze-ZLj8e-JJndh-Ud-I7t-O.jpg" loading="eager" />
              <b>13. Klub Průzkumníků</b>
              <p>Nindžové se vloupou do Klubu průzkumníků pro druhý svitek zakázaného Spinjitzu. Zapletou se ale do konfliktu s vedoucím klubu a jeho rozzuřenými členy.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-14">
              <img src="https://i.ibb.co/NxkSKDB/AAAABckao-L9-Ec-Ij5io-JGKVu-DUp-Tpk7-Jv-B1-Zeh-Jj-Vva-Juyszk-6s-F6d-deql-Rf-R3-Je-Wxqy-D1y0-G-MXxw-z.jpg" loading="eager" />
              <b>14. Pomsta je má!</b>
              <p>Aspheera dorazí do kláštera Spinjitzu, utká se s mistrem Wuem a přinutí jednoho z nindžů k velké oběti.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-15">
              <img src="https://i.ibb.co/Lg89R8f/AAAABYXtj-VVSLKFxzib5-E9-OFc-V35n4o-Hq-CPh-Mk5-PMHTGEQp-Mas-Fa-HFj-Arc-OCOBQQp-C3z-OCQIP4-KZ0-BBf-J0.jpg" loading="eager" />
              <b>15. Chladné sbohem</b>
              <p>Mistr Wu se chystá na záchrannou misi, ale Lloyd s ostatními vezmou věci do vlastních rukou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-16">
              <img src="https://i.ibb.co/R9Cv4HY/AAAABes-XXzl6-MLm-I0b-SEZV5zy-Sw-Cegx3hnrz-UEzzf1r-Hwz6xb-Les-XT9l-Ma-Qczio-Pgu-Hby-KDTy4rx-0-RQZfbo.jpg" loading="eager" />
              <b>16. Nikdy-země</b>
              <p>Nindžové dorazí do Nikdy-země, kde ale neví, kam dřív skočit. Naštěstí se jich ujme kmen rybářů, kteří jim pomůžou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-17">
              <img src="https://i.ibb.co/xjVFym9/AAAABexg-N23-Iva94-J6cp-AGYC8v-CPmm-S314-Ew-PV-ghor128i6-P-y-Hm-HCo-3q-Uv-Bi-Q4-Bnx2-VNzq-RCM9pv-H-y.jpg" loading="eager" />
              <b>17. Tvůrce ohně</b>
              <p>Ledový císař se doslechne o příchodu nindžů a pošle na ně sněžné samuraje. Ti je mají za úkol zničit společně s vesničany, kteří jim pomohli.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-18">
              <img src="https://i.ibb.co/5KT1g8h/AAAABYgc-Mv-Hg-PUh1e-Zm-Rc-Qvlx-Jd0-ZGVXx-BO5i-Be-m-P2i-Dq-Mtzi-DVzz-URJd-IQau-QRd-UPqk-JAG0j9-GXM8.jpg" loading="eager" />
              <b>18. Nečekaný spojenec</b>
              <p>Lloyd se dostane do konfliktu se sněžnými samuraji Ledového císaře. Zachrání ho ale podivná vlčice a oba se nečekaně spřátelí.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-19">
              <img src="https://i.ibb.co/NYMRN99/AAAABYbb-BJLz-Ajofp-c0j-6-s4l-Tbq-Y3-OA-v3ti-V37-METMx0g-MMXe3-Srtf-B2qo-0-QPi3l1-By6-Eas-Yc-Am-Y7-O.jpg" loading="eager" />
              <b>19. Úplně nejhorší nindža ze všech</b>
              <p>V Ninjagu se tři zločinci dozví, že nindžové jsou pryč, a po večerce proto uprchnou z vězení Kryptarium.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-20">
              <img src="https://i.ibb.co/GJmLs6j/AAAABY1qv3-BOlm-UEemc8t-RB6c-Z70z-Su0-O7-HY6k12o6e-Xo-ZKvrz8uf-In-Wm-Phr9-Vn-J-8-Net-AYMZVrj9s-oj-Jh.jpg" loading="eager" />
              <b>20. Vzkaz</b>
              <p>Lloyd s vlčí parťačkou objeví jeskyni, kde je oblek a vzkaz od Zanea, který je zavede na jeho stopu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-21">
              <img src="https://i.ibb.co/GpQD7g1/AAAABUv-Uw-Ec-Zz5-Nr-M1s-Jk-Y2lw-No-Cg7-Wspx-Djzt-V4g2-Qz2-Gl3-ZXM96-Yf-Cy-Ct-Iuj-QEGac-Xbc-H6hc-Amw.jpg" loading="eager" />
              <b>21. Strom cestovatele</b>
              <p>Cole se vydá na nebezpečnou cestu na vrchol nedaleké hory, kde má být legendární Strom cestovatele.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-22">
              <img src="https://i.ibb.co/3dsVXL3/AAAABb-KRxo-VRkub-Ula-Zor-WBam8-Ue85-Rzmc-RWj-LFO2vo-DA0ot4p-AL9y-KPA-NU01-Dkb-Cn-FFM-RSJe9x-R9-Pf-Q.jpg" loading="eager" />
              <b>22. Kragův nářek</b>
              <p>Cole se probere v ledové jeskyni, kde žije legendární tvor. Při pokusu o útěk pak odhalí nečekanou věc.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-23">
              <img src="https://i.ibb.co/cgdWRyb/AAAABVdn-Iq-LXXfq-Vm-TY7-OOdt-Cva-YN2a-BZAx3t6p7-Rp-CZxv-A7zv-ZW0-OFK5lp-Wxm-Jwl-N7z-Wb-Ow-BVi1gxn-U.jpg" loading="eager" />
              <b>23. Vlkovo tajemství</b>
              <p>Ledový císař povolá obrovskou obludu z ledu, aby zaútočila na Lloyda a jeho vlčici. Nečekaně přitom ale odhalí velké tajemství.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-24">
              <img src="https://i.ibb.co/hm813Rc/AAAABST306r-If-Nv-U7-Cg-Z-n-DFJOB1-PHAQc-OSbldd-CFO8-ZL4gb-CC1-HZ1l-Ll-N1-VLRld-U1-Kt-Ze0r-LPSrdeq-U.jpg" loading="eager" />
              <b>24. Poslední z Formlingů</b>
              <p>Akita promluví o takzvaném výběru i o tom, jak s bratrem Kataruem odešli do divočiny objevit svoje zvířecí já.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-25">
              <img src="https://i.ibb.co/G2807Q4/AAAABVb-C3j-Z8o-Pw-Dv-Mhx3-FSxm-EBx-EG4-LGMxsbb5-PB4c-Syw6d-ZRGk-ZHiy-ERwn7-DNz-FLW4p27-DA7y-GGqk6f.jpg" loading="eager" />
              <b>25. Můj nepřítel, můj přítel</b>
              <p>Hrůzostrašný drak Ledového císaře znovu udeří a zničí rybářskou vesnici. Nindžové proto musí za Lloydem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-26">
              <img src="https://i.ibb.co/Bg3B9my/AAAABUu-A9-V-k0-Qt-C-D6i-W-Ln2r-Wzor-Nh-LC4c5hof-Q5g-T-Obv8-MUlu-DBWBBVOlv-Lu-Om-REF5-Adz-KErx-BEKq.jpg" loading="eager" />
              <b>26. Protokol Kaiju</b>
              <p>Wu a Pixal v Ninjagu hledají Nikdy-zemi a omylem z Temné říše vypustí obávanou nestvůru.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-27">
              <img src="https://i.ibb.co/MnTm0z7/AAAABW0nx-Knqrtm-Wfbj-NB9c-KR-kp-IJxs-B4ap-O9-LJSl9ph-Nfbzb-UXn09s10-CCZy-Rs-W2j-Aa-LHZHNReat-PTpg-P.jpg" loading="eager" />
              <b>27. Zkažení</b>
              <p>Vex zahlédne Zanea, jak dorazí do Nikdy-země, a ovládne ho. Takovou příležitost si prostě nesmí nechat ujít.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-28">
              <img src="https://i.ibb.co/JsqhHLd/AAAABfutvpqpssn-Rf-St-RPom-Skd2-R-em-Bnmj-Zppuzqx-QWS-OWhv16-W54-Aixj-X6myzfwq-Km-RJygt-SQXgx-IGHLm.jpg" loading="eager" />
              <b>28. Křehká naděje</b>
              <p>Lloyd odhalí, co se stalo Zaneovi, a octne se sám za mřížemi ve spárech Ledového císaře.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-29">
              <img src="https://i.ibb.co/S0Rqyzs/AAAABcjqouv-Ve9-J8y-VFB07-M3x-RV2-TIl-Ff-Ia0gry5-Qr-Wd-Wdv52-Zz-RPWwr-7o-Gwr0-OP3-TKMWq-Fw1-E0dtej-T.jpg" loading="eager" />
              <b>29. Jednou provždy</b>
              <p>Nya, Jay, Cole a Kai se střetnou s obávaným drakem Ledového císaře. Lloyd a jeho noví spojenci se pak utkají se samotným Ledovým císařem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-rada-1-epizoda-30">
              <img src="https://i.ibb.co/Dkx8Vzy/AAAABTsw-ZFRO0n-EBHfy6-U8-Iz-P3-Aqyjt1s415-Q4d1-In8-SZYLu-UYUNJQn-LXVYTk9-Jeca-ZOj-FXp1-LYBo-Lf-S-RC.jpg" loading="eager" />
              <b>30. Probuzení</b>
              <p>V dramatickém závěru ságy Nikdy-země se střetne síla přátelství s prohnilou mocí zakázaného svitku Spinjitzu.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoSliderS11;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
