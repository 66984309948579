import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const VideoPlayer = () => {
    const videoRef = useRef(null);
    const [error, setError] = useState('');

    useEffect(() => {
        if (window.Player && videoRef.current) {
            try {
                const player = new window.Player(videoRef.current, {
                    sources: [{
                        src: 'https://listeamed.net/e/njJYOd3pnQQEe9o',
                        type: 'video/mp4', // Změňte na správný typ pokud je jiný
                    }],
                    controls: true,
                    autoplay: false,
                });

                player.on('error', () => {
                    setError('Došlo k chybě při načítání videa.');
                });

                return () => {
                    player.dispose();
                };
            } catch (err) {
                setError('Došlo k chybě při inicializaci přehrávače.');
            }
        }
    }, []);

    return (
        <div className="container-placer">
            <Helmet>
                <title>NCE+ | Přehrávač</title>
                <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="embed-container">
                <video ref={videoRef} className="player-js" controls preload="auto" width="800" height="600">
                    {/* Video bude přidáno skrze Player.js */}
                </video>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </div>
    );
};

export default VideoPlayer;
