import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import React from 'react';
import Serialylist from './Serialylist';

function Serialy(props) {
  return (
    <Container>
      <Background>
        <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" />
      </Background>
      <Helmet>
        <title>Seriály | NCE+</title>
        <meta name="description" content="Spousta zábavy u LEGO Seriálů na NCE+" />
      </Helmet>
      <Serialylist />
    </Container>
  );
}

export default Serialy;

const Container = styled.main`
  position: relative;
  display: block;
  padding: 0 calc(24px);
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.6;
    }
`;
