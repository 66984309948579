import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS09() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-1">
              <img src="https://i.ibb.co/CwkZ5vD/AAAABV-JUn2-Qe4-Bnr-Mc-WRnl5-SMnl7-70i-K5wvqmh-YT5-S2-CNuls6h-JMq-ZMhzf4-QSTn-Sc-JNI9mysl8ah-Wd-ZIbx.jpg" loading="eager" />
              <b>1. Prvorozená</b>
              <p>Lloyd se ukrývá před okupanty císaře Garmadona a Harumi. Mladý Wu a ninjové mezitím uvízli v říši Oniů a Draka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-2">
              <img src="https://i.ibb.co/myjHGfV/AAAABeo-Hhj-XPFac7dsla883-Ra0-Ly-SXHYYCCf-Ch-Xtmu-SAVWv-Lr-Z-1dyc-a-WGo-JXx9lj-NJ1-GEq-UG13-TBi-Mx0t.jpg" loading="eager" />
              <b>2. Železo a kámen</b>
              <p>Železný baron vyžene Jaye, Kaie a Zanea do arény proti drakovi. Lloyd v Ninjago svolá skupinu rebelů, Bojovníky živlů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-3">
              <img src="https://i.ibb.co/sq5h1Vy/AAAABRpm-UBwjq7c3yhxm-IVFr-Jy0m-H69-Kvz-YIQRK5wp-Sz-BX7-C13yr1-JV-lp-H0p-AO32f-RYP3-EO3g95-OMIFw3-MM.jpg" loading="eager" />
              <b>3. Rádio Svobodné Ninjago</b>
              <p>Ninji uvězní v kleci a použijí je jako návnadu na draka. Lloyd a jeho bojovníci se zmocní televizní stanice a odvysílají vzkaz naděje.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-4">
              <img src="https://i.ibb.co/BybZNb7/AAAABakc-CFe-W-j-Gwb-Kyqh2xr3l26fhey-Y29-Gqp-Jgq-MJr-CEJ44s7-R6b6yc8-K5gz-IX0q-G8v-Yqriq-Fx-QU-N19y1.jpg" loading="eager" />
              <b>4. Jak postavit draka</b>
              <p>Uvězněným ninjům se povede nasbírat materiál, ze kterého můžou Cole a Wu postavit dračí atrapu. Synové Garmadonu udeří proti Lloydovým rebelům.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-5">
              <img src="https://i.ibb.co/8KNkXrK/AAAABdo-TVRZKv-Lqt-B-Rna-Ro-Yfbb9gyp-Ab-BO3-Xf-Yho-AVCys-Xct5-So-Dy-V2-Lig-RUg-KFSh-VXwq7e7dq-Z7-Ib6.jpg" loading="eager" />
              <b>5. Zlatá stezka</b>
              <p>Lloyda a několik jeho bojovníků málem dopadnou v Nyině vozidle. Ninjové prchají z Říše Oniů a Draka.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-6">
              <img src="https://i.ibb.co/LZzS0wZ/AAAABQTCu-P4-ou-TZc-T6gpw-HT-Lcs-Og-Sk-40-ZB2alg-JITHT-Fc-LEj-Jn-T00-Eav-K0-Pq-Sd6r-ALh-Mz-Dd-Unn-VO.jpg" loading="eager" />
              <b>6. Dvě lži, jedna pravda</b>
              <p>Mystake odhalí svoji skutečnou povahu a minulost. Wu vede pátrání po Prvorozené.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-7">
              <img src="https://i.ibb.co/z5npwn1/AAAABZfpttcr-Yhee-Lu-UWIKr-JS-JULyve-Zmuqj-KVXy-LK1-X7s-F2g-HQ7-AEYNm-Ba3ip-v0-Nx-PXp-Nmqq20v9-L2d-E.jpg" loading="eager" />
              <b>7. Nejslabší článek</b>
              <p>Odhalí Wuovu identitu. Lloyd a jeho přátelé vymyslí nový plán, který zahrnuje Harumi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-8">
              <img src="https://i.ibb.co/m4zGsXc/AAAABdofqao-KN1-Uyx2-E-c8z13-Rht0-Zero-Nsy-TDz-HNJC4l-VSq-PIy9-H3-F11-On-EJs3-FU842-Ss-L3-TZj4-c396.jpg" loading="eager" />
              <b>8. Záchrana Víry</b>
              <p>Když Wua zajmou a mučí elektrošoky, najednou se mu vrátí paměť. Lloyd chce pomocí ukradené moci získat Titána Oniů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-9">
              <img src="https://i.ibb.co/b6kfcVJ/AAAABSG0m-UUBUJ5evd64i4q-RWROJLF0dse-ppm-K8-A-YIn6r-Zx3c-CQRsps1-Ib7-H0l-WK0-PBDJ2-Mq-Qrz-Xts-Lp-Iup.jpg" loading="eager" />
              <b>9. Lekce pro mistra</b>
              <p>Zuřící Garmadon se snaží dostat věci pod kontrolu. Wu a Železný baron přistanou v hnízdě Prvorozené.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-9-epizoda-10">
              <img src="https://i.ibb.co/Nn6qnnc/AAAABa7-Ls-Z9-0e2-N1-ZQWEH5-VJl-KEa-X1wtxn-J-Hw3vg-UMs-Pzw-Iyc-Pm-CDBz-Xe-Rvx-4-Qig-Wc-u-My-YCUPGi-O.jpg" loading="eager" />
              <b>10. Zelený osud</b>
              <p>Ninjové se vrací do Ninjago a míří rovnou do bitvy, protože se chystá strašný útok. Mistr Wu a Lloyd čelí lordu Garmadonovi.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS09;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
