import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS04() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-1">
              <img src="https://i.ibb.co/bs9cWQG/AAAABRU0-Hcc7t-PIf-EPc75k5-d-IEGl4-t-f-J6x8t-RLBl-WB5pjb-Usz-T-UBs-VIy76-BVTPXl-H0h4-Xq-im3f-Ozkk-Sp.jpg" loading="eager" />
              <b>1. Pozvání</b>
              <p>Nindžové se dozvědí, že Zane je možná stále naživu. Také zjistí, že pokud ho chtějí znovu vidět, musí se přihlásit na Turnaj živlů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-2">
              <img src="https://i.ibb.co/j6VxZPC/AAAABWl-DXZac9p4qlgo-o-Ci-BMa49-FP0x-BR9-PMk-A66i-Ltfi57-Gjgj-MTKse-QWvau-A160-kdc-Tdnc-Ehg-Rpl-Nc1.jpg" loading="eager" />
              <b>2. Jen jeden zůstane</b>
              <p>Tým se dozví o Nefritových čepelích a dalších tajemstvích Turnaje živlů. Karlof je z turnaje vyloučen.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-3">
              <img src="https://i.ibb.co/X3TsscW/AAAABRKQxbl-GQq-Ol2ew-Qgqp-BTb8p-AWXu-DZpf4-HIrf-GEKCXiz1-Ofk-CRe-Mt7j-HTEIwdy-Dzim-SDTsu-XD1yun-AJl.jpg" loading="eager" />
              <b>3. Souboj</b>
              <p>Turnaj začíná a Cole je brzy nucen bojovat proti Jayovi. P.I.X.A.L. pomáhá Zaneovi získat ztracenou paměť.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-4">
              <img src="https://i.ibb.co/MB6HzND/AAAABQQQo-GY-THOwb-UDd5z-Jx-C6ah9-N7-Hblv-Vy-AZAHK6d-WUa-QY2-PCueua-f-Lj-La3iyx8num-KIKReo-F4-Pw-UQ3.jpg" loading="eager" />
              <b>4. Nindžové na bruslích	</b>
              <p>Zbývající Mistři živlů soutěží v jízdě na kolečkových bruslích. Cole, Karlof a další vyloučení účastníci musí pracovat v Chenově továrně na nudle.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-5">
              <img src="https://i.ibb.co/nPHdW60/AAAABec7-N65gy-J9-RTEv-BUb-Oyp-Zhx1-O0vo3-D7l-EDWs-MCwsr3-Fl9-PRVAvc7-D6r-RADsy8-I2-DBRsn-Yl-N1clq-w.jpg" loading="eager" />
              <b>5. Špioni</b>
              <p>Ninžové odhalí Chenův plán a uzavřou spojenectví s Mistry živlů. Cole a Zane z továrny na nudle uprchnou.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-6">
              <img src="https://i.ibb.co/P6qzqsk/AAAABasyfx-Syd-Bm0i0-XUd-QO7y-Gt-QQ-xby-Gd-N3nqit8q73w4-PU3-IJUAUy-O7n5rb-Xa1kfa435-Qsd-P-FFdo7-QVx.jpg" loading="eager" />
              <b>6. Očarování</b>
              <p>Lloyd a Garmadon se dozvědí o velké bitvě o město Ninjago, ke které kdysi došlo mezi Chenem, Anacondrai a Mistry živlů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-7">
              <img src="https://i.ibb.co/8XWqzvR/AAAABWp-J2-Ud-Tafeh-Is-Tj6-EJe9p2vab2f-F5-I4uz8p-X46a-YJsbnl-RLJF4z-Fo2-Rjamv-Al-GSWLEjy-ZTx-O5-KCNW.jpg" loading="eager" />
              <b>7. Zapomenutý živel</b>
              <p>Chen sebere Mistrům živlů jejich síly a přemůže Lloyda v zápase. Zdá se, že vše je ztraceno.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-8">
              <img src="https://i.ibb.co/N1dsgSv/AAAABW8dx-Xt-i-Ra-VXn51saw-Q0j-PEVbpqn-V9-u-Tv-L5csa-Tf-AV4d-Jbrkatn-L-k-We5-LJIvi7-Fhjo-M-x-ICa-d-N.jpg" loading="eager" />
              <b>8. Den draka</b>
              <p>Chen se svou armádou napadne Ninjago. Mistrům se však podaří z továrny na nudle utéct a jsou Chenovi přímo v patách. Garmadon o vlásek uniká vyhnanství.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-9">
              <img src="https://i.ibb.co/3SsbdvW/AAAABWw-Qr-Rj-OG5-ZKPv2ia-US-St-KCk-Yv-Ju8-Wh55v-zvq-L5-NPc8-Ia9-Tr-HVbg-EGDQEY2-Fxf-DAEUy-Gu-Z2-M0.jpg" loading="eager" />
              <b>9. Největší obavy</b>
              <p>Mistr Chen promění pomocí kouzla sebe i své vojáky v zuřivé Anacondrai. Nindžové se však vydají do vězení města Ninjago pro neobvyklého spojence.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-4-epizoda-10">
              <img src="https://i.ibb.co/87NL0rJ/AAAABbsuv-Z-o-ZN-k-N-kj-Yx-VCRby-Pl-Hx-Zx6d-RVERxe-Ur8-Il1-BFROs-In-Og-j-Ly-A4-P28-DZJXIXm-Mp4m-C4z.jpg" loading="eager" />
              <b>10. Koridor starších</b>
              <p>Začíná závěrečný střet mezi nindžy a Anacondrai. Jestli naši hrdinové chtějí zvítězit, budou toho muset hodně obětovat.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS04;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
