import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS07() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-1">
              <img src="https://i.ibb.co/bBzcNXV/AAAABZx-BQf5wb-Uu-Gj2i-Abku-Ne9qru-Xhu-SByu-Enab6-Gpe-O5mc8-TPUgj-COId7a-O1-Efvi-U46fgz-Ne-NRz-S0th9.jpg" loading="eager" />
              <b>1. Ruce času</b>
              <p>Mistr Wu se pouští do desítky let nevyřešeného boje proti Acronixovi, jednomu z dvojčat zvaných Ruce času. Ninjové se ho snaží zachránit.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-2">
              <img src="https://i.ibb.co/0G5Dpyf/AAAABYqtq-NYGw-YEt-S1o-Y8-I7uzy5xc5ozf4-Ad-VKExz-Y9u-WBCvyj9-Uhym5a-BZh-N-8-MT-3p-M4dx5-Rl0-N02-Qj-G.jpg" loading="eager" />
              <b>2. Líheň</b>
              <p>Ninjové se dozvídají víc o padlých Mistrech živlů Kruxovi a Acronixovi a zjišťují, že mají nového nepřítele.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-3">
              <img src="https://i.ibb.co/Y75jr56/AAAABYj-YNAPbl-Wpa-YHl-TBPbznu3xrss-Vi-Dk-XC290-S3-Mg8-G6d-Ww1-Cm-St-AJef6-Iwof-Bi-U2d-EWPfpegwfo-Qg.jpg" loading="eager" />
              <b>3. Čas zrádců</b>
              <p>Kai použije helmu rumělkových válečníků, aby zjistil něco o Kruxovi, Lloyd trénuje, aby se stal mistrem, a Nya se snaží zprovoznit Zanea.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-4">
              <img src="https://i.ibb.co/wcFn59Q/AAAABVs-QPEFfy6af-Plu-HVSc-LSai3-SRX1uk00-F5-Dt-KTd-Zcy-c9r-L0d-Fw-NHcu8a-JZ1-Pr-ALCs-Un-C8-Tz-JPXMS.jpg" loading="eager" />
              <b>4. Mrchožrouti</b>
              <p>Rumělkoví válečníci ze záhadného důvodu kradou kovové předměty a Lloyd si uvědomí, že nahradit mistra Wu není žádná legrace.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-5">
              <img src="https://i.ibb.co/8g848j5/AAAABXow-g-7-OZr62uk9-Z17-Wbn1gxf-HIy5-XRlp-Xg-ZYL5-S0a-FAp-Gdqepd-XHk7b-Lohtmu8-HT4-PAv-Do51-B5-AZq.jpg" loading="eager" />
              <b>5. Druhý meč času</b>
              <p>Ninjové bojují za záchranu zábavního parku a Jay brání svůj domov před rumělkovými válečníky. A pak se objeví smaragdová Dýka času.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-6">
              <img src="https://i.ibb.co/RC0St83/AAAABVh-Onv-DPU4-Lz-MDk-K22a-D-2yvd-T9-Qq-Vwc-BY-VQKa-Cg7-Hp3-FLk-Az-G9-Bfm-L5-Qm-Zjiu4js-WZd-7m-OZC.jpg" loading="eager" />
              <b>6. Útok</b>
              <p>Tým slaví vítězství a získává zpátky smaragdovou Dýku času. Oslavy ale vezmou rychle zasvé.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-7">
              <img src="https://i.ibb.co/Q917gN3/AAAABU-J1y5-P-PT8-Mr-XQOa83-UIg-Naiw-DIWFgl-Oqtu-AZR3-Di2g-7m-FZ-WMgop-Y1moy-VBMWjuq-P-Zica-RAgb-AEL.jpg" loading="eager" />
              <b>7. Odhalená tajemství</b>
              <p>Zatímco se ostatní ninjové snaží najít a zachránit mistra Wu, Nya se utká s domnělým Samurajem X a Kai se snaží zjistit pravdu o svých rodičích.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-8">
              <img src="https://i.ibb.co/HhDdtzj/AAAABX00a-OI48w-Ng-MIXQbo9h0-LP2a-Ldw-Rg-Eov-Lv-Jt3qod2-Hg-Rd-BNe-QTAOm-Wi-Dj9-Oa07v-J6o-We-Lg3-JX3.jpg" loading="eager" />
              <b>8. Zastavení a důsledek</b>
              <p>Kai zjistí, že jsou oba jeho rodiče v zajetí. Mezitím se objeví třetí Dýka času.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-9">
              <img src="https://i.ibb.co/zPKQdpz/AAAABVv04-W3-D3f-Ft-Qx9wzzas-WCvfox-YIp5-N2-DUKVND-Gw-L-v6x-QKGlb681pq-F6-Xf-WJ1rpdcpp89-H0-Odty-R5.jpg" loading="eager" />
              <b><strong>9. Z ohně rovnou do vroucího moře</strong></b>
              <p>Ruce času donutí Kaie a Nyu, aby z Vroucího moře získali zpátky čtvrtou Dýku času.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-7-epizoda-10">
              <img src="https://i.ibb.co/bRzzGGr/AAAABbvo-Tx-Bo6c6b-Fncflpmf-dq-R3-Hdr0-Vf-N4-JHam-Ry-Gjb-EIe4kt-R6-FBq-Ntq-Qa-Ix-Mw3-Ls-Ars3-Gmd-VVh.jpg" loading="eager" />
              <b>10. Ztraceni v čase</b>
              <p>Kai a Nya cestují časem do minulosti, aby společně s Mistry živlů zabránili Rukám času změnit historii Ninjaga.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS07;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -64px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -64px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
