import React from 'react';
import { Helmet } from 'react-helmet';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import $ from 'jquery';
import NinjagoMSTSliderS00 from './S00/NinjagoMSTSliderS00';
import NinjagoMSTSliderS01 from './S01/NinjagoMSTSliderS01';
import NinjagoMSTSliderS02 from './S02/NinjagoMSTSliderS02';
import NinjagoMSTSliderS03 from './S03/NinjagoMSTSliderS03';
import NinjagoMSTSliderS04 from './S04/NinjagoMSTSliderS04';
import NinjagoMSTSliderS05 from './S05/NinjagoMSTSliderS05';
import NinjagoMSTSliderS06 from './S06/NinjagoMSTSliderS06';
import NinjagoMSTSliderS07 from './S07/NinjagoMSTSliderS07';
import NinjagoMSTSliderS08 from './S08/NinjagoMSTSliderS08';
import NinjagoMSTSliderS09 from './S09/NinjagoMSTSliderS09';
import NinjagoMSTSliderS10 from './S10/NinjagoMSTSliderS10';

function Ninjagomistrispinjitzu2() {
  $(window).scroll(() => {
    const st = $(window).scrollTop();
    const range = 420; // finetune this to the desired effect
    $('.scdown').css('opacity', 1 - st / range); // animate your element
  });

  window.addEventListener('DOMContentLoaded', () => {
    const tabs = document.querySelectorAll('[role="tab"]');
    const tabList = document.querySelector('[role="tablist"]');

    // Add a click event handler to each tab
    tabs.forEach((tab) => {
      tab.addEventListener('click', changeTabs);
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener('keydown', (e) => {
      // Move right
      if (e.keyCode === 39 || e.keyCode === 37) {
        tabs[tabFocus].setAttribute('tabindex', -1);
        if (e.keyCode === 39) {
          tabFocus++;
          // If we're at the end, go to the start
          if (tabFocus >= tabs.length) {
            tabFocus = 0;
          }
          // Move left
        } else if (e.keyCode === 37) {
          tabFocus--;
          // If we're at the start, move to the end
          if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
          }
        }

        tabs[tabFocus].setAttribute('tabindex', 0);
        tabs[tabFocus].focus();
      }
    });
  });

  function changeTabs(e) {
    const { target } = e;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')

      .forEach((t) => t.setAttribute('aria-selected', false));

    // Set this tab as selected
    target.setAttribute('aria-selected', true);

    // Hide all tab panels
    grandparent
      .querySelectorAll('[role="tabpanel"]')
      .forEach((p) => p.setAttribute('hidden', true));

    // Show the selected panel
    grandparent.parentNode
      .querySelector(`#${target.getAttribute('aria-controls')}`)
      .removeAttribute('hidden');
  }

  function changeTabs(e) {
    const { target } = e;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((t) => t.setAttribute('aria-selected', false));

    // Set this tab as selected
    target.setAttribute('aria-selected', true);

    // Hide all tab panels
    grandparent
      .querySelectorAll('[role="tabpanel"]')
      .forEach((p) => p.setAttribute('hidden', true));

    // Show the selected panel
    grandparent.parentNode
      .querySelector(`#${target.getAttribute('aria-controls')}`)
      .removeAttribute('hidden');
  }

  return (
    <Container>
      <NavMenu>
        <div className="tabs">
          <div className="child" role="tablist" aria-label="Sample Tabs">
            <text role="tab" className="hlavni-tab" aria-selected="false" aria-controls="panel-1" id="tab-1" tabIndex="0">
              Řada 0
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="true" aria-controls="panel-2" id="tab-2" tabIndex="0">
              Řada 1
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-3" id="tab-3" tabIndex="0">
              Řada 2
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-4" id="tab-4" tabIndex="0">
              Řada 3
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-5" id="tab-5" tabIndex="0">
              Řada 4
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-6" id="tab-6" tabIndex="0">
              Řada 5
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-7" id="tab-7" tabIndex="0">
              Řada 6
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-8" id="tab-8" tabIndex="0">
              Řada 7
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-9" id="tab-9" tabIndex="0">
              Řada 8
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-10" id="tab-10" tabIndex="0">
              Řada 9
            </text>
            <text role="tab" className="hlavni-tab2" aria-selected="false" aria-controls="panel-11" id="tab-11" tabIndex="0">
              Řada 10
            </text>
          </div>
          <div id="panel-1" role="tabpanel" tabIndex="0" aria-labelledby="tab-1" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS00 />
          </div>
          <div id="panel-2" role="tabpanel" tabIndex="0" aria-labelledby="tab-2" cursor="pointer" className="nav-bottom" aria-hidden="false">
            <NinjagoMSTSliderS01 />
          </div>
          <div id="panel-3" role="tabpanel" tabIndex="0" aria-labelledby="tab-3" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS02 />
          </div>
          <div id="panel-4" role="tabpanel" tabIndex="0" aria-labelledby="tab-4" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS03 />
          </div>
          <div id="panel-5" role="tabpanel" tabIndex="0" aria-labelledby="tab-5" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS04 />
          </div>
          <div id="panel-6" role="tabpanel" tabIndex="0" aria-labelledby="tab-6" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS05 />
          </div>
          <div id="panel-7" role="tabpanel" tabIndex="0" aria-labelledby="tab-7" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS06 />
          </div>
          <div id="panel-8" role="tabpanel" tabIndex="0" aria-labelledby="tab-8" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS07 />
          </div>
          <div id="panel-9" role="tabpanel" tabIndex="0" aria-labelledby="tab-9" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS08 />
          </div>
          <div id="panel-10" role="tabpanel" tabIndex="0" aria-labelledby="tab-10" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS09 />
          </div>
          <div id="panel-11" role="tabpanel" tabIndex="0" aria-labelledby="tab-11" cursor="pointer" className="nav-bottom" hidden>
            <NinjagoMSTSliderS10 />
          </div>
        </div>
      </NavMenu>
    </Container>
  );
}

export default Ninjagomistrispinjitzu2;

const NavMenu = styled.div`
@media (max-width: 768px) {
  font-size: 22px;
  justify-content: space-between;
border-radius: 4px;
padding: 0 0px;
margin-right: 0px;
letter-spacing: 1.8px;
bottom 11;
}
font-size: 22px;
border-radius: 4px;
padding: 0 60px;
margin-right: 22px;
letter-spacing: 1.8px;
bottom 11;
a {
  text-decoration:none;
}
`;

const Container = styled.div`
margin-top: -25px;
`;
