// src/JakVip.js
import React from 'react';
import styled from 'styled-components';

const JakVip = ({ closePopup }) => {
    return (
        <ModalContent>
            <CloseButton onClick={closePopup}>&times;</CloseButton>
            <h2>Jak získat VIP?</h2>
            <p>
                Chceš podpořit NCE+ a zároveň získat VIP?<br/><br/>
                získej výhody jako je předběžný přístup k epizodám, změna přezdívky, VIP avataři, Discord výhody a mnoho dalších.<br/><br/>
                Připoj se na náš server a dozvi se jak ho získat!
            </p>
            <a href="https://discord.com/invite/dwRN6v9vz8" className="discord-button" target="_blank" rel="noopener noreferrer">
                <img src="https://i.ibb.co/ZB161vk/png-transparent-discord-computer-servers-teamspeak-discord-icon-video-game-smiley-online-chat-thumbn.png" alt="Discord Logo" />
                Připojit se
            </a>
            <NceLogo src="https://i.ibb.co/DD6CQw3/NCE-PLUS-LOGO.webp" alt="NCE+ logo" />
        </ModalContent>
    );
};

const ModalContent = styled.div`
    background: #1d1f1e;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    position: relative;

    h2 {
        margin-bottom: 15px;
        color: #fff;
    }

    p {
        margin-bottom: 20px;
        color: #fff;
    }

    .discord-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #7289da;
        color: #fff;
        border-radius: 5px;
        padding: 10px 15px;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .discord-button img {
        margin-right: 10px;
        height: 24px;
    }
`;

const CloseButton = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
`;

const NceLogo = styled.img`
    width: 200px; /* Nastavení šířky loga */
    margin-top: 20px; /* Mezery nad logem */
`;

export default JakVip;
