import React from 'react';
import styled from 'styled-components';
import Bottom1 from '../Bottom';

function Serialylist() {
  return (
    <Container>
      <h4 className="serialy">Seriály</h4>
      <Content>
        <Wrap>
          <a className="prevent-select" href="/ninjago-mistri-spinjitzu">
            <img className="hovimg" src="/images/Nahledovky/NINJAGO-MST.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-dekodovani">
            <img className="hovimg" src="/images/Nahledovky/NINJAGO-DEKODOVANI.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/ninjago">
            <img className="hovimg" src="/images/Nahledovky/NINJAGO-SERIAL.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/ninjago-draci-povstani">
            <img className="hovimg" src="/images/Nahledovky/NINJAGO-DP-NA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/legendy-chima">
            <img className="hovimg" src="/images/Nahledovky/CHIMA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/nexo-knights">
            <img className="hovimg" src="/images/Nahledovky/NEXO-KNIGHTS.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-all-stars">
            <img className="hovimg" src="/images/Nahledovky/ALL-STARS.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-all-stars-shorts">
            <img className="hovimg" src="/images/Nahledovky/ALL-STARS-SHORTS.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-dobrodruzstvi-freemakeru">
            <img className="hovimg" src="/images/Nahledovky/DOBRODRUZSTVI-FREEMAKERU.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-dobrodruzstvi-freemakeru-shorts">
            <img className="hovimg" src="/images/Nahledovky/DOBRODRUZSTVI-FREEMAKERU-SHORTS.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-historky-z-odboje">
            <img className="hovimg" src="/images/Nahledovky/STAR-WARS-HISTORKY-Z-ODBOJE.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-pribehy-z-yodovi-kroniky">
            <img className="hovimg" src="/images/Nahledovky/PRIBEHY-Z-YODOVI-KRONIKY.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/star-wars-pribehy-droidu">
            <img className="hovimg" src="/images/Nahledovky/PRIBEHY-DROIDU.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/monkie-kid">
            <img className="hovimg" src="/images/Nahledovky/MONKIE-KID.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/hero-factory">
            <img className="hovimg" src="/images/Nahledovky/HERO-FACTORY.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/bionicle">
            <img className="hovimg" src="/images/Nahledovky/BIONICLE.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/atlantis">
            <img className="hovimg" src="/images/Nahledovky/ATLANTIS.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/ledove-kralovstvi-polarni-zare">
            <img className="hovimg" src="/images/Nahledovky/LEDOVE-KRALOVSTVI.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/dreamzzz">
            <img className="hovimg" src="/images/Nahledovky/DREAMZZZ.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a className="prevent-select" href="/city-dobrodruzstvi">
            <img className="hovimg" src="/images/Nahledovky/CITY-DOBRODRUZSTVI.jpg" />
          </a>
        </Wrap>
      </Content>
    </Container>
  );
}

export default Serialylist;

const Container = styled.div`
      font-size: 25px;
      padding: 0 42px;

      h4 {
        font-family: 'Noto Sans Lao', sans-serif;
        margin-bottom: 10px;
      }
`;

const Content = styled.div`
@media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(5, minmax(0,1fr));
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
padding-top: 56.25%;
border-radius: 4px;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;
cursor: pointer;
overflow: hidden;
position: relative;
transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
outline: 0px solid transparent;

img {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: fill;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}

    &:hover {
        outline: 3.2px solid #C7C7C7;
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
        rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        }
`;
