import React, { useRef, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import styled from 'styled-components';
import { useAuth } from './contexts/AuthContext'; // Importujte kontext pro autentizaci

const ZmenaPrezdivky = ({ closeModal }) => {
    const { user, updateUserDisplayName } = useAuth(); // Získání uživatelského objektu
    const inputRef = useRef(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [serverError, setServerError] = useState('');

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        let interval;
        if (timeLeft !== null) {
            interval = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    const remainingTime = Math.max(0, prevTimeLeft - 1000);
                    return remainingTime;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [timeLeft]);

    const formatTime = (time) => {
        const totalSeconds = Math.floor(time / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes} minut a ${seconds} sekund`;
    };

    const formik = useFormik({
        initialValues: {
            nickname: ''
        },
        validationSchema: Yup.object({
            nickname: Yup.string()
                .required('Přezdívka je povinná')
                .min(5, 'Přezdívka musí mít alespoň 5 znaků')
                .max(15, 'Přezdívka nesmí mít více než 15 znaků')
        }),
        onSubmit: async (values) => {
            if (!user) {
                console.error('Není přihlášen žádný uživatel.');
                return;
            }

            try {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);
                const lastChange = userDoc.data()?.lastNicknameChange?.toDate();
                const now = new Date();

                if (lastChange && (now - lastChange) < 3600000) { // 3600000 ms = 1 hodina
                    setTimeLeft(3600000 - (now - lastChange));
                    setServerError('Přezdívku můžete měnit pouze jednou za hodinu.');
                    return;
                }

                // Aktualizace přezdívky v Firestore
                await setDoc(userRef, { displayName: values.nickname, lastNicknameChange: now }, { merge: true });
                
                // Aktualizace přezdívky v AuthContext
                await updateUserDisplayName(values.nickname);

                console.log('Přezdívka úspěšně aktualizována');
                closeModal();
            } catch (error) {
                console.error('Chyba při aktualizaci přezdívky:', error);
                setServerError('Došlo k chybě při aktualizaci přezdívky.');
            }
        }
    });

    return (
        <ModalContent>
            <ModalTitle>Změna přezdívky</ModalTitle>
            <form onSubmit={formik.handleSubmit}>
                <ModalInput 
                    type="text"
                    {...formik.getFieldProps('nickname')}
                    placeholder="Zadejte novou přezdívku"
                    ref={inputRef}
                    autoComplete="off"
                    autoCorrect="off"
                />
                {formik.touched.nickname && formik.errors.nickname ? (
                    <ErrorText>{formik.errors.nickname}</ErrorText>
                ) : null}
                {serverError && (
                    <ErrorText>
                        {serverError}
                        {timeLeft !== null && timeLeft > 0 && (
                            <TimeLeftText> Můžete změnit za {formatTime(timeLeft)}.</TimeLeftText>
                        )}
                    </ErrorText>
                )}
                <ModalButtons>
                    <ModalButton type="submit">Uložit</ModalButton>
                    <ModalButton type="button" onClick={closeModal}>Zavřít</ModalButton>
                </ModalButtons>
            </form>
        </ModalContent>
    );
};

// Stylované komponenty
const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
`;

const ModalInput = styled.input`
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    box-sizing: border-box;
`;

const ErrorText = styled.p`
    color: #e74c3c;
    margin: 4px 0;
    font-size: 14px;
    text-align: center;
`;

const TimeLeftText = styled.span`
    color: #e74c3c;
    font-size: 14px;
    display: block;
    margin-top: 4px;
    text-align: center;
`;

const ModalButtons = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
`;

const ModalButton = styled.button`
    background: #e50914;
    border: none;
    color: #fff;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: #f40612;
    }
`;

export default ZmenaPrezdivky;
