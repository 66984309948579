import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    getAuth,
    onAuthStateChanged,
    signInWithPopup,
    signOut,
    GoogleAuthProvider,
    updateProfile,
    deleteUser as firebaseDeleteUser,
    reauthenticateWithCredential,
    EmailAuthProvider,
    fetchSignInMethodsForEmail
} from 'firebase/auth';
import { auth } from '../firebase';
import {
    getUserRole,
    setUserRole,
    deleteUserAccount,
    setUserId,
    getUserById,
    generateUniqueId,
    setUserEmail, // Nově přidaná funkce
} from '../firestore';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setLoading(true);
            if (user) {
                try {
                    setUser(user);

                    // Získání role uživatele
                    let userRole = await getUserRole(user.uid);
                    if (!userRole) {
                        await setUserRole(user.uid, 'User');
                        userRole = 'User';
                    }
                    setRole(userRole);

                    // Získání dokumentu uživatele a generování nového ID, pokud neexistuje
                    let userDoc = await getUserById(user.uid);
                    if (!userDoc.ID) {
                        console.log('Uživatelský dokument neexistuje, generuji nové ID.');
                        const uniqueId = await generateUniqueId();
                        console.log('Generované unikátní ID:', uniqueId);
                        await setUserId(user.uid, uniqueId); // Uložení unikátního ID do Firestore
                    } else {
                        console.log(`Existující ID: ${userDoc.ID}`);
                    }

                    // Nastavení emailu v dokumentu, pokud ještě není nastaven
                    if (!userDoc.email) {
                        console.log('Nastavuji email uživateli.');
                        await setUserEmail(user.uid, user.email);
                    }
                } catch (error) {
                    console.error('Chyba při získávání role uživatele:', error);
                }
            } else {
                setUser(null);
                setRole(null);
            }

            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const login = async (provider) => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            if (!user.photoURL) {
                await updateProfile(user, { photoURL: '/images/Avatars/Kai.png' });
            }

            setUser(user);

            // Získání role uživatele
            let userRole = await getUserRole(user.uid);
            if (!userRole) {
                await setUserRole(user.uid, 'User');
                userRole = 'User';
            }
            setRole(userRole);

            // Získání dokumentu uživatele a generování nového ID, pokud neexistuje
            let userDoc = await getUserById(user.uid);
            if (!userDoc.ID) {
                console.log('Uživatelský dokument neexistuje, generuji nové ID.');
                const uniqueId = await generateUniqueId();
                console.log('Generované unikátní ID:', uniqueId);
                await setUserId(user.uid, uniqueId); // Uložení unikátního ID do Firestore
            } else {
                console.log(`Existující ID: ${userDoc.ID}`);
            }

            // Nastavení emailu v dokumentu, pokud ještě není nastaven
            if (!userDoc.email) {
                console.log('Nastavuji email uživateli.');
                await setUserEmail(user.uid, user.email);
            }

        } catch (error) {
            console.error('Chyba při přihlášení:', error);
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setRole(null);
        } catch (error) {
            console.error('Chyba při odhlášení:', error);
        }
    };

    const updateAvatar = async (avatarURL) => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                await currentUser.reload();
                await updateProfile(currentUser, { photoURL: avatarURL });
                setUser(prevUser => ({ ...prevUser, photoURL: avatarURL }));
            } catch (error) {
                console.error('Chyba při aktualizaci avataru:', error);
            }
        } else {
            console.error('Chyba: Žádný uživatel není přihlášen.');
        }
    };

    const updateUserDisplayName = async (displayName) => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                await currentUser.reload();
                await updateProfile(currentUser, { displayName });
                setUser(prevUser => ({ ...prevUser, displayName }));
            } catch (error) {
                console.error('Chyba při aktualizaci zobrazeného jména:', error);
            }
        } else {
            console.error('Chyba: Žádný uživatel není přihlášen.');
        }
    };

    const reauthenticateUser = async (password) => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                const credential = EmailAuthProvider.credential(currentUser.email, password);
                await reauthenticateWithCredential(currentUser, credential);
                console.log('Reautentizace úspěšná');
            } catch (error) {
                console.error('Chyba při reautentizaci:', error);
                throw error;
            }
        } else {
            console.error('Chyba: Žádný uživatel není přihlášen.');
        }
    };

    const deleteUser = async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                // Smazání dokumentu uživatele z Firestore
                await deleteUserAccount(currentUser.uid);

                // Smazání uživatele z Firebase Authentication
                await firebaseDeleteUser(currentUser);
                setUser(null);
                setRole(null);
                console.log('Uživatelský účet smazán');
            } catch (error) {
                console.error('Chyba při mazání uživatelského účtu:', error);
                throw error;
            }
        } else {
            console.error('Chyba: Žádný uživatel není přihlášen.');
        }
    };

    const getUserEmail = () => {
        return user?.email || '';
    };

    const getUserAvatar = () => {
        return user?.photoURL || '/default-avatar.png';
    };

    return (
        <AuthContext.Provider value={{ 
            user, 
            role, 
            login, 
            logout, 
            updateAvatar, 
            updateUserDisplayName, 
            reauthenticateUser, 
            deleteUser, 
            getUserEmail, 
            getUserAvatar,
            loading
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
