// src/HeaderDropdown.js
import React, { useState } from 'react';
import styled from 'styled-components';
import SeznamUzivatelu from './SeznamUzivatelu';
import JakVip from './JakVip';  // Importujeme novou komponentu

const HeaderDropdown = ({
    isDropdownOpen,
    navigateToProfile,
    handleNicknameChange,
    openModal,
    shouldShowVIPLabel,
    userRole,
    openAddRoleModal
}) => {
    const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);
    const [isUserListOpen, setIsUserListOpen] = useState(false);
    const [isJakVipOpen, setIsJakVipOpen] = useState(false);  // Nový stav pro modal JakVIP

    const handleAdminMenuToggle = () => {
        setIsAdminMenuOpen(prevState => !prevState);
    };

    const handleUserListToggle = () => {
        setIsUserListOpen(true);  // Otevře modální okno
        setIsAdminMenuOpen(false); // Zavře admin menu
    };

    const handleCloseUserList = () => {
        setIsUserListOpen(false);
    };

    const handleJakVipToggle = () => {
        setIsJakVipOpen(prevState => !prevState);  // Otevře/uzavře modal JakVIP
    };

    if (!isDropdownOpen) {
        return null;
    }

    return (
        <>
            <Dropdown>
                <DropdownItem onClick={navigateToProfile}>Profil</DropdownItem>
                <DropdownItem onClick={handleNicknameChange}>
                    {shouldShowVIPLabel && <VIPLabel>VIP</VIPLabel>}
                    Změna přezdívky
                </DropdownItem>
                {userRole === 'User' && (
                    <DropdownItem onClick={handleJakVipToggle}>
                        Jak získat VIP?
                    </DropdownItem>
                )}
                {userRole === 'Majitel' || userRole === 'Admin' ? (
                    <DropdownItem onClick={handleAdminMenuToggle}>
                        Admin menu
                        {isAdminMenuOpen && (
                            <AdminDropdown>
                                <DropdownItem onClick={openAddRoleModal}>Přidat roli</DropdownItem>
                                <DropdownItem onClick={handleUserListToggle}>
                                    Hledat uživatele
                                </DropdownItem>
                            </AdminDropdown>
                        )}
                    </DropdownItem>
                ) : null}
                <DropdownItem onClick={openModal}>Odhlásit se</DropdownItem>
            </Dropdown>
            {isUserListOpen && (
                <ModalOverlay>
                    <SeznamUzivatelu closePopup={handleCloseUserList} />
                </ModalOverlay>
            )}
            {isJakVipOpen && (
                <ModalOverlay>
                    <JakVip closePopup={handleJakVipToggle} />
                </ModalOverlay>
            )}
        </>
    );
};

const Dropdown = styled.div`
    position: absolute;
    top: 130%;
    right: 0;
    background: #333;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    min-width: 250px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const DropdownItem = styled.div`
    padding: 10px 15px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover {
        background: #555;
    }
`;

const VIPLabel = styled.div`
    background-color: #e67e22;
    color: #fff;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 12px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    height: 24px;
    white-space: nowrap;
`;

const AdminDropdown = styled.div`
    position: absolute;
    top: 10;
    right: 100%; /* Umístí dropdown nalevo od rodičovského elementu */
    background: #333;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    min-width: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1001;
    transform: translateX(-10px); /* Přidá trochu mezery mezi položkami */
`;

const ModalOverlay = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export default HeaderDropdown;
