import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function SvetStarwarsSlider2() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Star Wars: Období Prvního řádu</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-1">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Historky z Odboje</div>
                <div className="svet-text">Ř1D1&nbsp;Poe&nbsp;zasahuje</div>
                <div className="svet-minuty">7 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/pzN00L0/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-2">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Historky z Odboje</div>
                <div className="svet-text">Ř1D2&nbsp;Trable&nbsp;s&nbsp;Rathary</div>
                <div className="svet-minuty">4 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/85BjB2C/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-3">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Historky z Odboje</div>
                <div className="svet-text">Ř1D3&nbsp;Hledá&nbsp;se&nbsp;Han</div>
                <div className="svet-minuty">5 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/QbnHPp6/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-4">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Historky z Odboje</div>
                <div className="svet-text">Ř1D4&nbsp;Rey&nbsp;rací&nbsp;úder</div>
                <div className="svet-minuty">5 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/8xQJKDn/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-5">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Historky z Odboje</div>
                <div className="svet-text">Ř1D5&nbsp;Svědomí&nbsp;útočí</div>
                <div className="svet-minuty">5 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/K95HWHX/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-svatecni-special-film">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Sváteční speciál</div>
                <div className="svet-text">Sváteční&nbsp;speciál</div>
                <div className="svet-minuty">44 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/vqBDJhj/SVATECNI-SPECIAL.png" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-hruzostrasne-historky">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Hrůzostrašné historky</div>
                <div className="svet-text">Hrůzostrašné&nbsp;historky</div>
                <div className="svet-minuty">44 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/vHh5MzD/HRUZOSTRASNE-HISTORKY.png" />
              </div>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-letni-prazdniny">
            <div className="btnsvet">
              <div className="content_img">
                <div className="svet-text-serial-sw">Star Wars: Letní prázdniny</div>
                <div className="svet-text">Letní&nbsp;prázdniny</div>
                <div className="svet-minuty">45 min</div>
                <div className="svet-play">▶</div>
                <img src="https://i.ibb.co/26jDzWW/LETNI-PRAZDNINY.png" />
              </div>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default SvetStarwarsSlider2;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

   a {
    text-decoration:none;
 }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        opacity 1;
    }

    &:hover {
        img {
            opacity 0.4;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
`;
