import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoDKDSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-dekodovani-sezona-1-epizoda-1">
              <img src="https://i.ibb.co/sJYbmSM/E1.webp" loading="eager" />
              <b>1. Dědictví a Vozidla a Mechy</b>
              <p>Zane při zálohování vzpomínek narazí na hádanku pohřbenou hluboko v jeho kódu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-dekodovani-sezona-1-epizoda-2">
              <img src="https://i.ibb.co/zSKyJDW/E2.webp" loading="eager" />
              <b>2. Legendární místa a Nejhledanější v Ninjagu</b>
              <p>Nya digitalizuje Jaye a nahraje ho do Zaneovy mysli aby vyřešil hádanku na téma vozidla.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-dekodovani-sezona-1-epizoda-3">
              <img src="https://i.ibb.co/ynHQ1m2/E3.webp" loading="eager" />
              <b>3. Digivesmír a za ním a Mistři živlů</b>
              <p>Cole se připojí k Jayovi aby znovu navštívili některé z charakteristických destinací Ninjaga.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-dekodovani-sezona-1-epizoda-4">
              <img src="https://i.ibb.co/4m1tSJ0/E4.webp" loading="eager" />
              <b>4. Bestie a draci a Vzestup Garmadona</b>
              <p>Kai navrhuje aby s ninjama zjistil který padouch se snaží hacknout jejich přítele.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-dekodovani-sezona-1-epizoda-5">
              <img src="https://i.ibb.co/jWVcCLw/E5.webp" loading="eager" />
              <b>5. Proroctví zeleného ninji a Největší bitvy</b>
              <p>Tým řeší hádanku o technologii v Ninjago zatímco hrozba pro Zanea narůstá.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoDKDSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 15px;
    transform: translateY(0%);
    translateZ(0px);
    letter-spacing: -0.8px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    transform: translateY(-30%);
    translateZ(0px);
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -8px;
    margin-top: -61px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -61px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
