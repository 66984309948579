import React from 'react';
import { Helmet } from 'react-helmet';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { useAuth } from '../../../contexts/AuthContext';

function NINJAGOSNOVYTYM() {
  const { user, role, loading } = useAuth();

  const allowedRoles = ['Majitel', 'Admin', 'Moderátor', 'VIP'];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-placer">
      {allowedRoles.includes(role) ? (
        <>
          <Helmet>
            <title>NCE+ | Přehrávač</title>
            <meta name="description" content="NCE+ vám nabídne záživné sledování v rozlišení HD až Full HD a to zcela zdarma." />
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="embed-container">
            <iframe
              src="https://filemoon.sx/e/mml17jwpucrk/Snov__t_m"
              autoPlay="true"
              frameBorder="0"
              allowFullScreen="true"
            />
          </div>
        </>
      ) : (
        <div className="access-denied">
          <h2>Pro přehrání epizody potřebuješ VIP</h2>
        </div>
      )}
    </div>
  );
}

export default NINJAGOSNOVYTYM;
