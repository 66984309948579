import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function DobrodruznyPOSTERSLIDER() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Dobrodružný</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/ninjago-draci-povstani">
            <img className="hovimg" src="/images/Postery/NINJAGO-DP-POSTER.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-ninjago-film">
            <img className="hovimg" src="https://i.ibb.co/YLMZNXn/NINJAGO-POSTER.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/monkie-kid">
            <img className="hovimg" src="https://i.ibb.co/tYC3FjX/NINJAGO-POSTERR.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-dekodovani">
            <img className="hovimg" src="https://i.ibb.co/zhFzDT8/NINJAGO-DEKODOVANI-POSTER.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/atlantis">
            <img className="hovimg" src="https://i.ibb.co/CJsmyQK/ATLANTIS-POSTER.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago">
            <img className="hovimg" src="https://i.ibb.co/84w8CSH/NINJAGO-POSTER-1.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-hruzostrasne-historky">
            <img className="hovimg" src="https://i.ibb.co/TmFWthM/STAR-WARS-HRUZOSTRASNE-HISTORKY.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-dobrodruzstvi-freemakeru">
            <img className="hovimg" src="https://i.ibb.co/jHN1qGC/STARWARS-DOBRODRUZSTVI.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/ninjago-mistri-spinjitzu">
            <img className="hovimg" src="https://i.ibb.co/C2RRhQ8/NINJAGO-MST.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/nexo-knights">
            <img className="hovimg" src="https://i.ibb.co/xMgm6q9/NEXO-KNIGHTS-POSTER.png" />
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default DobrodruznyPOSTERSLIDER;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }
b {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 3px;
   }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding: 10px;
    margin-top: -18px;
}

.slick-arrow{
    opacity: 0;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
    }
    
    .slick-arrow:hover{
    opacity: 1;
    }

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
border-radius: 4px;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;
cursor: pointer;
overflow: hidden;
position: relative;
transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
outline: 0px solid transparent;
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
    
    img  {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        border-radius: 4px;   
      }

      &:hover {
        outline: 3.2px solid #C7C7C7;
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
        rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        }
`;
