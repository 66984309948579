import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function KomedieSLIDER() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: false,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Zacatek>
        <h4>Komedie</h4>
      </Zacatek>
      <Carousel {...settings}>
        <Wrap>
          <a href="/lego-star-wars-padawanska-hrozba">
            <img className="hovimg" src="/images/Nahledovky/PADAWANSKA-HROZBA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-star-wars-imperium-utoci">
            <img className="hovimg" src="/images/Nahledovky/IMPERIUM-UTOCI.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-pribeh-1">
            <img className="hovimg" src="https://i.ibb.co/Wv1V3Nq/LEGO-PRIBEH.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-pribeh-2">
            <img className="hovimg" src="https://i.ibb.co/H7tQMnF/LEGOPRIBEH-2.png" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje">
            <img className="hovimg" src="/images/Nahledovky/STAR-WARS-HISTORKY-Z-ODBOJE.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-pribehy-droidu">
            <img className="hovimg" src="/images/Nahledovky/PRIBEHY-DROIDU.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-batman-do-ligy">
            <img className="hovimg" src="https://i.ibb.co/HCcmPxC/LEGO-DC-SUPER-HRDINOVE-BATMAN-DO-LIGY-NAHLEDOVKA.jpg" />
          </a>
        </Wrap>
        <Wrap>
          <a href="/lego-dc-super-hrdinove-aquaman">
            <img className="hovimg" src="https://i.ibb.co/KD6YbKV/DC-SUPER-HEROES-AQUA.jpg" />
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default KomedieSLIDER;

const Container = styled.div`
padding: 0 calc(3.5vw + 5px);
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25spx;
}
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }
b {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 3px;
   }


.slick-slide {
    width: 8%;
    height: 8%;
    display: block;
    padding:10px;
    margin-top: -18px;
}

.slick-arrow{
    display: block;
    opacity: 0;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
    }
    
    .slick-arrow:hover{
    opacity: 1;
    }

.slick-prev {
    margin-top: -20px;
  }

  .slick-next {
    margin-top: -20px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
padding-top: 56.25%;
border-radius: 4px;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;
cursor: pointer;
overflow: hidden;
position: relative;
transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
outline: 0px solid transparent;
background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));

img {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: fill;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}

    &:hover {
        outline: 3.2px solid #C7C7C7;
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
        rgb(0 0 0 / 72%) 0px 30px 22px -10px;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        }
`;
