import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import { useAuth } from '../contexts/AuthContext';
import { getUserRole } from '../firestore';

const Avatar = () => {
    const { user, updateAvatar } = useAuth();
    const [isAvatarPopupOpen, setIsAvatarPopupOpen] = useState(false);
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
    const [isAccessDeniedPopupOpen, setIsAccessDeniedPopupOpen] = useState(false);
    const [avatarToConfirm, setAvatarToConfirm] = useState(null);
    const [selectedAvatar, setSelectedAvatar] = useState('/images/Avatars/Kai.png'); // Default avatar
    const [currentPage, setCurrentPage] = useState(0);
    const [transitioning, setTransitioning] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [avatarVersion, setAvatarVersion] = useState(0);

    useEffect(() => {
        if (user) {
            setSelectedAvatar(user.photoURL || '/images/Avatars/Kai.png'); // Set default avatar if no user photoURL
            fetchUserRole(user.uid);
        }
    }, [user]);

    useEffect(() => {
        if (user?.photoURL) {
            setSelectedAvatar(user.photoURL);
        }
    }, [user?.photoURL]);

    const fetchUserRole = async (userId) => {
        try {
            const role = await getUserRole(userId);
            setUserRole(role);
        } catch (error) {
            console.error('Nepodařilo se načíst roli uživatele:', error);
        }
    };

    useEffect(() => {
        setImageLoaded(false);
        const timer = setTimeout(() => setImageLoaded(true), 100);
        return () => clearTimeout(timer);
    }, [currentPage]);

    useEffect(() => {
        document.body.classList.toggle('no-scroll', transitioning);
    }, [transitioning]);

    const openAvatarPopup = () => setIsAvatarPopupOpen(true);
    const closeAvatarPopup = () => setIsAvatarPopupOpen(false);
    const openConfirmPopup = (avatar) => {
        if (currentPage === 3 && !['Majitel', 'Admin', 'Moderátor', 'VIP'].includes(userRole)) {
            setIsAccessDeniedPopupOpen(true);
        } else {
            setAvatarToConfirm(avatar);
            setIsConfirmPopupOpen(true);
        }
    };
    const closeConfirmPopup = () => {
        setIsConfirmPopupOpen(false);
        setAvatarToConfirm(null);
    };
    const closeAccessDeniedPopup = () => setIsAccessDeniedPopupOpen(false);

    const handleConfirmAvatarChange = async () => {
        if (avatarToConfirm) {
            try {
                if (updateAvatar) {
                    await updateAvatar(avatarToConfirm);
                    setSelectedAvatar(avatarToConfirm);
                    setAvatarVersion(prevVersion => prevVersion + 1);
                } else {
                    console.error('Funkce updateAvatar není k dispozici');
                }
            } catch (error) {
                console.error('Nepodařilo se aktualizovat avatar:', error);
            }
            closeConfirmPopup();
            closeAvatarPopup();
        }
    };

    const avatars = [
        '/images/Avatars/Kai.png',
        '/images/Avatars/Jay.png',
        '/images/Avatars/Cole.png',
        '/images/Avatars/Zane.png',
        '/images/Avatars/Lloyd.png',
        '/images/Avatars/Nya.png',
        '/images/Avatars/Laval.png',
        '/images/Avatars/Cragger.png',
        '/images/Avatars/Eris.png',
        '/images/Avatars/Gorzan.png',
        '/images/Avatars/Worriz.png',
        '/images/Avatars/Razar.png',
        '/images/Avatars/Clay.png',
        '/images/Avatars/Macy.png',
        '/images/Avatars/Lence.png',
        '/images/Avatars/Aaron.png',
        '/images/Avatars/Axl.png',
        '/images/Avatars/Robin.png',
        '/images/Avatars/Milkman.png',
        '/images/Avatars/Postman.png',
        '/images/Avatars/Dareth.png',
        '/images/Avatars/Fsm.png',
        '/images/Avatars/Acronix.png',
        '/images/Avatars/Krux.png'
    ];

    const avatarsPerPage = 6;
    const totalPages = Math.ceil(avatars.length / avatarsPerPage);
    const currentAvatars = avatars.slice(currentPage * avatarsPerPage, (currentPage + 1) * avatarsPerPage);

    const handlePageChange = (direction) => {
        setTransitioning(true);
        setTimeout(() => {
            if (direction === 'next') {
                setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages - 1));
            } else if (direction === 'prev') {
                setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
            }
            setTransitioning(false);
        }, 300);
    };

    const getPopupImageSrc = (page) => {
        switch (page) {
            case 0:
                return '/images/Avatars/Ninjagoavatar.png';
            case 1:
                return '/images/Avatars/Chimavatar.png';
            case 2:
                return '/images/Avatars/Nexoknightsavatar.png';
            case 3:
                return '/images/Avatars/Ninjagoavatar.png';
        }
    };

    return (
        <>
            <UserAvatar
                key={avatarVersion}
                src={selectedAvatar}
                alt={user?.displayName || 'Uživatel'}
                onClick={openAvatarPopup}
            />
            <Modal
                isOpen={isAvatarPopupOpen}
                onRequestClose={closeAvatarPopup}
                contentLabel="Výběr avataru"
                style={avatarPopupStyles}
                ariaHideApp={false}
            >
                <AvatarPopupContent>
                    <FadeInAnimation>
                        <PopupTitle>VYBERTE AVATAR</PopupTitle>
                        <FadeInAnimation>
                        <AvatarPopupImage src={getPopupImageSrc(currentPage)} alt="Avatar Popup" imageLoaded={imageLoaded} />
                        </FadeInAnimation>
                        <AvatarGrid transitioning={transitioning}>
                            {currentAvatars.map((avatar, index) => (
                                <AvatarOption key={index} onClick={() => openConfirmPopup(avatar)}>
                                    <AvatarImage src={avatar} alt={`Avatar ${index + 1}`} />
                                </AvatarOption>
                            ))}
                        </AvatarGrid>
                        <PaginationControls>
                            {currentPage === 3 && userRole === 'User' && (
                                 <FadeInAnimation>
                                <RoleText>VIP</RoleText>
                                </FadeInAnimation>
                            )}
                            <PaginationButton onClick={() => handlePageChange('prev')} disabled={currentPage === 0}>
                                PŘEDCHOZÍ
                            </PaginationButton>
                            <PageIndicator>{currentPage + 1} / {totalPages}</PageIndicator>
                            <PaginationButton onClick={() => handlePageChange('next')} disabled={currentPage === totalPages - 1}>
                                DALŠÍ
                            </PaginationButton>
                        </PaginationControls>
                        <CloseButton onClick={closeAvatarPopup}>×</CloseButton>
                    </FadeInAnimation>
                </AvatarPopupContent>
            </Modal>

            <Modal
                isOpen={isConfirmPopupOpen}
                onRequestClose={closeConfirmPopup}
                contentLabel="Potvrďte výběr avataru"
                style={confirmPopupStyles}
                ariaHideApp={false}
            >
                <FadeInAnimation>
                    <ModalContent>
                        <ModalTitle>OPRAVDU CHCETE ZVOLIT TENTO AVATAR?</ModalTitle>
                        {avatarToConfirm && (
                            <AvatarImage src={avatarToConfirm} alt="Avatar k potvrzení" style={{ width: '100px', height: '100px' }} />
                        )}
                        <ModalButtons>
                            <ModalButton onClick={handleConfirmAvatarChange}>ANO</ModalButton>
                            <ModalButton onClick={closeConfirmPopup}>NE</ModalButton>
                        </ModalButtons>
                    </ModalContent>
                </FadeInAnimation>
            </Modal>

            <Modal
                isOpen={isAccessDeniedPopupOpen}
                onRequestClose={closeAccessDeniedPopup}
                contentLabel="Odmítnutí přístupu"
                style={confirmPopupStyles}
                ariaHideApp={false}
            >
                <FadeInAnimation>
                    <ModalContent>
                        <ModalTitle>PRO TOHOTO AVATARA MUSÍTE MÍT VIP</ModalTitle>
                        <ModalButtons>
                            <AccessDeniedButton onClick={closeAccessDeniedPopup}>OK</AccessDeniedButton>
                        </ModalButtons>
                    </ModalContent>
                </FadeInAnimation>
            </Modal>
        </>
    );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const FadeInAnimation = styled.div`
    animation: ${fadeIn} 0.3s;
`;

const UserAvatar = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 0 0 1px rgba(156, 152, 145, 0.8);
    }
`;

const avatarPopupStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        padding: '20px',
        border: 'none',
        background: '#333',
        color: '#fff',
        zIndex: 1000,
        maxWidth: '500px',
        width: '90%',
        maxHeight: '80vh',
        overflow: 'hidden',
    }
};

const confirmPopupStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        padding: '20px',
        border: 'none',
        background: '#333',
        color: '#fff',
        zIndex: 1000,
        maxWidth: '400px',
        width: '90%',
        maxHeight: '50vh',
        overflow: 'hidden',
    }
};

const AvatarPopupContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const PopupTitle = styled.h2`
    font-size: 24px;
    color: #fff;
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: center;
`;

const AvatarGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    transition: opacity 0.3s ease;
    opacity: ${props => props.transitioning ? 0.5 : 1};
`;

const AvatarOption = styled.div`
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: inline-block;
    will-change: transform, box-shadow;

    &:hover {
        box-shadow: 0 0 0 4px rgba(249, 249, 249, 0.8);
        transform: scale(1.05);
        z-index: 1;
    }

    img {
        transition: none;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        backface-visibility: hidden;
    }
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: box-shadow 0.3s ease, outline 0.3s ease;
    outline: 0px solid transparent;
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    box-sizing: border-box;
    transform: translateY(-1px);
`;

const AvatarPopupImage = styled.img`
    max-width: 30%;
    height: auto;
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
`;

const ModalTitle = styled.h2`
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: center;
`;

const ModalButtons = styled.div`
    display: flex;
    flex-direction: row; /* Změněno z column na row */
    justify-content: center; /* Přidáno pro vycentrování tlačítek */
    align-items: center;
    width: 100%;
    margin-top: 15px; /* Přidáno pro trochu prostoru nad tlačítky */
`;


const ModalButton = styled.button`
    background-color: #4285f4;
    border: none;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #357ae8;
    }
`;

const AccessDeniedButton = styled.button`
    background-color: #f44336; /* Červené pozadí */
    border: none;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #d32f2f; /* Tmavší červená při hoveru */
    }
`;

const PaginationControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    position: relative;
`;

const PaginationButton = styled.button`
    background-color: #4285f4;
    border: none;
    color: white;
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;

    &:disabled {
        background-color: #888;
        cursor: not-allowed;
    }
    &:hover:not(:disabled) {
        background-color: #357ae8;
    }
`;

const PageIndicator = styled.span`
    font-size: 14px;
    color: #fff;
`;

const RoleText = styled.span`
    background-color: #f57c00; /* Oranžové pozadí */
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
`;

export default Avatar;
