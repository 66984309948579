import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from './contexts/AuthContext';
import { getUserRole, getUserById } from './firestore';
import Modal from 'react-modal';
import SmazatUcet from './SmazatUcet';

// Mapování rolí na zobrazení
const mapRoleToDisplayName = (role) => {
    switch (role) {
        case 'User':
            return 'Uživatel';
        case 'Majitel':
            return 'Majitel';
        case 'Admin':
            return 'Admin';
        case 'Moderátor':
            return 'Moderátor';
        case 'VIP':
            return 'VIP';
        default:
            return role;
    }
};

// Funkce pro získání barvy na základě role
const getRoleColor = (role) => {
    switch (role) {
        case 'Majitel':
            return '#f39c12';
        case 'Admin':
            return '#e74c3c';
        case 'Moderátor':
            return '#2ecc71';
        case 'VIP':
            return '#e67e22';
        default:
            return '#3498db'; // Barva pro ostatní role
    }
};

const UserProfile = () => {
    const { user, loading, logout } = useAuth();
    const [role, setRole] = useState(null);
    const [joinDate, setJoinDate] = useState(null);
    const [userId, setUserId] = useState(null); // Přidáno pro ID uživatele
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // Přidána modal pro smazání účtu
    const [emailHidden, setEmailHidden] = useState(false);
    const [file, setFile] = useState(null);
    const [copyMessage, setCopyMessage] = useState('');

    useEffect(() => {
        const savedEmailHidden = localStorage.getItem('emailHidden') === 'true';
        setEmailHidden(savedEmailHidden);

        const fetchData = async () => {
            if (user) {
                try {
                    console.log('UID uživatele:', user.uid); // Debugging
                    const userRole = await getUserRole(user.uid);
                    setRole(userRole);
                    setJoinDate(new Date(user.metadata.creationTime));

                    // Získání ID uživatele
                    const userDoc = await getUserById(user.uid);
                    console.log('ID uživatele:', userDoc?.ID); // Debugging
                    setUserId(userDoc?.ID || 'Načítání...');
                } catch (error) {
                    console.error('Chyba při získávání dat uživatele:', error);
                }
            }
        };

        fetchData();
    }, [user]);

    const formatDate = (date) => {
        if (!date) return '';
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('cs-CZ', options).format(date);
    };

    const handleToggleEmailVisibility = () => {
        const newEmailHidden = !emailHidden;
        setEmailHidden(newEmailHidden);
        localStorage.setItem('emailHidden', newEmailHidden.toString());
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        // Zde můžete přidat logiku pro nahrávání souboru
        console.log('Vybraný soubor:', selectedFile);
    };

    const handleCopyId = () => {
        if (userId) {
            navigator.clipboard.writeText(userId);
            setCopyMessage('ID zkopírováno');
            setTimeout(() => setCopyMessage(''), 2000);
        }
    };

    if (loading) {
        return <LoadingMessage>Načítání...</LoadingMessage>;
    }

    if (!user) {
        return <LoadingMessage>Nejste přihlášeni</LoadingMessage>;
    }

    const activityStatus = role && ['Majitel', 'Admin', 'Moderátor', 'VIP'].includes(role)
        ? 'Aktivní'
        : 'Neaktivní';

    const statusColor = role && ['Majitel', 'Admin', 'Moderátor', 'VIP'].includes(role)
        ? '#3498db'
        : '#333';

    const handleLogout = () => {
        setLogoutModalOpen(true);
    };

    const handleConfirmLogout = () => {
        logout();
        setLogoutModalOpen(false);
    };

    const handleCloseLogoutModal = () => {
        setLogoutModalOpen(false);
    };

    const handleDeleteAccount = () => {
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    return (
        <ProfileWrapper>
            <BackgroundImage />
            <ProfileContainer>
                <ProfileContent>
                    <UserAvatar src={user.photoURL || '/images/Avatars/Kai.png'} alt={user.displayName || 'Uživatel'} />
                    <UserName>{user.displayName || 'Neznámý uživatel'}</UserName>
                    {role && <RoleBadge color={getRoleColor(role)}>{mapRoleToDisplayName(role)}</RoleBadge>}
                </ProfileContent>
                <InfoAndEmailContainer>
                    <InfoColumn>
                        <InfoItem>
                            <InfoTitle>Členem od:</InfoTitle>
                            <InfoValue>{formatDate(joinDate)}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoTitle>Moje ID:</InfoTitle>
                            <InfoValue onClick={handleCopyId} style={{ cursor: 'pointer' }}> {userId || 'Načítání...'} {copyMessage && <CopyMessage>{copyMessage}</CopyMessage>}</InfoValue> {/* Zobrazení ID uživatele */}
                        </InfoItem>
                    </InfoColumn>
                    <EmailColumn>
                        <InfoItem>
                            <InfoTitle>Email:</InfoTitle>
                            <InfoValue>
                                {emailHidden ? '**********' : user.email || 'N/A'}
                            </InfoValue>
                            <ToggleContainer>
                                <ToggleSwitch
                                    checked={emailHidden}
                                    onChange={handleToggleEmailVisibility}
                                />
                                <ToggleLabel>SKRÝT EMAIL</ToggleLabel>
                            </ToggleContainer>
                        </InfoItem>
                    </EmailColumn>
                </InfoAndEmailContainer>
                <ActionsContainer>
                    <VIPAndStatusColumn>
                        <VIPBenefitsText>VIP VÝHODY:</VIPBenefitsText>
                        <StatusBadge statusColor={statusColor}>{activityStatus}</StatusBadge>
                    </VIPAndStatusColumn>
                    <ActionColumnContainer>
                        <LogoutColumn onClick={handleLogout}>
                            <LogoutText>Odhlásit</LogoutText>
                        </LogoutColumn>
                        <DeleteAccountColumn onClick={handleDeleteAccount}>
                            <LogoutText>Smazat účet</LogoutText>
                        </DeleteAccountColumn>
                    </ActionColumnContainer>
                </ActionsContainer>
            </ProfileContainer>
            <Modal
                isOpen={isLogoutModalOpen}
                onRequestClose={handleCloseLogoutModal}
                style={modalStyles}
            >
                <ModalContent>
                    <ModalTitle>Opravdu se chcete odhlásit?</ModalTitle>
                    <ModalButtons>
                        <ModalButton onClick={handleConfirmLogout}>Ano</ModalButton>
                        <ModalButton onClick={handleCloseLogoutModal}>Ne</ModalButton>
                    </ModalButtons>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={handleCloseDeleteModal}
                style={modalStyles}
            >
                <SmazatUcet onClose={handleCloseDeleteModal} />
            </Modal>
        </ProfileWrapper>
    );
};

// Stylování modálního okna
const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        padding: '20px',
        border: 'none',
        background: '#333',
        color: '#fff',
        zIndex: 1000,
    }
};

const ProfileWrapper = styled.div`
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://i.ibb.co/kcLNjZh/BACK.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    z-index: -1;
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 1000px;
    width: 100%;
    gap: 20px;
`;

const ProfileContent = styled.div`
    text-align: center;
    background: #2f3136;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 820px;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const InfoAndEmailContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: center;
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
    background: #2f3136;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 400px;
    width: 100%;
`;

const EmailColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
    background: #2f3136;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 400px;
    width: 100%;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 20px;
    padding-top: 0px;
`;

const ActionColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 50%;
    max-width: 400px;
    justify-content: center;

          @media (max-width: 768px) {
width: 50%;
    gap: 5px;
    }
`;

const VIPAndStatusColumn = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    max-width: 400px;
    gap: 20px;
    justify-content: center;

              @media (max-width: 768px) {
                      margin-bottom 10px;
width: 50%;
    gap: 5px;
    }
`;

const VIPBenefitsText = styled.div`
    cursor: default;
    background: #e67e22;
    color: #fff;
    border-radius: 12px;
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    flex: 1;

    @media (max-width: 768px) {
    font-size: 15px;
        padding: 10px;
    }
`;

const StatusBadge = styled.div`
    background: ${props => props.statusColor || '#333'};
    cursor: default;
    color: #fff;
    border-radius: 12px;
    font-weight: bold;
    padding: 20px;
    font-size: 16px;
    text-align: center;
    flex: 1;
`;

const LogoutColumn = styled.div`
    background: #2c3e50;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 200px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

                  @media (max-width: 768px) {
                      margin-bottom 10px;
}
    }
`;

const DeleteAccountColumn = styled.div`
    background: #e74c3c; /* Červené pozadí */
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 200px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InfoItem = styled.div`
    margin-bottom: 10px;
`;

const InfoTitle = styled.h3`
    cursor: default;
    font-size: 16px;
    margin: 0 0 5px 0;
    color: #7289da;
`;

const InfoValue = styled.div`
    font-size: 14px;
    margin: 0;
    display: inline-flex;
    align-items: center;
    position: relative;
`;

const FileInput = styled.input`
    margin-top: 10px;
`;

const FileName = styled.p`
    font-size: 14px;
    margin: 10px 0 0;
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: flex-start;
`;

const ToggleSwitch = styled.input.attrs({ type: 'checkbox' })`
    position: relative;
    width: 60px;
    height: 30px;
    -webkit-appearance: none;
    background: #ccc;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s;
    &:checked {
        background: #11162e;
    }
    &:checked::before {
        transform: translateX(30px);
    }
    &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        top: 0;
        left: 0;
        transition: transform 0.3s;
    }
`;

const ToggleLabel = styled.span`
    font-family: 'Mukta', sans-serif;
    margin-left: 10px;
    cursor: default;
    font-size: 16px;
    color: #fff;
`;

const LogoutText = styled.div`
    font-size: 16px;
    font-weight: bold;

                          @media (max-width: 768px) {
                         font-size: 14px;
}
`;

const UserAvatar = styled.img`
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #444;
`;

const UserName = styled.h1`
    margin: 10px 0;
    font-size: 24px;
    color: #fff;
`;

const RoleBadge = styled.div`
    background: ${props => props.color || '#444'};
    color: #fff;
        cursor: default;
    border-radius: 12px;
    padding: 5px 10px;
    font-size: 14px;
    margin-top: 10px;
    display: inline-block;
`;

const LoadingMessage = styled.div`
    font-size: 18px;
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

// Stylování modálního okna
const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
`;

const ModalButtons = styled.div`
    display: flex;
    gap: 10px;
`;

const CopyMessage = styled.div`
    color: green;
    font-size: 12px;
    margin-left: 10px; /* Mezera mezi ID a zprávou */
    white-space: nowrap; /* Zajistí, že zpráva nebude zalamována */
`;

const ModalButton = styled.button`
    background: #e50914;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: #f40612;
    }
`;

export default UserProfile;
