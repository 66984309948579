import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function HistorkyzodbojeSliderS01() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-1">
            <div className="btn">
              <img src="https://i.ibb.co/pzN00L0/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" loading="eager" />
              <b>1. Poe zasahuje</b>
              <p>Poe Dameron vymyslí plán na záchranu admirála Ackbara.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-2">
            <div className="btn">
              <img src="https://i.ibb.co/85BjB2C/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" loading="eager" />
              <b>2. Trable s Rathary</b>
              <p>Hana a Chewbaccu zachytí stíhačky TIE! a u toho nastanou problémy s Rathary!</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-3">
            <div className="btn">
              <img src="https://i.ibb.co/QbnHPp6/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" loading="eager" />
              <b>3. Hledá se Han</b>
              <p>Banda pirátů se dohaduje o tom, kde asi tak může být Han.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-4">
            <div className="btn">
              <img src="https://i.ibb.co/8xQJKDn/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" loading="eager" />
              <b>4. Rey vrací úder</b>
              <p>Hrozí nebezpečí, že dojde k odcizení Reyiného tankového speederu.</p>
            </div>
          </a>
        </Wrap>
        <Wrap>
          <a href="/star-wars-historky-z-odboje-sezona-1-epizoda-5">
            <div className="btn">
              <img src="https://i.ibb.co/K95HWHX/scale-width-400-aspect-Ratio-Na-N-format-jpeg.jpg" loading="eager" />
              <b>5. Svědomí útočí</b>
              <p>Finn dostane svou velkou šanci stát se stormtrooperem.</p>
            </div>
          </a>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default HistorkyzodbojeSliderS01;

const Container = styled.div`
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 25px;
  }

b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    translateZ(0px);
    letter-spacing: -0.8px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    transform: translateY(-30%);
    translateZ(0px);
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -8px;
    margin-top: -61px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -61px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
