/// /////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// ////////////////////////////SERIÁLY//////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////NINJAGO - MST///////////////////////////
/// /////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FileInfo from './FileInfo';
import { AuthProvider } from './contexts/AuthContext';
import Loader from './Loader';
import { setOwnerRole } from './firestore';
import Ninjagomistrispinjitzu from './components/Ninjago - MST - ALT TABY/NInjagomistrispinjitzu';
/// /// SEZÓNA 0 - PILOT/////
import S00E01 from './components/Ninjago - MST - ALT TABY/S00/S00E01';
import S00E02 from './components/Ninjago - MST - ALT TABY/S00/S00E02';
/// /// SEZÓNA 1 - VZESTUP HADÍCH KMENŮ/////
import S01E01 from './components/Ninjago - MST - ALT TABY/S01/S01E01';
import S01E02 from './components/Ninjago - MST - ALT TABY/S01/S01E02';
import S01E03 from './components/Ninjago - MST - ALT TABY/S01/S01E03';
import S01E04 from './components/Ninjago - MST - ALT TABY/S01/S01E04';
import S01E05 from './components/Ninjago - MST - ALT TABY/S01/S01E05';
import S01E06 from './components/Ninjago - MST - ALT TABY/S01/S01E06';
import S01E07 from './components/Ninjago - MST - ALT TABY/S01/S01E07';
import S01E08 from './components/Ninjago - MST - ALT TABY/S01/S01E08';
import S01E09 from './components/Ninjago - MST - ALT TABY/S01/S01E09';
import S01E10 from './components/Ninjago - MST - ALT TABY/S01/S01E10';
import S01E11 from './components/Ninjago - MST - ALT TABY/S01/S01E11';
import S01E12 from './components/Ninjago - MST - ALT TABY/S01/S01E12';
import S01E13 from './components/Ninjago - MST - ALT TABY/S01/S01E13';
/// /// SEZÓNA 2 - ZELENÝ NINJA/////
import S02E01 from './components/Ninjago - MST - ALT TABY/S02/S02E01';
import S02E02 from './components/Ninjago - MST - ALT TABY/S02/S02E02';
import S02E03 from './components/Ninjago - MST - ALT TABY/S02/S02E03';
import S02E04 from './components/Ninjago - MST - ALT TABY/S02/S02E04';
import S02E05 from './components/Ninjago - MST - ALT TABY/S02/S02E05';
import S02E06 from './components/Ninjago - MST - ALT TABY/S02/S02E06';
import S02E07 from './components/Ninjago - MST - ALT TABY/S02/S02E07';
import S02E08 from './components/Ninjago - MST - ALT TABY/S02/S02E08';
import S02E09 from './components/Ninjago - MST - ALT TABY/S02/S02E09';
import S02E10 from './components/Ninjago - MST - ALT TABY/S02/S02E10';
import S02E11 from './components/Ninjago - MST - ALT TABY/S02/S02E11';
import S02E12 from './components/Ninjago - MST - ALT TABY/S02/S02E12';
import S02E13 from './components/Ninjago - MST - ALT TABY/S02/S02E13';
/// /// SEZÓNA 3 - RESTART/////
import S03E01 from './components/Ninjago - MST - ALT TABY/S03/S03E01';
import S03E02 from './components/Ninjago - MST - ALT TABY/S03/S03E02';
import S03E03 from './components/Ninjago - MST - ALT TABY/S03/S03E03';
import S03E04 from './components/Ninjago - MST - ALT TABY/S03/S03E04';
import S03E05 from './components/Ninjago - MST - ALT TABY/S03/S03E05';
import S03E06 from './components/Ninjago - MST - ALT TABY/S03/S03E06';
import S03E07 from './components/Ninjago - MST - ALT TABY/S03/S03E07';
import S03E08 from './components/Ninjago - MST - ALT TABY/S03/S03E08';
/// /// SEZÓNA 4 - TURNAJ ŽIVLŮ/////
import S04E01 from './components/Ninjago - MST - ALT TABY/S04/S04E01';
import S04E02 from './components/Ninjago - MST - ALT TABY/S04/S04E02';
import S04E03 from './components/Ninjago - MST - ALT TABY/S04/S04E03';
import S04E04 from './components/Ninjago - MST - ALT TABY/S04/S04E04';
import S04E05 from './components/Ninjago - MST - ALT TABY/S04/S04E05';
import S04E06 from './components/Ninjago - MST - ALT TABY/S04/S04E06';
import S04E07 from './components/Ninjago - MST - ALT TABY/S04/S04E07';
import S04E08 from './components/Ninjago - MST - ALT TABY/S04/S04E08';
import S04E09 from './components/Ninjago - MST - ALT TABY/S04/S04E09';
import S04E10 from './components/Ninjago - MST - ALT TABY/S04/S04E10';
/// /// SEZÓNA 5 - POSEDNUTÍ/////
import S05E01 from './components/Ninjago - MST - ALT TABY/S05/S05E01';
import S05E02 from './components/Ninjago - MST - ALT TABY/S05/S05E02';
import S05E03 from './components/Ninjago - MST - ALT TABY/S05/S05E03';
import S05E04 from './components/Ninjago - MST - ALT TABY/S05/S05E04';
import S05E05 from './components/Ninjago - MST - ALT TABY/S05/S05E05';
import S05E06 from './components/Ninjago - MST - ALT TABY/S05/S05E06';
import S05E07 from './components/Ninjago - MST - ALT TABY/S05/S05E07';
import S05E08 from './components/Ninjago - MST - ALT TABY/S05/S05E08';
import S05E09 from './components/Ninjago - MST - ALT TABY/S05/S05E09';
import S05E10 from './components/Ninjago - MST - ALT TABY/S05/S05E10';
/// /// SEZÓNA 6 - V NEBESÍCH/////
import S06E01 from './components/Ninjago - MST - ALT TABY/S06/S06E01';
import S06E02 from './components/Ninjago - MST - ALT TABY/S06/S06E02';
import S06E03 from './components/Ninjago - MST - ALT TABY/S06/S06E03';
import S06E04 from './components/Ninjago - MST - ALT TABY/S06/S06E04';
import S06E05 from './components/Ninjago - MST - ALT TABY/S06/S06E05';
import S06E06 from './components/Ninjago - MST - ALT TABY/S06/S06E06';
import S06E07 from './components/Ninjago - MST - ALT TABY/S06/S06E07';
import S06E08 from './components/Ninjago - MST - ALT TABY/S06/S06E08';
import S06E09 from './components/Ninjago - MST - ALT TABY/S06/S06E09';
import S06E10 from './components/Ninjago - MST - ALT TABY/S06/S06E10';
/// /// SEZÓNA 7 - RUCE ČASU/////
import S07E01 from './components/Ninjago - MST - ALT TABY/S07/S07E01';
import S07E02 from './components/Ninjago - MST - ALT TABY/S07/S07E02';
import S07E03 from './components/Ninjago - MST - ALT TABY/S07/S07E03';
import S07E04 from './components/Ninjago - MST - ALT TABY/S07/S07E04';
import S07E05 from './components/Ninjago - MST - ALT TABY/S07/S07E05';
import S07E06 from './components/Ninjago - MST - ALT TABY/S07/S07E06';
import S07E07 from './components/Ninjago - MST - ALT TABY/S07/S07E07';
import S07E08 from './components/Ninjago - MST - ALT TABY/S07/S07E08';
import S07E09 from './components/Ninjago - MST - ALT TABY/S07/S07E09';
import S07E10 from './components/Ninjago - MST - ALT TABY/S07/S07E10';
/// /// SEZÓNA 8 - SYNOVÉ GARMADONA/////
import S08E01 from './components/Ninjago - MST - ALT TABY/S08/S08E01';
import S08E02 from './components/Ninjago - MST - ALT TABY/S08/S08E02';
import S08E03 from './components/Ninjago - MST - ALT TABY/S08/S08E03';
import S08E04 from './components/Ninjago - MST - ALT TABY/S08/S08E04';
import S08E05 from './components/Ninjago - MST - ALT TABY/S08/S08E05';
import S08E06 from './components/Ninjago - MST - ALT TABY/S08/S08E06';
import S08E07 from './components/Ninjago - MST - ALT TABY/S08/S08E07';
import S08E08 from './components/Ninjago - MST - ALT TABY/S08/S08E08';
import S08E09 from './components/Ninjago - MST - ALT TABY/S08/S08E09';
import S08E10 from './components/Ninjago - MST - ALT TABY/S08/S08E10';
/// /// SEZÓNA 9 - LOV/////
import S09E01 from './components/Ninjago - MST - ALT TABY/S09/S09E01';
import S09E02 from './components/Ninjago - MST - ALT TABY/S09/S09E02';
import S09E03 from './components/Ninjago - MST - ALT TABY/S09/S09E03';
import S09E04 from './components/Ninjago - MST - ALT TABY/S09/S09E04';
import S09E05 from './components/Ninjago - MST - ALT TABY/S09/S09E05';
import S09E06 from './components/Ninjago - MST - ALT TABY/S09/S09E06';
import S09E07 from './components/Ninjago - MST - ALT TABY/S09/S09E07';
import S09E08 from './components/Ninjago - MST - ALT TABY/S09/S09E08';
import S09E09 from './components/Ninjago - MST - ALT TABY/S09/S09E09';
import S09E10 from './components/Ninjago - MST - ALT TABY/S09/S09E10';
/// /// SEZÓNA 10 - NÁVRAT ONI/////
import S10E04 from './components/Ninjago - MST - ALT TABY/S10/S10E04';
import S10E03 from './components/Ninjago - MST - ALT TABY/S10/S10E03';
import S10E02 from './components/Ninjago - MST - ALT TABY/S10/S10E02';
import S10E01 from './components/Ninjago - MST - ALT TABY/S10/S10E01';
/// /////////////////////////////////////////////////////////////////
/// /////////////////////NINJAGO - DEKÓDOVÁNÍ////////////////////////
/// /////////////////////////////////////////////////////////////////
import NinjagoDekodovani from './components/Ninjago - Dekodování/NinjagoDekodovani';
/// /// SEZÓNA 1/////
import NDS01E01 from './components/Ninjago - Dekodování/S01/NDS01E01';
import NDS01E02 from './components/Ninjago - Dekodování/S01/NDS01E02';
import NDS01E03 from './components/Ninjago - Dekodování/S01/NDS01E03';
import NDS01E04 from './components/Ninjago - Dekodování/S01/NDS01E04';
import NDS01E05 from './components/Ninjago - Dekodování/S01/NDS01E05';
/// /////////////////////////////////////////////////////////////////
/// ///////////////////////NINJAGO - SERIAL//////////////////////////
/// /////////////////////////////////////////////////////////////////
import Ninjago from './components/Ninjago - Seriál/Ninjago';
/// /// SEZÓNA 11 - TAJEMSTVÍ ZAKÁZANÉHO SPINJITZU/////
import NS01E01 from './components/Ninjago - Seriál/Řada 1/NS01E01';
import NS01E02 from './components/Ninjago - Seriál/Řada 1/NS01E02';
import NS01E03 from './components/Ninjago - Seriál/Řada 1/NS01E03';
import NS01E04 from './components/Ninjago - Seriál/Řada 1/NS01E04';
import NS01E05 from './components/Ninjago - Seriál/Řada 1/NS01E05';
import NS01E06 from './components/Ninjago - Seriál/Řada 1/NS01E06';
import NS01E07 from './components/Ninjago - Seriál/Řada 1/NS01E07';
import NS01E08 from './components/Ninjago - Seriál/Řada 1/NS01E08';
import NS01E09 from './components/Ninjago - Seriál/Řada 1/NS01E09';
import NS01E10 from './components/Ninjago - Seriál/Řada 1/NS01E10';
import NS01E11 from './components/Ninjago - Seriál/Řada 1/NS01E11';
import NS01E12 from './components/Ninjago - Seriál/Řada 1/NS01E12';
import NS01E13 from './components/Ninjago - Seriál/Řada 1/NS01E13';
import NS01E14 from './components/Ninjago - Seriál/Řada 1/NS01E14';
import NS01E15 from './components/Ninjago - Seriál/Řada 1/NS01E15';
import NS01E16 from './components/Ninjago - Seriál/Řada 1/NS01E16';
import NS01E17 from './components/Ninjago - Seriál/Řada 1/NS01E17';
import NS01E18 from './components/Ninjago - Seriál/Řada 1/NS01E18';
import NS01E19 from './components/Ninjago - Seriál/Řada 1/NS01E19';
import NS01E20 from './components/Ninjago - Seriál/Řada 1/NS01E20';
import NS01E21 from './components/Ninjago - Seriál/Řada 1/NS01E21';
import NS01E22 from './components/Ninjago - Seriál/Řada 1/NS01E22';
import NS01E23 from './components/Ninjago - Seriál/Řada 1/NS01E23';
import NS01E24 from './components/Ninjago - Seriál/Řada 1/NS01E24';
import NS01E25 from './components/Ninjago - Seriál/Řada 1/NS01E25';
import NS01E26 from './components/Ninjago - Seriál/Řada 1/NS01E26';
import NS01E27 from './components/Ninjago - Seriál/Řada 1/NS01E27';
import NS01E28 from './components/Ninjago - Seriál/Řada 1/NS01E28';
import NS01E29 from './components/Ninjago - Seriál/Řada 1/NS01E29';
import NS01E30 from './components/Ninjago - Seriál/Řada 1/NS01E30';
/// /// SEZÓNA 12 - SVRCHOVANÁ ŘÍŠE A PÁN HORY/////
import NS02E01 from './components/Ninjago - Seriál/Řada 2/NS02E01';
import NS02E02 from './components/Ninjago - Seriál/Řada 2/NS02E02';
import NS02E03 from './components/Ninjago - Seriál/Řada 2/NS02E03';
import NS02E04 from './components/Ninjago - Seriál/Řada 2/NS02E04';
import NS02E05 from './components/Ninjago - Seriál/Řada 2/NS02E05';
import NS02E06 from './components/Ninjago - Seriál/Řada 2/NS02E06';
import NS02E07 from './components/Ninjago - Seriál/Řada 2/NS02E07';
import NS02E08 from './components/Ninjago - Seriál/Řada 2/NS02E08';
import NS02E09 from './components/Ninjago - Seriál/Řada 2/NS02E09';
import NS02E10 from './components/Ninjago - Seriál/Řada 2/NS02E10';
import NS02E11 from './components/Ninjago - Seriál/Řada 2/NS02E11';
import NS02E12 from './components/Ninjago - Seriál/Řada 2/NS02E12';
import NS02E13 from './components/Ninjago - Seriál/Řada 2/NS02E13';
import NS02E14 from './components/Ninjago - Seriál/Řada 2/NS02E14';
import NS02E15 from './components/Ninjago - Seriál/Řada 2/NS02E15';
import NS02E16 from './components/Ninjago - Seriál/Řada 2/NS02E16';
import NS02E17 from './components/Ninjago - Seriál/Řada 2/NS02E17';
import NS02E18 from './components/Ninjago - Seriál/Řada 2/NS02E18';
import NS02E19 from './components/Ninjago - Seriál/Řada 2/NS02E19';
import NS02E20 from './components/Ninjago - Seriál/Řada 2/NS02E20';
import NS02E21 from './components/Ninjago - Seriál/Řada 2/NS02E21';
import NS02E22 from './components/Ninjago - Seriál/Řada 2/NS02E22';
import NS02E23 from './components/Ninjago - Seriál/Řada 2/NS02E23';
import NS02E24 from './components/Ninjago - Seriál/Řada 2/NS02E24';
import NS02E25 from './components/Ninjago - Seriál/Řada 2/NS02E25';
import NS02E26 from './components/Ninjago - Seriál/Řada 2/NS02E26';
import NS02E27 from './components/Ninjago - Seriál/Řada 2/NS02E27';
import NS02E28 from './components/Ninjago - Seriál/Řada 2/NS02E28';
import NS02E29 from './components/Ninjago - Seriál/Řada 2/NS02E29';
import NS02E30 from './components/Ninjago - Seriál/Řada 2/NS02E30';
import NS02E31 from './components/Ninjago - Seriál/Řada 2/NS02E31';
import NS02E32 from './components/Ninjago - Seriál/Řada 2/NS02E32';
/// /// SEZÓNA 13 - OSTROV A NA MOŘI/////
import NS03E01 from './components/Ninjago - Seriál/Řada 3/NS03E01';
import NS03E02 from './components/Ninjago - Seriál/Řada 3/NS03E02';
import NS03E03 from './components/Ninjago - Seriál/Řada 3/NS03E03';
import NS03E04 from './components/Ninjago - Seriál/Řada 3/NS03E04';
import NS03E05 from './components/Ninjago - Seriál/Řada 3/NS03E05';
import NS03E06 from './components/Ninjago - Seriál/Řada 3/NS03E06';
import NS03E07 from './components/Ninjago - Seriál/Řada 3/NS03E07';
import NS03E08 from './components/Ninjago - Seriál/Řada 3/NS03E08';
import NS03E09 from './components/Ninjago - Seriál/Řada 3/NS03E09';
import NS03E10 from './components/Ninjago - Seriál/Řada 3/NS03E10';
import NS03E11 from './components/Ninjago - Seriál/Řada 3/NS03E11';
import NS03E12 from './components/Ninjago - Seriál/Řada 3/NS03E12';
import NS03E13 from './components/Ninjago - Seriál/Řada 3/NS03E13';
import NS03E14 from './components/Ninjago - Seriál/Řada 3/NS03E14';
import NS03E15 from './components/Ninjago - Seriál/Řada 3/NS03E15';
import NS03E16 from './components/Ninjago - Seriál/Řada 3/NS03E16';
import NS03E17 from './components/Ninjago - Seriál/Řada 3/NS03E17';
import NS03E18 from './components/Ninjago - Seriál/Řada 3/NS03E18';
import NS03E19 from './components/Ninjago - Seriál/Řada 3/NS03E19';
import NS03E20 from './components/Ninjago - Seriál/Řada 3/NS03E20';
/// /// SEZÓNA 14 - KRYSTALIZOVÁNÍ/////
import NS04E01 from './components/Ninjago - Seriál/Řada 4/NS04E01';
import NS04E02 from './components/Ninjago - Seriál/Řada 4/NS04E02';
import NS04E03 from './components/Ninjago - Seriál/Řada 4/NS04E03';
import NS04E04 from './components/Ninjago - Seriál/Řada 4/NS04E04';
import NS04E05 from './components/Ninjago - Seriál/Řada 4/NS04E05';
import NS04E06 from './components/Ninjago - Seriál/Řada 4/NS04E06';
import NS04E07 from './components/Ninjago - Seriál/Řada 4/NS04E07';
import NS04E08 from './components/Ninjago - Seriál/Řada 4/NS04E08';
import NS04E09 from './components/Ninjago - Seriál/Řada 4/NS04E09';
import NS04E10 from './components/Ninjago - Seriál/Řada 4/NS04E10';
import NS04E11 from './components/Ninjago - Seriál/Řada 4/NS04E11';
import NS04E12 from './components/Ninjago - Seriál/Řada 4/NS04E12';
import NS04E13 from './components/Ninjago - Seriál/Řada 4/NS04E13';
import NS04E14 from './components/Ninjago - Seriál/Řada 4/NS04E14';
import NS04E15 from './components/Ninjago - Seriál/Řada 4/NS04E15';
import NS04E16 from './components/Ninjago - Seriál/Řada 4/NS04E16';
import NS04E17 from './components/Ninjago - Seriál/Řada 4/NS04E17';
import NS04E18 from './components/Ninjago - Seriál/Řada 4/NS04E18';
import NS04E19 from './components/Ninjago - Seriál/Řada 4/NS04E19';
import NS04E20 from './components/Ninjago - Seriál/Řada 4/NS04E20';
import NS04E21 from './components/Ninjago - Seriál/Řada 4/NS04E21';
import NS04E22 from './components/Ninjago - Seriál/Řada 4/NS04E22';
import NS04E23 from './components/Ninjago - Seriál/Řada 4/NS04E23';
import NS04E24 from './components/Ninjago - Seriál/Řada 4/NS04E24';
import NS04E25 from './components/Ninjago - Seriál/Řada 4/NS04E25';
import NS04E26 from './components/Ninjago - Seriál/Řada 4/NS04E26';
import NS04E27 from './components/Ninjago - Seriál/Řada 4/NS04E27';
import NS04E28 from './components/Ninjago - Seriál/Řada 4/NS04E28';
import NS04E29 from './components/Ninjago - Seriál/Řada 4/NS04E29';
import NS04E30 from './components/Ninjago - Seriál/Řada 4/NS04E30';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////LEGO LEGENDY CHIMA/////////////////////////
/// /////////////////////////////////////////////////////////////////
import Chima from './components/LEGO Chima/Chima';
/// /// SEZÓNA 1 - SÍLA CHI/////
import CS01E01 from './components/LEGO Chima/S01/CS01E01';
import CS01E02 from './components/LEGO Chima/S01/CS01E02';
import CS01E03 from './components/LEGO Chima/S01/CS01E03';
import CS01E04 from './components/LEGO Chima/S01/CS01E04';
import CS01E05 from './components/LEGO Chima/S01/CS01E05';
import CS01E06 from './components/LEGO Chima/S01/CS01E06';
import CS01E07 from './components/LEGO Chima/S01/CS01E07';
import CS01E08 from './components/LEGO Chima/S01/CS01E08';
import CS01E09 from './components/LEGO Chima/S01/CS01E09';
import CS01E10 from './components/LEGO Chima/S01/CS01E10';
import CS01E11 from './components/LEGO Chima/S01/CS01E11';
import CS01E12 from './components/LEGO Chima/S01/CS01E12';
import CS01E13 from './components/LEGO Chima/S01/CS01E13';
import CS01E14 from './components/LEGO Chima/S01/CS01E14';
import CS01E15 from './components/LEGO Chima/S01/CS01E15';
import CS01E16 from './components/LEGO Chima/S01/CS01E16';
import CS01E17 from './components/LEGO Chima/S01/CS01E17';
import CS01E18 from './components/LEGO Chima/S01/CS01E18';
import CS01E19 from './components/LEGO Chima/S01/CS01E19';
import CS01E20 from './components/LEGO Chima/S01/CS01E20';
/// /// SEZÓNA 2 - PÁTRÁNÍ PO ŠELMÁCH LEGENDY/////
import CS02E01 from './components/LEGO Chima/S02/CS02E01';
import CS02E02 from './components/LEGO Chima/S02/CS02E02';
import CS02E03 from './components/LEGO Chima/S02/CS02E03';
import CS02E04 from './components/LEGO Chima/S02/CS02E04';
import CS02E05 from './components/LEGO Chima/S02/CS02E05';
import CS02E06 from './components/LEGO Chima/S02/CS02E06';
/// /// SEZÓNA 3 - PŘÍBĚH OHNIVÉ CHI/////
import CS03E01 from './components/LEGO Chima/S03/CS03E01';
import CS03E02 from './components/LEGO Chima/S03/CS03E02';
import CS03E03 from './components/LEGO Chima/S03/CS03E03';
import CS03E04 from './components/LEGO Chima/S03/CS03E04';
import CS03E05 from './components/LEGO Chima/S03/CS03E05';
import CS03E06 from './components/LEGO Chima/S03/CS03E06';
import CS03E07 from './components/LEGO Chima/S03/CS03E07';
import CS03E08 from './components/LEGO Chima/S03/CS03E08';
import CS03E09 from './components/LEGO Chima/S03/CS03E09';
import CS03E10 from './components/LEGO Chima/S03/CS03E10';
import CS03E11 from './components/LEGO Chima/S03/CS03E11';
import CS03E12 from './components/LEGO Chima/S03/CS03E12';
import CS03E13 from './components/LEGO Chima/S03/CS03E13';
import CS03E14 from './components/LEGO Chima/S03/CS03E14';
import CS03E15 from './components/LEGO Chima/S03/CS03E15';
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////NEXO KNIGHTS////////////////////////////
/// /////////////////////////////////////////////////////////////////
import NexoKnights from './components/LEGO Nexo Knights/NexoKnights';
/// /// SEZÓNA 1 - KNIHA PŘÍŠER/////
import XS01E01 from './components/LEGO Nexo Knights/S01/XS01E01';
import XS01E02 from './components/LEGO Nexo Knights/S01/XS01E02';
import XS01E03 from './components/LEGO Nexo Knights/S01/XS01E03';
import XS01E04 from './components/LEGO Nexo Knights/S01/XS01E04';
import XS01E05 from './components/LEGO Nexo Knights/S01/XS01E05';
import XS01E06 from './components/LEGO Nexo Knights/S01/XS01E06';
import XS01E07 from './components/LEGO Nexo Knights/S01/XS01E07';
import XS01E08 from './components/LEGO Nexo Knights/S01/XS01E08';
import XS01E09 from './components/LEGO Nexo Knights/S01/XS01E09';
import XS01E10 from './components/LEGO Nexo Knights/S01/XS01E10';
/// /// SEZÓNA 2 - KNIHY MOCI/////
import XS02E01 from './components/LEGO Nexo Knights/S02/XS02E01';
import XS02E02 from './components/LEGO Nexo Knights/S02/XS02E02';
import XS02E03 from './components/LEGO Nexo Knights/S02/XS02E03';
import XS02E04 from './components/LEGO Nexo Knights/S02/XS02E04';
import XS02E05 from './components/LEGO Nexo Knights/S02/XS02E05';
import XS02E06 from './components/LEGO Nexo Knights/S02/XS02E06';
import XS02E07 from './components/LEGO Nexo Knights/S02/XS02E07';
import XS02E08 from './components/LEGO Nexo Knights/S02/XS02E08';
import XS02E09 from './components/LEGO Nexo Knights/S02/XS02E09';
import XS02E10 from './components/LEGO Nexo Knights/S02/XS02E10';
/// /// SEZÓNA 3 - BOUŘE NAD KNIGHTNEM/////
import XS03E01 from './components/LEGO Nexo Knights/S03/XS03E01';
import XS03E02 from './components/LEGO Nexo Knights/S03/XS03E02';
import XS03E03 from './components/LEGO Nexo Knights/S03/XS03E03';
import XS03E04 from './components/LEGO Nexo Knights/S03/XS03E04';
import XS03E05 from './components/LEGO Nexo Knights/S03/XS03E05';
import XS03E06 from './components/LEGO Nexo Knights/S03/XS03E06';
import XS03E07 from './components/LEGO Nexo Knights/S03/XS03E07';
import XS03E08 from './components/LEGO Nexo Knights/S03/XS03E08';
import XS03E09 from './components/LEGO Nexo Knights/S03/XS03E09';
import XS03E10 from './components/LEGO Nexo Knights/S03/XS03E10';
/// /// SEZÓNA 4 - KONCE/////
import XS04E01 from './components/LEGO Nexo Knights/S04/XS04E01';
import XS04E02 from './components/LEGO Nexo Knights/S04/XS04E02';
import XS04E03 from './components/LEGO Nexo Knights/S04/XS04E03';
import XS04E04 from './components/LEGO Nexo Knights/S04/XS04E04';
import XS04E05 from './components/LEGO Nexo Knights/S04/XS04E05';
import XS04E06 from './components/LEGO Nexo Knights/S04/XS04E06';
import XS04E07 from './components/LEGO Nexo Knights/S04/XS04E07';
import XS04E08 from './components/LEGO Nexo Knights/S04/XS04E08';
import XS04E09 from './components/LEGO Nexo Knights/S04/XS04E09';
import XS04E10 from './components/LEGO Nexo Knights/S04/XS04E10';
/// /////////////////////////////////////////////////////////////////
/// /////////////////SW DOBRODRUŽSTVÍ FREEMAKERŮ/////////////////////
/// /////////////////////////////////////////////////////////////////
import DobrodruzstviFreemakeru from './components/LEGO Star Wars/Dobrodružství freemakerů/DobrodruzstviFreemakeru';
/// /// SEZÓNA 1 /////
import DFS01E01 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E01';
import DFS01E02 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E02';
import DFS01E03 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E03';
import DFS01E04 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E04';
import DFS01E05 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E05';
import DFS01E06 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E06';
import DFS01E07 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E07';
import DFS01E08 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E08';
import DFS01E09 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E09';
import DFS01E10 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E10';
import DFS01E11 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E11';
import DFS01E12 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E12';
import DFS01E13 from './components/LEGO Star Wars/Dobrodružství freemakerů/S01/DFS01E13';
/// /// SEZÓNA 2 /////
import DFS02E01 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E01';
import DFS02E02 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E02';
import DFS02E03 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E03';
import DFS02E04 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E04';
import DFS02E05 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E05';
import DFS02E06 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E06';
import DFS02E07 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E07';
import DFS02E08 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E08';
import DFS02E09 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E09';
import DFS02E10 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E10';
import DFS02E11 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E11';
import DFS02E12 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E12';
import DFS02E13 from './components/LEGO Star Wars/Dobrodružství freemakerů/S02/DFS02E13';
/// /// SHORTS /////
/// /////////////////////////////////////////////////////////////////
/// //////////////////////SW YODOVI KRONIKY//////////////////////////
/// /////////////////////////////////////////////////////////////////
import PribehyzYodovikroniky from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/PribehyzYodovikroniky';
/// /// SEZÓNA 1 /////
import YDS01E01 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S01/YDS01E01';
import YDS01E02 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S01/YDS01E02';
import YDS01E03 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S01/YDS01E03';
/// /// SEZÓNA 2 /////
import YDS02E01 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S02/YDS02E01';
import YDS02E02 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S02/YDS02E02';
import YDS02E03 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S02/YDS02E03';
import YDS02E04 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S02/YDS02E04';
import YDS02E05 from './components/LEGO Star Wars/Nové příběhy z Yodovi kroniky/S02/YDS02E05';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////SW PŘÍBĚHY DROIDŮ//////////////////////////
/// /////////////////////////////////////////////////////////////////
import PribehyDroidu from './components/LEGO Star Wars/Příběhy droidů/PribehyDroidu';
/// /// SEZÓNA 1 /////
import PDS01E01 from './components/LEGO Star Wars/Příběhy droidů/S01/PDS01E01';
import PDS01E02 from './components/LEGO Star Wars/Příběhy droidů/S01/PDS01E02';
import PDS01E03 from './components/LEGO Star Wars/Příběhy droidů/S01/PDS01E03';
import PDS01E04 from './components/LEGO Star Wars/Příběhy droidů/S01/PDS01E04';
import PDS01E05 from './components/LEGO Star Wars/Příběhy droidů/S01/PDS01E05';
/// /////////////////////////////////////////////////////////////////
/// /////////////////////SW HISTORKY Z ODBOJE////////////////////////
/// /////////////////////////////////////////////////////////////////
import Historkyzodboje from './components/LEGO Star Wars/Historky z odboje/Historkyzodboje';
/// /// SEZÓNA 1 /////
import SHS01E01 from './components/LEGO Star Wars/Historky z odboje/S01/SHS01E01';
import SHS01E02 from './components/LEGO Star Wars/Historky z odboje/S01/SHS01E02';
import SHS01E03 from './components/LEGO Star Wars/Historky z odboje/S01/SHS01E03';
import SHS01E04 from './components/LEGO Star Wars/Historky z odboje/S01/SHS01E04';
import SHS01E05 from './components/LEGO Star Wars/Historky z odboje/S01/SHS01E05';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////////MONKIE KID/////////////////////////////
/// /////////////////////////////////////////////////////////////////
import MonkieKid from './components/Monkie Kid/MonkieKid';
/// /// SEZÓNA 1 /////
import MKS01E00 from './components/Monkie Kid/S01/MKS01E00';
import MKS01E01 from './components/Monkie Kid/S01/MKS01E01';
import MKS01E02 from './components/Monkie Kid/S01/MKS01E02';
import MKS01E03 from './components/Monkie Kid/S01/MKS01E03';
import MKS01E04 from './components/Monkie Kid/S01/MKS01E04';
import MKS01E05 from './components/Monkie Kid/S01/MKS01E05';
import MKS01E06 from './components/Monkie Kid/S01/MKS01E06';
import MKS01E07 from './components/Monkie Kid/S01/MKS01E07';
import MKS01E08 from './components/Monkie Kid/S01/MKS01E08';
import MKS01E09 from './components/Monkie Kid/S01/MKS01E09';
import MKS01E10 from './components/Monkie Kid/S01/MKS01E10';
/// /// SEZÓNA 2 /////
import MKS02E00 from './components/Monkie Kid/S02/MKS02E00';
import MKS02E01 from './components/Monkie Kid/S02/MKS02E01';
import MKS02E02 from './components/Monkie Kid/S02/MKS02E02';
import MKS02E03 from './components/Monkie Kid/S02/MKS02E03';
import MKS02E04 from './components/Monkie Kid/S02/MKS02E04';
import MKS02E05 from './components/Monkie Kid/S02/MKS02E05';
import MKS02E06 from './components/Monkie Kid/S02/MKS02E06';
import MKS02E07 from './components/Monkie Kid/S02/MKS02E07';
import MKS02E08 from './components/Monkie Kid/S02/MKS02E08';
import MKS02E09 from './components/Monkie Kid/S02/MKS02E09';
import MKS02E10 from './components/Monkie Kid/S02/MKS02E10';
/// /// SEZÓNA 3 /////
import MKS03E01 from './components/Monkie Kid/S03/MKS03E01';
import MKS03E02 from './components/Monkie Kid/S03/MKS03E02';
import MKS03E03 from './components/Monkie Kid/S03/MKS03E03';
import MKS03E04 from './components/Monkie Kid/S03/MKS03E04';
import MKS03E05 from './components/Monkie Kid/S03/MKS03E05';
import MKS03E06 from './components/Monkie Kid/S03/MKS03E06';
import MKS03E07 from './components/Monkie Kid/S03/MKS03E07';
import MKS03E08 from './components/Monkie Kid/S03/MKS03E08';
import MKS03E09 from './components/Monkie Kid/S03/MKS03E09';
import MKS03E10 from './components/Monkie Kid/S03/MKS03E10';
import MKS03E11 from './components/Monkie Kid/S03/MKS03E11';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////////ATLANTIS/////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /// SEZÓNA 1 /////
import Atlantis from './components/LEGO Atlantis/Atlantis';
import ATS01E01 from './components/LEGO Atlantis/Řada 1/ATS01E01';
/// /////////////////////////////////////////////////////////////////
/// /////////////////////CITY DOBRODRUZSTVI////////////////////////
/// /////////////////////////////////////////////////////////////////
import Citydobrodruzstvi from './components/LEGO City dobrodruzstvi/Citydobrodruzstvi';
/// /// SEZÓNA 1 /////
import CDS01E20 from './components/LEGO City dobrodruzstvi/S01/S01E20';
import CDS01E19 from './components/LEGO City dobrodruzstvi/S01/S01E19';
import CDS01E18 from './components/LEGO City dobrodruzstvi/S01/S01E18';
import CDS01E17 from './components/LEGO City dobrodruzstvi/S01/S01E17';
import CDS01E16 from './components/LEGO City dobrodruzstvi/S01/S01E16';
import CDS01E15 from './components/LEGO City dobrodruzstvi/S01/S01E15';
import CDS01E14 from './components/LEGO City dobrodruzstvi/S01/S01E14';
import CDS01E13 from './components/LEGO City dobrodruzstvi/S01/S01E13';
import CDS01E12 from './components/LEGO City dobrodruzstvi/S01/S01E12';
import CDS01E11 from './components/LEGO City dobrodruzstvi/S01/S01E11';
import CDS01E10 from './components/LEGO City dobrodruzstvi/S01/S01E10';
import CDS01E09 from './components/LEGO City dobrodruzstvi/S01/S01E09';
import CDS01E08 from './components/LEGO City dobrodruzstvi/S01/S01E08';
import CDS01E07 from './components/LEGO City dobrodruzstvi/S01/S01E07';
import CDS01E06 from './components/LEGO City dobrodruzstvi/S01/S01E06';
import CDS01E05 from './components/LEGO City dobrodruzstvi/S01/S01E05';
import CDS01E04 from './components/LEGO City dobrodruzstvi/S01/S01E04';
import CDS01E03 from './components/LEGO City dobrodruzstvi/S01/S01E03';
import CDS01E02 from './components/LEGO City dobrodruzstvi/S01/S01E02';
import CDS01E01 from './components/LEGO City dobrodruzstvi/S01/S01E01';
/// /// SEZÓNA 2 /////
import CDS02E20 from './components/LEGO City dobrodruzstvi/S02/S02E20';
import CDS02E19 from './components/LEGO City dobrodruzstvi/S02/S02E19';
import CDS02E18 from './components/LEGO City dobrodruzstvi/S02/S02E18';
import CDS02E17 from './components/LEGO City dobrodruzstvi/S02/S02E17';
import CDS02E16 from './components/LEGO City dobrodruzstvi/S02/S02E16';
import CDS02E15 from './components/LEGO City dobrodruzstvi/S02/S02E15';
import CDS02E14 from './components/LEGO City dobrodruzstvi/S02/S02E14';
import CDS02E13 from './components/LEGO City dobrodruzstvi/S02/S02E13';
import CDS02E12 from './components/LEGO City dobrodruzstvi/S02/S02E12';
import CDS02E11 from './components/LEGO City dobrodruzstvi/S02/S02E11';
import CDS02E10 from './components/LEGO City dobrodruzstvi/S02/S02E10';
import CDS02E09 from './components/LEGO City dobrodruzstvi/S02/S02E09';
import CDS02E08 from './components/LEGO City dobrodruzstvi/S02/S02E08';
import CDS02E07 from './components/LEGO City dobrodruzstvi/S02/S02E07';
import CDS02E06 from './components/LEGO City dobrodruzstvi/S02/S02E06';
import CDS02E05 from './components/LEGO City dobrodruzstvi/S02/S02E05';
import CDS02E04 from './components/LEGO City dobrodruzstvi/S02/S02E04';
import CDS02E03 from './components/LEGO City dobrodruzstvi/S02/S02E03';
import CDS02E02 from './components/LEGO City dobrodruzstvi/S02/S02E02';
import CDS02E01 from './components/LEGO City dobrodruzstvi/S02/S02E01';
/// /// SEZÓNA 3 /////
import CDS03E22 from './components/LEGO City dobrodruzstvi/S03/S03E22';
import CDS03E21 from './components/LEGO City dobrodruzstvi/S03/S03E21';
import CDS03E20 from './components/LEGO City dobrodruzstvi/S03/S03E20';
import CDS03E19 from './components/LEGO City dobrodruzstvi/S03/S03E19';
import CDS03E18 from './components/LEGO City dobrodruzstvi/S03/S03E18';
import CDS03E17 from './components/LEGO City dobrodruzstvi/S03/S03E17';
import CDS03E16 from './components/LEGO City dobrodruzstvi/S03/S03E16';
import CDS03E15 from './components/LEGO City dobrodruzstvi/S03/S03E15';
import CDS03E14 from './components/LEGO City dobrodruzstvi/S03/S03E14';
import CDS03E13 from './components/LEGO City dobrodruzstvi/S03/S03E13';
import CDS03E12 from './components/LEGO City dobrodruzstvi/S03/S03E12';
import CDS03E11 from './components/LEGO City dobrodruzstvi/S03/S03E11';
import CDS03E10 from './components/LEGO City dobrodruzstvi/S03/S03E10';
import CDS03E09 from './components/LEGO City dobrodruzstvi/S03/S03E09';
import CDS03E08 from './components/LEGO City dobrodruzstvi/S03/S03E08';
import CDS03E07 from './components/LEGO City dobrodruzstvi/S03/S03E07';
import CDS03E06 from './components/LEGO City dobrodruzstvi/S03/S03E06';
import CDS03E05 from './components/LEGO City dobrodruzstvi/S03/S03E05';
import CDS03E04 from './components/LEGO City dobrodruzstvi/S03/S03E04';
import CDS03E03 from './components/LEGO City dobrodruzstvi/S03/S03E03';
import CDS03E02 from './components/LEGO City dobrodruzstvi/S03/S03E02';
import CDS03E01 from './components/LEGO City dobrodruzstvi/S03/S03E01';
/// /// SEZÓNA 4 /////
import CDS04E20 from './components/LEGO City dobrodruzstvi/S04/S04E20';
import CDS04E19 from './components/LEGO City dobrodruzstvi/S04/S04E19';
import CDS04E18 from './components/LEGO City dobrodruzstvi/S04/S04E18';
import CDS04E17 from './components/LEGO City dobrodruzstvi/S04/S04E17';
import CDS04E16 from './components/LEGO City dobrodruzstvi/S04/S04E16';
import CDS04E15 from './components/LEGO City dobrodruzstvi/S04/S04E15';
import CDS04E14 from './components/LEGO City dobrodruzstvi/S04/S04E14';
import CDS04E13 from './components/LEGO City dobrodruzstvi/S04/S04E13';
import CDS04E12 from './components/LEGO City dobrodruzstvi/S04/S04E12';
import CDS04E11 from './components/LEGO City dobrodruzstvi/S04/S04E11';
import CDS04E10 from './components/LEGO City dobrodruzstvi/S04/S04E10';
import CDS04E09 from './components/LEGO City dobrodruzstvi/S04/S04E09';
import CDS04E08 from './components/LEGO City dobrodruzstvi/S04/S04E08';
import CDS04E07 from './components/LEGO City dobrodruzstvi/S04/S04E07';
import CDS04E06 from './components/LEGO City dobrodruzstvi/S04/S04E06';
import CDS04E05 from './components/LEGO City dobrodruzstvi/S04/S04E05';
import CDS04E04 from './components/LEGO City dobrodruzstvi/S04/S04E04';
import CDS04E03 from './components/LEGO City dobrodruzstvi/S04/S04E03';
import CDS04E02 from './components/LEGO City dobrodruzstvi/S04/S04E02';
import CDS04E01 from './components/LEGO City dobrodruzstvi/S04/S04E01';
/// /////////////////////////////////////////////////////////////////
/// ///////////////////NINJAGO DRAČÍ POVSTÁNÍ////////////////////////
/// /////////////////////////////////////////////////////////////////
import NinjagoDP from './components/Ninjago - DP/NinjagoDP';
import DPS01E01 from './components/Ninjago - DP/S01/S01E01';
import DPS01E02 from './components/Ninjago - DP/S01/S01E02';
import DPS01E03 from './components/Ninjago - DP/S01/S01E03';
import DPS01E04 from './components/Ninjago - DP/S01/S01E04';
import DPS01E05 from './components/Ninjago - DP/S01/S01E05';
import DPS01E06 from './components/Ninjago - DP/S01/S01E06';
import DPS01E07 from './components/Ninjago - DP/S01/S01E07';
import DPS01E08 from './components/Ninjago - DP/S01/S01E08';
import DPS01E09 from './components/Ninjago - DP/S01/S01E09';
import DPS01E10 from './components/Ninjago - DP/S01/S01E10';
import DPS01E11 from './components/Ninjago - DP/S01/S01E11';
import DPS01E12 from './components/Ninjago - DP/S01/S01E12';
import DPS01E13 from './components/Ninjago - DP/S01/S01E13';
import DPS01E14 from './components/Ninjago - DP/S01/S01E14';
import DPS01E15 from './components/Ninjago - DP/S01/S01E15';
import DPS01E16 from './components/Ninjago - DP/S01/S01E16';
import DPS01E17 from './components/Ninjago - DP/S01/S01E17';
import DPS01E18 from './components/Ninjago - DP/S01/S01E18';
import DPS01E19 from './components/Ninjago - DP/S01/S01E19';
import DPS01E20 from './components/Ninjago - DP/S01/S01E20';
/// /// SEZÓNA 2 /////
import DPS02E01 from './components/Ninjago - DP/S02/S02E01';
import DPS02E02 from './components/Ninjago - DP/S02/S02E02';
import DPS02E03 from './components/Ninjago - DP/S02/S02E03';
import DPS02E04 from './components/Ninjago - DP/S02/S02E04';
import DPS02E05 from './components/Ninjago - DP/S02/S02E05';
import DPS02E06 from './components/Ninjago - DP/S02/S02E06';
import DPS02E07 from './components/Ninjago - DP/S02/S02E07';
import DPS02E08 from './components/Ninjago - DP/S02/S02E08';
import DPS02E09 from './components/Ninjago - DP/S02/S02E09';
import DPS02E10 from './components/Ninjago - DP/S02/S02E10';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////LEDOVÉ KRÁLOVSTVÍ//////////////////////////
/// /////////////////////////////////////////////////////////////////
import LedoveKralovstvi from './components/LEGO Ledové království/LedoveKralovstvi';
/// /// SEZÓNA 1 /////
import LKS01E02 from './components/LEGO Ledové království/S01/LKS01E02';
import LKS01E03 from './components/LEGO Ledové království/S01/LKS01E03';
import LKS01E04 from './components/LEGO Ledové království/S01/LKS01E04';
import LKS01E01 from './components/LEGO Ledové království/S01/LKS01E01';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////////DREAMZZZ///////////////////////////////
/// /////////////////////////////////////////////////////////////////
import Dreamzzz from './components/LEGO Dreamzzz/Dreamzzz';
/// /// SEZÓNA 1 /////
import DZS01E01 from './components/LEGO Dreamzzz/S01/S01E01';
import DZS01E02 from './components/LEGO Dreamzzz/S01/S01E02';
import DZS01E03 from './components/LEGO Dreamzzz/S01/S01E03';
import DZS01E04 from './components/LEGO Dreamzzz/S01/S01E04';
import DZS01E05 from './components/LEGO Dreamzzz/S01/S01E05';
import DZS01E06 from './components/LEGO Dreamzzz/S01/S01E06';
import DZS01E07 from './components/LEGO Dreamzzz/S01/S01E07';
import DZS01E08 from './components/LEGO Dreamzzz/S01/S01E08';
import DZS01E09 from './components/LEGO Dreamzzz/S01/S01E09';
import DZS01E10 from './components/LEGO Dreamzzz/S01/S01E10';
import DZS01E11 from './components/LEGO Dreamzzz/S01/S01E11';
import DZS01E12 from './components/LEGO Dreamzzz/S01/S01E12';
import DZS01E13 from './components/LEGO Dreamzzz/S01/S01E13';
import DZS01E14 from './components/LEGO Dreamzzz/S01/S01E14';
import DZS01E15 from './components/LEGO Dreamzzz/S01/S01E15';
import DZS01E16 from './components/LEGO Dreamzzz/S01/S01E16';
import DZS01E17 from './components/LEGO Dreamzzz/S01/S01E17';
import DZS01E18 from './components/LEGO Dreamzzz/S01/S01E18';
import DZS01E19 from './components/LEGO Dreamzzz/S01/S01E19';
import DZS01E20 from './components/LEGO Dreamzzz/S01/S01E20';
/// /////////////////////////////////////////////////////////////////
/// ////////////////////////HERO FACTORY/////////////////////////////
/// /////////////////////////////////////////////////////////////////
import Herofactory from './components/LEGO Hero factory/Herofactory';
/// /// SEZÓNA 1 /////
import HFS01E01 from './components/LEGO Hero factory/Řada 1/HFS01E01';
/// /// SEZÓNA 2 /////
import HFS02E01 from './components/LEGO Hero factory/Řada 2/HFS02E01';
/// /// SEZÓNA 3 /////
import HFS03E01 from './components/LEGO Hero factory/Řada 3/HFS03E01';
/// /// SEZÓNA 4 /////
import HFS04E01 from './components/LEGO Hero factory/Řada 4/HFS04E01';
import HFS04E02 from './components/LEGO Hero factory/Řada 4/HFS04E02';
/// /// SEZÓNA 5 /////
import HFS05E01 from './components/LEGO Hero factory/Řada 5/HFS05E01';
/// /// SEZÓNA 6 /////
import HFS06E01 from './components/LEGO Hero factory/Řada 6/HFS06E01';
/// /////////////////////////////////////////////////////////////////
/// //////////////////////////BIONICLE///////////////////////////////
/// /////////////////////////////////////////////////////////////////
import Bionicle from './components/LEGO Bionicle/Bionicle';
/// /// SEZÓNA 1 /////
import BIS01E01 from './components/LEGO Bionicle/S01/BIS01E01';
import BIS02E01 from './components/LEGO Bionicle/S02/BIS02E01';
/// /////////////////////////////////////////////////////////////////
/// /////////////////////STAR WARS ALL STARS/////////////////////////
/// /////////////////////////////////////////////////////////////////
import AllStars from './components/LEGO Star Wars/All Stars/AllStars';
/// /// SEZÓNA 1 /////
import ALSS01E01 from './components/LEGO Star Wars/All Stars/S01/S01E01';
import ALSS01E02 from './components/LEGO Star Wars/All Stars/S01/S01E02';
import ALSS01E03 from './components/LEGO Star Wars/All Stars/S01/S01E03';
import ALSS01E04 from './components/LEGO Star Wars/All Stars/S01/S01E04';
import ALSS01E05 from './components/LEGO Star Wars/All Stars/S01/S01E05';
/// /////////////////////////////////////////////////////////////////
/// /////////////////STAR WARS ALL STARS SHORTS//////////////////////
/// /////////////////////////////////////////////////////////////////
import AllStarsshortsshorts from './components/LEGO Star Wars/All Stars Shorts/AllStarsshortsshorts';
/// /// SEZÓNA 1 /////
import ALSSS01E08 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E08';
import ALSSS01E07 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E07';
import ALSSS01E06 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E06';
import ALSSS01E05 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E05';
import ALSSS01E04 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E04';
import ALSSS01E03 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E03';
import ALSSS01E02 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E02';
import ALSSS01E01 from './components/LEGO Star Wars/All Stars Shorts/S01/S01E01';
/// /////////////////////////////////////////////////////////////////
/// //////////STAR WARS DOBRODRUŽSTVÍ FREEMAKERŮ SHORTS//////////////
/// /////////////////////////////////////////////////////////////////
import Dobrodruzstvifreemakerushorts from './components/LEGO Star Wars/Dobrodružství freemakerů Shorts/Dobrodruzstvifreemakerushorts';
import DFSS01E01 from './components/LEGO Star Wars/Dobrodružství freemakerů Shorts/S01/S01E01';
import DFSS01E02 from './components/LEGO Star Wars/Dobrodružství freemakerů Shorts/S01/S01E02';
import DFSS01E03 from './components/LEGO Star Wars/Dobrodružství freemakerů Shorts/S01/S01E03';
import DFSS01E04 from './components/LEGO Star Wars/Dobrodružství freemakerů Shorts/S01/S01E04';
import DFSS01E05 from './components/LEGO Star Wars/Dobrodružství freemakerů Shorts/S01/S01E05';
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////////FILMY///////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////
/// /////////////////////////FILMY - VIDEA///////////////////////////
import NINJAGOFILMCZECH from './components/Ninjago - MST - ALT TABY/Ninjago Filmy/NINJAGOFILMCZECH';
import STARWARSSVATECNISPECIALFILMCZ from './components/Filmy/Filmy - Videa/STARWARSSVATECNISPECIALFILMCZ';
import STARWARSLETNIPRAZDNINYFILMCZ from './components/Filmy/Filmy - Videa/STARWARSLETNIPRAZDNINYFILMCZ';
import STARWARSHRUZOSTRASNEHISTORKYFILMCZ from './components/Filmy/Filmy - Videa/STARWARSHRUZOSTRASNEHISTORKYFILMCZ';
import CLUTCHPOWERSFILM from './components/Filmy/Filmy - Videa/CLUTCHPOWERSFILM';
import BATMANFILM from './components/Filmy/Filmy - Videa/BATMANFILM';
import BATMAN2013FILM from './components/Filmy/Filmy - Videa/BATMAN2013FILM';
import DENZESNULYCHFILM from './components/Filmy/Filmy - Videa/DENZESNULYCHFILM';
import LEGOPRIBEH1FILM from './components/Filmy/Filmy - Videa/LEGOPRIBEH1FILM';
import LEGOPRIBEH2FILM from './components/Filmy/Filmy - Videa/LEGOPRIBEH2FILM';
import LEGODCSUPERHRDINOVEFLASHFILM from './components/Filmy/Filmy - Videa/LEGODCSUPERHRDINOVEFLASHFILM';
import LEGODCSUPERHRDINOVEAQUAMANFILM from './components/Filmy/Filmy - Videa/LEGODCSUPERHRDINOVEAQUAMANFILM';
import STARWARSPADAWANSKAHROZBAFILM from './components/Filmy/Filmy - Videa/STARWARSPADAWANSKAHROZBAFILM';
import PRINCEZNYDOBRODRUZSTVINAZAMKU from './components/Filmy/Filmy - Videa/PRINCEZNYDOBRODRUZSTVINAZAMKU';
import SSTARWARSIMPERIUMUTOCIFILM from './components/Filmy/Filmy - Videa/STARWARSIMPERIUMUTOCIFILM';
import BioniclezrozenilegendyFILM from './components/LEGO Bionicle/Filmy/Videa/BioniclezrozenilegendyFILM';
import BioniclemaskasvetlaFILM from './components/LEGO Bionicle/Filmy/Videa/BioniclemaskasvetlaFILM';
import BioniclepavucinastinuFILM from './components/LEGO Bionicle/Filmy/Videa/BioniclepavucinastinuFILM';
import BioniclelegendametrunuiFILM from './components/LEGO Bionicle/Filmy/Videa/BioniclelegendametrunuiFILM';
import LIGASPRAVEDLIVYCHVESMIRNYSOUBOJFILM from './components/Filmy/Filmy - Videa/LIGASPRAVEDLIVYCHVESMIRNYSOUBOJFILM';
import SUPERHRDINOVEBATMANDOLIGYFILM from './components/Filmy/Filmy - Videa/SUPERHRDINOVEBATMANDOLIGYFILM';
import LIGASPRAVEDLIVYCHUTEKZGOTHAMU from './components/Filmy/Filmy - Videa/LIGASPRAVEDLIVYCHUTEKZGOTHAMU';
import LIGASPRAVEDLIVYCHVSBIZARROFILM from './components/Filmy/Filmy - Videa/LIGASPRAVEDLIVYCHVSBIZARRO';
import LIGASPRAVEDLIVYCHLEGIEZKAZYFILM from './components/Filmy/Filmy - Videa/LIGASPRAVEDLIVYCHLEGIEZKAZYFILM';
/// //////////////////////////FILMY - STRÁNKY//////////////////////////
import Legoninjagofilm from './components/Filmy/Legoninjagofilm';
import Ninjagodenzesnulychfilm from './components/Ninjago - MST - ALT TABY/Ninjago Filmy/Ninjagodenzesnulychfilm';
import Legostarwarsletniprazdniny from './components/Filmy/Legostarwarsletniprazdniny';
import Legostarwarshruzostrasnehistorky from './components/Filmy/Legostarwarshruzostrasnehistorky';
import Legostarwarssvatecnispecial from './components/Filmy/Legostarwarssvatecnispecial';
import Legobatmanfilm from './components/Filmy/Legobatmanfilm';
import Legodcsuperhrdinoveflash from './components/Filmy/Legodcsuperhrdinoveflash';
import Legodcsuperhrdinoveaquaman from './components/Filmy/Legodcsuperhrdinoveaquaman';
import Legodcligaspravedlivychbatmandoligy from './components/Filmy/Legodcligaspravedlivychbatmandoligy';
import Legodcligaspravedlivychvslegiezkazy from './components/Filmy/Legodcligaspravedlivychvslegiezkazy';
import Legoclutchpowerszasahuje from './components/Filmy/Legoclutchpowerszasahuje';
import Legopribeh1 from './components/Filmy/Legopribeh1';
import Legopribeh2 from './components/Filmy/Legopribeh2';
import StarWarsPadawanskaHrozba from './components/Filmy/StarWarsPadawanskaHrozba';
import StarWarsImperiumUtoci from './components/Filmy/StarWarsImperiumUtoci';
import Princeznydobrodruzstvinazamku from './components/Filmy/Princeznydobrodruzstvinazamku';
import Bioniclemaskasvetla from './components/LEGO Bionicle/Filmy/Bioniclemaskasvetla';
import Bioniclelegendametrunui from './components/LEGO Bionicle/Filmy/Bioniclelegendametrunui';
import Bioniclepavucinastinu from './components/LEGO Bionicle/Filmy/Bioniclepavucinastinu';
import Bioniclezrozenilegendy from './components/LEGO Bionicle/Filmy/Bioniclezrozenilegendy';
import Legodcbatman2013 from './components/Filmy/Legodcbatman2013';
import Legodcligaspravedlivychvsbizarro from './components/Filmy/Legodcligaspravedlivychvsbizarro';
import Legodcligaspravedlivychvesmirnysouboj from './components/Filmy/Legodcligaspravedlivychvesmirnysouboj';
import Legodcligaspravedlivyutekzgothamu from './components/Filmy/Legodcligaspravedlivyutekzgothamu';
/// //////////////////////////SVĚTY///////////////////////////
import SvetNinjago from './components/Serialy/NinjagoObsah/SvetNinjago';
import SvetStarwars from './components/Serialy/StarWarsObsah/SvetStarwars';
import SvetDC from './components/Serialy/DCObsah/SvetDC';

/// //////////////////////////HEADER//////////////////////////
import Header from './components/Header';
import HeaderEP from './components/HeaderEP';
import Home from './components/Home';
import Serialy from './components/Serialy/Serialy';
import Filmy from './components/Filmy/Filmy';
import OdNce from './components/OdNCE/OdNce-Plus';
/// //////////////////////////NOT FOUND//////////////////////////
import NotFound from './components/NotFound';
/// //////////////////////////BOTTOM//////////////////////////
import Bottom from './components/Bottom';
/// //////////////////////////AKTUALIZACE//////////////////////////
import Aktualizace from './components/Aktualizace';
import Aktualizacejednanula from './components/Aktulizace-vsechny/1.0';
/// //////////////////////////REACT//////////////////////////
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Legoninjagosnovytym from './components/Filmy/Legoninjagosnovytym';
import PageLoader from './PageLoader';
import NINJAGOSNOVYTYM from './components/Filmy/Filmy - Videa/NINJAGOSNOVYTYM';
import UserProfile from './UserProfile';
import DPS02E11 from './components/Ninjago - DP/S02/S02E11';
import DPS02E14 from './components/Ninjago - DP/S02/S02E14';
import DPS02E13 from './components/Ninjago - DP/S02/S02E13';
import DPS02E12 from './components/Ninjago - DP/S02/S02E12';
import DPS02E15 from './components/Ninjago - DP/S02/S02E15';
import DPS02E16 from './components/Ninjago - DP/S02/S02E16';
import DPS02E17 from './components/Ninjago - DP/S02/S02E17';
import DPS02E18 from './components/Ninjago - DP/S02/S02E18';
import PatreonList from './PatreonList';
import DPS02E20 from './components/Ninjago - DP/S02/S02E20';
import DPS02E19 from './components/Ninjago - DP/S02/S02E19';
import VIPobsah from './components/VIP/VIPobsah';
import Wyldfyreshorts from './components/Ninjago - Wyldfyre shorts/Wyldfyreshorts';
import WFS01E01 from './components/Ninjago - Wyldfyre shorts/S01/S01E01';
import WFS01E04 from './components/Ninjago - Wyldfyre shorts/S01/S01E04';
import WFS01E03 from './components/Ninjago - Wyldfyre shorts/S01/S01E03';
import WFS01E02 from './components/Ninjago - Wyldfyre shorts/S01/S01E02';
import VideoPlayer from './Videoplayer';
/// NEZAŘAZENO / PROZATÍM NEZAŘAZENO///

function App() {
  function RedirectWithStatus({ to, status }) {
    return (
      <Route render={({ staticContext }) => {
        if (staticContext) staticContext.status = status;
        return <Redirect to={to} />;
      }}
      />
    );
  }
  
  const PageLoader = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('URL changed:', location.pathname);
        const loadData = async () => {
            setLoading(true);

            // Simulace načítání dat
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sekunda

            setLoading(false);
        };

        loadData();
    }, [location.pathname]); // Sleduje změny v URL

    console.log('Loading:', loading);

    if (loading) {
        return <Loader />;
    }

    return null;
};

  return (
    <div className="App">
      <AuthProvider>
      <Router>
      <PageLoader />
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
            <Bottom />
          </Route>
          <Route path="/file-info" component={FileInfo} />
          <Route exact path="/video-player">
            <VideoPlayer />
          </Route>
          <Route path="/profil">
           <UserProfile />
           </Route>
          <Route path="/serialy">
            <Serialy />
            <Bottom />
          </Route>
          <Route path="/filmy">
            <Filmy />
            <Bottom />
          </Route>
          <Route path="/vip-obsah">
            <VIPobsah />
          </Route>
          <Route path="/od-nce+">
            <OdNce />
            <Bottom />
          </Route>
          <Route path="/aktualizace">
            <Aktualizace />
            <Bottom />
          </Route>
          <Route path="/aktualizace-1.0">
            <Aktualizacejednanula />
            <Bottom />
          </Route>
          <Route path="/patreon">
            <PatreonList />
            <Bottom />
          </Route>
          <Route path="/svet-ninjago">
            <SvetNinjago />
            <Bottom />
          </Route>
          <Route path="/svet-star-wars">
            <SvetStarwars />
            <Bottom />
          </Route>
          <Route path="/svet-dc">
            <SvetDC />
            <Bottom />
          </Route>
          <Route path="/lego-ninjago-film">
            <Legoninjagofilm />
          </Route>
          <Route path="/lego-ninjago-film-film">
            <HeaderEP />
            <NINJAGOFILMCZECH />
          </Route>
          <Route path="/ninjago-snovy-tym">
            <Legoninjagosnovytym />
          </Route>
          <Route path="/ninjago-snovy-tym-film">
            <HeaderEP />
            <NINJAGOSNOVYTYM />
          </Route>
          <Route path="/ninjago-den-zesnulych">
            <Ninjagodenzesnulychfilm />
          </Route>
          <Route path="/ninjago-den-zesnulych-film">
            <HeaderEP />
            <DENZESNULYCHFILM />
          </Route>
          <Route path="/lego-star-wars-letni-prazdniny">
            <Legostarwarsletniprazdniny />
          </Route>
          <Route path="/lego-star-wars-letni-prazdniny-film">
            <HeaderEP />
            <STARWARSLETNIPRAZDNINYFILMCZ />
          </Route>
          <Route path="/lego-star-wars-hruzostrasne-historky">
            <Legostarwarshruzostrasnehistorky />
          </Route>
          <Route path="/lego-star-wars-hruzostrasne-historky-film">
            <HeaderEP />
            <STARWARSHRUZOSTRASNEHISTORKYFILMCZ />
          </Route>
          <Route path="/lego-star-wars-svatecni-special">
            <Legostarwarssvatecnispecial />
          </Route>
          <Route path="/lego-star-wars-svatecni-special-film">
            <HeaderEP />
            <STARWARSSVATECNISPECIALFILMCZ />
          </Route>
          <Route path="/lego-princezny-dobrodruzstvi-na-zamku">
            <Princeznydobrodruzstvinazamku />
          </Route>
          <Route path="/lego-princezny-dobrodruzstvi-na-zamku-film">
            <HeaderEP />
            <PRINCEZNYDOBRODRUZSTVINAZAMKU />
          </Route>
          <Route path="/lego-star-wars-padawanska-hrozba">
            <StarWarsPadawanskaHrozba />
          </Route>
          <Route path="/lego-star-wars-padawanska-hrozba-film">
            <HeaderEP />
            <STARWARSPADAWANSKAHROZBAFILM />
          </Route>
          <Route path="/lego-star-wars-imperium-utoci">
            <StarWarsImperiumUtoci />
          </Route>
          <Route path="/lego-star-wars-imperium-utoci-film">
            <HeaderEP />
            <SSTARWARSIMPERIUMUTOCIFILM />
          </Route>
          <Route path="/lego-batman-film">
            <HeaderEP />
            <Legobatmanfilm />
          </Route>
          <Route path="/lego-batman-film-film">
            <HeaderEP />
            <BATMANFILM />
          </Route>
          <Route path="/lego-dc-super-hrdinove-se-spojili">
            <Legodcbatman2013 />
          </Route>
          <Route path="/lego-dc-super-hrdinove-se-spojili-film">
            <HeaderEP />
            <BATMAN2013FILM />
          </Route>
          <Route path="/lego-dc-super-hrdinove-batman-do-ligy">
            <Legodcligaspravedlivychbatmandoligy />
          </Route>
          <Route path="/lego-dc-super-hrdinove-batman-do-ligy-film">
            <HeaderEP />
            <SUPERHRDINOVEBATMANDOLIGYFILM />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-vs-bizarro">
            <Legodcligaspravedlivychvsbizarro />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-vs-bizarro-film">
            <HeaderEP />
            <LIGASPRAVEDLIVYCHVSBIZARROFILM />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-vs-legie-zkazy">
            <Legodcligaspravedlivychvslegiezkazy />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-vesmirny-souboj">
            <Legodcligaspravedlivychvesmirnysouboj />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-vesmirny-souboj-film">
            <HeaderEP />
            <LIGASPRAVEDLIVYCHVESMIRNYSOUBOJFILM />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-vs-legie-zkazy-film">
            <HeaderEP />
            <LIGASPRAVEDLIVYCHLEGIEZKAZYFILM />
          </Route>
          <Route path="/lego-dc-super-hrdinove-flash">
            <Legodcsuperhrdinoveflash />
          </Route>
          <Route path="/lego-dc-super-hrdinove-flash-film">
            <HeaderEP />
            <LEGODCSUPERHRDINOVEFLASHFILM />
          </Route>
          <Route path="/lego-dc-super-hrdinove-aquaman">
            <Legodcsuperhrdinoveaquaman />
          </Route>
          <Route path="/lego-dc-super-hrdinove-aquaman-film">
            <HeaderEP />
            <LEGODCSUPERHRDINOVEAQUAMANFILM />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-utek-z-gothamu">
            <Legodcligaspravedlivyutekzgothamu />
          </Route>
          <Route path="/lego-dc-liga-spravedlivych-utek-z-gothamu-film">
            <HeaderEP />
            <LIGASPRAVEDLIVYCHUTEKZGOTHAMU />
          </Route>
          <Route path="/lego-pribeh-1">
            <Legopribeh1 />
          </Route>
          <Route path="/lego-pribeh-1-film">
            <HeaderEP />
            <LEGOPRIBEH1FILM />
          </Route>
          <Route path="/lego-pribeh-2">
            <Legopribeh2 />
          </Route>
          <Route path="/lego-pribeh-2-film">
            <HeaderEP />
            <LEGOPRIBEH2FILM />
          </Route>
          <Route path="/lego-clutch-powers-zasahuje">
            <Legoclutchpowerszasahuje />
          </Route>
          <Route path="/lego-clutch-powers-zasahuje-film">
            <HeaderEP />
            <CLUTCHPOWERSFILM />
          </Route>
          <Route path="/monkie-kid">
            <MonkieKid />
            <Bottom />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-0">
            <HeaderEP />
            <MKS01E00 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-1">
            <HeaderEP />
            <MKS01E01 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-2">
            <HeaderEP />
            <MKS01E02 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-3">
            <HeaderEP />
            <MKS01E03 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-4">
            <HeaderEP />
            <MKS01E04 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-5">
            <HeaderEP />
            <MKS01E05 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-6">
            <HeaderEP />
            <MKS01E06 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-7">
            <HeaderEP />
            <MKS01E07 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-8">
            <HeaderEP />
            <MKS01E08 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-9">
            <HeaderEP />
            <MKS01E09 />
          </Route>
          <Route path="/monkie-kid-sezona-1-epizoda-10">
            <HeaderEP />
            <MKS01E10 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-0">
            <HeaderEP />
            <MKS02E00 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-1">
            <HeaderEP />
            <MKS02E01 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-2">
            <HeaderEP />
            <MKS02E02 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-3">
            <HeaderEP />
            <MKS02E03 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-4">
            <HeaderEP />
            <MKS02E04 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-5">
            <HeaderEP />
            <MKS02E05 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-6">
            <HeaderEP />
            <MKS02E06 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-7">
            <HeaderEP />
            <MKS02E07 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-8">
            <HeaderEP />
            <MKS02E08 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-9">
            <HeaderEP />
            <MKS02E09 />
          </Route>
          <Route path="/monkie-kid-sezona-2-epizoda-10">
            <HeaderEP />
            <MKS02E10 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-1">
            <HeaderEP />
            <MKS03E01 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-2">
            <HeaderEP />
            <MKS03E02 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-3">
            <HeaderEP />
            <MKS03E03 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-4">
            <HeaderEP />
            <MKS03E04 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-5">
            <HeaderEP />
            <MKS03E05 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-6">
            <HeaderEP />
            <MKS03E06 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-7">
            <HeaderEP />
            <MKS03E07 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-8">
            <HeaderEP />
            <MKS03E08 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-9">
            <HeaderEP />
            <MKS03E09 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-10">
            <HeaderEP />
            <MKS03E10 />
          </Route>
          <Route path="/monkie-kid-sezona-3-epizoda-11">
            <HeaderEP />
            <MKS03E11 />
          </Route>
          <Route path="/atlantis">
            <Atlantis />
            <Bottom />
          </Route>
          <Route path="/atlantis-sezona-1-epizoda-1">
            <HeaderEP />
            <ATS01E01 />
          </Route>
          <Route path="/ninjago-dekodovani">
            <NinjagoDekodovani />
            <Bottom />
          </Route>
          <Route path="/ninjago-dekodovani-sezona-1-epizoda-1">
            <HeaderEP />
            <NDS01E01 />
          </Route>
          <Route path="/ninjago-dekodovani-sezona-1-epizoda-2">
            <HeaderEP />
            <NDS01E02 />
          </Route>
          <Route path="/ninjago-dekodovani-sezona-1-epizoda-3">
            <HeaderEP />
            <NDS01E03 />
          </Route>
          <Route path="/ninjago-dekodovani-sezona-1-epizoda-4">
            <HeaderEP />
            <NDS01E04 />
          </Route>
          <Route path="/ninjago-dekodovani-sezona-1-epizoda-5">
            <HeaderEP />
            <NDS01E05 />
          </Route>
          <Route path="/hero-factory">
            <Herofactory />
            <Bottom />
          </Route>
          <Route path="/hero-factory-sezona-1-epizoda-1">
            <HeaderEP />
            <HFS01E01 />
          </Route>
          <Route path="/hero-factory-sezona-2-epizoda-1">
            <HeaderEP />
            <HFS02E01 />
          </Route>
          <Route path="/hero-factory-sezona-3-epizoda-1">
            <HeaderEP />
            <HFS03E01 />
          </Route>
          <Route path="/hero-factory-sezona-4-epizoda-1">
            <HeaderEP />
            <HFS04E01 />
          </Route>
          <Route path="/hero-factory-sezona-4-epizoda-2">
            <HeaderEP />
            <HFS04E02 />
          </Route>
          <Route path="/hero-factory-sezona-5-epizoda-1">
            <HeaderEP />
            <HFS05E01 />
          </Route>
          <Route path="/hero-factory-sezona-6-epizoda-1">
            <HeaderEP />
            <HFS06E01 />
          </Route>
          <Route path="/bionicle">
            <Bionicle />
            <Bottom />
          </Route>
          <Route path="/bionicle-sezona-1-epizoda-1">
            <HeaderEP />
            <BIS01E01 />
          </Route>
          <Route path="/bionicle-sezona-2-epizoda-1">
            <HeaderEP />
            <BIS02E01 />
          </Route>
          <Route path="/bionicle-maska-svetla">
            <Bioniclemaskasvetla />
          </Route>
          <Route path="/bionicle-maska-svetla-film">
            <HeaderEP />
            <BioniclemaskasvetlaFILM />
          </Route>
          <Route path="/bionicle-legenda-metru-nui">
            <Bioniclelegendametrunui />
          </Route>
          <Route path="/bionicle-legenda-metru-nui-film">
            <HeaderEP />
            <BioniclelegendametrunuiFILM />
          </Route>
          <Route path="/bionicle-pavucina-stinu">
            <Bioniclepavucinastinu />
          </Route>
          <Route path="/bionicle-pavucina-stinu-film">
            <HeaderEP />
            <BioniclepavucinastinuFILM />
          </Route>
          <Route path="/bionicle-zrozeni-legendy">
            <Bioniclezrozenilegendy />
          </Route>
          <Route path="/bionicle-zrozeni-legendy-film">
            <HeaderEP />
            <BioniclezrozenilegendyFILM />
          </Route>
          <Route path="/legendy-chima">
            <Chima />
            <Bottom />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-1">
            <HeaderEP />
            <CS01E01 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-2">
            <HeaderEP />
            <CS01E02 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-3">
            <HeaderEP />
            <CS01E03 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-4">
            <HeaderEP />
            <CS01E04 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-5">
            <HeaderEP />
            <CS01E05 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-6">
            <HeaderEP />
            <CS01E06 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-7">
            <HeaderEP />
            <CS01E07 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-8">
            <HeaderEP />
            <CS01E08 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-9">
            <HeaderEP />
            <CS01E09 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-10">
            <HeaderEP />
            <CS01E10 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-11">
            <HeaderEP />
            <CS01E11 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-12">
            <HeaderEP />
            <CS01E12 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-13">
            <HeaderEP />
            <CS01E13 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-14">
            <HeaderEP />
            <CS01E14 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-15">
            <HeaderEP />
            <CS01E15 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-16">
            <HeaderEP />
            <CS01E16 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-17">
            <HeaderEP />
            <CS01E17 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-18">
            <HeaderEP />
            <CS01E18 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-19">
            <HeaderEP />
            <CS01E19 />
          </Route>
          <Route path="/legendy-chima-sezona-1-epizoda-20">
            <HeaderEP />
            <CS01E20 />
          </Route>
          <Route path="/legendy-chima-sezona-2-epizoda-1">
            <HeaderEP />
            <CS02E01 />
          </Route>
          <Route path="/legendy-chima-sezona-2-epizoda-2">
            <HeaderEP />
            <CS02E02 />
          </Route>
          <Route path="/legendy-chima-sezona-2-epizoda-3">
            <HeaderEP />
            <CS02E03 />
          </Route>
          <Route path="/legendy-chima-sezona-2-epizoda-4">
            <HeaderEP />
            <CS02E04 />
          </Route>
          <Route path="/legendy-chima-sezona-2-epizoda-5">
            <HeaderEP />
            <CS02E05 />
          </Route>
          <Route path="/legendy-chima-sezona-2-epizoda-6">
            <HeaderEP />
            <CS02E06 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-1">
            <HeaderEP />
            <CS03E01 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-2">
            <HeaderEP />
            <CS03E02 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-3">
            <HeaderEP />
            <CS03E03 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-4">
            <HeaderEP />
            <CS03E04 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-5">
            <HeaderEP />
            <CS03E05 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-6">
            <HeaderEP />
            <CS03E06 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-7">
            <HeaderEP />
            <CS03E07 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-8">
            <HeaderEP />
            <CS03E08 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-9">
            <HeaderEP />
            <CS03E09 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-10">
            <HeaderEP />
            <CS03E10 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-11">
            <HeaderEP />
            <CS03E11 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-12">
            <HeaderEP />
            <CS03E12 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-13">
            <HeaderEP />
            <CS03E13 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-14">
            <HeaderEP />
            <CS03E14 />
          </Route>
          <Route path="/legendy-chima-sezona-3-epizoda-15">
            <HeaderEP />
            <CS03E15 />
          </Route>
          <Route path="/nexo-knights">
            <NexoKnights />
            <Bottom />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-1">
            <HeaderEP />
            <XS01E01 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-2">
            <HeaderEP />
            <XS01E02 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-3">
            <HeaderEP />
            <XS01E03 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-4">
            <HeaderEP />
            <XS01E04 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-5">
            <HeaderEP />
            <XS01E05 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-6">
            <HeaderEP />
            <XS01E06 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-7">
            <HeaderEP />
            <XS01E07 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-8">
            <HeaderEP />
            <XS01E08 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-9">
            <HeaderEP />
            <XS01E09 />
          </Route>
          <Route path="/nexo-knights-sezona-1-epizoda-10">
            <HeaderEP />
            <XS01E10 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-1">
            <HeaderEP />
            <XS02E01 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-2">
            <HeaderEP />
            <XS02E02 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-3">
            <HeaderEP />
            <XS02E03 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-4">
            <HeaderEP />
            <XS02E04 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-5">
            <HeaderEP />
            <XS02E05 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-6">
            <HeaderEP />
            <XS02E06 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-7">
            <HeaderEP />
            <XS02E07 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-8">
            <HeaderEP />
            <XS02E08 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-9">
            <HeaderEP />
            <XS02E09 />
          </Route>
          <Route path="/nexo-knights-sezona-2-epizoda-10">
            <HeaderEP />
            <XS02E10 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-1">
            <HeaderEP />
            <XS03E01 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-2">
            <HeaderEP />
            <XS03E02 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-3">
            <HeaderEP />
            <XS03E03 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-4">
            <HeaderEP />
            <XS03E04 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-5">
            <HeaderEP />
            <XS03E05 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-6">
            <HeaderEP />
            <XS03E06 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-7">
            <HeaderEP />
            <XS03E07 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-8">
            <HeaderEP />
            <XS03E08 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-9">
            <HeaderEP />
            <XS03E09 />
          </Route>
          <Route path="/nexo-knights-sezona-3-epizoda-10">
            <HeaderEP />
            <XS03E10 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-1">
            <HeaderEP />
            <XS04E01 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-2">
            <HeaderEP />
            <XS04E02 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-3">
            <HeaderEP />
            <XS04E03 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-4">
            <HeaderEP />
            <XS04E04 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-5">
            <HeaderEP />
            <XS04E05 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-6">
            <HeaderEP />
            <XS04E06 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-7">
            <HeaderEP />
            <XS04E07 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-8">
            <HeaderEP />
            <XS04E08 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-9">
            <HeaderEP />
            <XS04E09 />
          </Route>
          <Route path="/nexo-knights-sezona-4-epizoda-10">
            <HeaderEP />
            <XS04E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu">
            <Ninjagomistrispinjitzu />
            <Bottom />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-0-epizoda-1">
            <HeaderEP />
            <S00E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-0-epizoda-2">
            <HeaderEP />
            <S00E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-1">
            <HeaderEP />
            <S01E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-2">
            <HeaderEP />
            <S01E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-3">
            <HeaderEP />
            <S01E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-4">
            <HeaderEP />
            <S01E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-5">
            <HeaderEP />
            <S01E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-6">
            <HeaderEP />
            <S01E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-7">
            <HeaderEP />
            <S01E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-8">
            <HeaderEP />
            <S01E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-9">
            <HeaderEP />
            <S01E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-10">
            <HeaderEP />
            <S01E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-11">
            <HeaderEP />
            <S01E11 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-12">
            <HeaderEP />
            <S01E12 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-1-epizoda-13">
            <HeaderEP />
            <S01E13 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-1">
            <HeaderEP />
            <S02E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-2">
            <HeaderEP />
            <S02E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-3">
            <HeaderEP />
            <S02E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-4">
            <HeaderEP />
            <S02E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-5">
            <HeaderEP />
            <S02E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-6">
            <HeaderEP />
            <S02E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-7">
            <HeaderEP />
            <S02E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-8">
            <HeaderEP />
            <S02E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-9">
            <HeaderEP />
            <S02E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-10">
            <HeaderEP />
            <S02E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-11">
            <HeaderEP />
            <S02E11 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-12">
            <HeaderEP />
            <S02E12 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-2-epizoda-13">
            <HeaderEP />
            <S02E13 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-1">
            <HeaderEP />
            <S03E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-2">
            <HeaderEP />
            <S03E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-3">
            <HeaderEP />
            <S03E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-4">
            <HeaderEP />
            <S03E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-5">
            <HeaderEP />
            <S03E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-6">
            <HeaderEP />
            <S03E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-7">
            <HeaderEP />
            <S03E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-3-epizoda-8">
            <HeaderEP />
            <S03E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-1">
            <HeaderEP />
            <S04E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-2">
            <HeaderEP />
            <S04E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-3">
            <HeaderEP />
            <S04E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-4">
            <HeaderEP />
            <S04E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-5">
            <HeaderEP />
            <S04E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-6">
            <HeaderEP />
            <S04E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-7">
            <HeaderEP />
            <S04E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-8">
            <HeaderEP />
            <S04E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-9">
            <HeaderEP />
            <S04E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-4-epizoda-10">
            <HeaderEP />
            <S04E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-1">
            <HeaderEP />
            <S05E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-2">
            <HeaderEP />
            <S05E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-3">
            <HeaderEP />
            <S05E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-4">
            <HeaderEP />
            <S05E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-5">
            <HeaderEP />
            <S05E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-6">
            <HeaderEP />
            <S05E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-7">
            <HeaderEP />
            <S05E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-8">
            <HeaderEP />
            <S05E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-9">
            <HeaderEP />
            <S05E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-5-epizoda-10">
            <HeaderEP />
            <S05E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-1">
            <HeaderEP />
            <S06E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-2">
            <HeaderEP />
            <S06E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-3">
            <HeaderEP />
            <S06E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-4">
            <HeaderEP />
            <S06E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-5">
            <HeaderEP />
            <S06E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-6">
            <HeaderEP />
            <S06E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-7">
            <HeaderEP />
            <S06E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-8">
            <HeaderEP />
            <S06E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-9">
            <HeaderEP />
            <S06E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-6-epizoda-10">
            <HeaderEP />
            <S06E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-1">
            <HeaderEP />
            <S07E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-2">
            <HeaderEP />
            <S07E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-3">
            <HeaderEP />
            <S07E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-4">
            <HeaderEP />
            <S07E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-5">
            <HeaderEP />
            <S07E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-6">
            <HeaderEP />
            <S07E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-7">
            <HeaderEP />
            <S07E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-8">
            <HeaderEP />
            <S07E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-9">
            <HeaderEP />
            <S07E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-7-epizoda-10">
            <HeaderEP />
            <S07E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-1">
            <HeaderEP />
            <S08E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-2">
            <HeaderEP />
            <S08E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-3">
            <HeaderEP />
            <S08E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-4">
            <HeaderEP />
            <S08E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-5">
            <HeaderEP />
            <S08E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-6">
            <HeaderEP />
            <S08E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-7">
            <HeaderEP />
            <S08E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-8">
            <HeaderEP />
            <S08E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-9">
            <HeaderEP />
            <S08E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-8-epizoda-10">
            <HeaderEP />
            <S08E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-1">
            <HeaderEP />
            <S09E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-2">
            <HeaderEP />
            <S09E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-3">
            <HeaderEP />
            <S09E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-4">
            <HeaderEP />
            <S09E04 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-5">
            <HeaderEP />
            <S09E05 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-6">
            <HeaderEP />
            <S09E06 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-7">
            <HeaderEP />
            <S09E07 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-8">
            <HeaderEP />
            <S09E08 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-9">
            <HeaderEP />
            <S09E09 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-9-epizoda-10">
            <HeaderEP />
            <S09E10 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-10-epizoda-1">
            <HeaderEP />
            <S10E01 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-10-epizoda-2">
            <HeaderEP />
            <S10E02 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-10-epizoda-3">
            <HeaderEP />
            <S10E03 />
          </Route>
          <Route path="/ninjago-mistri-spinjitzu-sezona-10-epizoda-4">
            <HeaderEP />
            <S10E04 />
          </Route>
          <Route path="/ninjago">
            <Ninjago />
            <Bottom />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-1">
            <HeaderEP />
            <NS01E01 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-2">
            <HeaderEP />
            <NS01E02 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-3">
            <HeaderEP />
            <NS01E03 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-4">
            <HeaderEP />
            <NS01E04 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-5">
            <HeaderEP />
            <NS01E05 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-6">
            <HeaderEP />
            <NS01E06 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-7">
            <HeaderEP />
            <NS01E07 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-8">
            <HeaderEP />
            <NS01E08 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-9">
            <HeaderEP />
            <NS01E09 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-10">
            <HeaderEP />
            <NS01E10 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-11">
            <HeaderEP />
            <NS01E11 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-12">
            <HeaderEP />
            <NS01E12 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-13">
            <HeaderEP />
            <NS01E13 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-14">
            <HeaderEP />
            <NS01E14 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-15">
            <HeaderEP />
            <NS01E15 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-16">
            <HeaderEP />
            <NS01E16 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-17">
            <HeaderEP />
            <NS01E17 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-18">
            <HeaderEP />
            <NS01E18 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-19">
            <HeaderEP />
            <NS01E19 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-20">
            <HeaderEP />
            <NS01E20 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-21">
            <HeaderEP />
            <NS01E21 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-22">
            <HeaderEP />
            <NS01E22 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-23">
            <HeaderEP />
            <NS01E23 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-24">
            <HeaderEP />
            <NS01E24 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-25">
            <HeaderEP />
            <NS01E25 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-26">
            <HeaderEP />
            <NS01E26 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-27">
            <HeaderEP />
            <NS01E27 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-28">
            <HeaderEP />
            <NS01E28 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-29">
            <HeaderEP />
            <NS01E29 />
          </Route>
          <Route path="/ninjago-rada-1-epizoda-30">
            <HeaderEP />
            <NS01E30 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-1">
            <HeaderEP />
            <NS02E01 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-2">
            <HeaderEP />
            <NS02E02 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-3">
            <HeaderEP />
            <NS02E03 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-4">
            <HeaderEP />
            <NS02E04 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-5">
            <HeaderEP />
            <NS02E05 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-6">
            <HeaderEP />
            <NS02E06 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-7">
            <HeaderEP />
            <NS02E07 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-8">
            <HeaderEP />
            <NS02E08 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-9">
            <HeaderEP />
            <NS02E09 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-10">
            <HeaderEP />
            <NS02E10 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-11">
            <HeaderEP />
            <NS02E11 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-12">
            <HeaderEP />
            <NS02E12 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-13">
            <HeaderEP />
            <NS02E13 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-14">
            <HeaderEP />
            <NS02E14 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-15">
            <HeaderEP />
            <NS02E15 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-16">
            <HeaderEP />
            <NS02E16 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-17">
            <HeaderEP />
            <NS02E17 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-18">
            <HeaderEP />
            <NS02E18 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-19">
            <HeaderEP />
            <NS02E19 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-20">
            <HeaderEP />
            <NS02E20 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-21">
            <HeaderEP />
            <NS02E21 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-22">
            <HeaderEP />
            <NS02E22 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-23">
            <HeaderEP />
            <NS02E23 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-24">
            <HeaderEP />
            <NS02E24 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-25">
            <HeaderEP />
            <NS02E25 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-26">
            <HeaderEP />
            <NS02E26 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-27">
            <HeaderEP />
            <NS02E27 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-28">
            <HeaderEP />
            <NS02E28 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-29">
            <HeaderEP />
            <NS02E29 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-30">
            <HeaderEP />
            <NS02E30 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-31">
            <HeaderEP />
            <NS02E31 />
          </Route>
          <Route path="/ninjago-rada-2-epizoda-32">
            <HeaderEP />
            <NS02E32 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-1">
            <HeaderEP />
            <NS03E01 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-2">
            <HeaderEP />
            <NS03E02 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-3">
            <HeaderEP />
            <NS03E03 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-4">
            <HeaderEP />
            <NS03E04 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-5">
            <HeaderEP />
            <NS03E05 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-6">
            <HeaderEP />
            <NS03E06 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-7">
            <HeaderEP />
            <NS03E07 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-8">
            <HeaderEP />
            <NS03E08 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-9">
            <HeaderEP />
            <NS03E09 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-10">
            <HeaderEP />
            <NS03E10 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-11">
            <HeaderEP />
            <NS03E11 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-12">
            <HeaderEP />
            <NS03E12 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-13">
            <HeaderEP />
            <NS03E13 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-14">
            <HeaderEP />
            <NS03E14 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-15">
            <HeaderEP />
            <NS03E15 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-16">
            <HeaderEP />
            <NS03E16 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-17">
            <HeaderEP />
            <NS03E17 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-18">
            <HeaderEP />
            <NS03E18 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-19">
            <HeaderEP />
            <NS03E19 />
          </Route>
          <Route path="/ninjago-rada-3-epizoda-20">
            <HeaderEP />
            <NS03E20 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-1">
            <HeaderEP />
            <NS04E01 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-2">
            <HeaderEP />
            <NS04E02 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-3">
            <HeaderEP />
            <NS04E03 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-4">
            <HeaderEP />
            <NS04E04 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-5">
            <HeaderEP />
            <NS04E05 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-6">
            <HeaderEP />
            <NS04E06 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-7">
            <HeaderEP />
            <NS04E07 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-8">
            <HeaderEP />
            <NS04E08 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-9">
            <HeaderEP />
            <NS04E09 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-10">
            <HeaderEP />
            <NS04E10 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-11">
            <HeaderEP />
            <NS04E11 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-12">
            <HeaderEP />
            <NS04E12 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-13">
            <HeaderEP />
            <NS04E13 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-14">
            <HeaderEP />
            <NS04E14 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-15">
            <HeaderEP />
            <NS04E15 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-16">
            <HeaderEP />
            <NS04E16 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-17">
            <HeaderEP />
            <NS04E17 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-18">
            <HeaderEP />
            <NS04E18 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-19">
            <HeaderEP />
            <NS04E19 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-20">
            <HeaderEP />
            <NS04E20 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-21">
            <HeaderEP />
            <NS04E21 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-22">
            <HeaderEP />
            <NS04E22 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-23">
            <HeaderEP />
            <NS04E23 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-24">
            <HeaderEP />
            <NS04E24 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-25">
            <HeaderEP />
            <NS04E25 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-26">
            <HeaderEP />
            <NS04E26 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-27">
            <HeaderEP />
            <NS04E27 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-28">
            <HeaderEP />
            <NS04E28 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-29">
            <HeaderEP />
            <NS04E29 />
          </Route>
          <Route path="/ninjago-rada-4-epizoda-30">
            <HeaderEP />
            <NS04E30 />
          </Route>
          <Route path="/ninjago-draci-povstani">
            <NinjagoDP />
            <Bottom />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-1">
            <HeaderEP />
            <DPS01E01 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-2">
            <HeaderEP />
            <DPS01E02 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-3">
            <HeaderEP />
            <DPS01E03 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-4">
            <HeaderEP />
            <DPS01E04 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-5">
            <HeaderEP />
            <DPS01E05 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-6">
            <HeaderEP />
            <DPS01E06 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-7">
            <HeaderEP />
            <DPS01E07 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-8">
            <HeaderEP />
            <DPS01E08 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-9">
            <HeaderEP />
            <DPS01E09 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-10">
            <HeaderEP />
            <DPS01E10 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-11">
            <HeaderEP />
            <DPS01E11 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-12">
            <HeaderEP />
            <DPS01E12 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-13">
            <HeaderEP />
            <DPS01E13 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-14">
            <HeaderEP />
            <DPS01E14 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-15">
            <HeaderEP />
            <DPS01E15 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-16">
            <HeaderEP />
            <DPS01E16 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-17">
            <HeaderEP />
            <DPS01E17 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-18">
            <HeaderEP />
            <DPS01E18 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-19">
            <HeaderEP />
            <DPS01E19 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-1-epizoda-20">
            <HeaderEP />
            <DPS01E20 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-1">
            <HeaderEP />
            <DPS02E01 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-2">
            <HeaderEP />
            <DPS02E02 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-3">
            <HeaderEP />
            <DPS02E03 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-4">
            <HeaderEP />
            <DPS02E04 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-5">
            <HeaderEP />
            <DPS02E05 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-6">
            <HeaderEP />
            <DPS02E06 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-7">
            <HeaderEP />
            <DPS02E07 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-8">
            <HeaderEP />
            <DPS02E08 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-9">
            <HeaderEP />
            <DPS02E09 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-10">
            <HeaderEP />
            <DPS02E10 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-11">
            <HeaderEP />
            <DPS02E11 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-12">
            <HeaderEP />
            <DPS02E12 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-13">
            <HeaderEP />
            <DPS02E13 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-14">
            <HeaderEP />
            <DPS02E14 />
          </Route>
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-15">
            <HeaderEP />
            <DPS02E15 />
          </Route>     
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-16">
            <HeaderEP />
            <DPS02E16 />
          </Route>         
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-17">
            <HeaderEP />
            <DPS02E17 />
          </Route>    
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-18">
            <HeaderEP />
            <DPS02E18 />
          </Route>    
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-19">
            <HeaderEP />
            <DPS02E19 />
          </Route>  
          <Route path="/ninjago-draci-povstani-sezona-2-epizoda-20">
            <HeaderEP />
            <DPS02E20 />
          </Route>    
          <Route path="/star-wars-pribehy-droidu">
            <PribehyDroidu />
            <Bottom />
          </Route>
          <Route path="/star-wars-pribehy-droidu-sezona-1-epizoda-1">
            <HeaderEP />
            <PDS01E01 />
          </Route>
          <Route path="/star-wars-pribehy-droidu-sezona-1-epizoda-2">
            <HeaderEP />
            <PDS01E02 />
          </Route>
          <Route path="/star-wars-pribehy-droidu-sezona-1-epizoda-3">
            <HeaderEP />
            <PDS01E03 />
          </Route>
          <Route path="/star-wars-pribehy-droidu-sezona-1-epizoda-4">
            <HeaderEP />
            <PDS01E04 />
          </Route>
          <Route path="/star-wars-pribehy-droidu-sezona-1-epizoda-5">
            <HeaderEP />
            <PDS01E05 />
          </Route>
          <Route path="/ninjago-wyldfyre-navrat-do-divociny">
            <Wyldfyreshorts />
          </Route>
          <Route path="/ninjago-wyldfyre-navrat-do-divociny-sezona-1-epizoda-1">
            <HeaderEP />
            <WFS01E01 />
          </Route>
          <Route path="/ninjago-wyldfyre-navrat-do-divociny-sezona-1-epizoda-2">
            <HeaderEP />
            <WFS01E02 />
          </Route>
          <Route path="/ninjago-wyldfyre-navrat-do-divociny-sezona-1-epizoda-3">
            <HeaderEP />
            <WFS01E03 />
          </Route>
          <Route path="/ninjago-wyldfyre-navrat-do-divociny-sezona-1-epizoda-4">
            <HeaderEP />
            <WFS01E04 />
          </Route>
          <Route path="/ledove-kralovstvi-polarni-zare">
            <LedoveKralovstvi />
            <Bottom />
          </Route>
          <Route path="/ledove-kralovstvi-polarni-zare-sezona-1-epizoda-1">
            <HeaderEP />
            <LKS01E01 />
          </Route>
          <Route path="/ledove-kralovstvi-polarni-zare-sezona-1-epizoda-2">
            <HeaderEP />
            <LKS01E02 />
          </Route>
          <Route path="/ledove-kralovstvi-polarni-zare-sezona-1-epizoda-3">
            <HeaderEP />
            <LKS01E03 />
          </Route>
          <Route path="/ledove-kralovstvi-polarni-zare-sezona-1-epizoda-4">
            <HeaderEP />
            <LKS01E04 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru">
            <DobrodruzstviFreemakeru />
            <Bottom />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-1">
            <HeaderEP />
            <DFS01E01 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-2">
            <HeaderEP />
            <DFS01E02 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-3">
            <HeaderEP />
            <DFS01E03 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-4">
            <HeaderEP />
            <DFS01E04 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-5">
            <HeaderEP />
            <DFS01E05 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-6">
            <HeaderEP />
            <DFS01E06 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-7">
            <HeaderEP />
            <DFS01E07 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-8">
            <HeaderEP />
            <DFS01E08 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-9">
            <HeaderEP />
            <DFS01E09 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-10">
            <HeaderEP />
            <DFS01E10 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-11">
            <HeaderEP />
            <DFS01E11 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-12">
            <HeaderEP />
            <DFS01E12 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-1-epizoda-13">
            <HeaderEP />
            <DFS01E13 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-1">
            <HeaderEP />
            <DFS02E01 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-2">
            <HeaderEP />
            <DFS02E02 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-3">
            <HeaderEP />
            <DFS02E03 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-4">
            <HeaderEP />
            <DFS02E04 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-5">
            <HeaderEP />
            <DFS02E05 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-6">
            <HeaderEP />
            <DFS02E06 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-7">
            <HeaderEP />
            <DFS02E07 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-8">
            <HeaderEP />
            <DFS02E08 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-9">
            <HeaderEP />
            <DFS02E09 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-10">
            <HeaderEP />
            <DFS02E10 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-11">
            <HeaderEP />
            <DFS02E11 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-12">
            <HeaderEP />
            <DFS02E12 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-sezona-2-epizoda-13">
            <HeaderEP />
            <DFS02E13 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-shorts">
            <Dobrodruzstvifreemakerushorts />
            <Bottom />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-shorts-sezona-1-epizoda-1">
            <HeaderEP />
            <DFSS01E01 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-shorts-sezona-1-epizoda-2">
            <HeaderEP />
            <DFSS01E02 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-shorts-sezona-1-epizoda-3">
            <HeaderEP />
            <DFSS01E03 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-shorts-sezona-1-epizoda-4">
            <HeaderEP />
            <DFSS01E04 />
          </Route>
          <Route path="/star-wars-dobrodruzstvi-freemakeru-shorts-sezona-1-epizoda-5">
            <HeaderEP />
            <DFSS01E05 />
          </Route>
          <Route path="/star-wars-all-stars">
            <AllStars />
            <Bottom />
          </Route>
          <Route path="/star-wars-all-stars-sezona-1-epizoda-1">
            <HeaderEP />
            <ALSS01E01 />
          </Route>
          <Route path="/star-wars-all-stars-sezona-1-epizoda-2">
            <HeaderEP />
            <ALSS01E02 />
          </Route>
          <Route path="/star-wars-all-stars-sezona-1-epizoda-3">
            <HeaderEP />
            <ALSS01E03 />
          </Route>
          <Route path="/star-wars-all-stars-sezona-1-epizoda-4">
            <HeaderEP />
            <ALSS01E04 />
          </Route>
          <Route path="/star-wars-all-stars-sezona-1-epizoda-5">
            <HeaderEP />
            <ALSS01E05 />
          </Route>
          <Route path="/star-wars-all-stars-shorts">
            <AllStarsshortsshorts />
            <Bottom />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-1">
            <HeaderEP />
            <ALSSS01E01 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-2">
            <HeaderEP />
            <ALSSS01E02 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-3">
            <HeaderEP />
            <ALSSS01E03 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-4">
            <HeaderEP />
            <ALSSS01E04 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-5">
            <HeaderEP />
            <ALSSS01E05 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-6">
            <HeaderEP />
            <ALSSS01E06 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-7">
            <HeaderEP />
            <ALSSS01E07 />
          </Route>
          <Route path="/star-wars-all-stars-shorts-sezona-1-epizoda-8">
            <HeaderEP />
            <ALSSS01E08 />
          </Route>
          <Route path="/star-wars-historky-z-odboje">
            <Historkyzodboje />
            <Bottom />
          </Route>
          <Route path="/star-wars-historky-z-odboje-sezona-1-epizoda-1">
            <HeaderEP />
            <SHS01E01 />
          </Route>
          <Route path="/star-wars-historky-z-odboje-sezona-1-epizoda-2">
            <HeaderEP />
            <SHS01E02 />
          </Route>
          <Route path="/star-wars-historky-z-odboje-sezona-1-epizoda-3">
            <HeaderEP />
            <SHS01E03 />
          </Route>
          <Route path="/star-wars-historky-z-odboje-sezona-1-epizoda-4">
            <HeaderEP />
            <SHS01E04 />
          </Route>
          <Route path="/star-wars-historky-z-odboje-sezona-1-epizoda-5">
            <HeaderEP />
            <SHS01E05 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky">
            <PribehyzYodovikroniky />
            <Bottom />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-1-epizoda-1">
            <HeaderEP />
            <YDS01E01 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-1-epizoda-2">
            <HeaderEP />
            <YDS01E02 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-1-epizoda-3">
            <HeaderEP />
            <YDS01E03 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-2-epizoda-1">
            <HeaderEP />
            <YDS02E01 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-2-epizoda-2">
            <HeaderEP />
            <YDS02E02 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-2-epizoda-3">
            <HeaderEP />
            <YDS02E03 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-2-epizoda-4">
            <HeaderEP />
            <YDS02E04 />
          </Route>
          <Route path="/star-wars-pribehy-z-yodovi-kroniky-sezona-2-epizoda-4-alternativni-konec">
            <HeaderEP />
            <YDS02E05 />
          </Route>
          <Route path="/dreamzzz">
            <Dreamzzz />
            <Bottom />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-1">
            <HeaderEP />
            <DZS01E01 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-2">
            <HeaderEP />
            <DZS01E02 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-3">
            <HeaderEP />
            <DZS01E03 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-4">
            <HeaderEP />
            <DZS01E04 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-5">
            <HeaderEP />
            <DZS01E05 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-6">
            <HeaderEP />
            <DZS01E06 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-7">
            <HeaderEP />
            <DZS01E07 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-8">
            <HeaderEP />
            <DZS01E08 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-9">
            <HeaderEP />
            <DZS01E09 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-10">
            <HeaderEP />
            <DZS01E10 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-11">
            <HeaderEP />
            <DZS01E11 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-12">
            <HeaderEP />
            <DZS01E12 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-13">
            <HeaderEP />
            <DZS01E13 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-14">
            <HeaderEP />
            <DZS01E14 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-15">
            <HeaderEP />
            <DZS01E15 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-16">
            <HeaderEP />
            <DZS01E16 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-17">
            <HeaderEP />
            <DZS01E17 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-18">
            <HeaderEP />
            <DZS01E18 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-19">
            <HeaderEP />
            <DZS01E19 />
          </Route>
          <Route path="/dreamzzz-sezona-1-epizoda-20">
            <HeaderEP />
            <DZS01E20 />
          </Route>
          <Route path="/city-dobrodruzstvi">
            <Citydobrodruzstvi />
            <Bottom />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-1">
            <HeaderEP />
            <CDS01E01 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-2">
            <HeaderEP />
            <CDS01E02 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-3">
            <HeaderEP />
            <CDS01E03 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-4">
            <HeaderEP />
            <CDS01E04 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-5">
            <HeaderEP />
            <CDS01E05 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-6">
            <HeaderEP />
            <CDS01E06 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-7">
            <HeaderEP />
            <CDS01E07 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-8">
            <HeaderEP />
            <CDS01E08 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-9">
            <HeaderEP />
            <CDS01E09 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-10">
            <HeaderEP />
            <CDS01E10 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-11">
            <HeaderEP />
            <CDS01E11 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-12">
            <HeaderEP />
            <CDS01E12 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-13">
            <HeaderEP />
            <CDS01E13 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-14">
            <HeaderEP />
            <CDS01E14 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-15">
            <HeaderEP />
            <CDS01E15 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-16">
            <HeaderEP />
            <CDS01E16 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-17">
            <HeaderEP />
            <CDS01E17 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-18">
            <HeaderEP />
            <CDS01E18 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-19">
            <HeaderEP />
            <CDS01E19 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-1-epizoda-20">
            <HeaderEP />
            <CDS01E20 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-1">
            <HeaderEP />
            <CDS02E01 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-2">
            <HeaderEP />
            <CDS02E02 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-3">
            <HeaderEP />
            <CDS02E03 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-4">
            <HeaderEP />
            <CDS02E04 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-5">
            <HeaderEP />
            <CDS02E05 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-6">
            <HeaderEP />
            <CDS02E06 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-7">
            <HeaderEP />
            <CDS02E07 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-8">
            <HeaderEP />
            <CDS02E08 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-9">
            <HeaderEP />
            <CDS02E09 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-10">
            <HeaderEP />
            <CDS02E10 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-11">
            <HeaderEP />
            <CDS02E11 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-12">
            <HeaderEP />
            <CDS02E12 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-13">
            <HeaderEP />
            <CDS02E13 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-14">
            <HeaderEP />
            <CDS02E14 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-15">
            <HeaderEP />
            <CDS02E15 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-16">
            <HeaderEP />
            <CDS02E16 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-17">
            <HeaderEP />
            <CDS02E17 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-18">
            <HeaderEP />
            <CDS02E18 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-19">
            <HeaderEP />
            <CDS02E19 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-2-epizoda-20">
            <HeaderEP />
            <CDS02E20 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-1">
            <HeaderEP />
            <CDS03E01 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-2">
            <HeaderEP />
            <CDS03E02 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-3">
            <HeaderEP />
            <CDS03E03 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-4">
            <HeaderEP />
            <CDS03E04 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-5">
            <HeaderEP />
            <CDS03E05 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-6">
            <HeaderEP />
            <CDS03E06 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-7">
            <HeaderEP />
            <CDS03E07 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-8">
            <HeaderEP />
            <CDS03E08 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-9">
            <HeaderEP />
            <CDS03E09 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-10">
            <HeaderEP />
            <CDS03E10 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-11">
            <HeaderEP />
            <CDS03E11 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-12">
            <HeaderEP />
            <CDS03E12 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-13">
            <HeaderEP />
            <CDS03E13 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-14">
            <HeaderEP />
            <CDS03E14 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-15">
            <HeaderEP />
            <CDS03E15 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-16">
            <HeaderEP />
            <CDS03E16 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-17">
            <HeaderEP />
            <CDS03E17 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-18">
            <HeaderEP />
            <CDS03E18 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-19">
            <HeaderEP />
            <CDS03E19 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-20">
            <HeaderEP />
            <CDS03E20 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-21">
            <HeaderEP />
            <CDS03E21 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-3-epizoda-22">
            <HeaderEP />
            <CDS03E22 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-1">
            <HeaderEP />
            <CDS04E01 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-2">
            <HeaderEP />
            <CDS04E02 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-3">
            <HeaderEP />
            <CDS04E03 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-4">
            <HeaderEP />
            <CDS04E04 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-5">
            <HeaderEP />
            <CDS04E05 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-6">
            <HeaderEP />
            <CDS04E06 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-7">
            <HeaderEP />
            <CDS04E07 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-8">
            <HeaderEP />
            <CDS04E08 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-9">
            <HeaderEP />
            <CDS04E09 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-10">
            <HeaderEP />
            <CDS04E10 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-11">
            <HeaderEP />
            <CDS04E11 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-12">
            <HeaderEP />
            <CDS04E12 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-13">
            <HeaderEP />
            <CDS04E13 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-14">
            <HeaderEP />
            <CDS04E14 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-15">
            <HeaderEP />
            <CDS04E15 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-16">
            <HeaderEP />
            <CDS04E16 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-17">
            <HeaderEP />
            <CDS04E17 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-18">
            <HeaderEP />
            <CDS04E18 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-19">
            <HeaderEP />
            <CDS04E19 />
          </Route>
          <Route path="/city-dobrodruzstvi-sezona-4-epizoda-20">
            <HeaderEP />
            <CDS04E20 />
          </Route>
          <Route path="*" component={NotFound} />
          <RedirectWithStatus
            status={302}
            to="/NotFound"
          />
        </Switch>
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
