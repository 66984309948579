import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS05() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-1">
              <img src="https://i.ibb.co/FKDdzvD/AAAABRSOP-OBSIPQVhoq-Rrya6-JR43-Mv8v-Pzt3-LXQIg-KTi86qz-Cl-QHpq-Yd-M0yyz-Nmm-BPVH92-QZim4-Nhn9u-TR-g.jpg" loading="eager" />
              <b>1. Změna větru</b>
              <p>Morro ovládne Lloyda. Využije ho k infiltraci čajovny a útoku na ostatní nindži, kteří záhadně přišli o své síly.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-2">
              <img src="https://i.ibb.co/J5SZwCx/AAAABQat-QULz-Jsiuz-NIZe-W4-Tp-JFA55-Fz-J7-RIb7-J-1-Zi-Ea-Ces-Au-Rrjfjlxhn-BRq-Sz-M-YNG29-Kfm1p-A2-N.jpg" loading="eager" />
              <b>2. Příběh o duchovi</b>
              <p>Nindžové se dozvědí, že Wuovi spolupracovníci mají vodítko k hrobce prvního mistra Spinjitzu. Vydávají se do Stiix získat ukradený svitek Airjitzu.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-3">
              <img src="https://i.ibb.co/3ddrkVj/AAAABfc-HAiv3-B1-KHVw-Q6-XZJzy-JIFZ1g-LBQCk1-Iy41w6vqyzst-VWSDZg-g-O9-Q5h-Pu-Q82-GUJRQMU5sy-Ooq-Q2j.jpg" loading="eager" />
              <b>3. Souboj ve Stiixu</b>
              <p>Wu učí Nyu používat její nové vodní síly. Nindžové zatím plánují krádež svitků Airjitzu, které má Ronin. Zjistí však, že nejdřív se budou muset utkat s Morrem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-4">
              <img src="https://i.ibb.co/mCYGqc9/AAAABel6ny-QV-KHucf-G68po-UBasmw-Ua-J7w-SN3k-F28slz-ZJRA-4chj-WHf7e-Hp-IBo-GPq549-Lnv-WOb7-Zh-M7d-LQ.jpg" loading="eager" />
              <b>4. Chrám na strašidelném vrchu</b>
              <p>Ninjové putují do nebezpečného strašidelného chrámu mistra Yanga naučit se Airjitzu. Nya absolvuje trénink a musí své síly použít, aby unikla Morrových duchům.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-5">
              <img src="https://i.ibb.co/48xBQnd/AAAABWNq-Fgqa9-Q5t-Ub-L4l-Clr-VUhd-I1-A4c-TQhim4d5y-YIFDpb-WUXfa-Ks-Te5-Vl-KMw2j-Ls-Sj-VAxor4-HAUS81.jpg" loading="eager" />
              <b>5. Cyklondo</b>
              <p>Nindžové spěchají na nebezpečnou horu, kde se pomocí svých nových sil Airjitzu snaží získat Meč svatyně dřív, než se ho zmocní Morro.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-6">
              <img src="https://i.ibb.co/fYTJbRB/AAAABQ3-Cs-Dzzn-NOZt-Wn5-HVK8pbl-Zntr2-Rl-Al-Jf5-3bdlbs9t-C5q-WFz68k2gvszi-nbhs-E-bg5-OFJU4-Z-B7k9ui.jpg" loading="eager" />
              <b>6. Království přichází</b>
              <p>Po zradě v Oblačném království bojují nindžové s Morrem o Meč svatyně. Zjišťují, že jeho hlavním cílem je Vladařský krystal.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-7">
              <img src="https://i.ibb.co/BG9C1cY/AAAABQJat-NVZbx-Oqk-WY46-UFO1n-K4-QLm0v3vmu-WUu-Jv3-Wg68i-X-Je-PSt6-Cxk-M4-b-Clb-HLVo12xr-NT3-MDU1mc.jpg" loading="eager" />
              <b>7. Podraz</b>
              <p>Nindžové se blíží k odhalení polohy hrobky prvního mistra Spinjitzu. Ronin ukradne Meč svatyně a chce ho dát Morrovi.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-8">
              <img src="https://i.ibb.co/cvHG4c6/AAAABQ2-ZGW4-A0-K32-Wwp-Ek4-EVlo-Ogy-ZUo-c-QQFNS4ezx-gtf-N-QKD3f-Aw-Z3v-WE-ITEf-RWge-Qfsbf7-H8-E2e-N.jpg" loading="eager" />
              <b>8. Nebezpečná hrobka</b>
              <p>Nindžové se vydávají k hrobce prvního mistra Spinjitzu získat mocný Vladařský krystal. Čeká je spousta nástrah – a Morro.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-9">
              <img src="https://i.ibb.co/1z4zxyp/AAAABXc8-Jkp-Phd-PEK43hk-EV2zdcf-W0-Y4-YWDvy-Mv5b-an-IIwh-H5k21-Lz-RHm-Ap8-Ksq-Rd-Bacgqss-ZPvq1i4f9d.jpg" loading="eager" />
              <b>9. Prokletý svět: část 1</b>
              <p>Nindžové spěchají, aby Morrovi zabránili ve vysvobození Preeminent. Morro pomocí Vladařského krystalu vyvolá armádu duchů z Prokleté říše.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-5-epizoda-10">
              <img src="https://i.ibb.co/Fmqjk2W/AAAABZh-E2uz6-In-Lzs09fga-XK4-FP780x46-Oxz-P5-WK5pbdki-Iks-Av8g3cfr-PJURZwrr9ie-3-OWZZMa-Tzi8-MGZOpn.jpg" loading="eager" />
              <b>10. Prokletý svět: část 2</b>
              <p>Nindžové se pokoušejí bránit Stiix. Lloyd se setkává se svým otcem v Prokleté říši. Nya odkryje svůj skutečný potenciál, díky kterému se dokáže postavit Preeminent.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS05;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        overflow: visible;
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
