import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

function NinjagoMSTSliderS10() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container>
      <Carousel {...settings}>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-10-epizoda-1">
              <img src="https://i.ibb.co/SNmvTNS/AAAABfdr4-BA6-JKc-Tx-X-m3-dvk-pf-FPe-He-Y2h-Wr-Z-15ol27gids-Tcn-IIELch2l-Vktuzt-Di1xbn3wd-Jw-N75-ZIu.jpg" loading="eager" />
              <b>1. Temnota přichází</b>
              <p>Faith dorazí se zprávou o invazi, která Lloyda přinutí zvážit spojenectví s Lordem Garmadonem.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-10-epizoda-2">
              <img src="https://i.ibb.co/tQSLyGM/AAAABT3gd-Rs2-KIVpb0-Yr-QF1dx69n3-URUQew-7x-Y-HIbq-Gn-Fvx-Bc2zl-VQVgwd-BSA-s-FXDs6-P0n-Vqy-RKp-Yx-I3.jpg" loading="eager" />
              <b>2. Pomoc v nouzi</b>
              <p>I přes vážné pochyby nindžové pustí Lorda Garmadona z vězení Kryptarium, aby spolu s Lloydem mohli vstoupit do oblaku temnoty.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-10-epizoda-3">
              <img src="https://i.ibb.co/pj61223/AAAABbw-VUvbr-FQSFtox-Qr2-Xr9viii-GHDgy-JAQYYH-ITq-MAm-U8brcr-Xiktb-Tu-JJJG8-YC7-Yz-Qu-CT9-Kg-b-Vml.jpg" loading="eager" />
              <b>3. Pád</b>
              <p>Tým se pokusí o záchranu Lloyda a Lorda Garmadona a strašlivě na to doplatí. Lord Garmadon během boje s Omegou objeví slabinu Oniů.</p>
            </a>
          </div>
        </Wrap>
        <Wrap>
          <div className="btn">
            <a href="/ninjago-mistri-spinjitzu-sezona-10-epizoda-4">
              <img src="https://i.ibb.co/cYC25M8/AAAABb9-Pz3-KX9zk-A4-Z3-VMLQPDFk-3mh-Znukcndz4w-MQBsf-Je-d-PDEKGt-FPOKy8pj-W8-Q910h-Tz-V05b-APTb-LUE.jpg" loading="eager" />
              <b>4. Konce</b>
              <p>Nindžové spěchají zpátky do kláštera Spinjitzu, kde musí spojit síly a naposledy se postavit Oniům.</p>
            </a>
          </div>
        </Wrap>
      </Carousel>
    </Container>
  );
}

export default NinjagoMSTSliderS10;

const Container = styled.div`
`;

const Zacatek = styled.div`
@media (max-width: 768px) {
    font-size: 25px;
}
      font-family: 'Teko', sans-serif;
      font-size: 25px;
      padding: 5 42px;
`;

const Carousel = styled(Slider)`
@media (max-width: 768px) {
    font-size: 15px;
  }
  
  b {
    font-family: 'Cairo', sans-serif;
    color: white;
    font-size: 14px;
    transform: translateY(0%);
    letter-spacing: -0.6px;
   }

   a {
    text-decoration:none;
 }

 p {
    color: #cacaca;
    letter-spacing: -0.5px;
    font-size: 12px;
    margin-top 2px;
   }


.slick-slide {
    display: block;
    padding:10px;
    margin-top: -18px;
}
      
.slick-prev {                                  
    margin-left: -15px;
    margin-top: -72px;
  }

  .slick-next {
    margin-right: -8px;
    margin-top: -72px;
  }

    ul li button {
        &:before {
            font-size: 8px;
            color: gray;
        }
    }

    li.slick-active button: before {
        color: white
    }

    .slick-list {
        right: 10%; 
        overflow: visible;

        @media (max-width: 768px) {
            right: 0px;
          }
    }
`;

const Wrap = styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        background: linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42));
        border-radius: 4px;
    }
        }
`;
