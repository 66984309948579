// src/components/Header.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { useAuth } from '../contexts/AuthContext'; 
import Modal from 'react-modal';
import Avatar from './Avatar';
import { GoogleAuthProvider } from 'firebase/auth'; 
import ZmenaPrezdivky from '../ZmenaPrezdivky';
import { useHistory } from 'react-router-dom';
import HeaderDropdown from '../HeaderDropdown';
import PridatRoli from '../PridatRoli'; // Import komponenty PridatRoli

Modal.setAppElement('#root');

function Header() {
    const { userCount, loadingUserCount } = useAuth(); // Použijeme loadingUserCount
    const { user, login, logout, loading, role } = useAuth();
    const [scrolled, setScrolled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNicknameModalOpen, setIsNicknameModalOpen] = useState(false); 
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false); // Stav pro modální okno Přidat roli
    const history = useHistory();

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const openNicknameModal = () => setIsNicknameModalOpen(true);
    const closeNicknameModal = () => setIsNicknameModalOpen(false);
    const openAlertModal = () => setIsAlertModalOpen(true);
    const closeAlertModal = () => setIsAlertModalOpen(false);
    const openAddRoleModal = () => setIsAddRoleModalOpen(true); // Funkce pro otevření modálního okna Přidat roli
    const closeAddRoleModal = () => setIsAddRoleModalOpen(false); // Funkce pro zavření modálního okna Přidat roli

    const confirmLogout = async () => {
        try {
            await logout();
            closeModal();
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleNicknameChange = () => {
        if (role === 'VIP' || role === 'Majitel' || role === 'Admin' || role === 'Moderátor') {
            openNicknameModal();
        } else {
            openAlertModal();
        }
    };

    const navigateToProfile = () => {
        history.push('/profil');
        setIsDropdownOpen(false);
    };

    const addRole = () => {
        openAddRoleModal(); // Otevře modální okno Přidat roli
    };

    const getRoleColor = (role) => {
        switch (role) {
            case 'Majitel':
                return '#f39c12'; // Žlutá
            case 'Admin':
                return '#e74c3c'; // Červená
            case 'Moderátor':
                return '#2ecc71'; // Světle zelená
            case 'VIP':
                return '#e67e22'; // Světle oranžová
            default:
                return '#3498db'; // Modrá
        }
    };

    const allowedRoles = ['Majitel', 'Admin', 'Moderátor', 'VIP'];

    const shouldShowVIPLabel = !['VIP', 'Majitel', 'Admin', 'Moderátor'].includes(role);

    return (
        <>
            <Nav scrolled={scrolled}>
                <Logo>
                <Tooltip title="Verze 2.0" placement="bottom-middle" arrow enterDelay={100} leaveDelay={50}>
                        <a href="/">
                            <div className="logonceheader">
                                <img src="https://i.ibb.co/qrpqqR0/NCELOGOFINA.png" alt="NCE+" loading="eager" />
                            </div>
                        </a>
                    </Tooltip>
                </Logo>
                <NavMenu>
                    <a className='cursorpointer' href="/">
                        <img className="prevent-select" src="/images/horni-menu/DOMŮ1.png" alt="DOMŮ" loading="eager" />
                        <span>DOMŮ</span>
                    </a>
                    <a className='cursorpointer' href="/serialy">
                        <img className="prevent-select" src="/images/horni-menu/SERIALY1.png" alt="SERIÁLY" loading="eager" />
                        <span>SERIÁLY</span>
                    </a>
                    <a className='cursorpointer' href="/filmy">
                        <img className="prevent-select" src="/images/horni-menu/FILMY1.png" alt="FILMY" loading="eager" />
                        <span>FILMY</span>
                    </a>
                    {allowedRoles.includes(role) ? (
                       <a className='cursorpointer' href="/vip-obsah">
                       <img className="prevent-select" src="/images/horni-menu/VIPOBSAH.png" alt="VIP OBSAH" loading="eager" />
                       <span>VIP OBSAH</span>
                   </a>
                    ) : null}      
                    <a className='cursorpointer' href="/od-nce+">
                        <img className="prevent-select" src="/images/horni-menu/OD_NCE+1.png" alt="OD NCE+" loading="eager" />
                        <span>OD NCE+</span>
                    </a>      
                </NavMenu>
                <AuthSection>
                    {loading ? (
                        <Spinner>Načítám...</Spinner>
                    ) : user ? (
                        <UserSection>
                            <Avatar key={user.photoURL} user={user} />
                            <UserName onClick={toggleDropdown}>
                                {user.displayName || 'Neznámý'}
                                {role && <RoleBadge color={getRoleColor(role)}>{role === 'User' ? 'Uživatel' : role}</RoleBadge>}
                            </UserName>
                            <HeaderDropdown 
                                isDropdownOpen={isDropdownOpen}
                                navigateToProfile={navigateToProfile}
                                handleNicknameChange={handleNicknameChange}
                                openModal={openModal}
                                shouldShowVIPLabel={shouldShowVIPLabel}
                                userRole={role}
                                openAddRoleModal={addRole} // Propojení s funkcí pro otevření modálního okna Přidat roli
                            />
                        </UserSection>
                    ) : (
                        <LoginButton onClick={() => login(new GoogleAuthProvider())}>Přihlásit se</LoginButton>
                    )}
                </AuthSection>
            </Nav>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Confirm Logout"
                style={modalStyles}
            >
                <ModalContent>
                    <ModalTitle>Chcete se opravdu odhlásit?</ModalTitle>
                    <ModalButtons>
                        <ModalButton onClick={confirmLogout}>Ano</ModalButton>
                        <ModalButton onClick={closeModal}>Ne</ModalButton>
                    </ModalButtons>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={isNicknameModalOpen}
                onRequestClose={closeNicknameModal}
                contentLabel="Změna přezdívky"
                style={modalStyles}
            >
                <ZmenaPrezdivky closeModal={closeNicknameModal} />
            </Modal>

            <Modal
                isOpen={isAlertModalOpen}
                onRequestClose={closeAlertModal}
                contentLabel="Upozornění"
                style={modalStyles}
            >
                <ModalContent>
                    <ModalTitle>Pro změnu přezdívky musíte mít VIP roli</ModalTitle>
                    <ModalButtons>
                        <ModalButton onClick={closeAlertModal}>OK</ModalButton>
                    </ModalButtons>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={isAddRoleModalOpen}
                onRequestClose={closeAddRoleModal}
                contentLabel="Přidat roli"
                style={modalStyles}
            >
                <PridatRoli closeModal={closeAddRoleModal} />
            </Modal>
        </>
    );
}

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        padding: '20px',
        border: 'none',
        background: '#333',
        color: '#fff',
        zIndex: 1000,
    }
};

const Nav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 34px;
    background: ${props => props.scrolled ? '#090b13' : 'transparent'};
    z-index: 3;
    transition: background 0.3s ease;
`;

const Logo = styled.div`
    width: 90px;
    margin-top: 4px;
    max-height: 70px;
    display: inline-block;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }

          @media (max-width: 768px) {
               width: 25px;

                   img {
        display: block;
        width: 120%;
    }
}
}
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 30px;
    justify-content: flex-start;

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 0 15px;

        img {
            height: 22px;
            margin-bottom: 1px;
        }

        span {
            font-family: 'Noto Sans Lao', sans-serif;
            color: rgb(249, 249, 249);
            font-size: 13px;
            letter-spacing: 1.42px;
            line-height: 1.08;
            padding: 2px 5px;
            white-space: nowrap;
            position: relative;

            &:before {
                background-color: rgb(249, 249, 249);
                border-radius: 0px 0px 4px 4px;
                bottom: -6px;
                content: "";
                height: 2px;
                left: 0px;
                opacity: 0;
                position: absolute;
                right: 0px;
                transform-origin: left center;
                transform: scaleX(0);
                transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
                visibility: hidden;
                width: auto;
            }
        }

        &:hover {
            span:before {
                transform: scaleX(1);
                visibility: visible;
                opacity: 1 !important;
            }
        }
      @media (max-width: 768px) {
           padding: 0;
    span { 
      font-size: 0px;
     }
              img {
            height: 32px;
            margin-bottom: 1px;
        }
                &:hover {
            span:before {
                transform: scaleX(0);
                visibility: hidden;
                opacity: 0 !important;
            }
    }
`;

const AuthSection = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
`;

const UserSection = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

              @media (max-width: 768px) {
    font-size: 0px;
    margin-bottom -2px;
}
`;

const UserName = styled.div`
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    align-items: center;

          @media (max-width: 768px) {
    font-size: 0px;
}
`;

const RoleBadge = styled.div`
    background: ${props => props.color || '#444'};
    color: #fff;
    border-radius: 12px;
    font-weight: normal;
    padding: 2px 8px;
    font-size: 12px;
    margin-left: 8px;
`;

const LoginButton = styled.button`
    background-color: #4285f4;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #357ae8;
    }

    @media (max-width: 768px) {
        padding: 10px 8px;
          font-size: 12px;
          margin-bottom: -4px;
          margin-left 10px;
    }
}
`;

const Spinner = styled.div`
    color: #fff;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
`;

const ModalButtons = styled.div`
    display: flex;
    gap: 10px;
`;

const ModalButton = styled.button`
    background: #e50914;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background: #f40612;
    }
`;

export default Header;
